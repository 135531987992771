import { Link } from 'react-router-dom';
import { Tab, Tabs } from '@mui/material';

import { HeaderTabs } from '@/layouts/Header';
import { useMainContractDocument } from '@/shared/hooks/useMainContractDocument';
import { DorimPromoPaths, useMatchLinks } from '@/shared/lib/react-router';

export const LayoutTabs = () => {
  const [matchedLink] = useMatchLinks(
    DorimPromoPaths.MAIN_CONTRACT_DOCUMENT_PATH,
    DorimPromoPaths.MAIN_CONTRACT_SIGNATURE_PROTOCOL_PATH,
  );

  const {
    query: { data: mainAgreementDocument },
  } = useMainContractDocument();

  return (
    <HeaderTabs>
      {matchedLink?.pathname && (
        <Tabs value={matchedLink.pathname} textColor="secondary" indicatorColor="secondary">
          <Tab
            component={Link}
            to={DorimPromoPaths.MAIN_CONTRACT_DOCUMENT_PATH}
            value={DorimPromoPaths.MAIN_CONTRACT_DOCUMENT_PATH}
            label="Документ"
          />
          <Tab
            disabled={!mainAgreementDocument?.is_signed || !mainAgreementDocument.protocol_src}
            component={Link}
            to={DorimPromoPaths.MAIN_CONTRACT_SIGNATURE_PROTOCOL_PATH}
            value={DorimPromoPaths.MAIN_CONTRACT_SIGNATURE_PROTOCOL_PATH}
            label="Протокол подписания"
          />
        </Tabs>
      )}
    </HeaderTabs>
  );
};
