import { paramsToQueryKey } from '@/shared/lib/react-query';

import type { FetchCheckoutCloudCartParams, FetchCloudCartParams } from './cloud-carts.api';

const ROOT = 'carts';

type GetCloudCartKeyParams = Partial<FetchCloudCartParams>;
type GetCheckoutCloudCartKeyParams = Partial<FetchCheckoutCloudCartParams>;

export const CloudCartsQueryKeys = {
  getRootKey: () => [ROOT],
  getLatestCloudCartUpdateKey: () => [ROOT, 'latest-cloud-cart-update'],
  getCloudCartKey: (params: GetCloudCartKeyParams) => paramsToQueryKey([ROOT, 'cloud-cart'], params),
  getCheckoutCloudCartKey: (params: GetCheckoutCloudCartKeyParams) =>
    paramsToQueryKey([ROOT, 'checkout-cloud-cart'], params),
};
