import { RHFCheckbox } from '@/components/form-controls';
import { OFFERS_LINK, PROCESSING_OF_PERSONAL_DATA_LINK } from '@/shared/constants/dorim.constants';

import { StyledLabel, StyledLink } from './styles';
import type { AgreementFieldProps } from './types';

export const AgreementField = ({ name }: AgreementFieldProps) => {
  return (
    <RHFCheckbox
      name={name}
      label={
        <StyledLabel>
          Я согласен(а) с условиями{' '}
          <StyledLink color="inherit" href={OFFERS_LINK} target="_blank">
            Договора Публичной Оферты
          </StyledLink>
          ,{' '}
          <StyledLink color="inherit" href={PROCESSING_OF_PERSONAL_DATA_LINK} target="_blank">
            обработкой моих персональных данных и использованием файлов cookie.
          </StyledLink>
        </StyledLabel>
      }
      CheckboxProps={{
        color: 'secondary',
      }}
    />
  );
};
