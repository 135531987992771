import { Fragment } from 'react';
import { Card, Divider, Stack, styled, Typography, type CardProps, type StackProps } from '@mui/material';
import type { Merge } from 'type-fest';

import { DocumentToolbarButton, type DocumentToolbarButtonProps } from './DocumentToolbarButton';

type SlotRootProps = Omit<Merge<CardProps, StackProps>, 'component'>;

export type DocumentToolbarProps = React.PropsWithChildren<
  {
    title: React.ReactNode;
    documentNumber?: React.ReactNode;
    footer?: React.ReactNode;
  } & (
    | {
        button?: never;
        slotProps?: {
          root?: SlotRootProps;
          button?: Omit<DocumentToolbarButtonProps, 'component'>;
        };
      }
    | {
        button?: React.ReactNode;
        slotProps?: {
          root?: SlotRootProps;
          button?: never;
        };
      }
  )
>;

const StyledRoot = styled(Card)({
  width: '100%',
  flexShrink: 0,
}) as typeof Card;

export const DocumentToolbar = (props: DocumentToolbarProps) => {
  const {
    title,
    documentNumber,
    slotProps,
    children,
    button = <DocumentToolbarButton {...slotProps?.button} />,
    footer,
  } = props;

  return (
    <StyledRoot component={Stack} variant="outlined" sx={{ width: '100%' }} {...slotProps?.root}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ width: '100%', minHeight: '72px', flexShrink: 0, p: 2, pl: 3 }}
      >
        <Stack direction="row" alignItems="baseline" spacing={2}>
          {title && (
            <Typography component="div" variant="Headline/Bold/small">
              {title}
            </Typography>
          )}
          {documentNumber && (
            <Typography component={Stack} direction="row" variant="Body/medium">
              №{documentNumber}
            </Typography>
          )}
          {children}
        </Stack>
        {button}
      </Stack>

      {footer && (
        <Fragment>
          <Divider />
          {footer}
        </Fragment>
      )}
    </StyledRoot>
  );
};
