import { useQueryClient } from '@tanstack/react-query';
import { useUnmount } from 'react-use';

import { AutoSelectionQueryKeys } from '@/api/domains/auto-selection-query.keys';

import { resetNecessityFileStore } from '../necessity-file';

export const useResetAutoSelectionOnUnmount = () => {
  const queryClient = useQueryClient();

  useUnmount(() => {
    resetNecessityFileStore(['isImportFileModalOpen']);
    queryClient.removeQueries({ queryKey: AutoSelectionQueryKeys.getRootKey() });
  });
};
