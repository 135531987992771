import { useQuery } from '@tanstack/react-query';

import { InfoApi, type FetchInfoResponse, type InfoParams } from '@/api/domains/info.api';
import { InfoQueryKeys } from '@/api/domains/info.query-keys';
import type { Info } from '@/api/domains/info.types';

// ----------------------------------------------------------------------

const defaultItems: Info[] = [];

type UseInfoOptions<TData> = {
  isEnabled?: boolean;
  select?: (info: FetchInfoResponse | undefined) => TData | undefined;
};

export const useInfo = <TData = FetchInfoResponse | undefined>(params: InfoParams, options?: UseInfoOptions<TData>) => {
  const infoQuery = useQuery({
    queryKey: InfoQueryKeys.info(params),
    queryFn: () => InfoApi.fetchInfo(params),
    keepPreviousData: true,
    staleTime: Infinity,
    select: options?.select,
    enabled: options?.isEnabled,
  });

  const { isLoading, data } = infoQuery;

  const info = (data as FetchInfoResponse)?.items ?? defaultItems;

  return { infoQuery, isLoading, info };
};
