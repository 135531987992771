export type CloudCartEntrySource =
  | typeof CLOUD_CART_ENTRY_SOURCE_MANUAL
  | typeof CLOUD_CART_ENTRY_SOURCE_PROMO
  | typeof CLOUD_CART_ENTRY_SOURCE_AUTO_SELECTION;

export const CLOUD_CART_ENTRY_SOURCE_MANUAL = 'manual';
export const CLOUD_CART_ENTRY_SOURCE_PROMO = 'promo';
export const CLOUD_CART_ENTRY_SOURCE_AUTO_SELECTION = 'auto_selection';

export const CloudCartEntrySources = [
  CLOUD_CART_ENTRY_SOURCE_MANUAL,
  CLOUD_CART_ENTRY_SOURCE_PROMO,
  CLOUD_CART_ENTRY_SOURCE_AUTO_SELECTION,
] as const;

export const NamesByCloudCartEntrySource = {
  [CLOUD_CART_ENTRY_SOURCE_MANUAL]: 'Ручной подбор',
  [CLOUD_CART_ENTRY_SOURCE_PROMO]: 'Все акции',
  [CLOUD_CART_ENTRY_SOURCE_AUTO_SELECTION]: 'Автоподбор',
} as const;
