import { Fragment, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { Card, Collapse, styled, Typography } from '@mui/material';

import type { SpecificationBase } from '@/api/domains/dorim-promo.types';

import { TotalAmount } from '../../../../ui';
import { getActionBarState, hasActionBar } from '../../lib';
import { useResetFloatingPanelStoreOnUnmount, useShouldFloatingPanelPanelBeVisible } from './lib';

const PANEL_HEIGHT = 72;

const StyledRoot = styled('div', {
  shouldForwardProp: prop => prop !== 'isFloating' && prop !== 'hasActionBar',
})<{
  isFloating: boolean;
  hasActionBar: boolean;
}>(
  {
    height: PANEL_HEIGHT,
    display: 'flex',
    alignItems: 'flex-end',
  },
  ({ isFloating, hasActionBar, theme }) =>
    isFloating && {
      position: 'sticky',
      bottom: hasActionBar ? `calc(${theme.dimensions.summaryBar.height}px + ${theme.spacing(1)})` : theme.spacing(1),
      zIndex: theme.zIndex.appBar,
    },
);

const StyledCard = styled(Card)(({ theme }) => ({
  height: PANEL_HEIGHT,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(0, 3),
  borderRadius: 16,
}));

type TotalAmountFloatingPanelProps = {
  agreement: SpecificationBase;
};

export const FloatingPanel = (props: TotalAmountFloatingPanelProps) => {
  const { agreement } = props;
  const { cashback_sum } = agreement;

  const shouldBeVisible = useShouldFloatingPanelPanelBeVisible();
  const [isFloatingState, setIsFloatingState] = useState(false);

  useResetFloatingPanelStoreOnUnmount();

  useEffect(() => {
    if (shouldBeVisible) {
      setIsFloatingState(true);
    }
  }, [shouldBeVisible]);

  const { ref, inView } = useInView({
    initialInView: true,
  });

  const isFloating = isFloatingState || shouldBeVisible;
  const isExpanded = inView || shouldBeVisible;

  return (
    <Fragment>
      <div ref={ref} style={{ margin: 0 }} />
      <StyledRoot isFloating={isFloating} hasActionBar={hasActionBar(getActionBarState(agreement))}>
        <Collapse in={isExpanded} sx={{ flexGrow: 1 }} onExited={() => setIsFloatingState(false)}>
          <StyledCard variant="outlined">
            <Typography component="p" variant="Title/Bold/large">
              Выплата по условиям договора
            </Typography>
            <TotalAmount paymentSum={cashback_sum} />
          </StyledCard>
        </Collapse>
      </StyledRoot>
    </Fragment>
  );
};
