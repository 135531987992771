import { useEffect } from 'react';
import type { TypeComputedProps } from '@inovua/reactdatagrid-community/types';
import { useUnmount, useUpdateEffect } from 'react-use';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

import type { DataGridInstanceId } from './types';

export type DataGridStore = {
  instances: Record<DataGridInstanceId, TypeComputedProps | undefined>;

  setInstance: (instanceId: DataGridInstanceId, instance: TypeComputedProps) => void;
  removeInstance: (instanceId: DataGridInstanceId) => void;
};

export const useDataGridStore = createWithEqualityFn<DataGridStore>(set => ({
  instances: {} as DataGridStore['instances'],

  setInstance: (instanceId: DataGridInstanceId, instance: TypeComputedProps) => {
    set(({ instances }) => ({
      instances: {
        ...instances,
        [instanceId]: instance,
      },
    }));
  },
  removeInstance: (instanceId: DataGridInstanceId) => {
    set(({ instances }) => {
      const newInstances = { ...instances };

      delete newInstances[instanceId];

      return { instances: newInstances };
    });
  },
}));

export const useDataGridInstanceHandlers = (instanceId: DataGridInstanceId | undefined) => {
  const { setInstance, removeInstance } = useDataGridStore(
    state => ({
      setInstance: state.setInstance,
      removeInstance: state.removeInstance,
    }),
    shallow,
  );

  const handleSetInstance = (instanceRef: React.MutableRefObject<TypeComputedProps | null>) => {
    const instance = instanceRef.current;

    if (instanceId && instance) {
      setInstance(instanceId, instance);
    }
  };

  useUnmount(() => {
    if (instanceId) {
      removeInstance(instanceId);
    }
  });

  return handleSetInstance;
};

export const useDataGridInstance = (instanceId: DataGridInstanceId | undefined) => {
  const instance = useDataGridStore(state => (instanceId ? state.instances[instanceId] : undefined));

  return { instance };
};

export const useResetDataGridFilters = (instanceId: DataGridInstanceId | undefined, deps: React.DependencyList) => {
  const { instance } = useDataGridInstance(instanceId);

  useUpdateEffect(() => {
    instance?.clearAllFilters?.();
  }, deps);
};

export const useDataGridFirstRowFocus = (
  instanceId: DataGridInstanceId | undefined,
  deps: React.DependencyList,
  condition: boolean = true,
) => {
  const { instance } = useDataGridInstance(instanceId);

  useEffect(() => {
    if (condition) {
      const timeout = setTimeout(() => instance?.focus(), 500);

      return () => {
        clearTimeout(timeout);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [condition, instance, ...deps]);
};

export const useDeselectDataGridRows = (instanceId: DataGridInstanceId | undefined, deps: React.DependencyList) => {
  const { instance } = useDataGridInstance(instanceId);

  useUpdateEffect(() => {
    instance?.deselectAll();
  }, deps);

  return { deselectAllRows: () => instance?.deselectAll() };
};
