import { ProductSelectionPaths, useMatchLinks } from '@/shared/lib/react-router';

export const useProductSelectionLayoutTabs = () => {
  const [matchedLink] = useMatchLinks(
    ProductSelectionPaths.MANUAL_SELECTION_PATH,
    ProductSelectionPaths.SPECIAL_OFFERS_SELECTION_PATH,
    ProductSelectionPaths.AUTO_SELECTION_PATH,
    ProductSelectionPaths.BOUND_DRUGS_PATH,
  );

  const manualTabValue = ProductSelectionPaths.MANUAL_SELECTION_PATH;
  const specialOffersTabValue = ProductSelectionPaths.SPECIAL_OFFERS_SELECTION_PATH;
  const autoTabValue = ProductSelectionPaths.AUTO_SELECTION_PATH;
  const boundDrugsValue = ProductSelectionPaths.BOUND_DRUGS_PATH;

  const currentTab = matchedLink?.pathname ?? manualTabValue;
  const isManualTab = currentTab === manualTabValue;
  const isSpecialOffersTab = currentTab === specialOffersTabValue;
  const isAutoTab = currentTab === autoTabValue;

  return {
    isManualTab,
    isSpecialOffersTab,
    isAutoTab,
    currentTab,
    manualTabValue,
    specialOffersTabValue,
    autoTabValue,
    boundDrugsValue,
  };
};
