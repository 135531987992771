import { generatePath as routerGeneratePath } from 'react-router-dom';

import type { GeneratePathResult } from './path.types';

export const joinPath = <THead extends string, TTail extends string>(head: THead, tail: TTail) =>
  `${head}/${tail}`.replace(/(\/){2,}/g, '/') as `${THead}/${TTail}`;

export const generatePath = <TPath extends string, TParams extends Record<string, string>>(
  path: TPath,
  params?: TParams,
) => routerGeneratePath(path, params) as GeneratePathResult<TPath, TParams>;
