import { styled } from '@mui/material/styles';

import { Navbar } from '@/layouts/Navbar';

const StyledRoot = styled('div')(({ theme }) => ({
  alignSelf: 'stretch',
  display: 'flex',
  minHeight: '100vh',
  width: '100%',
  minWidth: 1200,
  backgroundColor: theme.palette.neutral['200'],
}));

const StyledContainer = styled('main')(({ theme }) => ({
  width: `calc(100% - ${theme.dimensions.sidebar.width}px)`,
  minHeight: '100%',
  marginLeft: theme.dimensions.sidebar.width,
}));

export type BaseLayoutProps = React.PropsWithChildren<{}>;

export const BaseLayout = (props: BaseLayoutProps) => {
  const { children } = props;

  return (
    <StyledRoot>
      <Navbar />
      <StyledContainer>{children}</StyledContainer>
    </StyledRoot>
  );
};
