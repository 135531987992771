import { Box, Typography, type BoxProps, type TypographyProps } from '@mui/material';

import { AnimatedNumbers } from '@/components/AnimatedNumbers';
import { formatNumberByThousands } from '@/shared/helpers/format.helpers';

export type SummaryBarEntryProps = BoxProps & {
  name: string;
  value: React.ReactNode;
  color?: TypographyProps['color'];
  minWidth?: TypographyProps['minWidth'];
};

export const SummaryBarEntry = (props: SummaryBarEntryProps) => (
  <Box sx={{ minWidth: props.minWidth ?? '96px' }} {...props}>
    <Typography component="div" variant="Body/small" color="neutral.700">
      {props.name}
    </Typography>
    <Typography component="div" variant="Body/Bold/large" color={props.color} lineHeight={1}>
      {props.value}
    </Typography>
  </Box>
);

export type AnimatedSummaryEntryProps = Omit<SummaryBarEntryProps, 'value'> & {
  value: number;
};

export const AnimatedSummaryEntry = (props: AnimatedSummaryEntryProps) => (
  <SummaryBarEntry {...props} value={<AnimatedNumbers value={props.value} format={formatNumberByThousands} />} />
);
