import { Stack, type StackProps } from '@mui/material';

import type { CloudCartSummery } from '@/entities/cloud-carts';

import { SummaryBarEmpty, SummaryBarLoading } from './components/summary-bar.elements';
import { SummaryBarContainer } from './components/SummaryBarContainer';
import { SummaryBarContent } from './components/SummaryBarContent';

export type SummaryBarBaseProps = StackProps & {
  isLoading: boolean;
  isFetching: boolean;
  isEmpty: boolean;
  summery: CloudCartSummery | undefined;
  emptyCartActions?: React.ReactNode;
  slotProps?: {
    stack?: StackProps;
  };
};

export const SummaryBarBase = (props: SummaryBarBaseProps) => {
  const { isLoading, isFetching, isEmpty, summery, emptyCartActions, slotProps, ...restProps } = props;

  if (isLoading) {
    return <SummaryBarLoading />;
  }

  if (isEmpty || !summery) {
    return <SummaryBarEmpty>{emptyCartActions}</SummaryBarEmpty>;
  }

  return (
    <SummaryBarContainer justifyContent="space-between" {...restProps}>
      <SummaryBarContent isLoading={isFetching} summery={summery} />
      <Stack direction="row" spacing={1} alignItems="center" {...slotProps?.stack}>
        {props.children}
      </Stack>
    </SummaryBarContainer>
  );
};
