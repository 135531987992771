import { DorimPromoPaths, type RouteObjectWithBreadcrumbs } from '@/shared/lib/react-router';

export const PlanogramTaskPageRoute: RouteObjectWithBreadcrumbs = {
  path: DorimPromoPaths.SPECIFICATION_DETAIL_PLANOGRAM_TASK_PATH,
  index: true,
  lazy: async () => {
    const { PlanogramTaskPage } = await import(
      /* webpackChunkName: "PlanogramTaskPage" */
      './PlanogramTaskPage'
    );

    return {
      Component: PlanogramTaskPage,
    };
  },
};
