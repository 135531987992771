import { Fragment } from 'react';
import { Stack, styled, Tooltip, Typography } from '@mui/material';

import type { SpecificationDrug } from '@/api/domains/dorim-promo.types';
import { CustomTypography } from '@/components/CustomTypography';
import { ReactComponent as InfoSVG } from '@/shared/assets/icons-2.0/info.svg';
import { formatDrugName } from '@/shared/helpers/format.helpers';

type DistributorsTooltipProps = {
  distributors: NonNullable<SpecificationDrug['distributors']>;
};

const DistributorsTooltip = (props: DistributorsTooltipProps) => {
  const { distributors } = props;

  return (
    <Fragment>
      <Typography variant="Body/small" color="neutral.500">
        Дистрибьюторы
      </Typography>
      {distributors.map(distributor => (
        <Typography key={distributor.id} component="p" variant="Body/small">
          <Typography component="span" variant="inherit" color="neutral.100">
            {distributor.name}
          </Typography>
          {distributor.city_name && (
            <Typography component="span" variant="inherit" color="neutral.500">
              (г. {distributor.city_name})
            </Typography>
          )}
        </Typography>
      ))}
    </Fragment>
  );
};

type AgreementDrugInfoProps = {
  name: string;
  number: number;
  form: string;
  distributors: NonNullable<SpecificationDrug['distributors']>;
};

const StyledInfoSVG = styled(InfoSVG)(({ theme }) => ({
  color: theme.palette.neutral[700],
}));

export const DrugInfo = (props: AgreementDrugInfoProps) => {
  const { name, number, form, distributors } = props;

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Tooltip
        arrow
        placement="right"
        title={<DistributorsTooltip distributors={distributors} />}
        slotProps={{
          popper: {
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, -5],
                },
              },
            ],
          },
        }}
      >
        <span style={{ display: 'inherit' }}>
          <StyledInfoSVG />
        </span>
      </Tooltip>

      <CustomTypography noWrap lineClamp={2} variant="Body/Bold/large" flexWrap="wrap">
        {formatDrugName(name, form, number)}
      </CustomTypography>
    </Stack>
  );
};
