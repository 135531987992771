import { Tab, Tabs } from '@mui/material';

import { useSpecification } from '@/shared/hooks/useSpecification';
import { DorimPromoPaths, RouterLink, useMatchLinks } from '@/shared/lib/react-router';

import { useSpecificationIdFromPathParams } from '../../../../../lib';

export const ToolbarTabs = () => {
  const specificationId = useSpecificationIdFromPathParams();
  const stringifiedSpecificationId = String(specificationId);
  const { data } = useSpecification({ specificationId });
  const { has_display_tasks, has_purchase_drugs, has_sale_drugs } = data?.agreement ?? {};

  const procurementPlanPath = DorimPromoPaths.generateSpecificationDetailProcurementPlanPath({
    specificationId: stringifiedSpecificationId,
  });
  const planogramPath = DorimPromoPaths.generateSpecificationDetailPlanogramPath({
    specificationId: stringifiedSpecificationId,
  });
  const salesPlanPath = DorimPromoPaths.generateSpecificationDetailSalesPlanPath({
    specificationId: stringifiedSpecificationId,
  });

  const [matchedLink] = useMatchLinks(procurementPlanPath, planogramPath, salesPlanPath);

  if (!specificationId || !matchedLink?.pathname || !(has_display_tasks || has_purchase_drugs || has_sale_drugs)) {
    return null;
  }

  return (
    <Tabs value={matchedLink.pathname} sx={{ pl: 1 }} textColor="primary" indicatorColor="primary">
      {has_purchase_drugs && (
        <Tab component={RouterLink} to={procurementPlanPath} value={procurementPlanPath} replace label="План закупки" />
      )}
      {has_display_tasks && (
        <Tab component={RouterLink} to={planogramPath} value={planogramPath} replace label="План выкладки" />
      )}
      {has_sale_drugs && (
        <Tab component={RouterLink} to={salesPlanPath} value={salesPlanPath} replace label="План продажи" />
      )}
    </Tabs>
  );
};
