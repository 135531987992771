import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

export type BanState = {
  expiredAt: number;
};

export type BanActions = {
  setExpiredAt: (expiredAt: number) => void;
  resetBan: () => void;
};

export type BanSlice = BanState & BanActions;

const initialState: BanState = {
  expiredAt: 0,
};

const storeName = 'dorim-price:signup-ban-store';
const useBanStore = create<BanSlice>()(
  devtools(
    persist(
      set => ({
        // State
        ...initialState,

        // Actions
        setExpiredAt: expiredAt => set({ expiredAt }, false, 'setExpiredAt'),
        resetBan: () => set(initialState, false, 'resetBan'),
      }),
      {
        name: storeName,
      },
    ),
    {
      name: storeName,
    },
  ),
);

// Selectors for state
const selectState = (state: BanSlice) => ({
  expiredAt: state.expiredAt,
});

// Selectors for actions
const selectActions = (state: BanSlice) => ({
  setExpiredAt: state.setExpiredAt,
  resetBan: state.resetBan,
});

export const useBanActions = () => useBanStore(selectActions);
export const useBanState = () => useBanStore(selectState);
