import { type RouteObjectWithBreadcrumbs } from '@/shared/lib/react-router';

export const MarketingOrgPageRoute: RouteObjectWithBreadcrumbs = {
  index: true,
  lazy: async () => {
    const { MarketingOrgPage } = await import(
      /* webpackChunkName: "MarketingOrgPage" */
      './MarketingOrgPage'
    );

    return {
      Component: MarketingOrgPage,
    };
  },
};
