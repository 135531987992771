import { useMutation } from '@tanstack/react-query';

import type { SendOTPFormData, SendOTPResponseBody } from '@/api/domains/auth.types';

type UseSendOTPParams = {
  onSuccess?: (data: SendOTPResponseBody) => void;
  onError?: (error: unknown) => void;
  onMutate?: () => void;
  sendOtpApiMethod(requestData: SendOTPFormData): Promise<SendOTPResponseBody>;
};

export const useSendOTP = ({ onSuccess, onError, onMutate, sendOtpApiMethod }: UseSendOTPParams) => {
  const { mutateAsync: sendOTP } = useMutation({
    mutationFn: (requestData: SendOTPFormData) => {
      return sendOtpApiMethod(requestData);
    },
    onSuccess(data) {
      onSuccess?.(data);
    },
    onError(error) {
      onError?.(error);
    },
    onMutate: () => {
      onMutate?.();
    },
  });

  return sendOTP;
};
