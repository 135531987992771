export const paramsToQueryKey = <T extends Record<string, any>>(baseKey: unknown[], params?: T) => {
  if (params) {
    for (const key in params) {
      if (params[key] !== null && params[key] !== undefined) {
        if (typeof params[key] === 'object' && !Array.isArray(params[key])) {
          baseKey.push(key);
          paramsToQueryKey(baseKey, params[key]);
          continue;
        }

        baseKey.push({ [key]: params[key].toString() });
      }
    }
  }

  return baseKey;
};
