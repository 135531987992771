import { Outlet } from 'react-router';
import { Navigate } from 'react-router-dom';

import { DorimPromoPaths, type RouteObjectWithBreadcrumbs } from '@/shared/lib/react-router';

import { SignedMainContractGuard } from '../../../guards';
import { ViewPageRoute } from './detail';
import { NoticePageRoute } from './notice';

export const SpecificationContractPageRoute: RouteObjectWithBreadcrumbs = {
  path: DorimPromoPaths.SPECIFICATION_DETAIL_CONTRACT_ROOT_PATH,
  element: (
    <SignedMainContractGuard>
      <Outlet />
    </SignedMainContractGuard>
  ),
  children: [
    {
      index: true,
      element: <Navigate to={DorimPromoPaths.ROOT_PATH} />,
    },
    NoticePageRoute,
    ViewPageRoute,
  ],
};
