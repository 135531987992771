import type { To } from 'react-router-dom';

import { useMatchLink } from './useMatchLink';

// ----------------------------------------------------------------------

export const useActiveRoute = (link: To) => {
  const match = useMatchLink(link);

  return Boolean(match);
};
