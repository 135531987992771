import { useMemo, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useEffectOnce, useGetSet } from 'react-use';

import { AuthApi, type SignInFormData } from '@/api/domains/auth.api';
import type {
  ResetPasswordFormData,
  SendOTPFormData,
  SignInTokens,
  SignUpFormData,
  VerifyOTPFormData,
} from '@/api/domains/auth.types';
import {
  AuthContextMethods,
  AuthContextState,
  tokensStorage,
  type AuthMethods,
  type AuthState,
  type TokenPair,
  type TokenPairStorageItem,
} from '@/shared/lib/auth';
import { applyJwtInterceptors, httpClient } from '@/shared/lib/axios';
import { isAccessToken, isAccessTokenExpired } from '@/shared/lib/jwt';

export type AuthProviderProps = React.PropsWithChildren;

export const AuthProvider = (props: AuthProviderProps) => {
  const { children } = props;

  const [isInitialized, setIsInitialized] = useState(false);
  const [getTokenPair, setTokenPair] = useGetSet<TokenPairStorageItem>(tokensStorage.getTokenPair);

  const queryClient = useQueryClient();
  const methods = useMemo(() => {
    const updateTokens = (tokenPair: TokenPair) => {
      const { access_token } = tokenPair;

      try {
        if (isAccessToken(access_token)) {
          setTokenPair(tokenPair);
          tokensStorage.setTokenPair(tokenPair);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const removeToken = () => {
      tokensStorage.removeTokenPair();
      setTokenPair(tokensStorage.INITIAL_TOKEN_PAIR);
    };

    const resetToUnauthenticated = () => {
      removeToken();
      queryClient.clear();
    };

    const signIn = async (formData: SignInFormData) => {
      const { access_token, refresh_token } = await AuthApi.signIn(formData);

      updateTokens({ access_token, refresh_token });
    };

    const signUp = async (formData: SignUpFormData) => {
      const { access_token, refresh_token } = await AuthApi.signUp(formData);

      return {
        access_token,
        refresh_token,
      };
    };

    const updateSignInTokens = ({ access_token, refresh_token }: SignInTokens): void => {
      updateTokens({ access_token, refresh_token });
    };

    const signOut = async () => {
      const { refresh_token } = getTokenPair();

      try {
        if (refresh_token) {
          await AuthApi.signOut(refresh_token);
        }
      } finally {
        resetToUnauthenticated();
      }
    };

    const refreshToken = async () => {
      const { refresh_token } = getTokenPair();

      if (refresh_token) {
        const { access_token, refresh_token: updateRefreshToken } = await AuthApi.refreshToken(refresh_token);

        updateTokens({ access_token, refresh_token: updateRefreshToken });
      }
    };

    const getAuthenticatedState = (): boolean => {
      const { access_token } = getTokenPair();

      return isAccessToken(access_token);
    };

    const initialize = async () => {
      const { access_token } = getTokenPair();

      if (isAccessToken(access_token)) {
        if (isAccessTokenExpired(access_token)) {
          try {
            await refreshToken();
          } catch (error) {
            console.error(error);
          }
        }

        setIsInitialized(true);
      } else {
        resetToUnauthenticated();
        setIsInitialized(true);
      }
    };

    const signUpSendOTP = (formData: SendOTPFormData) => AuthApi.signUpSendOTP(formData);
    const signUpVerifyOTP = (formData: VerifyOTPFormData) => AuthApi.signUpVerifyOTP(formData);
    const signUpCheckIpLimits = () => AuthApi.signUpCheckIpLimits();

    const resetPasswordSendOTP = (formData: SendOTPFormData) => AuthApi.resetPasswordSendOTP(formData);
    const resetPasswordVerifyOTP = (formData: VerifyOTPFormData) => AuthApi.resetPasswordVerifyOTP(formData);
    const resetPassword = (formData: ResetPasswordFormData) => AuthApi.resetPassword(formData);
    const resetPasswordCheckIpLimits = () => AuthApi.resetPasswordCheckIpLimits();

    return {
      signIn,
      signOut,
      signUp,
      updateSignInTokens,
      refreshToken,
      signUpSendOTP,
      signUpVerifyOTP,
      signUpCheckIpLimits,
      getTokenPair,
      resetToUnauthenticated,
      getAuthenticatedState,
      initialize,
      resetPasswordSendOTP,
      resetPasswordVerifyOTP,
      resetPassword,
      resetPasswordCheckIpLimits,
    };
  }, [queryClient, setTokenPair, getTokenPair, setIsInitialized]);

  useEffectOnce(() => {
    applyJwtInterceptors(httpClient, {
      getAuthenticatedState: methods.getAuthenticatedState,
      getAccessToken: () => methods.getTokenPair().access_token,
      tokenRefresher: methods.refreshToken,
      onRefreshError: methods.resetToUnauthenticated,
    });

    methods.initialize();
  });

  if (!isInitialized) {
    return null;
  }

  const state: AuthState = {
    isAuthenticated: methods.getAuthenticatedState(),
    accessToken: methods.getTokenPair().access_token,
  };

  return (
    <AuthContextMethods.Provider value={methods as AuthMethods}>
      <AuthContextState.Provider value={state}>{children}</AuthContextState.Provider>
    </AuthContextMethods.Provider>
  );
};
