import { useQuery } from '@tanstack/react-query';

import { ContractorsApi, type FetchDistributorInfoParams } from '@/api/domains/contractors.api';
import { ContractorsQueryKeys } from '@/api/domains/contractors.query-keys';

export const useDistributorInfo = (params: Partial<FetchDistributorInfoParams>) =>
  useQuery({
    queryKey: ContractorsQueryKeys.getDistributorInfoKey(params),
    queryFn: () => {
      if (!params.distributor_id) {
        return;
      }

      return ContractorsApi.fetchDistributorInfo({ distributor_id: params.distributor_id });
    },
    staleTime: Infinity,
    enabled: !!params.distributor_id,
  });
