import { useEffect } from 'react';

import { useNavigateConfirmationStore } from '@/components/dialogs/NavigateConfirmationDialog/useNavigateConfirmationStore';

// ----------------------------------------------------------------------

export const useNavigationPrompt = (message: string, when: boolean) => {
  const { block, unblock } = useNavigateConfirmationStore();

  useEffect(() => {
    if (when) {
      block(message);
    } else {
      unblock();
    }
  }, [message, when, block, unblock]);
};
