import { CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledRoot = styled('div')({
  display: 'flex',
  height: '100%',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
});

export const LoadingIndicator = () => (
  <StyledRoot>
    <CircularProgress disableShrink color="secondary" />
  </StyledRoot>
);
