import { Card, Stack, styled, type CardProps, type StackProps } from '@mui/material';
import type { Merge } from 'type-fest';

const StyledContentRoot = styled(Card)({
  flexGrow: 1,
  flexShrink: 0,
  borderTop: 'none',
  overflow: 'visible',
}) as typeof Card;

export type ContentCardProps = Merge<CardProps, StackProps>;

export const ContentCard = (props: ContentCardProps) => (
  <StyledContentRoot component={Stack} variant="outlined" {...props} />
);
