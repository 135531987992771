import type { SignUpFormData } from '@/api/domains/auth.types';
import { PHYSIC_ADDRESS } from '@/pages/auth/sign-up/constants/registration.constants';
import type { SignUpCompany, SignUpPharmacy } from '@/pages/auth/sign-up/store/signUp.store';

import { INTERNAL_ERROR_MESSAGE } from '../data/warningMessage';

type RequestBody = {
  signupToken: string;
  password: string;
  firstName: string;
  lastName: string;
  companyData: SignUpCompany;
  pharmacyData: SignUpPharmacy;
};

export const getRequestBody = (params: RequestBody): SignUpFormData => {
  const { firstName, lastName, companyData, pharmacyData, signupToken, password } = params;

  if (
    !companyData.legalStatus ||
    !companyData.region ||
    !companyData.city ||
    !companyData.street ||
    !companyData.streetType ||
    !companyData.tin ||
    !pharmacyData.region ||
    !pharmacyData.city ||
    !pharmacyData.street ||
    !pharmacyData.streetType
  ) {
    throw new Error(INTERNAL_ERROR_MESSAGE);
  }

  const pharmacy: SignUpFormData['pharmacy'] = {
    name: pharmacyData.name,
    legal_status_id: companyData.legalStatus.id,
    country_id: Number(pharmacyData.region.country_id),
    country_name: pharmacyData.region.country?.name ?? '',
    address: {
      contractor_id: 0,
      region_id: pharmacyData.region.id,
      city_id: pharmacyData.city.id,
      address_type_id: PHYSIC_ADDRESS.id,
      street: pharmacyData.street,
      street_type_id: pharmacyData.streetType.id,
      house: pharmacyData.house,
      city_district_id: pharmacyData.cityDistrict?.id,
      building: pharmacyData.building,
      apartment: pharmacyData.apartment,
      postal_code: String(pharmacyData.postalCode),
      is_default: true,
    },
    description: '',
    tin: companyData.tin,
    phone: pharmacyData.phoneNumber?.toString(),
  };

  const contractor: SignUpFormData['contractor'] = {
    name: companyData.companyName,
    legal_status_id: companyData.legalStatus.id,
    country_id: Number(companyData.region.country_id),
    country_name: companyData.region.country?.name ?? '',
    address: {
      contractor_id: 0,
      region_id: companyData.region.id,
      city_id: companyData.city.id,
      address_type_id: PHYSIC_ADDRESS.id,
      street: companyData.street,
      street_type_id: companyData.streetType.id,
      house: companyData.house,
      city_district_id: companyData.cityDistrict?.id,
      building: companyData.building,
      apartment: companyData.apartment,
      postal_code: String(companyData.postalCode),
      is_default: true,
    },
    description: '',
    tin: companyData.tin,
    phone: companyData.phoneNumber?.toString(),
  };

  const requestBody = {
    signup_token: signupToken,
    password,
    first_name: firstName,
    last_name: lastName,
    contractor,
    pharmacy,
  };

  return requestBody;
};
