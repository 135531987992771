import { Navigate, type NavigateProps } from 'react-router-dom';

export type RedirectWithoutAccessProps = React.PropsWithChildren<
  NavigateProps & {
    hasAccess: boolean;
  }
>;

export const RedirectWithoutAccess = (props: RedirectWithoutAccessProps) => {
  const { hasAccess, children, ...restProps } = props;

  if (!hasAccess) {
    return <Navigate {...restProps} />;
  }

  return children;
};
