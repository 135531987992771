import type { StackProps } from '@mui/material';
import { Stack, Typography } from '@mui/material';
import type { Merge } from 'type-fest';

import type { ValidationRuleStatus } from '@/modules/account/PasswordStrengthValidation/types';
import { ValidationRuleIndicator } from '@/modules/account/PasswordStrengthValidation/ValidationRuleIndicator';

export type ValidationRuleProps = Merge<
  StackProps,
  {
    order: number;
    label: string;
    status: ValidationRuleStatus;
  }
>;

export const ValidationRule = (props: ValidationRuleProps) => {
  const { order, label, status, ...restOfProps } = props;

  return (
    <Stack direction="row" spacing={1} alignItems="center" {...restOfProps}>
      <ValidationRuleIndicator order={order} status={status} />
      <Typography variant="Body/medium" color="neutral.700">
        {label}
      </Typography>
    </Stack>
  );
};
