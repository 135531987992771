import { DorimPromoPaths, type RouteObjectWithBreadcrumbs } from '@/shared/lib/react-router';

import { SignedMainContractGuard } from '../../../../../guards';

const BaseRoute: RouteObjectWithBreadcrumbs = {
  lazy: async () => {
    const { SignatureProtocolPage } = await import(
      /* webpackChunkName: "SignatureProtocolPage" */
      './SignatureProtocolPage'
    );

    return {
      Component: () => (
        <SignedMainContractGuard>
          <SignatureProtocolPage />
        </SignedMainContractGuard>
      ),
    };
  },
};

export const SpecificationDetailMainContractSignatureProtocolPageRoute: RouteObjectWithBreadcrumbs = {
  ...BaseRoute,
  path: DorimPromoPaths.SPECIFICATION_DETAIL_MAIN_CONTRACT_SIGNATURE_PROTOCOL_PATH,
};

export const MainContractSignatureProtocolPageRoute: RouteObjectWithBreadcrumbs = {
  ...BaseRoute,
  path: DorimPromoPaths.MAIN_CONTRACT_SIGNATURE_PROTOCOL_PATH,
};
