import { useQuery } from '@tanstack/react-query';

import { DorimPromoApi, type FetchMarketingOrgParams, type MarketingOrgResponse } from '@/api/domains/dorim-promo.api';
import { DorimPromoQueryKeys } from '@/api/domains/dorim-promo.query-keys';

type UseMarketingOrgParams<TData> = FetchMarketingOrgParams & {
  select?: (marketingOrg: MarketingOrgResponse | undefined) => TData | undefined;
};

export const useMarketingOrg = <TData = MarketingOrgResponse | undefined>({
  specificationId,
  marketingOrgId,
  select,
}: UseMarketingOrgParams<TData>) =>
  useQuery({
    queryKey: DorimPromoQueryKeys.getMarketingOrgKey({ specificationId, marketingOrgId }),
    queryFn: () => DorimPromoApi.fetchMarketingOrg({ specificationId, marketingOrgId }),
    select,
    staleTime: 1000 * 60 * 5,
  });
