import { PasswordForm } from '@/pages/auth/components/PasswordForm';
import { useNavigation } from '@/pages/auth/sign-up/hooks/useNavigation';
import { useSignUpStoreActions } from '@/pages/auth/sign-up/store/signUp.store';

export const CreatePasswordForm = () => {
  const { setUserData } = useSignUpStoreActions();
  const { goToPreviousStep, goToNextStep, currentStepIndex, totalSteps } = useNavigation();

  const onSubmit = (password: string) => {
    setUserData({
      password,
    });
    goToNextStep();
  };

  return (
    <PasswordForm
      onSubmit={onSubmit}
      navigation={{
        totalSteps,
        goToPreviousStep,
        currentStep: currentStepIndex + 1,
      }}
    />
  );
};
