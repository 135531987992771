import { forwardRef, Fragment } from 'react';
import { Stack, Typography } from '@mui/material';

import { Slider, type SliderProps } from '@/components/Slider';
import { formatNumberByThousands } from '@/shared/helpers/format.helpers';

import { DorimPromoChartLabel } from './DorimPromoChartLabel';
import { useSliderColor } from './helpers';

type Plan = {
  planName: string;
  planValue: number;
};

const zeroPlan: Plan = {
  planName: '0',
  planValue: 0,
};

type GetMarksParams = {
  minPlan?: number;
  maxPlan?: number;
};

const getMarks = (params: GetMarksParams) => {
  const marks: Plan[] = [zeroPlan];
  if (params.minPlan) {
    marks.push({ planName: 'Мин. план', planValue: params.minPlan });
  }

  if (params.maxPlan) {
    marks.push({ planName: 'Макс. план', planValue: params.maxPlan });
  }

  return marks;
};

export type DorimPromoPlanSliderProps = Omit<SliderProps, 'currentValue' | 'marks' | 'values'> & {
  totalPurchased: number;
  minPlan?: number;
  maxPlan?: number;
  renderMark?: (mark: Plan, index: number) => React.ReactNode;
};

const defaultRenderMark: DorimPromoPlanSliderProps['renderMark'] = mark => (
  <Fragment>
    <Typography variant="inherit" sx={{ visibility: mark.planName === '0' ? 'hidden' : 'visible' }}>
      {mark.planName}
    </Typography>
    <Stack spacing={0.5} direction="row">
      <Typography variant="Body/Bold/medium">{formatNumberByThousands(mark.planValue)}</Typography>
      <Typography variant="inherit">UZS</Typography>
    </Stack>
  </Fragment>
);

export const DorimPromoPlanChart = forwardRef<HTMLSpanElement, DorimPromoPlanSliderProps>((props, ref) => {
  const {
    totalPurchased,
    minPlan,
    maxPlan,
    sx: passedSx,
    renderMark = defaultRenderMark,
    isShowExceedingValue = true,
    ...sliderProps
  } = props;

  const marks = getMarks({ minPlan, maxPlan });
  const values = marks.map(mark => mark.planValue);
  const currentSliderColor = useSliderColor({
    currentValue: totalPurchased,
    values,
    shouldUseDefaultColor: !!maxPlan && !minPlan,
  });

  const isWithoutPlans = !maxPlan && !minPlan;
  const isPurchaseWithoutPlan = totalPurchased > 0 && isWithoutPlans;
  const isWithoutPlansAndPurchases = isWithoutPlans && totalPurchased === 0;
  const isPlansExceeded = values.length > 0 && totalPurchased > values[values.length - 1];

  return (
    <Slider
      values={values}
      currentValue={totalPurchased}
      value={isPurchaseWithoutPlan ? 1 : undefined}
      max={isPurchaseWithoutPlan ? 1 : undefined}
      ref={ref}
      isShowExceedingValue={isShowExceedingValue}
      sliderColor={currentSliderColor}
      marks={marks.map((mark, index) => ({
        value: index,
        label: (
          <DorimPromoChartLabel
            isFirst={index === 0}
            isLast={index === marks.length - 1}
            isPlanExceeded={isPlansExceeded}
          >
            {renderMark(mark, index)}
          </DorimPromoChartLabel>
        ),
      }))}
      sx={{
        '.MuiSlider-rail': {
          background: theme =>
            `${
              isWithoutPlansAndPurchases
                ? `linear-gradient(90deg, ${theme.palette.neutral[300]}, ${theme.palette.neutral[100]})`
                : undefined
            } !important`,
        },
        ...passedSx,
      }}
      {...sliderProps}
    />
  );
});
