import { createContext, useContext } from 'react';

import { type SignInFormData } from '@/api/domains/auth.api';
import type {
  ResetPasswordFormData,
  ResetPasswordResponseBody,
  SendOTPFormData,
  SendOTPResponseBody,
  SignInTokens,
  SignUpFormData,
  SignUpResponseBody,
  VerifyOTPFormData,
  VerifyOTPResponseBody,
} from '@/api/domains/auth.types';

import type { TokenPair } from './auth.types';

// ----------------------------------------------------------------------

export type AuthState = {
  isAuthenticated: boolean;
  accessToken: string | null;
};

export type AuthMethods = {
  signIn: (formData: SignInFormData) => Promise<void>;
  signUp: (formData: SignUpFormData) => Promise<SignUpResponseBody>;
  updateSignInTokens: (data: SignInTokens) => void;
  signOut: () => Promise<void>;
  getAuthenticatedState: () => boolean;
  refreshToken: () => void;
  resetToUnauthenticated: () => void;
  initialize: () => void;
  getTokenPair: () => TokenPair;
  signUpSendOTP: (formData: SendOTPFormData) => Promise<SendOTPResponseBody>;
  signUpVerifyOTP: (formData: VerifyOTPFormData) => Promise<VerifyOTPResponseBody>;
  signUpCheckIpLimits: () => Promise<void>;
  resetPasswordSendOTP: (formData: SendOTPFormData) => Promise<SendOTPResponseBody>;
  resetPasswordVerifyOTP: (formData: VerifyOTPFormData) => Promise<VerifyOTPResponseBody>;
  resetPassword: (formData: ResetPasswordFormData) => Promise<ResetPasswordResponseBody>;
  resetPasswordCheckIpLimits: () => Promise<void>;
};

export const AuthContextMethods = createContext<AuthMethods | null>(null);

if (import.meta.env.DEV) {
  AuthContextMethods.displayName = 'AuthContextMethods';
}

export const AuthContextState = createContext<AuthState | null>(null);

if (import.meta.env.DEV) {
  AuthContextState.displayName = 'AuthContextState';
}

export const useAuthMethods = (): AuthMethods => {
  const context = useContext(AuthContextMethods);

  if (!context) {
    throw new Error('useAuthMethods must be used inside a AuthProvider.');
  }

  return context;
};

export const useAuthState = (): AuthState => {
  const context = useContext(AuthContextState);

  if (!context) {
    throw new Error('useAuthState must be used inside a AuthProvider.');
  }

  return context;
};
