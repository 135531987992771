import { Navigate } from 'react-router-dom';

import { DorimPromoPaths, type RouteObjectWithBreadcrumbs } from '@/shared/lib/react-router';

import { MainContractDetailPageRoute, SpecificationDetailMainContractDetailPageRoute } from './detail';
import { NoticePageRoute } from './notice';

export const SpecificationDetailMainContractPageRoute: RouteObjectWithBreadcrumbs = {
  path: DorimPromoPaths.SPECIFICATION_DETAIL_MAIN_CONTRACT_ROOT_PATH,
  children: [
    {
      index: true,
      element: <Navigate to={DorimPromoPaths.ROOT_PATH} />,
    },
    NoticePageRoute,
    SpecificationDetailMainContractDetailPageRoute,
  ],
};

export const MainContractPageRoute: RouteObjectWithBreadcrumbs = {
  path: DorimPromoPaths.MAIN_CONTRACT_ROOT_PATH,
  children: [
    {
      index: true,
      element: <Navigate to={DorimPromoPaths.MAIN_CONTRACT_DOCUMENT_PATH} />,
    },
    MainContractDetailPageRoute,
  ],
};
