import { useIsSpecificationContractAccepted } from '@/shared/hooks/useIsSpecificationContractAccepted';
import { DorimPromoPaths, RedirectWithoutAccess } from '@/shared/lib/react-router';

type UnacceptedSpecificationContractGuardProps = React.PropsWithChildren;

export const UnacceptedSpecificationContractGuard = (props: UnacceptedSpecificationContractGuardProps) => {
  const { children } = props;

  const {
    query: { isLoading },
    isAccepted,
  } = useIsSpecificationContractAccepted();

  if (isLoading) {
    return null;
  }

  return (
    <RedirectWithoutAccess hasAccess={!isAccepted} to={DorimPromoPaths.ROOT_PATH}>
      {children}
    </RedirectWithoutAccess>
  );
};
