export const calculateValuePosition = (activeValue: number, values: number[]) => {
  const maxIndex = values.length - 1;
  const maxValue = values[maxIndex];

  const x = activeValue;
  let startValueLimit = 0;
  let endValueLimit = 0;
  let startScaleLimit = 0;
  let endScaleLimit = 0;

  const isValueMoreThanMax = activeValue > maxValue;

  if (isValueMoreThanMax) {
    return maxIndex;
  }

  for (let i = 0; i < maxIndex; i++) {
    const currentValue = values[i];
    const nextValue = values[i + 1];

    if (activeValue >= currentValue && activeValue <= nextValue) {
      startValueLimit = currentValue;
      endValueLimit = nextValue;
      startScaleLimit = i;
      endScaleLimit = i + 1;
    }
  }

  if (!endValueLimit || !endScaleLimit) {
    return;
  }

  return (
    startScaleLimit + ((x - startValueLimit) / (endValueLimit - startValueLimit)) * (endScaleLimit - startScaleLimit)
  );
};
