import { paramsToQueryKey } from '@/shared/lib/react-query';

import type { FetchBindingDrugsParams } from './auto-selection.api';
import { type FetchNecessityDrugsParams } from './auto-selection.api';

const PREFIX = 'auto-selection';

export const AutoSelectionQueryKeys = {
  getRootKey: () => [PREFIX],

  getNecessityFileSettingsKey: () => [PREFIX, 'necessity-file-settings'],
  getNecessityFileColumnsKey: () => [PREFIX, 'necessity-file-columns'],

  getActiveAutoSelectionKey: () => [PREFIX, 'active-auto-selection'],

  getDrugsByBindingStatusKey: (params: Partial<FetchBindingDrugsParams>) =>
    paramsToQueryKey([PREFIX, 'binding-drugs'], params),

  getNecessityDrugsKey: (params: Partial<FetchNecessityDrugsParams>) =>
    paramsToQueryKey([PREFIX, 'necessity-drugs', params?.autoSelectionId], params?.payload),
} as const;
