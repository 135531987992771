import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { IconButton, ListItemIcon, ListItemText, ListSubheader, styled } from '@mui/material';

export const StyledQuestionIcon = styled(HelpOutlineIcon)`
  color: ${({ theme }) => theme.palette.neutral['700']};
  width: 24px;
  height: 24px;
`;

export const StyledButton = styled(IconButton)`
  background: #fff;
  width: 40px;
  height: 40px;
  padding: 0;
  font-size: 0;
  border-radius: 20px;

  &:hover {
    background: #fcfcfc;
  }

  &:active {
    transform: translateY(1px);
    background: #fcfcfc;
    box-shadow:
      0 0 3px 0 rgba(0, 0, 0, 0.2),
      inset 0 0 2px 0 rgba(0, 0, 0, 0.12);
  }
`;

export const StyledPopoverContent = styled('div')`
  padding-top: 10px;
  width: 204px;
`;

export const StyledPopoverTitle = styled(ListSubheader)`
  color: #212b36;

  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.25px;
`;

export const StyledListItemIcon = styled(ListItemIcon)`
  margin-right: 8px;
`;

export const StyledListItemText = styled(ListItemText)`
  color: #212b36;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;

  .MuiTypography-root {
    font-size: 14px;
  }
`;
