import { LoadingButton } from '@mui/lab';
import { css, styled } from '@mui/material';

export const StyledFormNavigation = styled('footer')`
  margin-top: auto;

  display: flex;
  justify-content: space-between;
  align-items: center;

  color: ${({ theme }) => theme.palette.neutral['700']};

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
`;

export const StyledButton = styled(LoadingButton)`
  ${({ hidden }) =>
    hidden
      ? css`
          visibility: hidden;
        `
      : ''}
`;
