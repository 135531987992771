import { useRef } from 'react';
import { useNavigate } from 'react-router';
import { useBlocker } from 'react-router-dom';
import type { Blocker } from 'history';

/**
 * {@link https://codesandbox.io/s/react-router-dom-v6-prompt-fixup-my91c?file=/src/components/utils/prompt.tsx:0-1898}
 */
export const useNavigationBlocker = (blocker: Blocker, when = true) => {
  const navigate = useNavigate();

  // Needed to implement retry
  const isBlockedRef = useRef(when);
  isBlockedRef.current = when;

  useBlocker(({ currentLocation, nextLocation, historyAction }) => {
    if (!isBlockedRef.current) return false;

    blocker({
      action: historyAction,
      location: currentLocation,
      retry: () => {
        isBlockedRef.current = false;
        navigate(nextLocation);
      },
    });

    return true;
  });
};
