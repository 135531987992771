import { Fragment } from 'react';

import { useUserContractor } from '@/shared/hooks/useUserContractor';

import { EntityInfoForm } from './EntityInfoForm';
import { EntityInfoFormSkeleton } from './EntityInfoFormSkeleton';

export const EntityInfoFormContent = () => {
  const { isInitialLoading, data } = useUserContractor();

  return (
    <Fragment>
      {isInitialLoading && <EntityInfoFormSkeleton />}
      {!isInitialLoading && data && <EntityInfoForm userContractor={data} />}
    </Fragment>
  );
};
