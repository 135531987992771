import { Button, styled } from '@mui/material';

export const StyledRoot = styled('div')`
  display: flex;
  flex-flow: column;
  flex: 1 1;
`;
export const StyledContent = styled('div')`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-flow: column;
  flex: 1;
`;
export const StyledReasonText = styled('div')`
  color: #ff3000;

  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.15px;
`;

export const StyledDetailsText = styled('div')`
  color: ${({ theme }) => theme.palette.neutral['700']};

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  margin-top: ${({ theme }) => theme.spacing(2)};
`;

export const StyledFooter = styled('footer')`
  text-align: center;
  color: #1e2f96;
  margin-top: auto;
`;
export const StyledButton = styled(Button)`
  margin-top: ${({ theme }) => theme.spacing(5)};
` as typeof Button;
