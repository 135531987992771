import { useMainContractDocument } from '@/shared/hooks/useMainContractDocument';
import { DorimPromoPaths, RedirectWithoutAccess } from '@/shared/lib/react-router';

type UnsignedMainContractGuardProps = React.PropsWithChildren;

export const UnsignedMainContractGuard = (props: UnsignedMainContractGuardProps) => {
  const { children } = props;

  const { isSigned, query } = useMainContractDocument();

  if (query.isLoading) {
    return null;
  }

  return (
    <RedirectWithoutAccess hasAccess={!isSigned} to={DorimPromoPaths.ROOT_PATH}>
      {children}
    </RedirectWithoutAccess>
  );
};
