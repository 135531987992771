import { create } from 'zustand';

type CloudCartStore = {
  isUpdating: boolean;

  actions: {
    setUpdateStatus: (isUpdating: boolean) => void;
  };
};

export const useCloudCartStore = create<CloudCartStore>(set => ({
  isUpdating: false,

  actions: {
    setUpdateStatus: isUpdating => set({ isUpdating }),
  },
}));

export const useCloudCartStoreActions = () => useCloudCartStore(store => store.actions);
export const useIsCloudCartUpdating = () => useCloudCartStore(store => store.isUpdating);
