import { styled } from '@mui/material';

export const StyledTitle = styled('header')`
  color: #1e2f96;

  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 24px;
`;
