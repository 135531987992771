import type { InfoParams } from '@/api/domains/info.api';
import { paramsToQueryKey } from '@/shared/lib/react-query';

// ----------------------------------------------------------------------

const PREFIX = 'info';

// ----------------------------------------------------------------------

export const InfoQueryKeys = {
  root: [PREFIX],

  info: (params?: InfoParams) => paramsToQueryKey([PREFIX, 'list'], params),
} as const;
