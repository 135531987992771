import { DorimPromoPaths, type RouteObjectWithBreadcrumbs } from '@/shared/lib/react-router';

import { UnsignedSpecificationContractGuard } from '../../../../guards';

export const NoticePageRoute: RouteObjectWithBreadcrumbs = {
  path: DorimPromoPaths.SPECIFICATION_DETAIL_CONTRACT_NOTICE_PATH,
  lazy: async () => {
    const { NoticePage } = await import(
      /* webpackChunkName: "NoticePage" */
      './NoticePage'
    );

    return {
      Component: () => (
        <UnsignedSpecificationContractGuard>
          <NoticePage />
        </UnsignedSpecificationContractGuard>
      ),
    };
  },
};
