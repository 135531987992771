import type { PropsWithChildren } from 'react';

import { IpLimitsVerification } from './IpLimitsVerification';
import { PersistedDataVerification } from './PersistedDataVerification';

export const SignUpGuard = ({ children }: PropsWithChildren) => {
  return (
    <IpLimitsVerification>
      <PersistedDataVerification>{children}</PersistedDataVerification>
    </IpLimitsVerification>
  );
};
