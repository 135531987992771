import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormControl, Stack } from '@mui/material';
import { AxiosError, HttpStatusCode } from 'axios';

import { useGlobalErrorStore } from '@/components/dialogs';
import { CustomFormLabel, RHFMaskedPhoneTextField } from '@/components/form-controls';
import { StepsNavigation } from '@/pages/auth/components/StepsNavigation';
import {
  StyledFormDescription,
  StyledFormProvider,
  StyledFormRoot,
  StyledFormTitle,
} from '@/pages/auth/components/styles';
import { useSendOTP } from '@/pages/auth/components/VerifyPhone/hooks/useSendOTP';
import { getExpiredTimestamp } from '@/pages/auth/helpers/getExpiredTimestamp';
import { useAuthMethods } from '@/shared/lib/auth';
import { AuthPaths } from '@/shared/lib/react-router';
import { useOTPStoreActions } from '@/shared/stores/otp.store';

import { useBan } from './hooks/useBan';
import { useNavigation } from './hooks/useNavigation';
import { useResetPasswordActions, useResetPasswordState } from './store/resetPassword.store';
import { accountSchema } from './validation-schema';

type FormValues = {
  phoneNumber: string;
};

export const AccountPage = () => {
  const navigate = useNavigate();
  const { totalSteps, goToNextStep } = useNavigation();
  const { setPhoneNumber } = useResetPasswordActions();
  const { phoneNumber } = useResetPasswordState();
  const { resetPasswordSendOTP, resetPasswordCheckIpLimits } = useAuthMethods();
  const { resetBan, applyBan } = useBan();
  const { setResetPasswordSendData, clearResetPasswordAttemptCounter, updateResetPasswordExpiredAt } =
    useOTPStoreActions();
  const { setGlobalError } = useGlobalErrorStore();

  const methods = useForm<FormValues>({
    mode: 'onSubmit',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    resolver: yupResolver(accountSchema),
    defaultValues: {
      phoneNumber,
    },
  });

  const {
    formState: { isSubmitting, isLoading },
    setError,
  } = methods;

  const checkIpLimits = async (lifetime: number) => {
    try {
      await resetPasswordCheckIpLimits();
      updateResetPasswordExpiredAt(getExpiredTimestamp(lifetime));
      goToNextStep();
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === HttpStatusCode.TooManyRequests) {
          if (error.response?.headers['retry-after']) {
            applyBan(parseInt(error.response.headers['retry-after']));
          }
        }
      }
    }
  };

  const sendOTP = useSendOTP({
    sendOtpApiMethod: resetPasswordSendOTP,
    onSuccess({ lifetime, sms_version }) {
      resetBan();
      setResetPasswordSendData({
        lifetime,
        expiredAt: getExpiredTimestamp(lifetime),
        sms_version,
      });
      clearResetPasswordAttemptCounter();
    },
  });

  const submitForm = async (formData: FormValues) => {
    const { phoneNumber } = formData;
    setPhoneNumber(formData.phoneNumber);

    try {
      await sendOTP({ phone: phoneNumber });
      goToNextStep();
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === HttpStatusCode.TooManyRequests) {
          if (error.response?.headers['retry-after']) {
            checkIpLimits(parseInt(error.response.headers['retry-after']));
            return;
          }
        }
        if (error.response?.data?.message) {
          setError('phoneNumber', {
            message: error.response.data.message,
          });
          return;
        }
      }

      setGlobalError(error);
    }
  };

  const onBackwardClick = () => {
    navigate(AuthPaths.SIGN_IN.ROOT);
  };
  const isFormLoading = isSubmitting && isLoading;

  return (
    <StyledFormRoot>
      <StyledFormTitle>Восстановление пароля</StyledFormTitle>
      <StyledFormDescription>Введите номер телефона для восстановления пароля</StyledFormDescription>
      <StyledFormProvider methods={methods} onSubmit={submitForm}>
        <Stack spacing={3} mb={7}>
          <FormControl fullWidth>
            <CustomFormLabel htmlFor="phoneNumber" isRequired>
              Телефон
            </CustomFormLabel>
            <RHFMaskedPhoneTextField fullWidth name="phoneNumber" id="phoneNumber" variant="filled" />
          </FormControl>
        </Stack>
        <StepsNavigation
          onBackwardClick={onBackwardClick}
          currentStep={1}
          totalSteps={totalSteps}
          forwardButtonProps={{
            type: 'submit',
            loading: isFormLoading,
          }}
        />
      </StyledFormProvider>
    </StyledFormRoot>
  );
};
