import { AcceptedSpecificationContractGuard } from '@/pages/dorim-promo/guards';
import type { RouteObjectWithBreadcrumbs } from '@/shared/lib/react-router';

export const SpecificationsPageRoute: RouteObjectWithBreadcrumbs = {
  index: true,
  lazy: async () => {
    const { SpecificationsPage } = await import(
      /* webpackChunkName: "SpecificationsPage" */
      './SpecificationsPage'
    );

    return {
      Component: () => (
        <AcceptedSpecificationContractGuard>
          <SpecificationsPage />
        </AcceptedSpecificationContractGuard>
      ),
    };
  },
};
