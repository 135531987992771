import type { Info } from '@/api/domains/info.types';
import type { FieldNames, Option } from '@/pages/auth/sign-up/forms/types';
import { isCity } from '@/pages/auth/sign-up/helpers/typesNarrower';

export const getOptionLabel = (option: Option | string, selectedFieldName: FieldNames | null): string => {
  if (typeof option === 'string') {
    return option;
  }

  let optionCustomLabel = option.name;

  if (isCity(option)) {
    optionCustomLabel = `${option.settlement_type?.name} ${option.full_name}`;
  } else if (selectedFieldName === 'streetType') {
    optionCustomLabel = `${option.name} - ${(option as Info).description}`;
  }

  return optionCustomLabel;
};
