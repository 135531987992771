import { DorimPromoPaths, type RouteObjectWithBreadcrumbs } from '@/shared/lib/react-router';

export const DocumentPageRoute: RouteObjectWithBreadcrumbs = {
  path: DorimPromoPaths.SPECIFICATION_DETAIL_CONTRACT_DOCUMENT_PATH,
  lazy: async () => {
    const { DocumentPage } = await import(
      /* webpackChunkName: "DocumentPage" */
      './DocumentPage'
    );

    return {
      Component: DocumentPage,
    };
  },
};
