import { Fragment } from 'react';
import { Typography } from '@mui/material';

import { formatNumberByThousands } from '@/shared/helpers/format.helpers';

type DorimPromoTooltipProps = {
  isCurrentCashback: boolean;
  cashbackAmount: number;
};

export const DorimPromoTooltip = (props: DorimPromoTooltipProps) => {
  const { cashbackAmount, isCurrentCashback } = props;

  return (
    <Fragment>
      <Typography component="p" variant="Body/small" color="neutral.500">
        {isCurrentCashback ? 'Текущая выплата' : 'Прогноз'}
      </Typography>
      <Typography component="p" variant="Body/small">
        <Typography component="span" variant="inherit" color="neutral.100">
          {formatNumberByThousands(Math.floor(cashbackAmount))}
        </Typography>{' '}
        <Typography component="span" variant="inherit" color="neutral.500">
          UZS
        </Typography>
      </Typography>
    </Fragment>
  );
};
