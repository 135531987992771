import { useQuery, useQueryClient, type UseQueryResult } from '@tanstack/react-query';
import { produce } from 'immer';
import { size } from 'lodash';

import { CloudCartsApi } from '@/api/domains/cloud-carts.api';
import { CLOUD_CART_ENTRY_SOURCE_MANUAL, type CloudCartEntrySource } from '@/api/domains/cloud-carts.constants';
import { CloudCartsQueryKeys } from '@/api/domains/cloud-carts.query-keys';

import {
  cancelCloudCartQueriesByCartId,
  getBaseCloudCartsQueryOptions,
  getCurrentCloudCartId,
  useCurrentCloudCartId,
  useUpdateCloudCart,
  type BaseCloudCartsOptions,
} from './cloud-carts-base';
import { computeTotalPackagesCount, transformCloudCartData, updateCloudCartCache } from './cloud-carts.helpers';
import type { TransformedCloudCart } from './cloud-carts.types';

// ----------------------------------------------------------------------

export type UseCloudCartSelector<TData> = (data: TransformedCloudCart) => TData;

export type UseCloudCartOptions<TData = TransformedCloudCart> = BaseCloudCartsOptions & {
  select?: UseCloudCartSelector<TData>;
};

export const useCloudCart = <TData = TransformedCloudCart>(
  options: UseCloudCartOptions<TData>,
): UseQueryResult<TData> =>
  useQuery({
    queryKey: CloudCartsQueryKeys.getCloudCartKey({ cartId: options.cartId }),
    queryFn: async () => {
      // !!! There is no additional check for `cartId` because the function should not return `undefined` !!!
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const rawCloudCart = await CloudCartsApi.fetchCloudCart({ cartId: options.cartId! });

      return transformCloudCartData(rawCloudCart);
    },
    select: options.select,
    ...getBaseCloudCartsQueryOptions(options),
  });

// ----------------------------------------------------------------------

export type UseCurrentCloudCartOptions<TData> = Omit<UseCloudCartOptions<TData>, 'cartId'>;

export const useCurrentCloudCart = <TData = TransformedCloudCart>(options?: UseCurrentCloudCartOptions<TData>) =>
  useCloudCart({
    cartId: useCurrentCloudCartId(),
    ...options,
  });

// ----------------------------------------------------------------------

export type UseUpdateCloudCartEntryOptions = {
  drugId: number;
  offerId: number;
  entrySource?: CloudCartEntrySource;
};

export const useUpdateCloudCartEntry = ({
  drugId,
  offerId,
  entrySource = CLOUD_CART_ENTRY_SOURCE_MANUAL,
}: UseUpdateCloudCartEntryOptions) => {
  const queryClient = useQueryClient();

  const { debouncedMutate, setUpdateStatus } = useUpdateCloudCart();

  const updateCloudCartEntry = async (count: number) => {
    setUpdateStatus(true);

    const cartId = await getCurrentCloudCartId(queryClient);
    await cancelCloudCartQueriesByCartId(queryClient, cartId);

    queryClient.setQueryData<TransformedCloudCart>(CloudCartsQueryKeys.getCloudCartKey({ cartId }), prevData => {
      if (!prevData) {
        return;
      }

      return produce(prevData, data => {
        const { entriesPackagesCounts } = updateCloudCartCache({
          data,
          drugId,
          offerId,
          count,
        });

        data.rawCloudCart.items_count = size(entriesPackagesCounts);
        data.rawCloudCart.packages_count = computeTotalPackagesCount(entriesPackagesCounts);
      });
    });

    return debouncedMutate({
      cartId,
      payload: {
        offer_id: offerId,
        quantity: count,
        source: entrySource,
      },
    });
  };

  return {
    updateCloudCartEntry,
  };
};
