import { SupportLink } from '@/pages/auth/components/SupportLink';
import { LANDING_PAGE_LINK } from '@/shared/constants/dorim.constants';

import { StyledButton, StyledContent, StyledDetailsText, StyledFooter, StyledReasonText, StyledRoot } from './styles';

export type BlockedUserProps = {
  reasonText: string;
  detailsText?: string;
};
export const BlockedUser = ({ reasonText, detailsText }: BlockedUserProps) => {
  return (
    <StyledRoot>
      <StyledContent>
        <StyledReasonText>{reasonText}</StyledReasonText>
        {detailsText && <StyledDetailsText>{detailsText}</StyledDetailsText>}
      </StyledContent>

      <StyledFooter>
        <SupportLink />
        <StyledButton component="a" href={LANDING_PAGE_LINK} variant="outlined" color="inherit">
          На главную
        </StyledButton>
      </StyledFooter>
    </StyledRoot>
  );
};
