import { forwardRef, useEffect, useState } from 'react';
import type { NumericFormatProps, OnValueChange } from 'react-number-format';

import type { CloudCartDistributor, CloudCartItem } from '@/api/domains/cloud-carts.types';
import { useCheckoutCloudCartEntryPackagesCount, useUpdateCheckoutCloudCartEntry } from '@/entities/cloud-carts';

import { CloudCartCounterBase, type CartCounterBaseProps } from './CloudCartCounterBase';

export type CheckoutCloudCartCounterProps = Omit<
  CartCounterBaseProps,
  'value' | 'disabled' | 'onIncrease' | 'onDecrease'
> & {
  distributorId: CloudCartDistributor['id'];
  drugId: CloudCartItem['drug_id'];
  offerId: CloudCartItem['offer_id'];
  entrySource?: CloudCartItem['source'];
};

export const CheckoutCloudCartCounter = forwardRef<HTMLDivElement, CheckoutCloudCartCounterProps>((props, ref) => {
  const { distributorId, drugId, offerId, entrySource, onBlur, ...restProps } = props;

  const count = useCheckoutCloudCartEntryPackagesCount({ distributorId, offerId });
  const [inputCountValue, setInputCountValue] = useState<number | string>(count);

  const { updateCheckoutCloudCartEntry } = useUpdateCheckoutCloudCartEntry({
    distributorId,
    drugId,
    offerId,
    entrySource,
  });

  useEffect(() => {
    setInputCountValue(count);
  }, [count]);

  const increaseCount = () => {
    updateCheckoutCloudCartEntry(count + 1);
    setInputCountValue(count + 1);
  };

  const decreaseCount = () => {
    updateCheckoutCloudCartEntry(count - 1);
    setInputCountValue(count - 1);
  };

  const handleValueChange: OnValueChange = ({ floatValue: count = 0, value: countValue }) => {
    updateCheckoutCloudCartEntry(count);
    setInputCountValue(countValue);
  };

  const handleBlur: NumericFormatProps['onBlur'] = event => {
    onBlur?.(event);
    setInputCountValue(count);
  };

  const handleKeyDown: NumericFormatProps['onKeyDown'] = event => {
    switch (event.key) {
      case 'ArrowUp':
        return increaseCount();
      case 'ArrowDown':
        return decreaseCount();
      default:
        return;
    }
  };

  return (
    <CloudCartCounterBase
      ref={ref}
      value={inputCountValue}
      onKeyDown={handleKeyDown}
      onIncrease={increaseCount}
      onDecrease={decreaseCount}
      onValueChange={handleValueChange}
      onBlur={handleBlur}
      {...restProps}
    />
  );
});
