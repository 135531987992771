import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

type State = {
  phoneNumber: string;
  password: string;
};

type Actions = {
  setPhoneNumber: (phoneNumber: string) => void;
  setPassword: (password: string) => void;
  clearResetPasswordData: () => void;
};

export type ResetPasswordStore = State & Actions;

const initialState: State = {
  phoneNumber: '',
  password: '',
};

const storeName = 'dorim-price:auth/reset-password';
const useStore = create<ResetPasswordStore>()(
  devtools(
    persist(
      set => ({
        ...initialState,

        // Actions
        setPhoneNumber: phoneNumber => set({ phoneNumber }, false, 'setPhoneNumber'),
        setPassword: password => set({ password }, false, 'setPassword'),
        clearResetPasswordData: () => set(initialState, false, 'reset'),
      }),
      {
        name: storeName,
      },
    ),
    {
      name: storeName,
    },
  ),
);

// Selectors for state
const selectState = (state: ResetPasswordStore) => ({
  phoneNumber: state.phoneNumber,
  password: state.password,
});

// Selectors for actions
const selectActions = (state: ResetPasswordStore) => ({
  setPhoneNumber: state.setPhoneNumber,
  setPassword: state.setPassword,
  clearResetPasswordData: state.clearResetPasswordData,
});

// Hooks
export const useResetPasswordState = () => useStore(selectState);
export const useResetPasswordActions = () => useStore(selectActions);
