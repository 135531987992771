import type { CardProps } from '@mui/material';
import { Card } from '@mui/material';
import { styled } from '@mui/material/styles';

export type DataGridContainerProps = React.PropsWithChildren<CardProps>;

const StyledRoot = styled(Card)({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
});

export const DataGridContainer = (props: DataGridContainerProps) => <StyledRoot variant="outlined" {...props} />;
