import { useUnmount } from 'react-use';
import { devtools } from 'zustand/middleware';

import { createStoreWithReset } from '@/shared/lib/zustand';

type SignInState = {
  phone: string;
};

type SignInActions = {
  setPhone: (phone: SignInState['phone']) => void;
};

type SignInStore = SignInState & SignInActions;

const initialState: SignInState = {
  phone: '',
};

const storeName = 'dorim-price:sign-in-store';
export const [useSignInStore, resetSignInStore] = createStoreWithReset<SignInStore>()(
  devtools(
    set => ({
      ...initialState,

      setPhone: phone => set({ phone }, false, 'setPhone'),
    }),
    {
      name: storeName,
    },
  ),
);

// Selectors
const selectSignInPhone = (state: SignInStore) => state.phone;

const selectSignInActions = (state: SignInStore) => ({
  setPhone: state.setPhone,
});

// Hooks
export const useSignInStorePhone = () => useSignInStore(selectSignInPhone);
export const useSignInStoreActions = () => useSignInStore(selectSignInActions);

export const useSignInStoreResetOnUnmount = () => useUnmount(resetSignInStore);
