import { useStepsNavigation } from '@/pages/auth/hooks/useStepsNavigation';
import type { Step } from '@/pages/auth/reset-password/constants';
import { RESET_PASSWORD_FORM_STEPS } from '@/pages/auth/reset-password/constants';
import { AuthPaths } from '@/shared/lib/react-router';

export const useNavigation = () => {
  return useStepsNavigation<Step>({
    steps: RESET_PASSWORD_FORM_STEPS,
    routerPath: AuthPaths.RESET_PASSWORD.STEPS,
  });
};
