import { lazy } from 'react';

export const CartPage = lazy(
  () =>
    import(
      /* webpackPreload: true */
      /* webpackChunkName: "CartPage" */
      './CartPage'
    ),
);
