import { httpClient } from '@/shared/lib/axios';

import type { UserContractor } from './user-contractor.type';

export type UpdateContractorParams = {
  bank_name: string;
  bank_mfo: string;
  bank_checking_account: string;
  director_name: string;
  director_phone: string;
  address_row: string;
};

export const UserContractorApi = {
  fetchContractor: async () => {
    const { data } = await httpClient.get<UserContractor>('contractor');

    return data;
  },

  updateContractor: async (params: UpdateContractorParams) => {
    await httpClient.patch<void>('contractor', params);
  },
};
