import { Fragment } from 'react';
import { Skeleton, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { CustomFormLabel } from '@/components/form-controls';

export const EntityInfoFormSkeleton = () => (
  <Fragment>
    <Grid container spacing={2} mb={5}>
      <Grid xs={12}>
        <Typography component="div" variant="Body/Bold/large">
          <Skeleton variant="text" width={150} />
        </Typography>
      </Grid>
      <Grid xs={4}>
        <CustomFormLabel>
          <Skeleton variant="text" width={75} />
        </CustomFormLabel>
        <Skeleton variant="rounded" height={48} width="100%" />
      </Grid>
      <Grid xs={4}>
        <CustomFormLabel>
          <Skeleton variant="text" width={75} />
        </CustomFormLabel>
        <Skeleton variant="rounded" height={48} width="100%" />
      </Grid>
      <Grid xs={4}>
        <CustomFormLabel>
          <Skeleton variant="text" width={75} />
        </CustomFormLabel>
        <Skeleton variant="rounded" height={48} width="100%" />
      </Grid>
    </Grid>

    <Grid container spacing={2} mb={5}>
      <Grid xs={12}>
        <Typography component="div" variant="Body/Bold/large">
          <Skeleton variant="text" width={150} />
        </Typography>
      </Grid>
      <Grid xs={4}>
        <CustomFormLabel>
          <Skeleton variant="text" width={75} />
        </CustomFormLabel>
        <Skeleton variant="rounded" height={48} width="100%" />
      </Grid>
      <Grid xs={4}>
        <CustomFormLabel>
          <Skeleton variant="text" width={75} />
        </CustomFormLabel>
        <Skeleton variant="rounded" height={48} width="100%" />
      </Grid>
    </Grid>

    <Grid container spacing={2}>
      <Grid xs={12}>
        <Typography component="div" variant="Body/Bold/large">
          <Skeleton variant="text" width={150} />
        </Typography>
      </Grid>
      <Grid xs={8}>
        <CustomFormLabel>
          <Skeleton variant="text" width={75} />
        </CustomFormLabel>
        <Skeleton variant="rounded" height={48} width="100%" />
      </Grid>
      <Grid xs={12}>
        <Typography component="div" variant="Body/small" color="grey.500" mb={1}>
          <CustomFormLabel>
            <Skeleton variant="text" width={75} />
          </CustomFormLabel>
        </Typography>
        <Typography component="div" variant="Body/medium">
          <Skeleton variant="text" width={250} />
        </Typography>
        <Typography component="div" variant="Body/medium">
          <Skeleton variant="text" width={350} />
        </Typography>
      </Grid>
    </Grid>
  </Fragment>
);
