import { STEP_ACCOUNT, STEP_NEW_PASSWORD, STEP_VERIFY_PHONE } from '@/pages/auth/reset-password/constants';

export const AuthPaths = {
  ROOT: '/auth',
  SIGN_IN: {
    ROOT: '/auth/sign-in',
    BAN: '/auth/sign-in/ban',
  },
  SIGN_UP: {
    ROOT: '/auth/sign-up',
    DEFAULT: '/auth/sign-up/step/1',
    REGISTRATION: '/auth/sign-up/step/1',
    VERIFY_PHONE: '/auth/sign-up/step/2',
    CREATE_PASSWORD: '/auth/sign-up/step/3',
    COMPANY_INFO: '/auth/sign-up/step/4',
    PHARMACY_INFO: '/auth/sign-up/step/5',
    STEP: '/auth/sign-up/step/:step',
    BAN: '/auth/sign-up/ban',
    FINAL: '/auth/sign-up/final',
    AFTER_SUCCESSFUL_SIGNUP: '/',
    PHONE_ALREADY_IN_USE: '/auth/sign-in',
    OLD_ROOT: '/sign-up',
  },
  RESET_PASSWORD: {
    ROOT: '/auth/reset-password',
    STEPS: '/auth/reset-password/:step',
    ACCOUNT: `/auth/reset-password/${STEP_ACCOUNT}`,
    VERIFY_PHONE: `/auth/reset-password/${STEP_VERIFY_PHONE}`,
    NEW_PASSWORD: `/auth/reset-password/${STEP_NEW_PASSWORD}`,
    SUCCESS: '/auth/reset-password/success',
    BAN: '/auth/reset-password/ban',
  },
} as const;
