import { some } from 'lodash';

import type { SpecificationBase } from '@/api/domains/dorim-promo.types';
import {
  CASHBACK_STATUS_CALCULATED,
  CASHBACK_STATUS_CANCELED,
  CASHBACK_STATUS_PAYMENT_COMPLETED,
} from '@/shared/constants/cashback-status.constants';

export const getActionBarState = (agreement: SpecificationBase) => ({
  isCashbackCalculated: agreement.record_status_id === CASHBACK_STATUS_CALCULATED,
  isCashbackPaymentCompleted: agreement.record_status_id === CASHBACK_STATUS_PAYMENT_COMPLETED,
  isCashbackCanceled: agreement.record_status_id === CASHBACK_STATUS_CANCELED,
});

export const hasActionBar = (actionBarState: ReturnType<typeof getActionBarState>) => some(actionBarState);
