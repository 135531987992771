import { DorimPromoPaths, type RouteObjectWithBreadcrumbs } from '@/shared/lib/react-router';

const BaseRoute: RouteObjectWithBreadcrumbs = {
  lazy: async () => {
    const { DocumentPage } = await import(
      /* webpackChunkName: "DocumentPage" */
      './DocumentPage'
    );

    return { Component: DocumentPage };
  },
};

export const MainContractDocumentPageRoute: RouteObjectWithBreadcrumbs = {
  ...BaseRoute,
  path: DorimPromoPaths.MAIN_CONTRACT_DOCUMENT_PATH,
};

export const SpecificationDetailMainContractDocumentPageRoute: RouteObjectWithBreadcrumbs = {
  ...BaseRoute,
  path: DorimPromoPaths.SPECIFICATION_DETAIL_MAIN_CONTRACT_DOCUMENT_PATH,
};
