import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import type { TokenPair } from './auth.types';

type SessionTokensStoreState = {
  shouldStaySignedIn: boolean;
  tokens: TokenPair | null;
};

type SessionTokensStoreActions = {
  setShouldStaySignedIn: (shouldStaySignedIn: SessionTokensStoreState['shouldStaySignedIn']) => void;
  setTokens: (tokens: SessionTokensStoreState['tokens']) => void;
  resetTokens: () => void;
};

type SessionTokensStore = SessionTokensStoreState & SessionTokensStoreActions;

const useSessionTokensStore = create<SessionTokensStore>()(
  devtools(
    set => ({
      shouldStaySignedIn: true,
      tokens: null,

      setShouldStaySignedIn: shouldStaySignedIn => set({ shouldStaySignedIn }, false, 'setShouldStaySignedIn'),

      setTokens: tokens => set({ tokens }, false, 'setTokens'),
      resetTokens: () => set({ tokens: null }, false, 'resetTokens'),
    }),
    {
      name: 'dorim:session-tokens-store',
    },
  ),
);

// Selectors
const selectSessionShouldStaySignedIn = (state: SessionTokensStoreState) => state.shouldStaySignedIn;
const selectSessionTokens = (state: SessionTokensStoreState) => state.tokens;

const selectSessionActions = (state: SessionTokensStoreActions) => ({
  setShouldStaySignedIn: state.setShouldStaySignedIn,
  setTokens: state.setTokens,
  resetTokens: state.resetTokens,
});

// Hooks
export const useSessionTokensActions = () => useSessionTokensStore(selectSessionActions);

export const useSessionTokensShouldStaySignedIn = () => useSessionTokensStore(selectSessionShouldStaySignedIn);
export const useSessionTokens = () => useSessionTokensStore(selectSessionTokens);

// Reading/writing state outside of components
export const getShouldStaySignedIn = () => useSessionTokensStore.getState().shouldStaySignedIn;
export const getSessionTokens = () => useSessionTokensStore.getState().tokens;
export const setSessionTokens = (tokens: TokenPair) => useSessionTokensStore.getState().setTokens(tokens);
export const resetSessionTokens = () => useSessionTokensStore.getState().resetTokens();
