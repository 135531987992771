import { Stack, type StackProps } from '@mui/material';
import { styled } from '@mui/material/styles';

import { PageContainerBase, type PageContainerBaseProps } from '@/layouts/Page/PageContainerBase';

const StyledRoot = styled('div')({
  width: '100%',
  minHeight: '100%',
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
});

const StyledContent = styled(Stack)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(1),
  backgroundColor: theme.palette.background.neutral,
})) as typeof Stack;

export type PageContentProps = StackProps;

export const PageContent = (props: PageContentProps) => <StyledContent {...props} />;

export type PageContainerProps = PageContainerBaseProps;

export const PageContainer = (props: PageContainerProps) => {
  const { children } = props;

  return (
    <PageContainerBase {...props}>
      <StyledRoot>{children}</StyledRoot>
    </PageContainerBase>
  );
};
