import { Link } from 'react-router-dom';
import { Box, styled } from '@mui/material';

export const StyledFormContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  flex-grow: 1;
  margin-bottom: 118px;
`;

export const StyledLandingLink = styled(Link)`
  align-self: center;
`;

export const StyledRestorePasswordLink = styled(Link)`
  width: 100%;
`;
