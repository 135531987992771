import type { PropsWithChildren } from 'react';
import { omit } from 'lodash';
import type { Schema } from 'yup';

import { useCurrentStep } from '@/pages/auth/hooks/useStepsNavigation';
import type { Step } from '@/pages/auth/sign-up/constants';
import {
  STEP_COMPANY_INFO,
  STEP_CREATE_PASSWORD,
  STEP_PHARMACY_INFO,
  STEP_REGISTRATION,
  STEP_VERIFY_PHONE,
} from '@/pages/auth/sign-up/constants';
import {
  companyFormSchema,
  passwordSchema,
  pharmacyFormSchema,
  userSchema,
} from '@/pages/auth/sign-up/forms/validation-schemas';
import { useSignUpStoreCompanyData, useSignUpStoreUserData } from '@/pages/auth/sign-up/store/signUp.store';
import { AuthPaths, RedirectWithoutAccess } from '@/shared/lib/react-router';
import { Yup } from '@/shared/lib/yup';
import { useOTPStoreSignUpState } from '@/shared/stores/otp.store';

const schemas: Record<Step, Schema> = {
  [STEP_REGISTRATION]: userSchema,
  [STEP_VERIFY_PHONE]: Yup.object().shape({
    sendData: Yup.object().shape({
      lifetime: Yup.number().required(),
      expiredAt: Yup.number().required(),
      sms_version: Yup.number().required(),
    }),
    verifyData: Yup.object().shape({
      signup_token: Yup.string().required(),
    }),
  }),
  [STEP_CREATE_PASSWORD]: passwordSchema.pick(['password']),
  [STEP_COMPANY_INFO]: companyFormSchema,
  [STEP_PHARMACY_INFO]: pharmacyFormSchema,
};

const useValidatePersistedData = (step: Step) => {
  const userData = useSignUpStoreUserData();
  const companyData = useSignUpStoreCompanyData();
  const { signUpOTP } = useOTPStoreSignUpState();

  const isValidRegistrationData = schemas[STEP_REGISTRATION].isValidSync(omit(userData, 'password'));
  const isValidVerificationData = schemas[STEP_VERIFY_PHONE].isValidSync(signUpOTP);
  const isValidPasswordData = schemas[STEP_CREATE_PASSWORD].isValidSync({ password: userData.password });
  const isValidCompanyData = schemas[STEP_COMPANY_INFO].isValidSync(companyData);

  if (step === STEP_REGISTRATION) {
    return true;
  }
  if (step === STEP_VERIFY_PHONE) {
    return isValidRegistrationData;
  }
  if (step === STEP_CREATE_PASSWORD) {
    return isValidRegistrationData && isValidVerificationData;
  }
  if (step === STEP_COMPANY_INFO) {
    return isValidRegistrationData && isValidVerificationData && isValidPasswordData;
  }
  if (step === STEP_PHARMACY_INFO) {
    return isValidRegistrationData && isValidVerificationData && isValidPasswordData && isValidCompanyData;
  }

  return false;
};

export const PersistedDataVerification = ({ children }: PropsWithChildren) => {
  const urlCurrentStep = useCurrentStep<Step>({
    routerPath: AuthPaths.SIGN_UP.STEP,
  });
  const isDataVerified = useValidatePersistedData(urlCurrentStep);

  return (
    <RedirectWithoutAccess to={AuthPaths.SIGN_UP.DEFAULT} hasAccess={isDataVerified}>
      {children}
    </RedirectWithoutAccess>
  );
};
