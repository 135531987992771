import { conformsTo, pick } from 'lodash';

import { PostMessageContractorUpdated } from '@/shared/constants/post-message.constants';
import { usePostMessageListener } from '@/shared/hooks/usePostMessageListener';
import { useUserContractor } from '@/shared/hooks/useUserContractor';

export const useContractorDetail = () => {
  const query = useUserContractor();
  const { data } = query;
  const agreementsData = data?.agreements_data;
  const directorContact = data?.director_contact;

  const hasAgreementsData = conformsTo(agreementsData, {
    bank_name: Boolean,
    bank_mfo: Boolean,
    bank_checking_account: Boolean,
    address_row: Boolean,
  });

  const hasDirectorData = conformsTo(pick(directorContact, ['name', 'phone_number']), {
    name: Boolean,
    phone_number: Boolean,
  });

  usePostMessageListener(PostMessageContractorUpdated.type, () => query.refetch());

  return {
    hasAgreementsData,
    hasDirectorData,
    agreementsData,
    directorContact,
    query,
  };
};
