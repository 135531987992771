import { Fragment } from 'react';
import { Divider, Skeleton, Stack, Typography } from '@mui/material';

export const ContractorSkeleton = () => (
  <Fragment>
    <Stack spacing={1}>
      <Typography variant="Body/small" color="grey.500">
        <Skeleton variant="text" width={60} />
      </Typography>
      <Typography variant="Body/medium">
        <Skeleton variant="text" width={200} />
      </Typography>
    </Stack>

    <Stack spacing={1}>
      <Typography variant="Body/small" color="grey.500">
        <Skeleton variant="text" width={60} />
      </Typography>
      <Typography variant="Body/medium">
        <Skeleton variant="text" width={60} />
      </Typography>
    </Stack>

    <Stack spacing={1}>
      <Typography variant="Body/small" color="grey.500">
        <Skeleton variant="text" width={60} />
      </Typography>
      <Typography variant="Body/medium">
        <Skeleton variant="text" width={200} />
      </Typography>
    </Stack>

    <Divider />

    <Stack spacing={1}>
      <Typography variant="Body/small" color="grey.500">
        <Skeleton variant="text" width={60} />
      </Typography>
      <Typography variant="Body/medium">
        <Skeleton variant="text" width={150} />
      </Typography>
    </Stack>

    <Stack spacing={1}>
      <Typography variant="Body/small" color="grey.500">
        <Skeleton variant="text" width={60} />
      </Typography>
      <Typography variant="Body/medium">
        <Skeleton variant="text" width={130} />
      </Typography>
    </Stack>

    <Stack spacing={1}>
      <Typography variant="Body/small" color="grey.500">
        <Skeleton variant="text" width={130} />
      </Typography>
      <Typography variant="Body/medium">
        <Skeleton variant="text" width="100%" />
        <Skeleton variant="text" width="50%" />
      </Typography>
    </Stack>

    <Skeleton variant="rounded" height={48} width={169} sx={{ borderRadius: `${48 / 2}px` }} />
  </Fragment>
);
