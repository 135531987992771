import { useRef, useState } from 'react';
import type { AvatarEditorProps } from 'react-avatar-editor';
import AvatarEditor from 'react-avatar-editor';
import { useUpdateEffect } from 'react-use';
import type { DialogProps, SliderOwnProps } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogTitle, Slider, Stack, styled, SvgIcon } from '@mui/material';

import { ConfirmationDialogActionCancelButton, ConfirmationDialogActionProceedButton } from '@/components/dialogs';
import { ReactComponent as ImageSVG } from '@/shared/assets/icons-2.0/image.svg';

type ImageCropDialog = Omit<DialogProps, 'onClose'> & {
  title?: string;
  isLoading: boolean;
  onClose: () => void;
  onEdit: (blob: Blob) => void;
  imageFile: AvatarEditorProps['image'] | null;
  variant?: 'circle' | 'square';
  slotProps?: {
    avatarEditor?: Omit<AvatarEditorProps, 'image'>;
  };
};

const StyledSlider = styled(Slider)(({ theme }) => ({
  '.MuiSlider-track': {
    height: '8px',
    backgroundColor: theme.palette.secondary.main,
    border: 'none',
  },
  '.MuiSlider-thumb': {
    borderRadius: theme.shape.borderRadius * 1.5,
    backgroundColor: theme.palette.secondary.main,
    height: '24px',
    width: '14px',
  },
  '.MuiSlider-rail': {
    backgroundColor: theme.palette.grey[600],
    height: '8px',
  },
}));

const EDITOR_SIZE = 400;
const MIN_SCALE = 0.5;
const MAX_SCALE = 2;
const DEFAULT_SLIDER_VALUE = 34;
const DEFAULT_SCALE = 1;

export const ImageCropDialog = (props: ImageCropDialog) => {
  const {
    isLoading,
    onEdit,
    onClose,
    imageFile,
    variant = 'circle',
    title = 'Загрузка изображения',
    slotProps,
    open,
    ...restOfDialogProps
  } = props;

  const logoEditorRef = useRef<AvatarEditor>(null);
  const [sliderValue, setSliderValue] = useState<number>(DEFAULT_SLIDER_VALUE);
  const [scale, setScale] = useState<number>(DEFAULT_SCALE);

  useUpdateEffect(() => {
    if (!open) {
      setSliderValue(DEFAULT_SLIDER_VALUE);
      setScale(DEFAULT_SCALE);
    }
  }, [open]);

  if (!imageFile) {
    return null;
  }

  const handleSliderChange: SliderOwnProps['onChange'] = (_, value) => {
    if (typeof value === 'number') {
      setSliderValue(value);
      const newScale = MIN_SCALE + (value / 100) * (MAX_SCALE - MIN_SCALE);
      setScale(newScale);
    }
  };

  const handleSendButtonClick = () => {
    const canvasScaled = logoEditorRef.current?.getImageScaledToCanvas();

    if (!canvasScaled) {
      return;
    }

    canvasScaled.toBlob(blob => {
      if (!blob) {
        return;
      }

      onEdit(blob);
    });
  };

  return (
    <Dialog open={open} fullWidth maxWidth="xs" onClose={onClose} {...restOfDialogProps}>
      <DialogTitle variant="h4">{title}</DialogTitle>
      <DialogContent>
        <Stack spacing={1.5}>
          <AvatarEditor
            ref={logoEditorRef}
            image={imageFile}
            width={EDITOR_SIZE}
            height={EDITOR_SIZE}
            color={[0, 0, 0, 0.4]}
            border={0}
            borderRadius={variant === 'circle' ? EDITOR_SIZE / 2 : 0}
            scale={scale}
            rotate={0}
            {...slotProps?.avatarEditor}
          />

          <Stack spacing={3} direction="row" sx={{ mb: 1, paddingBottom: '24px' }} alignItems="center">
            <SvgIcon component={ImageSVG} sx={{ fontSize: '16px' }} />
            <StyledSlider aria-label="Volume" value={sliderValue} onChange={handleSliderChange} disabled={isLoading} />
            <SvgIcon component={ImageSVG} sx={{ fontSize: '24px' }} />
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions
        sx={{
          padding: theme => `${theme.spacing(2, 3)} !important`,
          borderTop: theme => theme.shape.divider,
        }}
      >
        <ConfirmationDialogActionCancelButton fullWidth={false} onClick={onClose} />
        <ConfirmationDialogActionProceedButton
          autoFocus
          fullWidth={false}
          loading={isLoading}
          onClick={handleSendButtonClick}
        >
          Отправить
        </ConfirmationDialogActionProceedButton>
      </DialogActions>
    </Dialog>
  );
};
