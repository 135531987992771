import { Stack, Typography } from '@mui/material';

import type { Contact } from '@/api/domains/contractors.types';
import { formatPhoneNumber } from '@/shared/helpers/format.helpers';

import { InfoItem } from './InfoItem';

type DistributorContactItemProps = {
  contact: Contact;
};

export const DistributorContactItem = (props: DistributorContactItemProps) => {
  const { contact } = props;
  const { name, phone_number, contact_type } = contact;

  if (!name && !phone_number) {
    return null;
  }

  return (
    <Stack spacing={0.5}>
      <InfoItem title={contact_type?.name ?? ''} description={name} />
      {!!phone_number && <Typography variant="inherit">{formatPhoneNumber(phone_number)}</Typography>}
    </Stack>
  );
};
