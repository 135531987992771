import type { CardProps, StackProps } from '@mui/material';
import { Card, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

// ----------------------------------------------------------------------

export type DataGridActionbarProps = Omit<StackProps, 'direction'> & CardProps;

// ----------------------------------------------------------------------

const StyledRoot = styled(Card)(({ theme }) => ({
  display: 'inline-flex',
  padding: theme.spacing(0.5),
  borderRadius: theme.shape.borderRadius,
})) as typeof Card;

// ----------------------------------------------------------------------

export const DataGridActionbar = (props: DataGridActionbarProps) => (
  <div className="DataGrid-actionbar">
    <StyledRoot component={Stack} variant="elevation" elevation={3} direction="row" spacing={1} {...props} />
  </div>
);
