import { Stack, Typography } from '@mui/material';

import { formatNumberByThousands } from '@/shared/helpers/format.helpers';

type TotalAmountProps = {
  paymentSum: number;
};

export const TotalAmount = (props: TotalAmountProps) => (
  <Stack direction="row" spacing={1} justifyContent="flex-end">
    <Typography variant="Headline/Bold/large">{formatNumberByThousands(props.paymentSum)}</Typography>
    <Typography variant="Headline/large">UZS</Typography>
  </Stack>
);
