import { DorimPromoPaths, type RouteObjectWithBreadcrumbs } from '@/shared/lib/react-router';

export const ProcurementPlanPageRoute: RouteObjectWithBreadcrumbs = {
  path: DorimPromoPaths.SPECIFICATION_DETAIL_PROCUREMENT_PLAN,
  lazy: async () => {
    const { ProcurementPlanPage } = await import(
      /* webpackChunkName: "ProcurementPlanPage" */
      './ProcurementPlanPage'
    );

    return {
      Component: ProcurementPlanPage,
    };
  },
};
