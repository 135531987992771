import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

type StyledRootProps = {
  size?: 'small' | 'medium';
};

const StyledRoot = styled('div', {
  shouldForwardProp: prop => prop !== 'size',
})<StyledRootProps>(({ theme, size }) => ({
  display: 'flex',
  alignItems: 'center',
  height: size === 'small' ? 24 : 26,
  padding: theme.spacing(0, 1),
  borderRadius: theme.spacing(0.5),
  backgroundColor: theme.palette.neutral['100'],
}));

export type InputLabelProps = React.PropsWithChildren<StyledRootProps>;

export const InputLabel = (props: InputLabelProps) => {
  const { size = 'small' } = props;

  return (
    <StyledRoot size={size} className="InputLabel">
      <Typography component="kbd" variant={size === 'small' ? 'Label/medium' : 'Label/large'} color="neutral.500">
        {props.children}
      </Typography>
    </StyledRoot>
  );
};
