import { Controller, useFormContext } from 'react-hook-form';
import { useToggle } from 'react-use';
import { ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import type { TextFieldProps } from '@mui/material';
import { multiSectionDigitalClockSectionClasses } from '@mui/x-date-pickers';

import type { TimePickerProps } from './TimePicker';
import { TimePicker } from './TimePicker';

export type RHFTimePickerProps = Omit<TimePickerProps<Date>, 'value' | 'onChange'> & {
  name: string;
  id: string;
  label?: string;
  format?: string;
  TextFieldProps?: Omit<TextFieldProps, 'onBlur'>;
};

const TimePickerLayoutSx = {
  [`.${multiSectionDigitalClockSectionClasses.root}:after`]: {
    display: 'none',
  },
};

const TimePickerPopperSx = {
  '& .MuiMultiSectionDigitalClockSection-item.Mui-selected': {
    backgroundColor: '#3366FF',

    '&:hover, &:focus-visible': {
      backgroundColor: '#1939B7',
    },
  },
  '& .MuiDialogActions-root .MuiButton-text': {
    color: '#3366FF',
  },
  '& ::-webkit-scrollbar': {
    width: '12px',
  },
  '& ::-webkit-scrollbar-thumb': {
    backgroundColor: '#9AA7B5',
    border: '4px solid transparent',
    backgroundClip: 'padding-box',
    borderRadius: '9px',
  },
};

const TimePickerTextFieldSx = {
  width: '120px',
  caretColor: 'transparent',
  input: {
    cursor: 'pointer',
    userSelect: 'none',
  },
};

export const RHFTimePicker = (props: RHFTimePickerProps) => {
  const { id, name, label, format = 'HH:mm', TextFieldProps, ...timePickerProps } = props;
  const { placeholder = 'чч:мм', ...restOfTextFieldProps } = TextFieldProps ?? {};

  const [isDropdownOpen, toggleIsDropdownOpen] = useToggle(false);
  const { control } = useFormContext();

  const closeDropdown = () => toggleIsDropdownOpen(false);

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onBlur, ...restFieldProps }, fieldState: { error } }) => (
        <TimePicker
          {...restFieldProps}
          {...timePickerProps}
          format={format}
          onClose={closeDropdown}
          open={isDropdownOpen}
          selectedSections={null}
          timeSteps={{ minutes: 1 }}
          closeOnSelect={false}
          slots={{
            openPickerIcon: () =>
              isDropdownOpen ? <ExpandLessIcon fontSize="small" /> : <ExpandMoreIcon fontSize="small" />,
          }}
          slotProps={{
            layout: {
              sx: TimePickerLayoutSx,
            },
            popper: {
              sx: TimePickerPopperSx,
            },
            textField: {
              ...restOfTextFieldProps,
              id,
              sx: TimePickerTextFieldSx,
              variant: 'filled',
              placeholder,
              label,
              error: !!error,
              helperText: error?.message ?? '',
              onBlur: onBlur,
              onKeyDown: handleOnKeyDown,
              onClick: toggleIsDropdownOpen,
            },
            inputAdornment: {
              onBlur: onBlur,
              onKeyDown: handleOnKeyDown,
              onClick: toggleIsDropdownOpen,
            },
          }}
        />
      )}
    />
  );
};
