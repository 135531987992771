import type { To as RouterLinkTo } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import type { TypographyTypeMap } from '@mui/material';
import { Typography } from '@mui/material';
import type { OverridableComponent } from '@mui/material/OverridableComponent';
import { styled } from '@mui/material/styles';

import { useActiveRoute } from '@/shared/lib/react-router';

// ----------------------------------------------------------------------

export type NavbarSubmenuButtonProps = {
  to: RouterLinkTo;
  title: string;
  onClick?: () => void;
};

type StyledProps = {
  isActive?: boolean;
};

// ----------------------------------------------------------------------

const StyledRoot = styled(Typography, {
  shouldForwardProp: propName => propName !== 'isActive',
})<StyledProps>(
  ({ theme }) => ({
    display: 'block',
    width: '100%',
    padding: theme.spacing(1.5),
    borderRadius: theme.shape.borderRadius,
    textDecoration: 'none',
    color: theme.palette.neutral['700'],
    transition: theme.transitions.create(['color', 'background-color']),

    ':hover': {
      backgroundColor: theme.palette.custom.grey,
    },
  }),
  ({ isActive }) =>
    isActive && {
      color: '#0C63E7',
    },
) as OverridableComponent<TypographyTypeMap<StyledProps>>;

// ----------------------------------------------------------------------

export const NavbarSubmenuButton = (props: NavbarSubmenuButtonProps) => {
  const { to, title, onClick } = props;

  const isActiveRoute = useActiveRoute(to);

  return (
    <StyledRoot isActive={isActiveRoute} component={RouterLink} to={to} fontSize={14} onClick={onClick}>
      {title}
    </StyledRoot>
  );
};
