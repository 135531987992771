import { Outlet } from 'react-router';

import { SupportButton } from '@/components/SupportButton';
import { SupportLinks } from '@/components/SupportLinks';
import { PageContainerBase } from '@/layouts/Page';
import { Welcome } from '@/pages/auth/components/Welcome';
import { useResponsive } from '@/shared/lib/mui';

import {
  StyledContainer,
  StyledContent,
  StyledContentOuter,
  StyledCopyright,
  StyledFooter,
  StyledFormWrapper,
  StyledOvalContainer,
  StyledRoot,
  StyledSupportSection,
} from './styles';

export type AuthLayoutProps = {
  title: string;
};

export const AuthLayout = ({ title }: AuthLayoutProps) => {
  const isMobile = useResponsive('down', 'tablet');

  return (
    <PageContainerBase title={title}>
      <StyledRoot>
        <StyledContainer>
          <StyledContentOuter>
            <StyledOvalContainer>
              <Welcome />
            </StyledOvalContainer>

            <StyledContent>
              <StyledFormWrapper>
                <Outlet />
              </StyledFormWrapper>
            </StyledContent>
          </StyledContentOuter>
        </StyledContainer>

        <StyledFooter>
          <StyledCopyright>Dorim © 2024. Все права защищены.</StyledCopyright>
          <StyledSupportSection>{isMobile ? <SupportLinks /> : <SupportButton />}</StyledSupportSection>
        </StyledFooter>
      </StyledRoot>
    </PageContainerBase>
  );
};
