import { Fragment } from 'react';

import { BlockedUser } from '@/pages/auth/components/Ban/BlockedUser';
import { StyledTitle } from '@/pages/auth/components/Ban/TemporarilyBlocked/styles';
import { formatDateTime } from '@/shared/helpers/format.helpers';

export type TemporarilyBlockedProps = {
  expiryDate: Date;
  title?: string;
};

export const TemporarilyBlocked = ({ expiryDate, title }: TemporarilyBlockedProps) => {
  const formattedDate = formatDateTime(expiryDate, 'dd.MM.yyyy HH:mm');

  return (
    <Fragment>
      {title && <StyledTitle>{title}</StyledTitle>}
      <BlockedUser reasonText="Ваш IP-адрес заблокирован" detailsText={`до ${formattedDate}`} />
    </Fragment>
  );
};
