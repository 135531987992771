import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { createStoreWithReset } from '@/shared/lib/zustand';

type DialogType = 'create' | 'edit';

export type DialogProps = {
  isLoading: boolean;
  open: boolean;
  onClose: () => void;
  onProceed: () => void;
};

type SetDialogProps = Partial<Pick<DialogProps, 'isLoading' | 'onProceed'>>;

type ChangeTabIfNeededEvent = {
  isTabChanged: boolean;
};

type ChangeTabIfNeededHandler = (event: ChangeTabIfNeededEvent) => void;

export type ModalTabbarStore = {
  shouldInterceptTabChange: boolean;
  changeTabHandler: (() => void) | null;
  dialogType: DialogType | null;
  dialogProps: DialogProps;

  actions: {
    initDialog: (dialogType: DialogType) => void;
    setDialogProps: (props: SetDialogProps) => void;
    openDialog: () => void;
    closeDialog: () => void;

    setShouldInterceptTabChange: (shouldInterceptTabChange: boolean) => void;
    setChangeTabHandler: (changeTabHandler: () => void) => void;
    resetChangeTabHandler: () => void;
    changeTabIfNeeded: (changeTabIfNeededHandler?: ChangeTabIfNeededHandler) => void;
  };
};

let uid = 0;

export const createModalTabbarStore = () =>
  createStoreWithReset<ModalTabbarStore>()(
    devtools(
      immer((set, get) => ({
        shouldInterceptTabChange: false,
        changeTabHandler: null,
        dialogType: null,
        dialogProps: {
          isLoading: false,
          open: false,
          onClose: () => {
            if (get().dialogType === 'edit') {
              get().actions.changeTabIfNeeded();
            } else {
              get().actions.resetChangeTabHandler();
            }

            get().actions.closeDialog();
          },
          onProceed: () => {},
        },

        actions: {
          initDialog: dialogType => set({ dialogType }, false, 'initDialog'),
          setDialogProps: props =>
            set(
              store => {
                store.dialogProps = {
                  ...store.dialogProps,
                  ...props,
                };
              },
              false,
              'setDialogProps',
            ),
          openDialog: () =>
            set(
              store => {
                store.dialogProps.open = true;
              },
              false,
              'openDialog',
            ),
          closeDialog: () =>
            set(
              store => {
                store.dialogProps.open = false;
              },
              false,
              'closeDialog',
            ),

          setShouldInterceptTabChange: shouldInterceptTabChange =>
            set({ shouldInterceptTabChange }, false, 'setShouldInterceptTabChange'),
          setChangeTabHandler: changeTabHandler => set({ changeTabHandler }, false, 'setChangeTabHandler'),
          resetChangeTabHandler: () => set({ changeTabHandler: null }, false, 'resetChangeTabHandler'),
          changeTabIfNeeded: changeTabIfNeededHandler => {
            const { changeTabHandler, actions } = get();

            let isTabChanged = false;
            if (changeTabHandler) {
              changeTabHandler();
              actions.resetChangeTabHandler();

              isTabChanged = true;
            }

            changeTabIfNeededHandler?.({ isTabChanged });
          },
        },
      })),
      {
        name: `modal-tabbar-store-${uid++}`,
        enabled: process.env.NODE_ENV === 'development',
      },
    ),
  );
