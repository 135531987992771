import { useMutation } from '@tanstack/react-query';

import type { VerifyOTPFormData, VerifyOTPResponseBody } from '@/api/domains/auth.types';

type UseVerifyOTPParams = {
  onSuccess?: (data: VerifyOTPResponseBody) => void;
  onError?: (error: unknown) => void;
  onMutate?: () => void;
  verifyOtpApiMethod: (formData: VerifyOTPFormData) => Promise<VerifyOTPResponseBody>;
};

export const useVerifyOTP = ({ onSuccess, onError, onMutate, verifyOtpApiMethod }: UseVerifyOTPParams) => {
  const { mutateAsync: verifyOTP } = useMutation({
    mutationFn(formData: VerifyOTPFormData) {
      return verifyOtpApiMethod(formData);
    },
    onSuccess({ signup_token }) {
      if (signup_token) {
        onSuccess?.({ signup_token });
      }
    },
    onError(error) {
      onError?.(error);
    },
    onMutate: () => {
      onMutate?.();
    },
  });

  return verifyOTP;
};
