import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useEffectOnce } from 'react-use';
import { Box } from '@mui/material';

import { useGlobalErrorStore } from '@/components/dialogs';
import { ModalBody, ModalFooter, ModalHeader, Modal as MuiModal } from '@/components/Modal';
import { profileApi, profileQueryKeys } from '@/modules/profile';
import { OFFERS_LINK } from '@/shared/constants/dorim.constants';

import { FooterForm } from './FooterForm';
import { FooterText } from './FooterText';
import { styles } from './styles';

const EVENT_MESSAGE_TYPE_NAME = 'scrolled-to-bottom';

type Props = {
  isOpen?: boolean;
};

export const Modal = (props: Props) => {
  const { isOpen = false } = props;
  const [isEnd, setIsEnded] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isNotChecked, setIsNotChecked] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const handleScrolledBottom = (event: MessageEvent<{ type: string; message: string }>) => {
    if (event.data.type === EVENT_MESSAGE_TYPE_NAME) {
      setIsEnded(true);
    }
  };

  const handleCheckboxChange = (value: boolean) => {
    setIsChecked(value);
    setIsNotChecked(false);
  };

  const queryClient = useQueryClient();
  const { setGlobalError } = useGlobalErrorStore();

  const handleSubmit = async () => {
    if (!isChecked) {
      setIsNotChecked(true);

      return;
    }

    setIsLoaded(true);

    try {
      await profileApi.profileOffer();
      await queryClient.invalidateQueries({
        queryKey: profileQueryKeys.root,
      });
    } catch (error) {
      setGlobalError(error);
      setIsLoaded(false);
    }
  };

  useEffectOnce(() => {
    window.addEventListener('message', handleScrolledBottom, false);

    return () => {
      window.removeEventListener('message', handleScrolledBottom);
    };
  });

  return (
    <MuiModal open={isOpen}>
      <ModalHeader title="Публичная оферта" hideCloseButton />

      <ModalBody sx={styles.modalBody}>
        <Box sx={styles.iFrame} component="iframe" src={OFFERS_LINK} />
      </ModalBody>

      <ModalFooter>
        {isEnd ? (
          <FooterForm
            isNotChecked={isNotChecked}
            isChecked={isChecked}
            onCheckboxChange={handleCheckboxChange}
            onClick={handleSubmit}
            isLoaded={isLoaded}
          />
        ) : (
          <FooterText />
        )}
      </ModalFooter>
    </MuiModal>
  );
};
