import { alpha, type Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Backdrop(_: Theme) {
  return {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: alpha('#0B0E12', 0.5),
        },
        invisible: {
          background: 'transparent',
        },
      },
    },
  };
}
