import { Fragment } from 'react';
import { Outlet } from 'react-router-dom';
import { Card } from '@mui/material';

import { NavigateConfirmationDialog } from '@/components/dialogs';
import { BaseLayout } from '@/layouts/BaseLayout';
import { Header } from '@/layouts/Header';
import { PageContainer, PageContent } from '@/layouts/Page';

import { ProfileTabs } from './ProfileTabs';

export const ProfileLayout = () => {
  return (
    <Fragment>
      <BaseLayout>
        <PageContainer title="Профиль пользователя">
          <Header title="Профиль пользователя" />
          <ProfileTabs />
          <PageContent>
            <Card variant="outlined" sx={{ flexGrow: 1 }}>
              <Outlet />
            </Card>
          </PageContent>
        </PageContainer>
      </BaseLayout>

      <NavigateConfirmationDialog />
    </Fragment>
  );
};
