import Grid from '@mui/material/Unstable_Grid2';

import { useProfile } from '@/modules/profile';

import { EntityInfoContent } from './EntityInfoContent';
import { EntityInfoSkeleton } from './EntityInfoSkeleton';

export const EntityInfo = () => {
  const { isInitialLoading, contractor } = useProfile();

  return (
    <Grid container mb={5} rowSpacing={5} columnSpacing={2}>
      {isInitialLoading && <EntityInfoSkeleton />}
      {!isInitialLoading && contractor && <EntityInfoContent contractor={contractor} />}
    </Grid>
  );
};
