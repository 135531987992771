import { useMutation, useQueryClient } from '@tanstack/react-query';

import { UserContractorApi } from '@/api/domains/user-contractor.api';
import { UserContractorQueryKeys } from '@/api/domains/user-contractor.query-keys';
import { PostMessageContractorUpdated } from '@/shared/constants/post-message.constants';

export const useUpdateUserContractor = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: UserContractorApi.updateContractor,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: UserContractorQueryKeys.contractor });
      window.opener?.postMessage(PostMessageContractorUpdated, window.location.origin);
    },
  });
};
