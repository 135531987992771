import { Stack, Typography } from '@mui/material';

import type { DistributorBranch, DistributorInfo } from '@/api/domains/contractors.types';
import { formatDateTime, formatNumberByThousands } from '@/shared/helpers/format.helpers';

import { DeliveryRegions, InfoSection, LoadingIndicator, MainDistributorInfo } from './components';
import { useSelectedDistributor } from './distributor-info-modal.store';
import { useDistributorInfo } from './useDistributorInfo';

export type Office = DistributorBranch | DistributorInfo;

export const DistributorInfoContent = () => {
  const distributor = useSelectedDistributor();
  const { isLoading, data: distributorInfo } = useDistributorInfo({
    distributor_id: distributor?.id,
  });

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (!distributorInfo) {
    return <Typography variant="inherit">Данные не найдены</Typography>;
  }

  return (
    <Stack spacing={5}>
      <InfoSection title="Главный офис">
        <MainDistributorInfo distributorInfo={distributorInfo} />
      </InfoSection>

      <DeliveryRegions branches={distributorInfo.branches} distributorInfo={distributorInfo} />

      <Stack direction="row" spacing={8}>
        {distributorInfo.published_at && (
          <InfoSection title="Прайс-лист">
            <Stack spacing={1} width={118}>
              <Typography variant="Body/medium" color="neutral.700">
                Дата публикации
              </Typography>
              <Typography variant="Body/medium">{formatDateTime(distributorInfo.published_at)}</Typography>
            </Stack>
          </InfoSection>
        )}

        {(distributorInfo.min_order_amount > 0 || distributorInfo.min_order_amount_with_delivery > 0) && (
          <InfoSection title="Условия заказа">
            <Stack spacing={1} width={458}>
              {distributorInfo.min_order_amount > 0 && (
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="Body/medium" color="neutral.700">
                    Минимальная сумма заказа, UZS
                  </Typography>
                  <Typography variant="Body/medium">
                    {formatNumberByThousands(distributorInfo.min_order_amount)}
                  </Typography>
                </Stack>
              )}
              {distributorInfo.min_order_amount_with_delivery > 0 && (
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="Body/medium" color="neutral.700">
                    Минимальная сумма заказа с доставкой, UZS
                  </Typography>
                  <Typography variant="Body/medium">
                    {formatNumberByThousands(distributorInfo.min_order_amount_with_delivery)}
                  </Typography>
                </Stack>
              )}
            </Stack>
          </InfoSection>
        )}
      </Stack>
    </Stack>
  );
};
