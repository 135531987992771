import { Fragment } from 'react';

import { DocumentToolbarButton } from '@/components/DocumentToolbar';

import { useSpecificationStoreActions } from '../../../../../../../lib';
import { SpecificationContractAcceptanceDialog } from './ui';

export const AcceptContractButton = () => {
  const { openAcceptContractConfirmationDialog } = useSpecificationStoreActions();

  return (
    <Fragment>
      <DocumentToolbarButton variant="contained" onClick={openAcceptContractConfirmationDialog}>
        Подтвердить спецификацию
      </DocumentToolbarButton>
      <SpecificationContractAcceptanceDialog />
    </Fragment>
  );
};
