import { Stack, styled, Typography, type StackProps } from '@mui/material';
import type { Merge } from 'type-fest';

import { DorimPromoBadge } from '@/components/badges';

type StyledRootProps = Merge<
  StackProps,
  {
    hasDivider?: boolean;
  }
>;

const StyledRoot = styled(Stack)<Pick<StyledRootProps, 'hasDivider'>>(
  ({ theme }) => ({
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: theme.spacing(2, 0, 3),
  }),
  ({ hasDivider, theme }) =>
    hasDivider && {
      borderBottom: theme.shape.divider,
    },
) as React.FC<Merge<StackProps, StyledRootProps>>;

export type DescriptionBaseProps = Merge<
  StyledRootProps,
  {
    description: React.ReactNode;
  }
>;

export const DescriptionBase = (props: DescriptionBaseProps) => {
  const { description, children, ...restProps } = props;

  return (
    <StyledRoot direction="row" spacing={1} {...restProps}>
      <Stack spacing={1}>
        <DorimPromoBadge />
        <Typography variant="Title/medium">{description}</Typography>
      </Stack>
      {children}
    </StyledRoot>
  );
};
