import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import { Card, Divider, Stack, styled, Typography } from '@mui/material';

import { prepareAddress } from '@/shared/helpers/format.helpers';

import type { Office } from '../DistributorInfoContent';
import { DistributorContactItem } from './DistributorContactCard';
import { LoadingIndicator } from './LoadingIndicator';
import { useDistributorContacts } from './useDistributorContacts';

const StyledContactsContainer = styled(Card)(({ theme }) => ({
  padding: theme.spacing(2, 3),
}));

type ContactsCardProps = {
  office: Office;
};

export const ContactsCard = (props: ContactsCardProps) => {
  const {
    office: { id, address },
  } = props;

  const { data: contacts, isLoading } = useDistributorContacts({
    params: {
      contractor_id: id,
    },
  });

  return (
    <StyledContactsContainer variant="outlined">
      <Stack direction="column" spacing={3} divider={<Divider />}>
        {address && (
          <Stack spacing={1} direction="row" justifyContent="flex-start">
            <PlaceOutlinedIcon color="secondary" />
            <Typography variant="Body/large">{prepareAddress(address).completeAddress}</Typography>
          </Stack>
        )}

        {isLoading && <LoadingIndicator />}
        {!isLoading && contacts && contacts?.length > 0 && (
          <Stack spacing={5} direction="row">
            {contacts.map((contact, index) => (
              <DistributorContactItem key={index} contact={contact} />
            ))}
          </Stack>
        )}
      </Stack>
    </StyledContactsContainer>
  );
};
