import { useUnmount } from 'react-use';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import type { FieldNames, Option } from '@/pages/auth/sign-up/forms/types';

type AutocompleteModalState = {
  isAutocompleteModalVisible: boolean;

  selectedOptionTitle: string;
  selectedOptions: Option[];
  selectedFieldName: FieldNames | null;
};

type AutocompleteModalActions = {
  openAutocompleteModal: () => void;
  closeAutocompleteModal: () => void;

  setSelectedOptionTitle: (title: string) => void;
  setSelectedOptions: (options: Option[]) => void;
  setSelectedFieldName: (fieldName: FieldNames) => void;

  resetStore: () => void;
};

export type AutocompleteModalStore = AutocompleteModalState & AutocompleteModalActions;

const initialState: AutocompleteModalState = {
  isAutocompleteModalVisible: false,

  selectedOptionTitle: '',
  selectedOptions: [],
  selectedFieldName: null,
};

const storeName = 'autocomplete-modal-store';
const useAutocompleteModalStore = create<AutocompleteModalStore>()(
  devtools(
    persist(
      set => ({
        // State
        ...initialState,

        // Actions
        openAutocompleteModal: () => set({ isAutocompleteModalVisible: true }, false, 'openAutocompleteModal'),
        closeAutocompleteModal: () => set({ isAutocompleteModalVisible: false }, false, 'closeAutocompleteModal'),

        setSelectedOptionTitle: title => set({ selectedOptionTitle: title }, false, 'setSelectedOptionTitle'),
        setSelectedOptions: options => set({ selectedOptions: options }, false, 'setSelectedOptions'),
        setSelectedFieldName: fieldName => set({ selectedFieldName: fieldName }, false, 'setSelectedFieldName'),

        resetStore: () => set(initialState, false, 'resetAutocompleteStore'),
      }),
      {
        name: storeName,
      },
    ),
    {
      name: storeName,
    },
  ),
);

// Selectors for state
const selectAutocompleteModalState = (state: AutocompleteModalState) => ({
  isAutocompleteModalVisible: state.isAutocompleteModalVisible,

  selectedOptionTitle: state.selectedOptionTitle,
  selectedOptions: state.selectedOptions,
  selectedFieldName: state.selectedFieldName,
});
const selectFieldName = (state: AutocompleteModalState) => state.selectedFieldName;
const selectAutocompleteModalVisible = (state: AutocompleteModalState) => state.isAutocompleteModalVisible;
const selectSelectedOptionTitle = (state: AutocompleteModalState) => state.selectedOptionTitle;
const selectSelectedOptions = (state: AutocompleteModalState) => state.selectedOptions;

// Selectors for actions
const selectAutocompleteModalActions = (state: AutocompleteModalActions) => ({
  openAutocompleteModal: state.openAutocompleteModal,
  closeAutocompleteModal: state.closeAutocompleteModal,

  setSelectedOptionTitle: state.setSelectedOptionTitle,
  setSelectedOptions: state.setSelectedOptions,
  setSelectedFieldName: state.setSelectedFieldName,

  resetStore: state.resetStore,
});

export const useAutocompleteModalStoreState = () => useAutocompleteModalStore(selectAutocompleteModalState);
export const useAutocompleteModalStoreFieldName = () => useAutocompleteModalStore(selectFieldName);
export const useAutocompleteModalStoreVisible = () => useAutocompleteModalStore(selectAutocompleteModalVisible);
export const useAutocompleteModalStoreSelectedOptionTitle = () => useAutocompleteModalStore(selectSelectedOptionTitle);
export const useAutocompleteModalStoreSelectedOptions = () => useAutocompleteModalStore(selectSelectedOptions);

export const useAutocompleteModalStoreActions = () => useAutocompleteModalStore(selectAutocompleteModalActions);
export const useAutocompleteModalResetOnUnmount = () =>
  useUnmount(useAutocompleteModalStore(state => state.resetStore));
