import {
  Card,
  Dialog,
  DialogContent,
  Stack,
  Typography,
  type CardProps,
  type DialogProps,
  type StackProps,
  type TypographyProps,
} from '@mui/material';
import type { OverridableComponent } from '@mui/material/OverridableComponent';

import { ReactComponent as CartSVG } from '@/components/dialogs/assets/icons/cart.svg';
import { ReactComponent as ConfirmSVG } from '@/components/dialogs/assets/icons/confirm.svg';
import { ReactComponent as CreateSVG } from '@/components/dialogs/assets/icons/create.svg';
import { ReactComponent as DeleteSVG } from '@/components/dialogs/assets/icons/delete.svg';
import { ReactComponent as EditSVG } from '@/components/dialogs/assets/icons/edit.svg';
import { ReactComponent as ErrorSVG } from '@/components/dialogs/assets/icons/error.svg';
import { ReactComponent as PublishSVG } from '@/components/dialogs/assets/icons/publish.svg';
import { ReactComponent as WarningSVG } from '@/components/dialogs/assets/icons/warning.svg';

import { RoundedButton, type RoundedButtonProps, type RoundedButtonTypeMap } from '../buttons';

// ----------------------------------------------------------------------

export type DialogSubject =
  | 'warning'
  | 'create'
  | 'edit'
  | 'delete'
  | 'duplicate'
  | 'publish'
  | 'confirm'
  | 'error'
  | 'order';

export type ConfirmationDialogContainerProps = Omit<DialogProps, 'onClose'> & {
  subject: DialogSubject;
  onClose?: () => void;
  testId?: string;
};

export type ConfirmationDialogDoubleContainerProps = Omit<DialogProps, 'onClose'> & {
  onClose?: () => void;
};

// ----------------------------------------------------------------------

const iconsBySubject: Record<DialogSubject, React.ElementType<React.ComponentProps<'svg'>>> = {
  warning: WarningSVG,
  duplicate: WarningSVG,
  create: CreateSVG,
  edit: EditSVG,
  delete: DeleteSVG,
  publish: PublishSVG,
  confirm: ConfirmSVG,
  error: ErrorSVG,
  order: CartSVG,
};

// ----------------------------------------------------------------------

export const ConfirmationDialogContainer = (props: ConfirmationDialogContainerProps) => {
  const { subject = 'warning', children, testId, ...restOfDialogProps } = props;
  const IconSVG = iconsBySubject[subject];
  return (
    <Dialog fullWidth maxWidth="xs" {...restOfDialogProps}>
      <DialogContent sx={{ paddingTop: 5, paddingBottom: 3 }}>
        <Stack data-testid={testId} spacing={3} alignItems="center" textAlign="center">
          <IconSVG width={94} height={94} />
          {children}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

// ----------------------------------------------------------------------

export const ConfirmationDialogHeader = (props: TypographyProps) => (
  <Typography variant="Headline/Bold/small" {...props} />
);

// ----------------------------------------------------------------------

export const ConfirmationDialogBodyCard = (props: CardProps & StackProps) => {
  const { sx, ...restOfProps } = props;

  return (
    <Card
      component={Stack}
      spacing={2}
      sx={{
        width: '100%',
        textAlign: 'left',
        padding: theme => theme.spacing(2),
        backgroundColor: theme => theme.palette.background.neutral,
        ...sx,
      }}
      {...restOfProps}
    />
  );
};

// ----------------------------------------------------------------------

export const ConfirmationDialogBodyText = (props: TypographyProps) => <Typography variant="Body/large" {...props} />;

// ----------------------------------------------------------------------

export const ConfirmationDialogBodyMutedText = (props: TypographyProps<'div'>) => (
  <Typography component="div" variant="Body/medium" color="neutral.700" {...props} />
);

// ----------------------------------------------------------------------

export const ConfirmationDialogActions = (props: StackProps) => (
  <Stack direction="row" width="100%" spacing={1.5} pt={1.5} justifyContent="flex-end" {...props} />
);

// ----------------------------------------------------------------------

export const ConfirmationDialogActionCancelButton = (props: RoundedButtonProps) => {
  const { children = 'Отмена' } = props;

  return (
    <RoundedButton variant="outlined" {...props}>
      {children}
    </RoundedButton>
  );
};

// ----------------------------------------------------------------------

export const ConfirmationDialogActionProceedButton: OverridableComponent<RoundedButtonTypeMap> = (
  props: RoundedButtonProps,
) => {
  const { children = 'Применить' } = props;

  return (
    <RoundedButton variant="contained" {...props}>
      {children}
    </RoundedButton>
  );
};
