import { useEffect, useState } from 'react';
import { AxiosError, HttpStatusCode } from 'axios';

import { useBan } from '@/pages/auth/sign-up/hooks/useBan';
import { useAuthMethods } from '@/shared/lib/auth';

export type IpLimitsVerificationProps = React.PropsWithChildren;

export const IpLimitsVerification = (props: IpLimitsVerificationProps) => {
  const { children } = props;

  const [isAllow, setIsAllow] = useState<boolean>(false);
  const { signUpCheckIpLimits } = useAuthMethods();
  const { applyBan } = useBan();

  useEffect(() => {
    const asyncEffect = async () => {
      try {
        await signUpCheckIpLimits();
        setIsAllow(true);
      } catch (error) {
        if (error instanceof AxiosError) {
          if (error.response?.status === HttpStatusCode.TooManyRequests) {
            if (error.response.headers['retry-after']) {
              applyBan(parseInt(error.response.headers['retry-after'], 10));
            }
          }
        }
      }
    };

    asyncEffect();
    /* eslint-disable-next-line */
  }, []);

  if (isAllow) {
    return children;
  }

  return null;
};
