import { Fragment } from 'react';
import { Outlet } from 'react-router';

import { DistributorInfoModal } from '@/components/DistributorInfoModal';

import { BaseLayout } from '../BaseLayout';
import { Header } from '../Header';
import { PageContainer } from '../Page';
import { ProductSelectionLayoutTabs } from './ProductSelectionLayoutTabs';

export const ProductSelectionLayout = () => (
  <Fragment>
    <BaseLayout>
      <PageContainer title="Подбор предложений">
        <Header title="Подбор предложений" />
        <ProductSelectionLayoutTabs />
        <Outlet />
      </PageContainer>
    </BaseLayout>

    <DistributorInfoModal />
  </Fragment>
);
