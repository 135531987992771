import { Box } from '@mui/material';
import type { OverridableComponent, OverrideProps } from '@mui/material/OverridableComponent';
import type { Theme } from '@mui/material/styles';
import { alpha, styled } from '@mui/material/styles';
import type { SystemProps } from '@mui/system';

// ----------------------------------------------------------------------

type StyledLabelProps = {
  color?: 'secondary' | 'secondary-light';
};

export interface LabelAltPropsMap<P = {}, D extends React.ElementType = 'div'> {
  props: P & SystemProps<Theme> & React.PropsWithChildren<StyledLabelProps>;
  defaultComponent: D;
}

export type LabelAltProps<D extends React.ElementType = LabelAltPropsMap['defaultComponent'], P = {}> = OverrideProps<
  LabelAltPropsMap<P, D>,
  D
>;

// ----------------------------------------------------------------------

const StyledRoot = styled(Box, {
  shouldForwardProp: prop => prop !== 'color',
})<StyledLabelProps>(({ theme, color = 'secondary' }) => ({
  width: 'fit-content',
  display: 'flex',
  alignItems: 'center',
  height: 18,
  padding: '0 5px',
  borderRadius: 4,
  color: color === 'secondary' ? theme.palette.grey[100] : theme.palette.text.primary,
  fontSize: 12,
  fontWeight: 400,
  backgroundColor: color === 'secondary' ? theme.palette.secondary.main : alpha(theme.palette.secondary.main, 0.16),
})) as typeof Box;

// ----------------------------------------------------------------------

export const LabelAlt: OverridableComponent<LabelAltPropsMap> = (props: LabelAltProps) => <StyledRoot {...props} />;
