import { Card, styled } from '@mui/material';

import { PDFViewer, type PDFViewerProps } from '@/components/PDFViewer';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/print/lib/styles/index.css';

const StyledRoot = styled(Card)({
  position: 'relative',
  height: '100%',
  flexGrow: 1,
}) as typeof Card;

const StyledViewerContainer = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
});

export type ContractPDFViewerProps = React.PropsWithChildren<PDFViewerProps>;

export const ContractPDFViewer = (props: ContractPDFViewerProps) => {
  const { children, ...viewerProps } = props;

  return (
    <StyledRoot variant="outlined">
      <StyledViewerContainer>
        <PDFViewer {...viewerProps} />
        {children}
      </StyledViewerContainer>
    </StyledRoot>
  );
};
