import type { LabelColor } from '@/components/labels';

export type CashbackStatusId =
  | typeof CASHBACK_STATUS_DRAFT
  | typeof CASHBACK_STATUS_ACTIVE
  | typeof CASHBACK_STATUS_WILL_BE_ACTIVE
  | typeof CASHBACK_STATUS_CALCULATING
  | typeof CASHBACK_STATUS_CALCULATED
  | typeof CASHBACK_STATUS_AWAITING_PAYMENT
  | typeof CASHBACK_STATUS_CLARIFICATION
  | typeof CASHBACK_STATUS_PAYMENT_COMPLETED
  | typeof CASHBACK_STATUS_CANCELED
  | typeof CASHBACK_STATUS_ARCHIVE
  | typeof CASHBACK_STATUS_NOT_CONFIRMED;

export const CASHBACK_STATUS_DRAFT = 50;
export const CASHBACK_STATUS_ACTIVE = 280;
export const CASHBACK_STATUS_WILL_BE_ACTIVE = 275;
export const CASHBACK_STATUS_CALCULATING = 285;
export const CASHBACK_STATUS_CALCULATED = 290;
export const CASHBACK_STATUS_AWAITING_PAYMENT = 286;
export const CASHBACK_STATUS_CLARIFICATION = 287;
export const CASHBACK_STATUS_PAYMENT_COMPLETED = 295;
export const CASHBACK_STATUS_CANCELED = 30;
export const CASHBACK_STATUS_ARCHIVE = 300;
export const CASHBACK_STATUS_NOT_CONFIRMED = 510;
export const CASHBACK_STATUS_UNKNOWN = 'Unknown';

export const CashbackColorsByStatusId: Record<CashbackStatusId, LabelColor> = {
  [CASHBACK_STATUS_CANCELED]: 'default',
  [CASHBACK_STATUS_DRAFT]: 'warning',
  [CASHBACK_STATUS_WILL_BE_ACTIVE]: 'primary',
  [CASHBACK_STATUS_ACTIVE]: 'success',
  [CASHBACK_STATUS_CALCULATING]: 'warning',
  [CASHBACK_STATUS_AWAITING_PAYMENT]: 'warning',
  [CASHBACK_STATUS_CLARIFICATION]: 'warning',
  [CASHBACK_STATUS_CALCULATED]: 'tetriary',
  [CASHBACK_STATUS_PAYMENT_COMPLETED]: 'success',
  [CASHBACK_STATUS_ARCHIVE]: 'default',
  [CASHBACK_STATUS_NOT_CONFIRMED]: 'default',
};

export const LabelsByCashbackStatusId = {
  [CASHBACK_STATUS_DRAFT]: 'Черновик',
  [CASHBACK_STATUS_NOT_CONFIRMED]: 'Не подтвержден',
  [CASHBACK_STATUS_ACTIVE]: 'Активен',
  [CASHBACK_STATUS_WILL_BE_ACTIVE]: 'Будет активен',
  [CASHBACK_STATUS_CALCULATING]: 'Подсчет выплаты',
  [CASHBACK_STATUS_CALCULATED]: 'Выплата подсчитана',
  [CASHBACK_STATUS_AWAITING_PAYMENT]: 'Ожидается выплата',
  [CASHBACK_STATUS_CLARIFICATION]: 'Уточнение',
  [CASHBACK_STATUS_PAYMENT_COMPLETED]: 'Выплата проведена',
  [CASHBACK_STATUS_CANCELED]: 'Отменен',
  [CASHBACK_STATUS_ARCHIVE]: 'Архивный',
};

export const TOTAL_PAYMENT_STATUSES: CashbackStatusId[] = [
  CASHBACK_STATUS_CALCULATED,
  CASHBACK_STATUS_AWAITING_PAYMENT,
  CASHBACK_STATUS_PAYMENT_COMPLETED,
  CASHBACK_STATUS_CLARIFICATION,
  CASHBACK_STATUS_ARCHIVE,
];
