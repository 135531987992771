import { styled, Typography } from '@mui/material';

export const StyledFieldsContainer = styled('div')`
  margin-bottom: ${({ theme }) => theme.spacing(7)};
  display: flex;
  flex: 1;
  flex-flow: column;
`;

export const StyledResendBox = styled('div')`
  text-align: center;
  display: flex;
  flex-flow: column;
  margin-top: ${({ theme }) => theme.spacing(3)};
  margin-bottom: ${({ theme }) => theme.spacing(3)};
  gap: ${({ theme }) => theme.spacing(3)};
`;

export const StyledErrorText = styled(Typography)`
  color: #ff3000;
  letter-spacing: 0.25px;
`;
