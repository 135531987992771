import { styled } from '@mui/material';

import { ReactComponent as CallSVG } from '@/shared/assets/icons-2.0/call.svg';

export const StyledSupport = styled('div')`
  vertical-align: middle;
  margin-top: auto;
  color: ${({ theme }) => theme.palette.neutral['700']};

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledPhoneIcon = styled(CallSVG)`
  width: 16px;
  height: 16px;
  margin-left: 3px;
  margin-right: 3px;
  vertical-align: middle;
`;
