export type WarningType = 'address' | 'signupToken' | 'internalError' | 'default';
type WarningMessage = {
  headerText: string;
  bodyText: string;
};

export const warningMessage: Record<WarningType, WarningMessage> = {
  address: {
    headerText: 'Замена адреса',
    bodyText: 'Текущий адрес будет заменен данными из юр. лица',
  },
  signupToken: {
    headerText: 'Токен не действителен',
    bodyText: 'Время ожидания истекло. Пожалуйста, зарегистрируйтесь повторно.',
  },
  internalError: {
    headerText: 'Внутренняя ошибка',
    bodyText:
      'Пожалуйста, попробуйте перезагрузить страницу. \nЕсли ошибка повторяется, обратитесь в службу поддержки.',
  },
  default: {
    headerText: 'Неизвестная ошибка',
    bodyText: 'Пожалуйста, обратитесь в службу поддержки.',
  },
};

export const INTERNAL_ERROR_MESSAGE =
  warningMessage.internalError.headerText + '\n' + warningMessage.internalError.bodyText;
