import './DataGrid.scss';

import { Fragment } from 'react';
import ReactDataGrid from '@inovua/reactdatagrid-enterprise';
import { Typography } from '@mui/material';
import moment from 'moment';

import {
  renderSortTool,
  renderTreeCollapseTool,
  renderTreeExpandTool,
  renderTreeLoadingTool,
} from './data-grid.elements';
import { filterTypes } from './data-grid.filter-types';
import { DataGridEmptyText } from './DataGridEmptyText';
import { DataGridPagination } from './pagination';
import type { DataGridPropsBase } from './types';
import { useDataGridInstanceHandlers } from './useDataGridStore';

export const DataGrid = <TData extends Record<string, unknown>>(props: DataGridPropsBase<TData>) => {
  const { paginationProps, loading, instanceId, dataSource, onReady, ...restOfProps } = props;

  const setInstance = useDataGridInstanceHandlers(instanceId);

  const handleReady: DataGridPropsBase<TData>['onReady'] = instanceRef => {
    setInstance(instanceRef);
    onReady?.(instanceRef);
  };

  return (
    <Fragment>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-expect-error */}
      <ReactDataGrid loading={loading} dataSource={dataSource} onReady={handleReady} {...restOfProps} />
      {paginationProps && (
        <DataGridPagination {...paginationProps} isLoading={loading} instanceId={instanceId} dataSource={dataSource} />
      )}
    </Fragment>
  );
};

DataGrid.defaultProps = {
  ...(ReactDataGrid.defaultProps as unknown as DataGridPropsBase),

  useNativeFlex: true,
  enableSelection: true,
  showZebraRows: false,
  showColumnMenuTool: false,
  enableColumnFilterContextMenu: false,
  enableKeyboardNavigation: true,
  columnUserSelect: true,

  rowHeight: 56,
  headerHeight: 40,
  treeNestingSize: 8,
  theme: 'dorim-theme',
  loadingText: 'Загрузка...',
  showCellBorders: 'horizontal',
  emptyText: (
    <DataGridEmptyText>
      <Typography component="p" variant="Body/Bold/large" color="neutral.700">
        Нет записей для отображения
      </Typography>
    </DataGridEmptyText>
  ),
  renderSortTool,
  renderTreeLoadingTool,
  renderTreeCollapseTool,
  renderTreeExpandTool,
} as DataGridPropsBase;

DataGrid.defaultProps.filterTypes = {
  ...DataGrid.defaultProps.filterTypes,
  ...filterTypes,
};

// Moment is used by DataGrid to format dates
(window.moment as unknown) = moment;
