import { Typography } from '@mui/material';

import { StyledButton, StyledFormNavigation } from './styles';
import type { StepsNavigationProps } from './types';

export const StepsNavigation = ({
  onBackwardClick,
  onForwardClick,
  forwardButtonProps,
  backwardButtonProps,
  backwardButtonText = 'Назад',
  forwardButtonText = 'Далее',
  totalSteps,
  currentStep,
}: StepsNavigationProps) => {
  return (
    <StyledFormNavigation>
      <StyledButton onClick={onBackwardClick} variant="outlined" color="inherit" {...backwardButtonProps}>
        {backwardButtonText}
      </StyledButton>
      <Typography>
        Шаг {currentStep} из {totalSteps}
      </Typography>
      <StyledButton onClick={onForwardClick} variant="contained" color="secondary" {...forwardButtonProps}>
        {forwardButtonText}
      </StyledButton>
    </StyledFormNavigation>
  );
};
