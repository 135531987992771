import { Outlet } from 'react-router-dom';
import { Stack } from '@mui/material';

import { Header } from '@/layouts/Header';
import { PageContainer, PageContent } from '@/layouts/Page';
import { useSpecification } from '@/shared/hooks/useSpecification';

import { useSpecificationIdFromPathParams } from '../../lib';
import { useSpecificationStoreResetOnUnmount } from '../lib';
import { ActionBar, FloatingPanel, FloatingPanelTrigger, Toolbar } from './ui';

export const Layout = () => {
  const specificationId = useSpecificationIdFromPathParams();
  const { data: specification } = useSpecification({ specificationId });

  useSpecificationStoreResetOnUnmount();

  return (
    <PageContainer title="Dorim.Promo • Спецификация">
      <Header hasBreadcrumbs />
      <FloatingPanelTrigger />
      <Toolbar />

      <PageContent>
        <Stack spacing={1} height="100%">
          <Outlet />
          {specification?.agreement && <FloatingPanel agreement={specification.agreement} />}
        </Stack>
      </PageContent>

      <ActionBar />
    </PageContainer>
  );
};
