import { useStepsNavigation } from '@/pages/auth/hooks/useStepsNavigation';
import type { Step } from '@/pages/auth/sign-up/constants';
import { SIGN_UP_FORM_STEPS } from '@/pages/auth/sign-up/constants';
import { AuthPaths } from '@/shared/lib/react-router';

export const useNavigation = () => {
  return useStepsNavigation<Step>({
    steps: SIGN_UP_FORM_STEPS,
    routerPath: AuthPaths.SIGN_UP.STEP,
  });
};
