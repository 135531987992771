import { useNavigate } from 'react-router-dom';
import { Stack, Typography } from '@mui/material';

import {
  ConfirmationDialogActionCancelButton,
  ConfirmationDialogActionProceedButton,
  ConfirmationDialogActions,
  ConfirmationDialogContainer,
  ConfirmationDialogHeader,
} from '@/components/dialogs';
import { DEFAULT_DATE_FORMAT } from '@/shared/constants/date-formats.constants';
import { formatDateTime } from '@/shared/helpers/format.helpers';
import { useSpecification } from '@/shared/hooks/useSpecification';
import { DorimPromoPaths } from '@/shared/lib/react-router';

import { useSpecificationIdFromPathParams } from '../../../../../lib';
import { useSpecificationContextWindowsVisibility, useSpecificationStoreActions } from '../../../../lib';

export const MainContractSigningConfirmationDialog = () => {
  const specificationId = useSpecificationIdFromPathParams();
  const { data: specification } = useSpecification({ specificationId });

  const { isMainContractConfirmationDialogVisible } = useSpecificationContextWindowsVisibility();
  const { closeMainContractConfirmationDialog } = useSpecificationStoreActions();

  const navigate = useNavigate();
  const navigateToMainContractDocument = async () => {
    if (!specificationId) {
      return;
    }

    navigate(
      DorimPromoPaths.generateSpecificationDetailContractDocumentPath({
        specificationId: String(specificationId),
      }),
    );
  };

  if (!specification?.agreement) {
    return null;
  }

  const { start_date, end_date } = specification.agreement;

  return (
    <ConfirmationDialogContainer
      open={isMainContractConfirmationDialogVisible}
      subject="confirm"
      onClose={closeMainContractConfirmationDialog}
    >
      <ConfirmationDialogHeader>Нет подписей в документах</ConfirmationDialogHeader>
      <Stack spacing={3}>
        <Typography variant="Body/medium" color="neutral.700">
          Договор и спецификация на{' '}
          {`${formatDateTime(start_date, DEFAULT_DATE_FORMAT)}-${formatDateTime(end_date, DEFAULT_DATE_FORMAT)}`} еще не
          подписаны.
        </Typography>
        <Typography variant="Body/medium" color="neutral.700">
          Выплата бонуса будет доступна после подписания документов.
        </Typography>
      </Stack>

      <ConfirmationDialogActions>
        <ConfirmationDialogActionCancelButton onClick={closeMainContractConfirmationDialog} />
        <ConfirmationDialogActionProceedButton autoFocus onClick={navigateToMainContractDocument}>
          Перейти к подписанию
        </ConfirmationDialogActionProceedButton>
      </ConfirmationDialogActions>
    </ConfirmationDialogContainer>
  );
};
