import { Link, styled } from '@mui/material';

export const StyledLink = styled(Link)`
  text-decoration: underline;
  color: #1e2f96;

  &:hover {
    text-decoration: none;
  }
`;
export const StyledLabel = styled('span')`
  display: inline-block;

  color: #212b36;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
`;
