import { Fragment } from 'react';
import { OpenInNew as OpenInNewIcon } from '@mui/icons-material';
import { Alert, Divider, Fab, Stack, Typography } from '@mui/material';

import type { UserContractor } from '@/api/domains/user-contractor.type';
import { formatPhoneNumber } from '@/shared/helpers/format.helpers';
import { ProfilePaths } from '@/shared/lib/react-router';

export type AgreementContractorContentProps = {
  isSigned: boolean;
  hasAgreementsData: boolean;
  hasDirectorData: boolean;
  agreementsData: UserContractor['agreements_data'];
  directorContact: UserContractor['director_contact'];
};

export const ContractorContent = (props: AgreementContractorContentProps) => {
  const { isSigned, hasAgreementsData, hasDirectorData, agreementsData, directorContact } = props;

  const openEntityInfo = () => {
    window.open(ProfilePaths.ENTITY_INFO_PATH, '_blank');
  };

  const hasContractorData = hasAgreementsData && hasDirectorData;

  return (
    <Fragment>
      {!hasContractorData && !isSigned && (
        <Alert variant="outlined" severity="error">
          Заполните реквизиты
        </Alert>
      )}

      {isSigned && (
        <Alert variant="outlined" severity="info">
          Договор подписан и доступен для просмотра
        </Alert>
      )}

      <Stack spacing={1}>
        <Typography variant="Body/small" color="grey.500">
          Название банка
        </Typography>
        <Typography variant="Body/medium">{hasAgreementsData ? agreementsData?.bank_name : 'Не заполнено'}</Typography>
      </Stack>

      <Stack spacing={1}>
        <Typography variant="Body/small" color="grey.500">
          МФО банка
        </Typography>
        <Typography variant="Body/medium">{hasAgreementsData ? agreementsData?.bank_mfo : 'Не заполнено'}</Typography>
      </Stack>

      <Stack spacing={1}>
        <Typography variant="Body/small" color="grey.500">
          Расчётный счёт
        </Typography>
        <Typography variant="Body/medium">
          {hasAgreementsData ? agreementsData?.bank_checking_account : 'Не заполнено'}
        </Typography>
      </Stack>

      <Divider />

      <Stack spacing={1}>
        <Typography variant="Body/small" color="grey.500">
          Директор
        </Typography>
        <Typography variant="Body/medium">{hasDirectorData ? directorContact?.name : 'Не заполнено'}</Typography>
      </Stack>

      <Stack spacing={1}>
        <Typography variant="Body/small" color="grey.500">
          Телефон
        </Typography>
        <Typography variant="Body/medium">
          {hasDirectorData && directorContact?.phone_number
            ? formatPhoneNumber(directorContact.phone_number)
            : 'Не заполнено'}
        </Typography>
      </Stack>

      <Stack spacing={1}>
        <Typography variant="Body/small" color="grey.500">
          Юридический адрес
        </Typography>
        <Typography variant="Body/medium">
          {hasAgreementsData ? agreementsData?.address_row : 'Не заполнено'}
        </Typography>
      </Stack>

      <Fab
        variant="outlinedExtended"
        color="inherit"
        size="large"
        sx={{ alignSelf: 'flex-start' }}
        onClick={openEntityInfo}
      >
        <OpenInNewIcon />
        <Typography component="span" variant="button" color="secondary">
          Редактировать
        </Typography>
      </Fab>
    </Fragment>
  );
};
