import { Children, Fragment } from 'react';
import { Stack, Typography } from '@mui/material';

import type { Address } from '@/api/types/address.types';
import { prepareAddress } from '@/shared/helpers/format.helpers';

type AddressStackProps = React.PropsWithChildren<{}>;

const AddressStack = (props: AddressStackProps) => {
  const children = Children.toArray(props.children);
  const lastChild = children.pop();

  return (
    <Typography variant="Body/medium">
      {children.map(
        (child, index) =>
          child && (
            <Fragment key={index}>
              {child}
              {', '}
              <br />
            </Fragment>
          ),
      )}
      {lastChild}
    </Typography>
  );
};

type EntityInfoItemAddressProps = {
  title: React.ReactNode;
  address: Address;
};

export const EntityInfoItemAddress = (props: EntityInfoItemAddressProps) => {
  const { title, address } = props;

  const { region, city, fullAddress } = prepareAddress(address);

  return (
    <Stack spacing={1}>
      <Typography variant="Body/small" color="grey.500">
        {title}
      </Typography>
      <AddressStack>
        {region !== city && region}
        {city}
        {fullAddress}
      </AddressStack>
    </Stack>
  );
};
