import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { DorimPromoApi } from '@/api/domains/dorim-promo.api';
import { DorimPromoQueryKeys } from '@/api/domains/dorim-promo.query-keys';
import { useGlobalErrorStore } from '@/components/dialogs';

export const useAcceptSpecificationContract = () => {
  const snackbar = useSnackbar();
  const queryClient = useQueryClient();
  const { setGlobalError } = useGlobalErrorStore();

  return useMutation({
    mutationFn: DorimPromoApi.acceptSpecificationContract,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: DorimPromoQueryKeys.root });
      snackbar.enqueueSnackbar('Условия спецификации приняты', {
        variant: 'success',
      });
    },
    onError: setGlobalError,
  });
};
