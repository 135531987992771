import { useSignUpStoreActions } from '@/pages/auth/sign-up/store/signUp.store';
import { useOTPStoreActions } from '@/shared/stores/otp.store';

export const useClearSignUpData = () => {
  const { resetSignUpData } = useSignUpStoreActions();
  const { resetSignUpOTPData } = useOTPStoreActions();

  const clearSignUpData = () => {
    resetSignUpData();
    resetSignUpOTPData();
  };

  return clearSignUpData;
};
