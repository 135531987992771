import { Stack, styled, SvgIcon, Typography } from '@mui/material';

import type { MarketingOrgInfo, SumPlanState } from '@/api/domains/dorim-promo.types';
import { ReactComponent as DoneSVG } from '@/shared/assets/icons-2.0/done.svg';

const getDescription = (planState: SumPlanState) => {
  switch (planState) {
    case 'underMinPlan':
      return 'При достижении минимального плана, вы получите бонус по текущему прогрессу в товарах';
    case 'minPlanCompletion':
      return 'По достижению максимального значения вы получите максимальный % бонуса по всем товарам';
    case 'maxPlanCompletion':
      return 'Вы достигли максимального плана и получите максимальный % бонуса по всем товарам';
    default:
      return 'Выполняйте индивидуальный план по каждому товару и получите указанный % бонуса';
  }
};

const StyledRoot = styled(Stack)({
  width: 360,
  justifyContent: 'center',
}) as typeof Stack;

type MarketingOrgCardInfoBlockProps = {
  hasSubtitle?: boolean;
  hasDescription?: boolean;
  marketingOrgName?: MarketingOrgInfo['full_name'];
  sumPlanState: SumPlanState;
};

export const MarketingOrgCardInfoBlock = (props: MarketingOrgCardInfoBlockProps) => {
  const { hasSubtitle = true, hasDescription = true, marketingOrgName = '-', sumPlanState } = props;

  const renderName = () => {
    const nameElement = (
      <Stack spacing={1} alignItems="center" direction="row">
        <Typography variant="Title/Bold/large">{marketingOrgName}</Typography>
        {sumPlanState === 'maxPlanCompletion' && <SvgIcon component={DoneSVG} sx={{ color: 'custom.green' }} />}
      </Stack>
    );

    if (hasSubtitle) {
      return (
        <div>
          {nameElement}
          <Typography variant="Body/medium" color="neutral.700">
            Маркетирующая организация
          </Typography>
        </div>
      );
    }

    return nameElement;
  };

  return (
    <StyledRoot spacing={2}>
      {renderName()}
      {hasDescription && <Typography variant="Body/medium">{getDescription(sumPlanState)}</Typography>}
    </StyledRoot>
  );
};
