import type { To } from 'react-router-dom';
import { useMatch, useResolvedPath } from 'react-router-dom';

// ----------------------------------------------------------------------

export const useMatchLink = (link: To) => {
  const { pathname: path } = useResolvedPath(link);

  return useMatch({ path, end: true });
};
