import { useMarketingOrg } from '@/shared/hooks/useMarketingOrg';
import {
  BreadcrumbLink,
  DorimPromoPaths,
  LazyBreadcrumb,
  type RouteObjectWithBreadcrumbs,
  type SpecificationDetailSalesPlanProductViewPathParams,
} from '@/shared/lib/react-router';

export const SalesPlanProductsPageRoute: RouteObjectWithBreadcrumbs = {
  path: DorimPromoPaths.SPECIFICATION_DETAIL_SALES_PLAN_PRODUCT_VIEW_PATH,
  handle: {
    breadcrumb: (params: SpecificationDetailSalesPlanProductViewPathParams) => ({
      Component: () => {
        const { data, isLoading } = useMarketingOrg({
          specificationId: Number(params.specificationId),
          marketingOrgId: Number(params.marketingOrgId),
        });

        return (
          <LazyBreadcrumb isLoading={isLoading}>
            <BreadcrumbLink to={DorimPromoPaths.generateMarketingOrgDetailPath(params)}>
              {data?.marketing_org_details?.marketing_org?.full_name || 'Маркетирующая организация'}
            </BreadcrumbLink>
          </LazyBreadcrumb>
        );
      },
    }),
  },
  lazy: async () => {
    const { SalesPlanProductsPage } = await import(
      /* webpackChunkName: "SalesPlanProductsPage" */
      './SalesPlanProductsPage'
    );

    return {
      Component: SalesPlanProductsPage,
    };
  },
};
