import type { StackProps } from '@mui/material';
import { Stack, styled } from '@mui/material';

const StyledRoot = styled(Stack)({
  height: '54px',
  width: '100%',
  alignItems: 'center',
}) as typeof Stack;

export const PanelBase = (props: StackProps) => <StyledRoot {...props} />;
