import jwtDecode from 'jwt-decode';

import { getUnixTimestamp } from '../date-fns';
import type { DecodedJwt } from './jwt.types';

export const isAccessToken = (accessToken: unknown): accessToken is string => {
  if (typeof accessToken !== 'string') {
    return false;
  }

  try {
    const decodedHeader = jwtDecode<DecodedJwt>(accessToken, {
      header: true,
    });

    return typeof decodedHeader.typ === 'string' && typeof decodedHeader.alg === 'string';
  } catch (error) {
    console.error({ isAccessToken: error });

    return false;
  }
};

export const isAccessTokenExpired = (accessToken: string): boolean => {
  try {
    const decoded = jwtDecode<{ exp: number }>(accessToken);
    const currentTime = getUnixTimestamp();

    return currentTime > decoded.exp;
  } catch (error) {
    console.error({ isAccessTokenExpired: error });

    return false;
  }
};
