import { Fragment } from 'react';
import { Outlet } from 'react-router';
import { Stack } from '@mui/material';

import { NavigateConfirmationDialog } from '@/components/dialogs';
import { BaseLayout } from '@/layouts/BaseLayout';

export const SettingsLayout = () => (
  <Fragment>
    <BaseLayout>
      <Stack sx={{ height: '100%' }}>
        <Outlet />
      </Stack>
    </BaseLayout>

    <NavigateConfirmationDialog />
  </Fragment>
);
