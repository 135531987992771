import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import queryString from 'query-string';

import type { CitiesParams } from '@/api/domains/cities.api';
import { CitiesQueryKeys } from '@/api/domains/cities.query-keys';
import type { City } from '@/api/domains/cities.types';
import type { CityDistrictsParams } from '@/api/domains/city-districts.api';
import { CityDistrictsQueryKeys } from '@/api/domains/city-districts.query-keys';
import type { CityDistrict } from '@/api/domains/city-districts.types';
import type { InfoAlias, InfoParams } from '@/api/domains/info.api';
import { InfoQueryKeys } from '@/api/domains/info.query-keys';
import type { Info } from '@/api/domains/info.types';
import type { RegionsParams } from '@/api/domains/regions.api';
import { RegionsQueryKeys } from '@/api/domains/regions.query-keys';
import type { Region } from '@/api/domains/regions.types';
import type { PaginatedList } from '@/api/types/pagination.types';
import { COUNTRY_UZBEKISTAN } from '@/shared/constants/contries.constants';
import { useOTPStoreSignUpVerifyData } from '@/shared/stores/otp.store';

type UseSignupData = {
  canHaveCityDistricts: boolean;
  selectedRegion?: Region | null;
  selectedCity?: City | null;
};

// Специальный HttpClient для формы регистрации. Используется для возможности делать запросы с помощью signup_token.
// В основном httpClient используются access_token и refresh_token, доступные только после авторизации.
const signUpHttpClient = axios.create({
  withCredentials: false,
  baseURL: import.meta.env.VITE_APP_API_URL,
  timeout: 30 * 1000,
  paramsSerializer: {
    ...axios.defaults.paramsSerializer,
    serialize: params => queryString.stringify(params, { arrayFormat: 'comma' }),
  },
});

const getData = async <T extends CityDistrict | Region | City | Info>(
  token: string,
  params: CityDistrictsParams | RegionsParams | CitiesParams | InfoParams,
  endpoint: string,
) => {
  const { data } = await signUpHttpClient.get<PaginatedList<'items', T>>(endpoint, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
};

const fetchRegions = async (token: string, country_ids?: string[]) => {
  const { items } = await getData<Region>(token, { country_ids }, 'regions');

  return items;
};

const fetchCities = async (token: string, region_ids?: string[]) => {
  const { items } = await getData<City>(token, { region_ids }, 'cities');

  return items;
};

const fetchCityDistricts = async (token: string, city_ids?: string[]) => {
  const { items } = await getData<CityDistrict>(token, { city_ids }, 'cities/districts');

  return items;
};

const fetchStreetType = async (token: string, aliases?: InfoAlias[]) => {
  const { items } = await getData<Info>(token, { aliases }, 'info');

  return items;
};

export const useSignupData = (fieldsInfo: UseSignupData) => {
  const verifyData = useOTPStoreSignUpVerifyData();
  const { signup_token } = verifyData;

  const { canHaveCityDistricts, selectedRegion, selectedCity } = fieldsInfo;

  const regionsParams: RegionsParams = { country_ids: [COUNTRY_UZBEKISTAN] };
  const { isFetching: isFetchingRegions, data: regions = [] } = useQuery({
    queryKey: RegionsQueryKeys.regions(regionsParams),
    queryFn: () => fetchRegions(signup_token, regionsParams.country_ids),
    staleTime: Infinity,
  });

  const citiesParams: CitiesParams = { region_ids: [String(selectedRegion?.id)] };
  const { isFetching: isFetchingCities, data: cities = [] } = useQuery({
    queryKey: CitiesQueryKeys.cities(citiesParams),
    queryFn: () => fetchCities(signup_token, citiesParams.region_ids),
    staleTime: Infinity,
    enabled: !!selectedRegion,
  });

  const cityDistrictsParams: CityDistrictsParams = { city_ids: [String(selectedCity?.id)] };
  const { isFetching: isFetchingCityDistricts, data: cityDistricts = [] } = useQuery({
    queryKey: CityDistrictsQueryKeys.cityDistricts(cityDistrictsParams),
    queryFn: () => fetchCityDistricts(signup_token, cityDistrictsParams.city_ids),
    staleTime: Infinity,
    enabled: canHaveCityDistricts,
  });

  const streetTypeParams: InfoParams = { aliases: ['street_type'] };
  const { isFetching: isFetchingStreetType, data: streetTypes = [] } = useQuery({
    queryKey: InfoQueryKeys.info(streetTypeParams),
    queryFn: () => fetchStreetType(signup_token, streetTypeParams.aliases),
    staleTime: Infinity,
  });

  return {
    isFetchingRegions,
    isFetchingCities,
    isFetchingCityDistricts,
    isFetchingStreetType,
    regions,
    cities,
    cityDistricts,
    streetTypes,
  };
};
