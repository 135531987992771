import type { ControllerRenderProps } from 'react-hook-form';
import { Controller, useFormContext } from 'react-hook-form';

import { MaskedPhoneTextField, type MaskedPhoneTextFieldProps } from './MaskedPhoneTextField';

type LimitedMaskedPhoneTextFieldProps = Omit<
  MaskedPhoneTextFieldProps,
  'name' | 'label' | 'error' | 'helperText' | keyof ControllerRenderProps
>;

export type RHFMaskedPhoneTextFieldProps = LimitedMaskedPhoneTextFieldProps & {
  name: string;
  // eslint-disable-next-line react/boolean-prop-naming
  disabled?: boolean;
};

export const RHFMaskedPhoneTextField = (props: RHFMaskedPhoneTextFieldProps) => {
  const { name, ...restProps } = props;

  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, ...fieldProps }, fieldState: { error } }) => {
        const reassignedOnChange: MaskedPhoneTextFieldProps['onValueChange'] = ({ value }) => {
          onChange(value);
        };

        return (
          <MaskedPhoneTextField
            valueIsNumericString
            error={!!error}
            helperText={error?.message}
            {...fieldProps}
            {...restProps}
            onValueChange={reassignedOnChange}
          />
        );
      }}
    />
  );
};
