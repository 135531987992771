import { AxiosError, HttpStatusCode } from 'axios';

import type { SendOTPResponseBody } from '@/api/domains/auth.types';
import { VerifyPhone } from '@/pages/auth/components/VerifyPhone';
import { getExpiredTimestamp } from '@/pages/auth/helpers/getExpiredTimestamp';
import { useAuthMethods } from '@/shared/lib/auth';
import {
  useOTPStoreActions,
  useOTPStoreResetPasswordAttemptCounter,
  useOTPStoreResetPasswordSendData,
} from '@/shared/stores/otp.store';

import { useBan } from './hooks/useBan';
import { useNavigation } from './hooks/useNavigation';
import { useResetPasswordState } from './store/resetPassword.store';

export const VerifyPhonePage = () => {
  const { applyBan } = useBan();
  const navigation = useNavigation();
  const { phoneNumber } = useResetPasswordState();
  const sendData = useOTPStoreResetPasswordSendData();
  const { resetPasswordSendOTP, resetPasswordVerifyOTP, resetPasswordCheckIpLimits } = useAuthMethods();
  const {
    setResetPasswordSendData,
    setResetPasswordVerifyData,
    updateResetPasswordExpiredAt,
    clearResetPasswordAttemptCounter,
    incrementResetPasswordAttemptCounter,
  } = useOTPStoreActions();

  const attemptCounter = useOTPStoreResetPasswordAttemptCounter();

  const onVerified = (token: string) => {
    clearResetPasswordAttemptCounter();
    setResetPasswordVerifyData({ signup_token: token });
    navigation.goToNextStep();
  };

  const onCreateOTP = ({ lifetime, sms_version }: SendOTPResponseBody) => {
    if (lifetime && sms_version) {
      setResetPasswordSendData({
        lifetime,
        expiredAt: getExpiredTimestamp(lifetime),
        sms_version,
      });
    }
  };

  const onFailedCreateOTP = async (lifetime: number) => {
    try {
      await resetPasswordCheckIpLimits();
      updateResetPasswordExpiredAt(getExpiredTimestamp(lifetime));
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === HttpStatusCode.TooManyRequests) {
          if (error.response?.headers['retry-after']) {
            applyBan(parseInt(error.response.headers['retry-after']));
          }
        }
      }
    }
  };

  const handleBeforeVerify = () => {
    incrementResetPasswordAttemptCounter();
  };
  const handleBeforeCreateOTP = () => {
    clearResetPasswordAttemptCounter();
  };

  return (
    <VerifyPhone
      title="Восстановление пароля"
      attemptCounter={attemptCounter}
      beforeVerify={handleBeforeVerify}
      beforeCreateOTP={handleBeforeCreateOTP}
      applyBanFn={applyBan}
      verifyOtpApiMethod={resetPasswordVerifyOTP}
      sendOtpApiMethod={resetPasswordSendOTP}
      phoneNumber={phoneNumber}
      otpSendData={{
        smsVersion: sendData.sms_version,
        expiredAt: sendData.expiredAt,
      }}
      onCreateOTP={onCreateOTP}
      onFailedCreateOTP={onFailedCreateOTP}
      onVerified={onVerified}
      navigation={{
        goToNextStep: navigation.goToNextStep,
        goToPreviousStep: navigation.goToPreviousStep,
        currentStep: navigation.currentStepIndex + 1,
        totalSteps: navigation.totalSteps,
      }}
    />
  );
};
