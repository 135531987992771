import { Link } from 'react-router-dom';
import { Tab, Tabs } from '@mui/material';

import { HeaderTabs } from '@/layouts/Header';
import { ProfilePaths, useMatchLinks } from '@/shared/lib/react-router';

export const ProfileTabs = () => {
  const [matchedLink] = useMatchLinks(ProfilePaths.PERSONAL_INFO_PATH, ProfilePaths.ENTITY_INFO_PATH);

  return (
    <HeaderTabs>
      {matchedLink?.pathname && (
        <Tabs value={matchedLink.pathname} textColor="secondary" indicatorColor="secondary">
          <Tab
            component={Link}
            to={ProfilePaths.PERSONAL_INFO_PATH}
            value={ProfilePaths.PERSONAL_INFO_PATH}
            label="Персональные данные"
          />
          <Tab
            component={Link}
            to={ProfilePaths.ENTITY_INFO_PATH}
            value={ProfilePaths.ENTITY_INFO_PATH}
            label="Данные юр. лица"
          />
        </Tabs>
      )}
    </HeaderTabs>
  );
};
