import { CssBaseline } from '@mui/material';
import { ThemeProvider as MuiThemeProvider, StyledEngineProvider } from '@mui/material/styles';

import { theme } from '@/shared/theme';

import { globalStyles } from './GlobalStyles';

export type ThemeProviderProps = React.PropsWithChildren;

export const ThemeProvider = (props: ThemeProviderProps) => {
  const { children } = props;

  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        {globalStyles}
        {children}
      </MuiThemeProvider>
    </StyledEngineProvider>
  );
};
