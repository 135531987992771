import { useParams } from 'react-router-dom';

import { useMainContractDocument } from '@/shared/hooks/useMainContractDocument';
import { DorimPromoPaths, RedirectWithoutAccess, type SpecificationDetailPathParams } from '@/shared/lib/react-router';

type SignedMainContractGuardProps = React.PropsWithChildren;

export const SignedMainContractGuard = (props: SignedMainContractGuardProps) => {
  const { children } = props;
  const { specificationId } = useParams<SpecificationDetailPathParams>();

  const { isSigned, query } = useMainContractDocument();

  if (query.isLoading) {
    return null;
  }

  const path = specificationId
    ? DorimPromoPaths.generateSpecificationDetailMainContractNoticePath({ specificationId })
    : DorimPromoPaths.SPECIFICATIONS_ROOT_PATH;

  return (
    <RedirectWithoutAccess hasAccess={isSigned} to={path}>
      {children}
    </RedirectWithoutAccess>
  );
};
