import type { AvatarProps } from '@mui/material';
import { Avatar, styled } from '@mui/material';

import type { DistributorInfo } from '@/api/domains/contractors.types';

const StyledAvatar = styled(Avatar)<AvatarProps>(({ theme: { palette }, src }) => ({
  width: 36,
  height: 36,
  bottom: 0,
  border: `1px solid ${palette.neutral[300]}`,
  borderRadius: '50%',
  padding: 1,
  backgroundColor: src ? palette.background.paper : palette.neutral[300],
  fontWeight: 700,

  '.MuiAvatar-img': {
    borderRadius: '50%',
  },
}));

type DistributorAvatarProps = AvatarProps & {
  distributor: DistributorInfo;
};

export const DistributorAvatar = (props: DistributorAvatarProps) => {
  const { distributor, ...restProps } = props;

  const altText = (distributor.full_name || distributor.name).replace(/^\W*/, '');
  const fallbackText = altText.charAt(0);

  return (
    <StyledAvatar src={distributor.logo_url} alt={altText} {...restProps}>
      {fallbackText}
    </StyledAvatar>
  );
};
