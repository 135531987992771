import type { TabProps } from '@mui/material';
import type { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Tabs(theme: Theme) {
  return {
    MuiTabs: {
      defaultProps: {
        textColor: 'inherit',
        allowScrollButtonsMobile: true,
        variant: 'scrollable',
      },
      styleOverrides: {
        root: {
          minHeight: 40,
        },
        indicator: {
          display: 'none',
        },
        scrollButtons: {
          width: 48,
          borderRadius: '50%',
        },
      },
    },
    MuiTab: {
      defaultProps: {
        disableRipple: true,
        iconPosition: 'start',
      },
      styleOverrides: {
        root: ({ ownerState }: { ownerState: TabProps }) => ({
          minWidth: 48,
          padding: theme.spacing(0, 1),
          opacity: 1,

          textTransform: 'none',
          ...theme.typography['Title/small'],

          '&:not(:last-of-type)': {
            marginRight: theme.spacing(1),
          },
          '&:not(.Mui-selected)': {
            color: theme.palette.neutral['700'],
          },
          ...((ownerState.iconPosition === 'start' || ownerState.iconPosition === 'end') && {
            minHeight: 40,
          }),
        }),
      },
    },
  };
}
