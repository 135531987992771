import { useUnmount } from 'react-use';
import { devtools } from 'zustand/middleware';

import { createStoreWithReset } from '@/shared/lib/zustand';

type SpecificationStore = {
  isMainContractConfirmationDialogVisible: boolean;
  isBonusConfirmationDialogVisible: boolean;
  isContractClosingDialogVisible: boolean;
  isCalculationErrorReportDialogVisible: boolean;
  isAcceptContractConfirmationDialogVisible: boolean;

  shouldTotalAmountPanelBeVisible: boolean;

  actions: {
    openMainContractConfirmationDialog: () => void;
    closeMainContractConfirmationDialog: () => void;

    openAcceptContractConfirmationDialog: () => void;
    closeAcceptContractConfirmationDialog: () => void;

    openBonusConfirmationDialog: () => void;
    closeBonusConfirmationDialog: () => void;

    openContractClosingDialog: () => void;
    closeContractClosingDialog: () => void;

    openCalculationErrorReportDialog: () => void;
    closeCalculationErrorReportDialog: () => void;

    showTotalAmountPanel: () => void;
    hideTotalAmountPanel: () => void;
  };
};

const [useSpecificationStore, resetSpecificationStore] = createStoreWithReset<SpecificationStore>()(
  devtools(
    set => ({
      isMainContractConfirmationDialogVisible: false,
      isBonusConfirmationDialogVisible: false,
      isContractClosingDialogVisible: false,
      isCalculationErrorReportDialogVisible: false,
      isAcceptContractConfirmationDialogVisible: false,

      shouldTotalAmountPanelBeVisible: false,

      actions: {
        openMainContractConfirmationDialog: () =>
          set({ isMainContractConfirmationDialogVisible: true }, false, 'openMainContractConfirmationDialog'),
        closeMainContractConfirmationDialog: () =>
          set({ isMainContractConfirmationDialogVisible: false }, false, 'closeMainContractConfirmationDialog'),

        openAcceptContractConfirmationDialog: () =>
          set({ isAcceptContractConfirmationDialogVisible: true }, false, 'openAcceptContractConfirmationDialog'),
        closeAcceptContractConfirmationDialog: () =>
          set({ isAcceptContractConfirmationDialogVisible: false }, false, 'closeAcceptContractConfirmationDialog'),

        openBonusConfirmationDialog: () =>
          set({ isBonusConfirmationDialogVisible: true }, false, 'openBonusConfirmationDialog'),
        closeBonusConfirmationDialog: () =>
          set({ isBonusConfirmationDialogVisible: false }, false, 'closeBonusConfirmationDialog'),

        openContractClosingDialog: () =>
          set({ isContractClosingDialogVisible: true }, false, 'openContractClosingDialog'),
        closeContractClosingDialog: () =>
          set({ isContractClosingDialogVisible: false }, false, 'closeContractClosingDialog'),

        openCalculationErrorReportDialog: () =>
          set({ isCalculationErrorReportDialogVisible: true }, false, 'openCalculationErrorReportDialog'),
        closeCalculationErrorReportDialog: () =>
          set({ isCalculationErrorReportDialogVisible: false }, false, 'closeCalculationErrorReportDialog'),

        showTotalAmountPanel: () => set({ shouldTotalAmountPanelBeVisible: true }, false, 'showTotalAmountPanel'),
        hideTotalAmountPanel: () => set({ shouldTotalAmountPanelBeVisible: false }, false, 'hideTotalAmountPanel'),
      },
    }),
    {
      name: 'specification-store',
    },
  ),
);

export const useSpecificationContextWindowsVisibility = () =>
  useSpecificationStore(state => ({
    isMainContractConfirmationDialogVisible: state.isMainContractConfirmationDialogVisible,
    isBonusConfirmationDialogVisible: state.isBonusConfirmationDialogVisible,
    isContractClosingDialogVisible: state.isContractClosingDialogVisible,
    isCalculationErrorReportDialogVisible: state.isCalculationErrorReportDialogVisible,
    isAcceptContractConfirmationDialogVisible: state.isAcceptContractConfirmationDialogVisible,
  }));

export const useSpecificationStoreActions = () => useSpecificationStore(state => state.actions);

export const useShouldSpecificationTotalAmountPanelBeVisible = () =>
  useSpecificationStore(state => state.shouldTotalAmountPanelBeVisible);

export const useSpecificationStoreResetOnUnmount = () => useUnmount(resetSpecificationStore);
