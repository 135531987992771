import { useState } from 'react';
import type { TextFieldProps } from '@mui/material';
import { IconButton, InputAdornment } from '@mui/material';

import { RHFTextField } from '@/components/form-controls/RHFTextField';
import { ReactComponent as EyeOffSVG } from '@/shared/assets/icons/minimal/ic_eye_off.svg';
import { ReactComponent as EyeSVG } from '@/shared/assets/icons/minimal/ic_eye.svg';

// ----------------------------------------------------------------------

type Props = TextFieldProps & {
  name: string;
  label?: string;
};

// ----------------------------------------------------------------------

export const RHFPasswordTextField = (props: Props) => {
  const { name, label, ...restProps } = props;

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const handlePasswordVisibility = () => setIsPasswordVisible(isVisible => !isVisible);

  return (
    <RHFTextField
      name={name}
      label={label}
      type={isPasswordVisible ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handlePasswordVisibility} edge="end" data-testid="password-visibility-btn">
              {isPasswordVisible ? <EyeSVG /> : <EyeOffSVG />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...restProps}
    />
  );
};
