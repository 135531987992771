import { LoadingButton, type LoadingButtonProps } from '@mui/lab';
import { styled } from '@mui/material';
import type { OverridableComponent } from '@mui/material/OverridableComponent';
import type { Merge } from 'type-fest';

const StyledRoot = styled(LoadingButton)(({ theme }) => ({
  padding: '8px 24px',
  borderRadius: '20px',

  '&:hover': {
    backgroundColor: theme.palette.primary['-1'],
    boxShadow: 'none',
  },

  variants: [
    {
      props: { variant: 'outlined' },
      style: {
        '&.MuiButton-outlined': {
          borderColor: theme.palette.neutral[500],
        },

        '&:hover': {
          backgroundColor: theme.palette.neutral[200],
        },
      },
    },
  ],
}));

export type RoundedButtonProps = Omit<LoadingButtonProps, 'color' | 'variant'> & {
  variant?: 'outlined' | 'contained';
  color?: never;
  'data-testid'?: string;
};

export type RoundedButtonTypeMap<AdditionalProps = {}, RootComponent extends React.ElementType = 'button'> = {
  props: Merge<RoundedButtonProps, AdditionalProps>;
  defaultComponent: RootComponent;
};

export const RoundedButton: OverridableComponent<RoundedButtonTypeMap> = (props: RoundedButtonProps) => (
  <StyledRoot size="large" variant="contained" {...props} />
);
