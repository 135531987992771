import { useNavigate, useParams } from 'react-router-dom';
import { Stack, Typography } from '@mui/material';

import {
  ConfirmationDialogActionCancelButton,
  ConfirmationDialogActionProceedButton,
  ConfirmationDialogActions,
  ConfirmationDialogContainer,
  ConfirmationDialogHeader,
} from '@/components/dialogs';
import { formatNumberByThousands } from '@/shared/helpers/format.helpers';
import { useSpecification } from '@/shared/hooks/useSpecification';
import { DorimPromoPaths, type SpecificationDetailPathParams } from '@/shared/lib/react-router';

import { useSpecificationContextWindowsVisibility, useSpecificationStoreActions } from '../../../../lib';

export const ReceivePaymentDialog = () => {
  const params = useParams<SpecificationDetailPathParams>();
  const { data } = useSpecification({ specificationId: Number(params.specificationId) });
  const { agreement } = data || {};

  const { isBonusConfirmationDialogVisible } = useSpecificationContextWindowsVisibility();
  const { closeBonusConfirmationDialog } = useSpecificationStoreActions();

  const navigate = useNavigate();
  const navigateToContractDocument = async () => {
    if (!params.specificationId) {
      return;
    }

    navigate(
      DorimPromoPaths.generateSpecificationDetailContractDocumentPath({
        specificationId: params.specificationId,
      }),
    );
  };

  return (
    <ConfirmationDialogContainer
      open={isBonusConfirmationDialogVisible}
      subject="confirm"
      onClose={closeBonusConfirmationDialog}
    >
      <ConfirmationDialogHeader>Подтверждение</ConfirmationDialogHeader>
      <Stack spacing={3}>
        {agreement && (
          <Typography variant="Body/medium" color="neutral.700">
            Вы согласны получить выплату{' '}
            <Typography component="span" variant="inherit" color="text.primary">
              <Typography component="span" variant="inherit" fontWeight={600}>
                {formatNumberByThousands(agreement.cashback_sum)}
              </Typography>{' '}
              <Typography component="span" variant="inherit">
                UZS
              </Typography>
            </Typography>{' '}
            за выполнение плана?
          </Typography>
        )}
        <Typography variant="Body/medium" color="neutral.700">
          Для получения выплаты нужно подписать спецификацию.
        </Typography>
      </Stack>

      <ConfirmationDialogActions>
        <ConfirmationDialogActionCancelButton onClick={closeBonusConfirmationDialog} />
        <ConfirmationDialogActionProceedButton autoFocus onClick={navigateToContractDocument}>
          Да, все верно
        </ConfirmationDialogActionProceedButton>
      </ConfirmationDialogActions>
    </ConfirmationDialogContainer>
  );
};
