import { AuthPaths } from './auth.paths';
import { OrdersPaths } from './router.paths';

// ----------------------------------------------------------------------
// SIGN IN

export const SignInLinks = {
  root: AuthPaths.SIGN_IN.ROOT,
  ban: AuthPaths.SIGN_IN.BAN,
};

// ----------------------------------------------------------------------
// MY ORDERS

export const OrdersLinks = {
  root: OrdersPaths.ROOT,
};

// ----------------------------------------------------------------------
// SIGNUP

export const SignUpLinks = {
  root: AuthPaths.SIGN_UP.ROOT,
  step: AuthPaths.SIGN_UP.STEP,
  ban: AuthPaths.SIGN_UP.BAN,
  final: AuthPaths.SIGN_UP.FINAL,
};
