import { useQuery } from '@tanstack/react-query';

import { profileApi } from '../profile.api';
import { profileQueryKeys } from './profileQueryKeys';

export const useProfile = () => {
  const profileQueryResult = useQuery({
    queryKey: profileQueryKeys.root,
    queryFn: profileApi.profile,
    staleTime: Infinity,
    retry: false,
  });

  const { data: profile } = profileQueryResult;

  const contractor = profile?.contractor;
  const user = profile?.user;
  const fullName = profile?.user ? `${profile.user.first_name} ${profile.user.last_name}`.trim() : '';

  return {
    ...profileQueryResult,
    profile,
    contractor,
    user,
    fullName,
  };
};
