import { has, reduce, size } from 'lodash';

import type {
  CheckoutCloudCart,
  CheckoutCloudCartItem,
  CloudCart,
  CloudCartItem,
  CloudCartSpecialOffer,
} from '@/api/domains/cloud-carts.types';

import type {
  CheckoutDistributorEntries,
  CheckoutDistributorEntry,
  CloudCartEntriesPackagesCounts,
  CloudCartSummery,
  RawCloudCartSummeryData,
  TransformedCheckoutCloudCart,
  TransformedCloudCart,
  TransformedCloudCartBase,
} from './cloud-carts.types';

const transformCloudCartDataReducer = <TAcc extends TransformedCloudCartBase>(
  acc: TAcc,
  cloudCartItem: CloudCartItem,
) => {
  acc.entries[cloudCartItem.offer_id] = cloudCartItem;
  acc.entriesPackagesCounts[cloudCartItem.offer_id] = {
    packagesCount: cloudCartItem.quantity,
    giftPackagesCount: cloudCartItem.share_quantity,
  };

  if (!has(acc.drugsPackagesCount, cloudCartItem.drug_id)) {
    acc.drugsPackagesCount[cloudCartItem.drug_id] = { packagesCount: 0 };
  }

  const previousDrugPackagesCount = acc.drugsPackagesCount[cloudCartItem.drug_id].packagesCount;
  acc.drugsPackagesCount[cloudCartItem.drug_id].packagesCount = previousDrugPackagesCount + cloudCartItem.quantity;

  return acc;
};

export const transformCloudCartData = (rawCloudCart: CloudCart) =>
  reduce<CloudCartItem, TransformedCloudCart>(rawCloudCart.items, transformCloudCartDataReducer, {
    entries: {},
    entriesPackagesCounts: {},
    drugsPackagesCount: {},
    rawCloudCart,
  });

export const transformCheckoutCloudCartData = (rawCloudCartByDistributor: CheckoutCloudCart) =>
  reduce<CheckoutCloudCartItem, TransformedCheckoutCloudCart>(
    rawCloudCartByDistributor.items,
    (acc, rawCheckoutCloudCartItem) => {
      if (!rawCheckoutCloudCartItem.distributor) {
        return acc;
      }

      const { distributor } = rawCheckoutCloudCartItem;

      acc.checkoutDistributorEntries[distributor.id] = {
        entries: {},
        entriesPackagesCounts: {},
        drugsPackagesCount: {},
        distributor,
        distributorId: distributor.id,
        rawCheckoutCloudCartItem,
      };

      reduce<CloudCartItem, CheckoutDistributorEntry>(
        rawCheckoutCloudCartItem.items,
        transformCloudCartDataReducer,
        acc.checkoutDistributorEntries[distributor.id],
      );

      return acc;
    },
    {
      checkoutDistributorEntries: {},
      rawCheckoutCloudCart: rawCloudCartByDistributor,
    },
  );

// ----------------------------------------------------------------------

export const composeCloudCartSummery = <TData extends RawCloudCartSummeryData>(
  rawCloudCartSummeryData: TData,
): CloudCartSummery => ({
  totalPrice: rawCloudCartSummeryData.total_price,
  totalPriceWithVat: rawCloudCartSummeryData.total_price_with_vat,
  totalPrepayment: rawCloudCartSummeryData.first_payment,
  totalPrepaymentWithVat: rawCloudCartSummeryData.first_payment_with_vat,
  totalInstallmentPayment: rawCloudCartSummeryData.postpone_payment,
  totalInstallmentPaymentWithVat: rawCloudCartSummeryData.postpone_payment_with_vat,
  totalDiscount: rawCloudCartSummeryData.discount,
  totalDiscountWithVat: rawCloudCartSummeryData.discount_with_vat,
  entriesCount: rawCloudCartSummeryData.items_count,
  totalPackagesCount: rawCloudCartSummeryData.packages_count,
});

// ----------------------------------------------------------------------

export type UpdateCloudCartCacheParams<TData extends TransformedCloudCartBase> = {
  offerId: CloudCartItem['offer_id'];
  drugId: CloudCartItem['drug_id'];
  count: number;
  data: TData;
};

export const updateCloudCartCache = <TData extends TransformedCloudCartBase>({
  offerId,
  drugId,
  count: unsafeUpdatedPackagesCount,
  data: { entries, entriesPackagesCounts, drugsPackagesCount },
}: UpdateCloudCartCacheParams<TData>) => {
  const entry = entries[offerId];

  if (!has(entriesPackagesCounts, offerId)) {
    entriesPackagesCounts[offerId] = { packagesCount: 0, giftPackagesCount: 0 };
  }
  const entryPackagesCounts = entriesPackagesCounts[offerId];

  if (!has(drugsPackagesCount, drugId)) {
    drugsPackagesCount[drugId] = { packagesCount: 0 };
  }

  const drugPackagesCount = drugsPackagesCount[drugId];
  const previousPackagesCount = entryPackagesCounts.packagesCount;
  const updatedPackagesCount = Math.max(0, unsafeUpdatedPackagesCount);

  const previousDrugPackagesCount = drugPackagesCount.packagesCount;
  drugPackagesCount.packagesCount = previousDrugPackagesCount + updatedPackagesCount - previousPackagesCount;

  entryPackagesCounts.packagesCount = updatedPackagesCount;
  entryPackagesCounts.giftPackagesCount = computeGiftPackagesCount(updatedPackagesCount, entry?.offer?.share);

  return {
    entries,
    entriesPackagesCounts,
    drugsPackagesCount,
  };
};

export const computeGiftPackagesCount = (
  updatedPackagesCount: number,
  cloudCartShare: CloudCartSpecialOffer | null | undefined,
) =>
  cloudCartShare ? Math.floor(updatedPackagesCount / cloudCartShare.required_amount) * cloudCartShare.share_amount : 0;

export const computeTotalPackagesCount = (entriesPackagesCounts: CloudCartEntriesPackagesCounts) =>
  reduce(
    entriesPackagesCounts,
    (acc, { packagesCount, giftPackagesCount }) => acc + packagesCount + giftPackagesCount,
    0,
  );

export const computeCheckoutTotalPackagesCount = (checkoutDistributorEntries: CheckoutDistributorEntries) =>
  reduce(
    checkoutDistributorEntries,
    (acc, { entriesPackagesCounts }) => acc + computeTotalPackagesCount(entriesPackagesCounts),
    0,
  );

export const computeCheckoutEntriesCount = (checkoutDistributorEntries: CheckoutDistributorEntries) =>
  reduce(checkoutDistributorEntries, (acc, { entriesPackagesCounts }) => acc + size(entriesPackagesCounts), 0);
