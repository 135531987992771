import type React from 'react';
import type { MuiOtpInputProps } from 'mui-one-time-password-input';

import { StyledOTPInput, StyledRoot } from './styles';

export const OTPInput = (props: MuiOtpInputProps): React.JSX.Element => {
  return (
    <StyledRoot>
      <StyledOTPInput {...props} />
    </StyledRoot>
  );
};
