import { useMemo } from 'react';
import { useLatest, useUnmount } from 'react-use';
import { debounce, type DebouncedFunc, type DebounceSettings } from 'lodash';

export type UseDebouncedCallbackReturn<TCallback extends (...args: any) => any> = [
  DebouncedFunc<TCallback>,
  DebouncedFunc<TCallback>['cancel'],
];

export const useDebouncedCallback = <TCallback extends (...args: any) => any>(
  callback: TCallback,
  settings: DebounceSettings & { wait: number },
  deps: React.DependencyList = [],
): UseDebouncedCallbackReturn<TCallback> => {
  const { wait, ...options } = settings;

  const latestCallback = useLatest<TCallback>(callback);
  const debouncedCallback = useMemo(
    () => debounce((...args) => latestCallback.current(...args), wait, options),
    /* eslint-disable-next-line */
    deps,
  );

  useUnmount(debouncedCallback.cancel);

  return [debouncedCallback, debouncedCallback.cancel];
};
