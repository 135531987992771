import { createRoot } from 'react-dom/client';

import { applyGlobalConfigDateFns } from '@/shared/lib/date-fns';
import { applyGlobalConfigI18next } from '@/shared/lib/i18next';

import { Router } from './Router';

applyGlobalConfigDateFns();
applyGlobalConfigI18next();

const rootElement = document.getElementById('root') as HTMLElement;
const root = createRoot(rootElement);
root.render(<Router />);

if (import.meta.env.DEV) {
  // https://github.com/vitejs/vite/issues/2076#issuecomment-1500765186
  window.onerror = (_event, _source, _lineno, _colno, error) => {
    // must be within function call because that's when the element is defined for sure.
    const ErrorOverlay = customElements.get('vite-error-overlay');
    // don't open outside vite environment
    if (!ErrorOverlay) {
      return;
    }

    if (error) {
      const overlay = new ErrorOverlay(error);
      document.body.appendChild(overlay);
    }
  };
}
