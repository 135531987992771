import { Fragment } from 'react';
import Grid from '@mui/material/Unstable_Grid2';

import type { Contractor } from '@/modules/profile/types';

import { EntityInfoItem } from './EntityInfoItem';
import { EntityInfoItemAddress } from './EntityInfoItemAddress';
import { EntityInfoItemContent } from './EntityInfoItemContent';

type EntityInfoContentProps = {
  contractor: Contractor;
};

export const EntityInfoContent = (props: EntityInfoContentProps) => {
  const { contractor } = props;
  const { children: contractorPharmacies } = contractor;

  return (
    <Fragment>
      {contractor.name && (
        <Grid xs={4}>
          <EntityInfoItem title="Юридическое лицо">
            <EntityInfoItemContent title="Название" description={contractor.name} />
            {contractor.address && <EntityInfoItemAddress title="Адрес" address={contractor.address} />}
            {contractor.tin > 0 && <EntityInfoItemContent title="ИНН" description={contractor.tin} />}
          </EntityInfoItem>
        </Grid>
      )}

      {contractorPharmacies?.map(
        pharmacy =>
          pharmacy.name && (
            <Grid key={pharmacy.id} xs={4}>
              <EntityInfoItem title="Аптека">
                <EntityInfoItemContent title="Название" description={pharmacy.name} />
                {pharmacy.address && <EntityInfoItemAddress title="Адрес" address={pharmacy.address} />}
              </EntityInfoItem>
            </Grid>
          ),
      )}
    </Fragment>
  );
};
