import { useLocation } from 'react-router-dom';

import { useAuthState } from '@/shared/lib/auth';
import type { RedirectWithoutAccessProps } from '@/shared/lib/react-router';
import { RedirectWithoutAccess, SignInLinks } from '@/shared/lib/react-router';

// ----------------------------------------------------------------------

type AuthGuardProps = Pick<RedirectWithoutAccessProps, 'children'>;

// ----------------------------------------------------------------------

export const AuthGuard = (props: AuthGuardProps) => {
  const { children } = props;

  const { pathname: from } = useLocation();
  const { isAuthenticated } = useAuthState();

  return (
    <RedirectWithoutAccess hasAccess={isAuthenticated} to={SignInLinks.root} state={{ from }}>
      {children}
    </RedirectWithoutAccess>
  );
};
