import { Fragment } from 'react';
import { Outlet } from 'react-router-dom';

import { Header } from '@/layouts/Header';
import { PageContainer, PageContent } from '@/layouts/Page';

import { LayoutTabs } from './LayoutTabs';

export const Layout = () => {
  return (
    <PageContainer
      title="Dorim.Promo • Договор"
      meta={
        <Fragment>
          <script defer src="/e-imzo/e-imzo.js"></script>
          <script defer src="/e-imzo/e-imzo-client.js"></script>
        </Fragment>
      }
    >
      <Header hasBreadcrumbs />
      <LayoutTabs />
      <PageContent>
        <Outlet />
      </PageContent>
    </PageContainer>
  );
};
