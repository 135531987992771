import { useMutation, useQueryClient } from '@tanstack/react-query';

import { DorimPromoApi } from '@/api/domains/dorim-promo.api';
import { DorimPromoQueryKeys } from '@/api/domains/dorim-promo.query-keys';
import { useGlobalErrorStore } from '@/components/dialogs';

export const useConfirmReceivingBonus = () => {
  const queryClient = useQueryClient();
  const { setGlobalError } = useGlobalErrorStore();

  return useMutation({
    mutationFn: DorimPromoApi.confirmReceivingCashbackBonus,
    onSuccess: (_, specificationId) =>
      Promise.all([
        queryClient.invalidateQueries({ queryKey: DorimPromoQueryKeys.getSpecificationsKey() }),
        queryClient.invalidateQueries({ queryKey: DorimPromoQueryKeys.getSpecificationKey({ specificationId }) }),
      ]),
    onError: setGlobalError,
  });
};
