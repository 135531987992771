import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';

import { SuccessAction } from '@/components/SuccessAction';
import { StyledFormRoot } from '@/pages/auth/components/styles';
import { useAuthMethods } from '@/shared/lib/auth';

import { useClearResetPasswordData } from './hooks/useClearResetPasswordData';

export const SuccessPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { updateSignInTokens } = useAuthMethods();
  const clearDate = useClearResetPasswordData();

  useEffect(() => {
    clearDate();
    /* eslint-disable-next-line */
  }, []);

  const handleClick = () => {
    if (location.state) {
      const { access_token, refresh_token } = location.state;
      if (access_token && refresh_token && typeof access_token === 'string' && typeof refresh_token === 'string') {
        updateSignInTokens({ access_token, refresh_token });
      }
    }

    navigate('/');
  };

  return (
    <StyledFormRoot>
      <SuccessAction
        title="Поздравляем!"
        description="Пароль успешно изменен"
        onActionButtonClick={handleClick}
        actionButtonTitle="Войти"
      />
    </StyledFormRoot>
  );
};
