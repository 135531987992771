import { lazy } from 'react';

export const ProductSelectionAutoBindingPage = lazy(
  () =>
    import(
      /* webpackPreload: true */
      /* webpackChunkName: "ProductSelectionAutoBindingPage" */
      './ProductSelectionAutoBindingPage'
    ),
);
