import { Controller, useFormContext, type ControllerRenderProps } from 'react-hook-form';
import type { Merge } from 'type-fest';

import { ClearableTextField, type ClearableTextFieldProps } from './ClearableTextField';

type LimitedClearableTextFieldProps = Omit<ClearableTextFieldProps, Exclude<keyof ControllerRenderProps, 'disabled'>>;

export type RHFTextFieldProps = Merge<
  LimitedClearableTextFieldProps,
  {
    name: string;
    inputRef?: React.Ref<HTMLInputElement>;
  }
>;

export const RHFTextField = (props: RHFTextFieldProps) => {
  const { name, inputRef, helperText, ...restOfProps } = props;

  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, onChange, ...restFieldProps }, fieldState: { error } }) => {
        const refCallback: React.RefCallback<HTMLInputElement> = instance => {
          if (instance) {
            ref(inputRef);

            if (typeof inputRef === 'function') {
              inputRef(instance);
            } else if (inputRef) {
              (inputRef as React.MutableRefObject<HTMLInputElement>).current = instance;
            }
          }
        };

        return (
          <ClearableTextField
            ref={refCallback}
            fullWidth
            error={!!error}
            helperText={error ? error.message : helperText}
            onValueChange={onChange}
            sx={{
              '& .MuiInputBase-input': {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              },
              ...restOfProps.sx,
            }}
            {...restFieldProps}
            {...restOfProps}
          />
        );
      }}
    />
  );
};
