import { useRef } from 'react';
import type { TextFieldProps } from '@mui/material';
import { Autocomplete, TextField } from '@mui/material';

import { StartAdornment } from '@/components/form-controls/SearchField/components/StartAdornment';
import { ModalHeader } from '@/components/Modal';
import { RHFAutocompleteStyle } from '@/pages/auth/components/styles';
import type { Option } from '@/pages/auth/sign-up/forms/types';
import {
  useAutocompleteModalStoreActions,
  useAutocompleteModalStoreState,
} from '@/pages/auth/sign-up/store/autocompleteModal.store';

import { getOptionLabel } from './helper';
import { listboxStyle, paperStyle, popperStyle, StyledModal } from './styles';

type Props = {
  onClose?: () => void;
  onChange: (event: React.SyntheticEvent, value: Option | null) => void;
  TextFieldProps?: TextFieldProps;
};

export const AutocompleteModal = (props: Props) => {
  const { onClose, onChange, TextFieldProps, ...restOfProps } = props;
  const { isAutocompleteModalVisible, selectedOptionTitle, selectedOptions, selectedFieldName } =
    useAutocompleteModalStoreState();
  const { closeAutocompleteModal } = useAutocompleteModalStoreActions();
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClose = () => {
    closeAutocompleteModal();
    onClose?.();
  };

  const handleChange = (event: React.SyntheticEvent, value: Option | string | null) => {
    if (typeof value === 'string') {
      return;
    }

    onChange(event, value);
  };

  const handleKeyboardPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      inputRef.current?.blur();
      return;
    }
  };

  return (
    <StyledModal open={isAutocompleteModalVisible}>
      <ModalHeader title={selectedOptionTitle} hideHotkey onClickCapture={handleClose} />

      <Autocomplete
        freeSolo={true}
        fullWidth
        options={selectedOptions}
        getOptionLabel={option => getOptionLabel(option, selectedFieldName)}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        open
        onChange={handleChange}
        disablePortal
        sx={{ ...RHFAutocompleteStyle, marginTop: '16px' }}
        componentsProps={{
          popper: {
            disablePortal: true,
            sx: popperStyle,
          },
          paper: {
            sx: paperStyle,
          },
        }}
        ListboxProps={{
          sx: listboxStyle,
        }}
        renderInput={({ ...params }) => (
          <TextField
            inputRef={inputRef}
            {...params}
            {...TextFieldProps}
            variant="filled"
            autoComplete="off"
            InputProps={{
              ...params.InputProps,
              startAdornment: <StartAdornment />,
              endAdornment: null,
            }}
            fullWidth
            onKeyUp={handleKeyboardPress}
          />
        )}
        {...restOfProps}
        blurOnSelect
      />
    </StyledModal>
  );
};
