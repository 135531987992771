import type { DialogProps } from '@mui/material';
import { Dialog, styled } from '@mui/material';

// ----------------------------------------------------------------------

export type ModalProps = DialogProps;

// ----------------------------------------------------------------------

const StyledRoot = styled(Dialog)(({ theme }) => ({
  '.MuiDialog-paper': {
    alignSelf: 'stretch',
    height: 'auto',
    maxHeight: 'none',
    margin: theme.spacing(2),
    overflow: 'hidden',
  },

  '.MuiDialogContent-root': {
    padding: theme.spacing(0, 0, 1),
  },

  '&&& .MuiDialogActions-root': {
    padding: 0,
  },
})) as typeof Dialog;

// ----------------------------------------------------------------------

export const Modal = (props: ModalProps) => <StyledRoot fullWidth maxWidth="xl" {...props} />;
