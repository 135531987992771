import { Navigate } from 'react-router-dom';

import { useMainContractDocument } from '@/shared/hooks/useMainContractDocument';
import {
  BreadcrumbLink,
  DorimPromoPaths,
  LazyBreadcrumb,
  type RouteObjectWithBreadcrumbs,
} from '@/shared/lib/react-router';

import { Layout } from './_layout';
import { MainContractDocumentPageRoute, SpecificationDetailMainContractDocumentPageRoute } from './document';
import {
  MainContractSignatureProtocolPageRoute,
  SpecificationDetailMainContractSignatureProtocolPageRoute,
} from './signature-protocol';

const BaseRoute: RouteObjectWithBreadcrumbs = {
  element: <Layout />,
};

export const SpecificationDetailMainContractDetailPageRoute: RouteObjectWithBreadcrumbs = {
  ...BaseRoute,
  path: DorimPromoPaths.SPECIFICATION_DETAIL_MAIN_CONTRACT_DETAIL_PATH,
  handle: {
    breadcrumb: {
      Component: () => {
        const { query } = useMainContractDocument();

        return (
          <LazyBreadcrumb isLoading={query.isLoading}>
            <BreadcrumbLink to={DorimPromoPaths.SPECIFICATION_DETAIL_MAIN_CONTRACT_DOCUMENT_PATH}>
              {`Договор${query.data?.document_number ? ` №${query.data.document_number}` : ''}`}
            </BreadcrumbLink>
          </LazyBreadcrumb>
        );
      },
    },
  },
  children: [
    {
      index: true,
      element: <Navigate to={DorimPromoPaths.SPECIFICATION_DETAIL_MAIN_CONTRACT_DOCUMENT_PATH} />,
    },
    SpecificationDetailMainContractDocumentPageRoute,
    SpecificationDetailMainContractSignatureProtocolPageRoute,
  ],
};

export const MainContractDetailPageRoute: RouteObjectWithBreadcrumbs = {
  ...BaseRoute,
  handle: {
    breadcrumb: {
      Component: () => {
        const { query } = useMainContractDocument();

        return (
          <LazyBreadcrumb isLoading={query.isLoading}>
            <BreadcrumbLink to={DorimPromoPaths.MAIN_CONTRACT_DOCUMENT_PATH}>
              {`Договор${query.data?.document_number ? ` №${query.data.document_number}` : ''}`}
            </BreadcrumbLink>
          </LazyBreadcrumb>
        );
      },
    },
  },
  children: [MainContractDocumentPageRoute, MainContractSignatureProtocolPageRoute],
};
