import { Card, Stack, type StackProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledRoot = styled(Card)(({ theme }) => ({
  width: '100%',
  height: theme.dimensions.summaryBar.height,
  padding: theme.spacing(1, 4),
  borderRadius: 0,
  borderRight: 'none',
  borderLeft: 'none',
  borderBottom: 'none',
})) as typeof Card;

export type SummaryBarContainerProps = Omit<StackProps, 'direction'>;

export const SummaryBarContainer = (props: SummaryBarContainerProps) => (
  <StyledRoot component={Stack} variant="outlined" direction="row" alignItems="center" {...props} />
);
