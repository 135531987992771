import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

export type OTPData = {
  sendData: {
    lifetime: number;
    expiredAt: number;
    sms_version: number;
  };
  verifyData: {
    signup_token: string;
  };
  attemptCounter: number;
};

type OTPState = {
  signUpOTP: OTPData;
  resetPasswordOTP: OTPData;
};

type OTPActions = {
  setSignUpSendData: (sendData: OTPState['signUpOTP']['sendData']) => void;
  updateSignUpExpiredAt: (updatedExpiredAt: OTPState['signUpOTP']['sendData']['expiredAt']) => void;
  setSignUpVerifyData: (verifyData: OTPState['signUpOTP']['verifyData']) => void;
  resetSignUpOTPData: () => void;

  setResetPasswordSendData: (sendData: OTPState['resetPasswordOTP']['sendData']) => void;
  updateResetPasswordExpiredAt: (updatedExpiredAt: OTPState['resetPasswordOTP']['sendData']['expiredAt']) => void;
  setResetPasswordVerifyData: (verifyData: OTPState['resetPasswordOTP']['verifyData']) => void;
  clearResetPasswordOTPData: () => void;

  incrementSignUpAttemptCounter: () => void;
  resetSignUpAttemptCounter: () => void;

  incrementResetPasswordAttemptCounter: () => void;
  clearResetPasswordAttemptCounter: () => void;
};

type OTPStore = OTPState & OTPActions;

const baseState = {
  sendData: {
    lifetime: -1,
    expiredAt: -1,
    sms_version: -1,
  },
  verifyData: {
    signup_token: '',
  },
  attemptCounter: 0,
};

const initialState: OTPState = {
  signUpOTP: { ...baseState },
  resetPasswordOTP: { ...baseState },
};

const storeName = 'dorim-price:otp-store';
const useOTPStore = create<OTPStore>()(
  devtools(
    persist(
      immer(set => ({
        // State
        ...initialState,

        // Actions
        setSignUpSendData: sendData =>
          set(
            (state: OTPState) => {
              state.signUpOTP.sendData = sendData;
            },
            false,
            'setSignUpSendData',
          ),
        updateSignUpExpiredAt: updatedExpiredAt =>
          set(
            (state: OTPState) => {
              state.signUpOTP.sendData.expiredAt = updatedExpiredAt;
            },
            false,
            'updateSignUpSendData',
          ),
        setSignUpVerifyData: verifyData =>
          set(
            state => {
              state.signUpOTP.verifyData = verifyData;
            },
            false,
            'setSignUpVerifyData',
          ),
        resetSignUpOTPData: () =>
          set(
            state => {
              state.signUpOTP = initialState.signUpOTP;
            },
            false,
            'resetSignUpOTPData',
          ),
        incrementSignUpAttemptCounter: () =>
          set(
            state => {
              state.signUpOTP.attemptCounter += 1;
            },
            false,
            'incrementSignUpAttemptCounter',
          ),
        resetSignUpAttemptCounter: () =>
          set(
            state => {
              state.signUpOTP.attemptCounter = 0;
            },
            false,
            'resetSignUpAttemptCounter',
          ),
        setResetPasswordSendData: sendData =>
          set(
            state => {
              state.resetPasswordOTP.sendData = sendData;
            },
            false,
            'setResetPasswordSendData',
          ),
        updateResetPasswordExpiredAt: updatedExpiredAt =>
          set(
            (state: OTPState) => {
              state.resetPasswordOTP.sendData.expiredAt = updatedExpiredAt;
            },
            false,
            'updateResetPasswordSendData',
          ),
        setResetPasswordVerifyData: verifyData =>
          set(
            state => {
              state.resetPasswordOTP.verifyData = verifyData;
            },
            false,
            'setResetPasswordVerifyData',
          ),
        clearResetPasswordOTPData: () =>
          set(
            state => {
              state.resetPasswordOTP = initialState.resetPasswordOTP;
            },
            false,
            'clearResetPasswordOTPData',
          ),

        incrementResetPasswordAttemptCounter: () =>
          set(
            state => {
              state.resetPasswordOTP.attemptCounter += 1;
            },
            false,
            'incrementResetPasswordAttemptCounter',
          ),
        clearResetPasswordAttemptCounter: () =>
          set(
            state => {
              state.resetPasswordOTP.attemptCounter = 0;
            },
            false,
            'clearResetPasswordAttemptCounter',
          ),
      })),
      {
        name: storeName,
      },
    ),
    {
      name: storeName,
    },
  ),
);

// Selectors for state
const selectSignUpOTPState = (state: OTPStore) => ({
  signUpOTP: state.signUpOTP,
});

const selectSignUpVerifyData = (state: OTPStore) => state.signUpOTP.verifyData;
const selectSignUpSendData = (state: OTPStore) => state.signUpOTP.sendData;

const selectResetPasswordVerifyData = (state: OTPStore) => state.resetPasswordOTP.verifyData;
const selectResetPasswordSendData = (state: OTPStore) => state.resetPasswordOTP.sendData;

const selectSignUpAttemptCounter = (state: OTPStore) => state.signUpOTP.attemptCounter;
const selectResetPasswordAttemptCounter = (state: OTPStore) => state.resetPasswordOTP.attemptCounter;

// Selectors for actions
const selectOTPActions = (state: OTPStore) => ({
  setSignUpSendData: state.setSignUpSendData,
  updateSignUpExpiredAt: state.updateSignUpExpiredAt,
  setSignUpVerifyData: state.setSignUpVerifyData,

  setResetPasswordVerifyData: state.setResetPasswordVerifyData,
  updateResetPasswordExpiredAt: state.updateResetPasswordExpiredAt,
  setResetPasswordSendData: state.setResetPasswordSendData,

  resetSignUpOTPData: state.resetSignUpOTPData,
  clearResetPasswordOTPData: state.clearResetPasswordOTPData,

  incrementSignUpAttemptCounter: state.incrementSignUpAttemptCounter,
  resetSignUpAttemptCounter: state.resetSignUpAttemptCounter,

  incrementResetPasswordAttemptCounter: state.incrementResetPasswordAttemptCounter,
  clearResetPasswordAttemptCounter: state.clearResetPasswordAttemptCounter,
});

// Hooks
export const useOTPStoreActions = () => useOTPStore(selectOTPActions);

export const useOTPStoreSignUpVerifyData = () => useOTPStore(selectSignUpVerifyData);
export const useOTPStoreSignUpSendData = () => useOTPStore(selectSignUpSendData);
export const useOTPStoreSignUpAttemptCounter = () => useOTPStore(selectSignUpAttemptCounter);
export const useOTPStoreSignUpState = () => useOTPStore(selectSignUpOTPState);

export const useOTPStoreResetPasswordVerifyData = () => useOTPStore(selectResetPasswordVerifyData);
export const useOTPStoreResetPasswordSendData = () => useOTPStore(selectResetPasswordSendData);
export const useOTPStoreResetPasswordAttemptCounter = () => useOTPStore(selectResetPasswordAttemptCounter);
