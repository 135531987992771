import type { PopoverOrigin } from '@mui/material';

export const ANCHOR_ORIGIN_CONFIG: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'left',
};
export const TRANSFORM_ORIGIN_CONFIG: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 220,
};
