import {
  CheckOutlined as CheckOutlinedIcon,
  Close as CloseIcon,
  InfoOutlined as InfoOutlinedIcon,
} from '@mui/icons-material';
import { IconButton, styled, SvgIcon } from '@mui/material';
import { closeSnackbar, MaterialDesignContent, SnackbarProvider as NotistackProvider } from 'notistack';

import { ReactComponent as WarningIcon } from '@/shared/assets/icons-2.0/warning.svg';

const StyledMaterialDesignContent = styled(MaterialDesignContent)(({ theme }) => ({
  '&.notistack-MuiContent': {
    minWidth: 340,
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,

    '#notistack-snackbar': {
      padding: 0,
    },
  },

  '&.notistack-MuiContent-info': {
    backgroundColor: theme.palette.secondary.main,
  },

  '&.notistack-MuiContent-warning': {
    backgroundColor: theme.palette.warning.main,
  },

  '&.notistack-MuiContent-success': {
    backgroundColor: theme.palette.success.main,
  },

  '&.notistack-MuiContent-error': {
    backgroundColor: theme.palette.error.main,
  },
}));

export type SnackbarProviderProps = React.PropsWithChildren<{}>;

export const SnackbarProvider = (props: SnackbarProviderProps) => {
  const { children } = props;

  return (
    <NotistackProvider
      variant="info"
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'top',
      }}
      Components={{
        success: StyledMaterialDesignContent,
        error: StyledMaterialDesignContent,
        warning: StyledMaterialDesignContent,
        info: StyledMaterialDesignContent,
      }}
      iconVariant={{
        success: <CheckOutlinedIcon sx={{ mr: 1 }} />,
        error: <InfoOutlinedIcon sx={{ mr: 1 }} />,
        warning: <SvgIcon component={WarningIcon} sx={{ mr: 1 }} />,
        info: <InfoOutlinedIcon sx={{ mr: 1 }} />,
      }}
      action={snackbarId => (
        <IconButton
          onClick={() => closeSnackbar(snackbarId)}
          sx={{
            padding: theme => theme.spacing(0, 1, 0, 0),
          }}
        >
          <CloseIcon sx={{ color: theme => theme.palette.common.white }} />
        </IconButton>
      )}
    >
      {children}
    </NotistackProvider>
  );
};
