import { Fragment } from 'react';
import { usePopupState } from 'material-ui-popup-state/hooks';

import type { NavbarButtonProps } from '@/layouts/Navbar/NavbarButton';
import { NavbarButton } from '@/layouts/Navbar/NavbarButton';
import { NavbarSubmenu } from '@/layouts/Navbar/NavbarSubmenu';
import { NavbarSubmenuButton } from '@/layouts/Navbar/NavbarSubmenuButton';
import { useActiveRoutes } from '@/shared/lib/react-router';

// ----------------------------------------------------------------------

export type NavbarItemProps = Omit<NavbarButtonProps, 'hasSubmenu' | 'popupState'> & {
  submenu?: Array<Pick<NavbarItemProps, 'to' | 'title'>>;
};

// ----------------------------------------------------------------------

export const NavbarItem = (props: NavbarItemProps) => {
  const { iconComponent, to, title, submenu = [], ...restOfProps } = props;

  const hasSubmenu = submenu.length > 0;

  const isActiveRoutes = useActiveRoutes(to, ...submenu.map(({ to }) => to));

  const popupState = usePopupState({ variant: 'popover', popupId: 'NavbarSubmenu' });

  return (
    <Fragment>
      <NavbarButton
        {...restOfProps}
        isActive={isActiveRoutes}
        hasSubmenu={hasSubmenu}
        iconComponent={iconComponent}
        to={to}
        title={title}
        popupState={popupState}
      />

      {hasSubmenu && (
        <NavbarSubmenu popupState={popupState}>
          {submenu.map(({ to, title }) => (
            <NavbarSubmenuButton key={title} to={to} title={title} onClick={popupState.close} />
          ))}
        </NavbarSubmenu>
      )}
    </Fragment>
  );
};
