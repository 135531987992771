import { Fragment, useRef, type KeyboardEvent } from 'react';
import { useBoolean } from 'react-use';
import { Popover } from '@mui/material';

import { StyledSupportMenu } from '@/modules/support/components/SupportMenu/styles';
import { SupportButton } from '@/modules/support/components/SupportMenu/SupportButton';
import { SupportMenuPopover } from '@/modules/support/components/SupportMenu/SupportMenuPopover';

export const SupportMenu = () => {
  const [isOpenPopover, triggerOpenPopover] = useBoolean(false);
  const anchorEl = useRef(null);

  const handleHelpKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.code === 'Enter') {
      triggerOpenPopover();
    }
  };

  return (
    <Fragment>
      <StyledSupportMenu
        direction="row"
        onClick={triggerOpenPopover}
        onKeyDown={handleHelpKeyDown}
        ref={anchorEl}
        role="button"
        tabIndex={2}
      >
        <SupportButton size={32} isAura={isOpenPopover} />
      </StyledSupportMenu>

      <Popover
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        anchorEl={anchorEl.current}
        onClose={triggerOpenPopover}
        open={isOpenPopover}
      >
        <SupportMenuPopover />
      </Popover>
    </Fragment>
  );
};
