import {
  HttpStatusCode,
  type AxiosError,
  type AxiosInstance,
  type AxiosRequestHeaders,
  type AxiosResponse,
  type InternalAxiosRequestConfig,
} from 'axios';
import { debounce } from 'lodash';

// ----------------------------------------------------------------------

export type JwtConfig = {
  header?: string;
  headerPrefix?: string;
  getAuthenticatedState: () => boolean;
  getAccessToken: () => string | null | undefined;
  tokenRefresher: () => Promise<void>;
  onRefreshError: () => void;
};

type RequestQueue = Array<{
  resolve: (value?: unknown) => void;
  reject: (value?: unknown) => void;
}>;

// ----------------------------------------------------------------------

let isRefreshing = false;
let requestQueue: RequestQueue = [];

const waitForTokenRefresh = () => new Promise((resolve, reject) => requestQueue.push({ resolve, reject }));

const requestAfterTokenRefresh = (axiosInstance: AxiosInstance, requestConfig: InternalAxiosRequestConfig) =>
  new Promise<AxiosResponse>((done, reject) => {
    const resolve = () =>
      axiosInstance
        .request({
          ...requestConfig,
          shouldRefreshTokenIfNeeded: false,
        })
        .then(done, reject);

    requestQueue.push({ resolve, reject });
  });

const debouncedRefreshAccessToken = debounce(async (tokenRefresher: JwtConfig['tokenRefresher']) => {
  try {
    await tokenRefresher();

    // resolves all promises that are waiting for a token refresh
    requestQueue.forEach(({ resolve }) => resolve());
  } catch (e) {
    // rejects all promises that are waiting for a token refresh
    requestQueue.forEach(({ reject }) => reject(e));
  }

  isRefreshing = false;
  requestQueue = [];
}, 300);

const refreshToken = (accessTokenRefresher: JwtConfig['tokenRefresher']) => {
  isRefreshing = true;
  debouncedRefreshAccessToken(accessTokenRefresher);
};

// ----------------------------------------------------------------------

export const getJwtRequestInterceptor = (axiosInstance: AxiosInstance, config: JwtConfig) => {
  axiosInstance.defaults.shouldInjectAccessToken = true;

  return async (requestConfig: InternalAxiosRequestConfig) => {
    const { header = 'Authorization', headerPrefix = 'Bearer ', getAccessToken } = config;

    // if the access token is refreshed, the request will wait for the refresh to complete
    if (isRefreshing) {
      await waitForTokenRefresh();
    }

    if (!requestConfig.headers) {
      requestConfig.headers = {} as AxiosRequestHeaders;
    }

    const accessToken = getAccessToken();

    if (accessToken) {
      requestConfig.headers[header] = `${headerPrefix}${accessToken}`;
    }

    return requestConfig;
  };
};

// ----------------------------------------------------------------------

export const getJwtErrorInterceptor = (
  axiosInstance: AxiosInstance,
  config: JwtConfig,
): ((error: AxiosError) => Promise<AxiosResponse | void>) => {
  axiosInstance.defaults.shouldRefreshTokenIfNeeded = true;

  return async error => {
    const { getAuthenticatedState, tokenRefresher, onRefreshError } = config;
    const { config: requestConfig, response } = error;

    const isAuthenticated = getAuthenticatedState();

    if (response?.status !== HttpStatusCode.Unauthorized || !isAuthenticated) {
      throw error;
    }

    if (!requestConfig?.shouldRefreshTokenIfNeeded) {
      onRefreshError();
      throw error;
    }

    refreshToken(tokenRefresher);

    // returns a promise that will be resolved after refreshing the token
    return requestAfterTokenRefresh(axiosInstance, requestConfig);
  };
};

// ----------------------------------------------------------------------

export const applyJwtRequestInterceptor = (axiosInstance: AxiosInstance, config: JwtConfig): AxiosInstance => {
  axiosInstance.interceptors.request.use(getJwtRequestInterceptor(axiosInstance, config), undefined, {
    runWhen: requestConfig => {
      const { getAuthenticatedState } = config;
      const isAuthenticated = getAuthenticatedState();

      // We need check to access to do any authenticated requests
      return isAuthenticated && !!requestConfig.shouldInjectAccessToken;
    },
  });

  return axiosInstance;
};

// ----------------------------------------------------------------------

export const applyJwtResponseInterceptor = (axiosInstance: AxiosInstance, config: JwtConfig): AxiosInstance => {
  axiosInstance.interceptors.response.use(undefined, getJwtErrorInterceptor(axiosInstance, config));

  return axiosInstance;
};

// ----------------------------------------------------------------------

export const applyJwtInterceptors = (axiosInstance: AxiosInstance, config: JwtConfig): AxiosInstance => {
  applyJwtRequestInterceptor(axiosInstance, config);
  applyJwtResponseInterceptor(axiosInstance, config);

  return axiosInstance;
};
