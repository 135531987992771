import { Fragment } from 'react';
import { Skeleton } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { EntityInfoItem } from './EntityInfoItem';
import { EntityInfoItemContent } from './EntityInfoItemContent';

export const EntityInfoSkeleton = () => (
  <Fragment>
    <Grid xs={4}>
      <EntityInfoItem title={<Skeleton variant="text" width={160} />}>
        <EntityInfoItemContent
          title={<Skeleton variant="text" width={55} />}
          description={<Skeleton variant="text" width={120} />}
        />
        <EntityInfoItemContent
          title={<Skeleton variant="text" width={55} />}
          description={
            <Fragment>
              <Skeleton variant="text" width={100} />
              <Skeleton variant="text" width={80} />
              <Skeleton variant="text" width={50} />
            </Fragment>
          }
        />
        <EntityInfoItemContent
          title={<Skeleton variant="text" width={55} />}
          description={<Skeleton variant="text" width={80} />}
        />
      </EntityInfoItem>
    </Grid>
    <Grid xs={4}>
      <EntityInfoItem title={<Skeleton variant="text" width={160} />}>
        <EntityInfoItemContent
          title={<Skeleton variant="text" width={55} />}
          description={<Skeleton variant="text" width={120} />}
        />
        <EntityInfoItemContent
          title={<Skeleton variant="text" width={55} />}
          description={
            <Fragment>
              <Skeleton variant="text" width={100} />
              <Skeleton variant="text" width={80} />
              <Skeleton variant="text" width={80} />
              <Skeleton variant="text" width={50} />
            </Fragment>
          }
        />
      </EntityInfoItem>
    </Grid>
  </Fragment>
);
