import type { AxiosRequestConfig } from 'axios';

import { httpClient } from '@/shared/lib/axios';

import type { PaginatedList, PaginationParams } from '../types/pagination.types';
import type { AutoSelectionInfo, BindingDrug, NecessityDrugs } from './auto-selection.types';
import type { SearchSettingAlias } from './drugs.api';
import type { FieldMatchingFileColumn, FieldMatchingSettings } from './field-matching.types';

export type UpdateNecessityFileFieldsSettingsParams = FieldMatchingSettings;

type UploadNecessityFileParams = {
  file: File;
};

export type FetchBindingDrugsParams = PaginationParams & {
  autoSelectionId: number;
  notBinded?: boolean;
};

export type UpdateBindingParams = {
  bindingId: number;
  drugId: number;
};

export type DeleteBindingParams = {
  bindingId: number;
};

export type FetchNecessityDrugsParams = {
  autoSelectionId: number;
  payload?: {
    settings: SearchSettingAlias[];
  };
};

export type PrefillCartWithNecessityProductsParams = {
  autoSelectionId: number;
  payload?: {
    settings: SearchSettingAlias[];
  };
};

export type DeleteAutoSelectionParams = {
  autoSelectionId: number;
};

const slowConnectionAxiosRequestConfig: AxiosRequestConfig = { timeout: 5 * 60 * 1000 };

export const AutoSelectionApi = {
  fetchActiveAutoSelection: async () => {
    const { data } = await httpClient.get<PaginatedList<'items', AutoSelectionInfo>>('auto-selection');

    return data.items;
  },

  fetchNecessityFileFieldsSettings: async () => {
    const { data } = await httpClient.get<FieldMatchingSettings>('auto-selection/settings');

    return data;
  },

  updateNecessityFileFieldsSettings: async (params: UpdateNecessityFileFieldsSettingsParams) =>
    await httpClient.post('auto-selection/settings', params),

  fetchNecessityFileColumns: async () => {
    const { data } = await httpClient.get<PaginatedList<'items', FieldMatchingFileColumn>>('auto-selection/columns');

    return data;
  },

  uploadNecessityFile: async (params: UploadNecessityFileParams) => {
    const payload = new FormData();
    payload.append('file', params.file, params.file.name);

    const { data } = await httpClient.post<AutoSelectionInfo>(
      'auto-selection/import',
      payload,
      slowConnectionAxiosRequestConfig,
    );

    return data;
  },

  fetchDrugsByBindingStatus: async ({ autoSelectionId, notBinded, ...params }: FetchBindingDrugsParams) => {
    const { data } = await httpClient.get<PaginatedList<'items', BindingDrug>>(
      `auto-selection/${autoSelectionId}/bindings`,
      {
        params: {
          not_binded: notBinded,
          ...params,
        },
      },
    );

    return data;
  },

  updateBinding: async (params: UpdateBindingParams) => {
    const { data } = await httpClient.put(`binding/${params.bindingId}`, { drug_id: params.drugId });

    return data;
  },

  deleteBinding: async (params: DeleteBindingParams) => {
    const { data } = await httpClient.delete(`binding/${params.bindingId}`);

    return data;
  },

  fetchNecessityDrugs: async (params: FetchNecessityDrugsParams) => {
    const { data } = await httpClient.get<NecessityDrugs>(`auto-selection/${params.autoSelectionId}/drugs`, {
      params: params.payload,
      ...slowConnectionAxiosRequestConfig,
    });

    return data.drugs;
  },

  prefillCartWithNecessityProducts: (params: PrefillCartWithNecessityProductsParams) =>
    httpClient.post<void>(`auto-selection/${params.autoSelectionId}/select-offers`, params.payload, {
      ...slowConnectionAxiosRequestConfig,
    }),

  deleteAutoSelection: (params: DeleteAutoSelectionParams) =>
    httpClient.delete(`auto-selection/${params.autoSelectionId}`),
};
