import type { TFilterItem } from '@/components/ReactDataGrid';

export const matchIsNumeric = (text: string) => /^\d+$/.test(text);

export const isSafeNumeric = (value: string) => Number.isSafeInteger(Number(value.replace(/\D+/g, '')));

export const compareFilterItemLabel = (
  { label: a }: TFilterItem<string | number>,
  { label: b }: TFilterItem<string | number>,
) => a.localeCompare(b);

export const replaceIncorrectLineBreaks = (text: string) => text.replace(/\\n/g, '\n');

const ALLOWED_CHARS = 'А-Яа-яёËЁa-zA-Z0-9';
const NOT_ALLOWED_CHARS_REGEXP = new RegExp(`[^${ALLOWED_CHARS}]+`, 'g');
const NOT_ALLOWED_CHARS_AT_END_REGEXP = new RegExp(`[^${ALLOWED_CHARS}]+$`, 'g');

export const trimInvalidEndChars = (text: string) => text.replace(NOT_ALLOWED_CHARS_AT_END_REGEXP, '');
export const replaceSpecialChars = (text: string) => text.replace(NOT_ALLOWED_CHARS_REGEXP, ' ').trim();
