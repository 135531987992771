import { DorimPromoPaths, type RouteObjectWithBreadcrumbs } from '@/shared/lib/react-router';

export const SignatureProtocolPageRoute: RouteObjectWithBreadcrumbs = {
  path: DorimPromoPaths.SPECIFICATION_DETAIL_CONTRACT_SIGNATURE_PROTOCOL_PATH,
  lazy: async () => {
    const { SignatureProtocolPage } = await import(
      /* webpackChunkName: "SignatureProtocolPage" */
      './SignatureProtocolPage'
    );

    return {
      Component: SignatureProtocolPage,
    };
  },
};
