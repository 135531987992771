import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ProfilePopoverStyled = styled(Stack)(({ theme: { palette } }) => ({
  backgroundColor: palette.common.white,
  padding: 8,
}));

export const ProfilePopoverHeaderStyled = styled(Stack)(({ theme: { palette } }) => ({
  borderBottom: `1px solid ${palette.custom.grey}`,
  marginBottom: 8,
  padding: 8,
}));

export const ProfilePopoverFooterStyled = styled(Stack)(({ theme: { palette } }) => ({
  borderTop: `1px solid ${palette.custom.grey}`,
  marginTop: 8,
  paddingTop: 8,
}));

export const ProfilePopoverHeaderUserInfoStyled = styled(Stack)(({ theme: { palette } }) => ({
  backgroundColor: palette.common.white,
  paddingLeft: 16,
}));

export const popoverSxStyle = {
  userName: {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    height: '62px',
    justifyContent: 'center',
    letterSpacing: '0.5px',
    lineHeight: '24px',
  },

  contractorInfo: {
    color: 'neutral.700',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    letterSpacing: '0.4px',
    lineHeight: '16px',
    whiteSpace: 'nowrap',

    name: {
      color: '#9AA7B5',
      marginTop: '8px',
    },
  },
} as const;
