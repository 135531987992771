import { createTheme } from '@mui/material/styles';

import { breakpoints } from './breakpoints.theme';
import { getCustomShadows } from './custom-shadows.theme';
import { dimensions } from './dimensions.theme';
import { overrideComponents } from './overrides';
import { getPalette } from './palette.theme';
import { getShadows } from './shadows.theme';
import { shape } from './shape.theme';
import { typography } from './typography.theme';

export const theme = createTheme({
  palette: getPalette('light'),
  typography,
  shape,
  shadows: getShadows('light'),
  customShadows: getCustomShadows('light'),
  dimensions,
  breakpoints,
});

theme.components = overrideComponents(theme);

if (import.meta.env.DEV) {
  window.theme = theme;
}
