import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';
import HttpBackend, { type HttpBackendOptions } from 'i18next-http-backend';

export const applyGlobalConfigI18next = () =>
  i18next
    .use(initReactI18next)
    .use(HttpBackend)
    .init<HttpBackendOptions>({
      lng: 'ru',
      fallbackLng: 'ru',
      ns: ['navbar', 'cart', 'drug-info'],

      backend: {
        loadPath: '/locales/{{lng}}/{{ns}}.json',
      },

      interpolation: {
        escapeValue: false, // react already safes from xss
      },
    });
