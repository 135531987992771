import { createContext, useContext } from 'react';

type BreadcrumbContextProps = {
  isCurrentPage: boolean;
};

export const BreadcrumbContext = createContext<BreadcrumbContextProps | null>(null);

if (import.meta.env.DEV) {
  BreadcrumbContext.displayName = 'BreadcrumbsContext';
}

export const BreadcrumbProvider = BreadcrumbContext.Provider;

export const useBreadcrumb = () => {
  const context = useContext(BreadcrumbContext);

  if (!context) {
    throw new Error('useBreadcrumb must be used within a BreadcrumbProvider');
  }

  return context;
};
