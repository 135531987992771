import { alpha, type Theme } from '@mui/material/styles';

export default function Input(theme: Theme) {
  return {
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            '& svg': {
              color: theme.palette.neutral[500],
            },
          },
        },
        input: {
          '&::placeholder': {
            opacity: 1,
            color: theme.palette.neutral[500],
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          '&:before': {
            borderBottomColor: alpha(theme.palette.grey[500], 0.56),
          },
          '&:after': {
            borderBottomColor: theme.palette.text.primary,
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputLabel-root.Mui-focused': {
            color: theme.palette.text.primary,
          },

          '& ::-webkit-scrollbar': {
            width: '12px',
          },
          '& ::-webkit-scrollbar-thumb': {
            backgroundColor: '#9AA7B5',
            border: '4px solid transparent',
            backgroundClip: 'padding-box',
            borderRadius: '9px',
          },
        },

        '& .MuiInputBase-input': {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          '&.Mui-disabled': {
            pointerEvents: 'none',
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          ...theme.typography['Body/medium'],

          '&.MuiInputBase-root.MuiFilledInput-root': {
            alignItems: 'center',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: theme.palette.neutral['200'],
            padding: 0,
            paddingBottom: `0 !important`,

            '&.MuiInputBase-adornedStart': {
              paddingLeft: theme.spacing(1.5),
              '&.MuiInputBase-sizeSmall': {
                paddingLeft: theme.spacing(1),
              },
            },

            '&.MuiInputBase-adornedEnd': {
              paddingRight: theme.spacing(1),
              '&.MuiInputBase-sizeSmall': {
                paddingRight: theme.spacing(0.5),
              },
            },

            '&:hover': {
              backgroundColor: theme.palette.neutral['300'],
            },
            '&.Mui-focused': {
              backgroundColor: theme.palette.neutral['300'],
              boxShadow: `inset 0 0 0 2px ${theme.palette.secondary.main}`,
              '.MuiInputAdornment-root:not(.MuiInputAdornment-hiddenLabel)': {
                color: theme.palette.secondary.main,
              },
            },
            '&.Mui-disabled': {
              color: theme.palette.neutral['500'],
              backgroundColor: theme.palette.neutral['300'],
            },
            '&.Mui-error': {
              boxShadow: `inset 0 0 0 2px ${theme.palette.error.main}`,
              '.MuiInputAdornment-root:not(.MuiInputAdornment-hiddenLabel)': {
                color: theme.palette.error.main,
              },
            },

            '.MuiFilledInput-input': {
              display: 'flex',
              alignItems: 'center',
              boxSizing: 'border-box',
              minHeight: '40px !important',
              padding: theme.spacing(1, 1.5),
            },

            '.MuiSelect-select': {
              paddingRight: theme.spacing(4),
            },

            '.MuiSelect-iconOutlined': {
              top: '50%',
              transform: 'translateY(-50%)',
            },

            '&.MuiInputBase-sizeSmall': {
              '.MuiInputBase-input': {
                minHeight: '32px !important',
              },
            },

            '&.MuiInputBase-adornedStart.MuiInputBase-adornedEnd': {
              '.MuiFilledInput-input': {
                padding: theme.spacing(1, 0),
              },

              '&.MuiInputBase-sizeSmall': {
                '.MuiFilledInput-input': {
                  padding: theme.spacing(1, 0),
                },
              },
            },
            '&.MuiInputBase-adornedStart:not(.MuiInputBase-adornedEnd)': {
              '.MuiFilledInput-input': {
                padding: theme.spacing(1, 1.5, 1, 0),
              },

              '&.MuiInputBase-sizeSmall': {
                '.MuiFilledInput-input': {
                  padding: theme.spacing(1, 1, 1, 0),
                },
              },
            },
            '&.MuiInputBase-adornedEnd:not(.MuiInputBase-adornedStart)': {
              '.MuiFilledInput-input': {
                padding: theme.spacing(1, 0, 1, 1.5),
              },

              '&.MuiInputBase-sizeSmall': {
                '.MuiFilledInput-input': {
                  padding: theme.spacing(1, 0, 1, 1),
                },
              },
            },

            '.MuiInputAdornment-root:not(.MuiInputAdornment-hiddenLabel)': {
              marginTop: '0 !important',
              color: theme.palette.neutral[500],
            },
          },
        },

        underline: {
          '&:before, :after': {
            display: 'none',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: alpha(theme.palette.grey[500], 0.32),
          },
          '&.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderWidth: 1,
              borderColor: theme.palette.text.primary,
            },
          },
          '&.Mui-disabled': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.action.disabledBackground,
            },
          },
        },
      },
    },
  };
}
