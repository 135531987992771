import { useResetPasswordActions } from '@/pages/auth/reset-password/store/resetPassword.store';
import { useOTPStoreActions } from '@/shared/stores/otp.store';

export const useClearResetPasswordData = () => {
  const { clearResetPasswordOTPData } = useOTPStoreActions();
  const { clearResetPasswordData } = useResetPasswordActions();

  return () => {
    clearResetPasswordOTPData();
    clearResetPasswordData();
  };
};
