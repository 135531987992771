import { lazy } from 'react';

// ----------------------------------------------------------------------

const UsersSettingsPage = lazy(
  () =>
    import(
      /* webpackPreload: true */
      /* webpackChunkName: "UsersSettingsPage" */
      './UsersSettingsPage'
    ),
);

export default UsersSettingsPage;
