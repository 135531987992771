import { useId } from 'react';
import { useForm, type SubmitHandler } from 'react-hook-form';
import { useUpdateEffect } from 'react-use';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormControl, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useSnackbar } from 'notistack';
import type { InferType } from 'yup';

import type { UserContractor } from '@/api/domains/user-contractor.type';
import { useGlobalErrorStore, useNavigationPrompt } from '@/components/dialogs';
import {
  CustomFormLabel,
  FormProvider,
  RHFMaskedPhoneTextField,
  RHFNumberTextField,
  RHFTextField,
} from '@/components/form-controls';
import { FormActionbar } from '@/components/FormActionbar';
import { Yup } from '@/shared/lib/yup';

import { useUpdateUserContractor } from '../useUpdateUserContractor';

const formSchema = Yup.object().shape({
  bankName: Yup.string().trim().required('Обязательное поле'),
  bankMfo: Yup.string()
    .trim()
    .required('Обязательное поле')
    .test('length', 'Должно быть ровно 5 символов', value => value.length === 5),
  bankCheckingAccount: Yup.string()
    .trim()
    .required('Обязательное поле')
    .test('length', 'Должно быть ровно 20 символов', value => value.length === 20),
  directorName: Yup.string().trim().required('Обязательное поле'),
  directorPhone: Yup.string().isPossiblePhoneNumber().required('Обязательное поле'),
  addressRow: Yup.string().trim().required('Обязательное поле'),
});

type FormSchema = InferType<typeof formSchema>;

export type EntityInfoFormProps = {
  userContractor: UserContractor;
};

export const EntityInfoForm = (props: EntityInfoFormProps) => {
  const { userContractor } = props;

  const formId = useId();
  const agreementsData = userContractor?.agreements_data;
  const directorContact = userContractor?.director_contact;

  const methods = useForm<FormSchema>({
    mode: 'onSubmit',
    resolver: yupResolver(formSchema),
    defaultValues: {
      bankName: agreementsData?.bank_name ?? '',
      bankMfo: agreementsData?.bank_mfo ?? '',
      bankCheckingAccount: agreementsData?.bank_checking_account ?? '',
      directorName: directorContact?.name ?? '',
      directorPhone: directorContact?.phone_number ?? '',
      addressRow: agreementsData?.address_row ?? '',
    },
  });

  const {
    reset,
    formState: { isDirty, isSubmitting },
  } = methods;

  const updateUserContractorMutation = useUpdateUserContractor();
  const { isSuccess: isUserContractorUpdated } = updateUserContractorMutation;

  useUpdateEffect(() => {
    if (isUserContractorUpdated && agreementsData && directorContact) {
      reset({
        bankName: agreementsData.bank_name,
        bankMfo: agreementsData.bank_mfo,
        bankCheckingAccount: agreementsData.bank_checking_account,
        directorName: directorContact.name,
        directorPhone: directorContact.phone_number,
        addressRow: agreementsData.address_row,
      });
    }
  }, [isUserContractorUpdated, agreementsData, directorContact, reset]);

  const { enqueueSnackbar } = useSnackbar();

  useUpdateEffect(() => {
    if (isUserContractorUpdated) {
      enqueueSnackbar('Данные юр. лица успешно изменены', {
        variant: 'success',
      });
    }
  }, [isUserContractorUpdated, enqueueSnackbar]);

  const { setGlobalError } = useGlobalErrorStore();

  const handleSubmit: SubmitHandler<FormSchema> = async formData => {
    try {
      await updateUserContractorMutation.mutateAsync({
        bank_name: formData.bankName,
        bank_mfo: formData.bankMfo,
        bank_checking_account: formData.bankCheckingAccount,
        director_name: formData.directorName,
        director_phone: formData.directorPhone,
        address_row: formData.addressRow,
      });
    } catch (error) {
      if (error) {
        setGlobalError(error);
      }
    }
  };

  const handleReset = () => reset();

  useNavigationPrompt('При переходе на другую страницу все несохраненные изменения будут утеряны', isDirty);

  return (
    <FormProvider isDisabled={isSubmitting} id={formId} methods={methods} onSubmit={handleSubmit}>
      <Grid container spacing={2} mb={5}>
        <Grid xs={12}>
          <Typography component="div" variant="Body/Bold/large">
            Банковские реквизиты
          </Typography>
        </Grid>
        <Grid xs={4}>
          <FormControl fullWidth>
            <CustomFormLabel isRequired htmlFor="bankName">
              Название банка
            </CustomFormLabel>
            <RHFTextField fullWidth variant="filled" name="bankName" id="bankName" />
          </FormControl>
        </Grid>
        <Grid xs={4}>
          <FormControl fullWidth>
            <CustomFormLabel isRequired htmlFor="bankMfo">
              МФО банка
            </CustomFormLabel>
            <RHFNumberTextField fullWidth variant="filled" name="bankMfo" id="bankMfo" />
          </FormControl>
        </Grid>
        <Grid xs={4}>
          <FormControl fullWidth>
            <CustomFormLabel isRequired htmlFor="bankCheckingAccount">
              Расчётный счёт
            </CustomFormLabel>
            <RHFNumberTextField fullWidth variant="filled" name="bankCheckingAccount" id="bankCheckingAccount" />
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={2} mb={5}>
        <Grid xs={12}>
          <Typography component="div" variant="Body/Bold/large">
            Директор
          </Typography>
        </Grid>
        <Grid xs={4}>
          <FormControl fullWidth>
            <CustomFormLabel isRequired htmlFor="directorName">
              ФИО
            </CustomFormLabel>
            <RHFTextField fullWidth variant="filled" name="directorName" id="directorName" />
          </FormControl>
        </Grid>
        <Grid xs={4}>
          <FormControl fullWidth>
            <CustomFormLabel isRequired htmlFor="directorPhone">
              Телефон
            </CustomFormLabel>
            <RHFMaskedPhoneTextField fullWidth variant="filled" name="directorPhone" id="directorPhone" />
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid xs={12}>
          <Typography component="div" variant="Body/Bold/large">
            Реквизиты
          </Typography>
        </Grid>
        <Grid xs={8}>
          <FormControl fullWidth>
            <CustomFormLabel isRequired htmlFor="addressRow">
              Юридический адрес (для договоров)
            </CustomFormLabel>
            <RHFTextField fullWidth variant="filled" name="addressRow" id="addressRow" />
          </FormControl>
        </Grid>
        <Grid xs={12}>
          <Typography component="div" variant="Body/small" color="grey.500" mb={1}>
            Примеры
          </Typography>
          <Typography component="div" variant="Body/medium">
            123456, г. Ташкент, ул. Бабура, д. 1, корп. А, кв. 15
          </Typography>
          <Typography component="div" variant="Body/medium">
            123456, Андижанская обл., п. Айрилиш (Андижанский р-н), ул. Мустакилик, д. 43
          </Typography>
        </Grid>
      </Grid>

      <FormActionbar isFloating isOpen={isDirty} isSubmitting={isSubmitting} formId={formId} onReset={handleReset}>
        В профиле внесены изменения
      </FormActionbar>
    </FormProvider>
  );
};
