import { AvatarAuraStyled, AvatarStyled } from './styles';

const DEFAULT_SIZE = 32;

const getInitials = (fullName: string) => {
  const nameSplit = fullName.split(' ');

  return nameSplit.length > 0 ? [nameSplit?.[0]?.[0], nameSplit?.[1]?.[0]].filter(Boolean).join('') : '';
};

export type AvatarProps = {
  fullName: string;
  imgLink?: string;
  isAura?: boolean;
  size?: number;
  onClick?: () => void;
};

export const Avatar = (props: AvatarProps) => {
  const { imgLink, fullName, size = DEFAULT_SIZE, isAura, onClick } = props;

  const handleClick = () => {
    onClick?.();
  };

  return (
    <AvatarAuraStyled width={size} isAura={isAura}>
      <AvatarStyled width={size} alt={fullName} src={imgLink} onClick={handleClick} isLoad={!!onClick}>
        {getInitials(fullName)}
      </AvatarStyled>
    </AvatarAuraStyled>
  );
};
