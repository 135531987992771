import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTimer } from 'react-timer-hook';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, FormControl, FormHelperText } from '@mui/material';
import { AxiosError, HttpStatusCode } from 'axios';

import { useGlobalErrorStore } from '@/components/dialogs';
import { CustomFormLabel } from '@/components/form-controls';
import { OTPInput } from '@/components/OTPInput';
import { StepsNavigation } from '@/pages/auth/components/StepsNavigation';
import { SupportLink } from '@/pages/auth/components/SupportLink';
import { getExpiredTimestamp } from '@/pages/auth/helpers/getExpiredTimestamp';
import { formatPhoneNumber } from '@/shared/helpers/format.helpers';
import { matchIsNumeric } from '@/shared/helpers/strings.helpers';

import { StyledFormDescription, StyledFormProvider, StyledFormRoot, StyledFormTitle } from '../styles';
import { CountdownButton } from './components/CountdownButton';
import { useSendOTP } from './hooks/useSendOTP';
import { useVerifyOTP } from './hooks/useVerifyOTP';
import { StyledErrorText, StyledFieldsContainer, StyledResendBox } from './styles';
import type { FormValues, VerifyPhoneProps } from './types';
import { FormSchema } from './validation-schema';

const validateChar = (value: string) => {
  return matchIsNumeric(value);
};

const MAX_ATTEMPTS = 5;

export const VerifyPhone = ({
  sendOtpApiMethod,
  verifyOtpApiMethod,
  phoneNumber,
  otpSendData,
  beforeCreateOTP,
  onCreateOTP,
  onVerified,
  beforeVerify,
  navigation,
  applyBanFn,
  attemptCounter,
  title,
  onFailedCreateOTP,
}: VerifyPhoneProps) => {
  const { setGlobalError } = useGlobalErrorStore();

  const methods = useForm<FormValues>({
    mode: 'onSubmit',
    resolver: yupResolver(FormSchema),
    defaultValues: {
      code: '',
    },
  });

  const { watch, formState, setError, setValue, clearErrors, reset } = methods;

  const resetForm = () => {
    reset({ code: '' });
  };

  const onErrorHandler = (error: unknown) => {
    if (error instanceof AxiosError) {
      if (error.response?.status !== HttpStatusCode.TooManyRequests) {
        if (error.response?.data?.message) {
          setError('code', {
            message: error.response.data.message,
          });
        }
      }
    } else {
      setGlobalError(error);
    }
  };

  const { totalSeconds, minutes, restart } = useTimer({
    autoStart: true,
    expiryTimestamp: new Date(otpSendData.expiredAt),
  });

  const isExpired = totalSeconds <= 0;

  const isAttemptLimitExceeded = attemptCounter >= MAX_ATTEMPTS;
  const disableInputs = isExpired || isAttemptLimitExceeded;

  useEffect(() => {
    if (isExpired) {
      resetForm();
    }
    /* eslint-disable-next-line */
  }, [isExpired]);

  const sendOTP = useSendOTP({
    sendOtpApiMethod,
    onSuccess(data) {
      restart(new Date(getExpiredTimestamp(data.lifetime)));
      onCreateOTP(data);
    },
    onError(error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === HttpStatusCode.TooManyRequests) {
          if (error.response?.headers['retry-after']) {
            restart(new Date(getExpiredTimestamp(error.response.headers['retry-after'])));
            onFailedCreateOTP(parseInt(error.response.headers['retry-after']));
          }
        }
      }
    },
    onMutate() {
      beforeCreateOTP?.();
    },
  });

  const verifyOTP = useVerifyOTP({
    verifyOtpApiMethod,
    onSuccess({ signup_token }) {
      onVerified(signup_token);
    },
    onError(error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === HttpStatusCode.TooManyRequests) {
          if (error.response?.headers['retry-after']) {
            applyBanFn(parseInt(error.response.headers['retry-after']));
          }
        }
      }
    },
    onMutate() {
      beforeVerify?.();
    },
  });

  const submitForm = async (formData: FormValues) => {
    try {
      const { code } = formData;

      await verifyOTP({
        phone: phoneNumber,
        otp_code: code,
      });
    } catch (error) {
      onErrorHandler(error);
    }
  };

  const resendOTP = async () => {
    try {
      resetForm();
      clearErrors();

      await sendOTP({ phone: phoneNumber });
    } catch (error) {
      onErrorHandler(error);
    }
  };

  const onBackwardClick = () => {
    navigation.goToPreviousStep();
  };

  const formattedPhoneNumber = formatPhoneNumber(phoneNumber);

  const isFormLoading = formState.isSubmitting || formState.isSubmitSuccessful;
  const code = watch('code');

  return (
    <StyledFormRoot>
      <StyledFormTitle>{title}</StyledFormTitle>

      <StyledFormDescription>
        На номер <b>{formattedPhoneNumber}</b> отправлено сообщение с кодом проверки.
      </StyledFormDescription>

      <StyledFormProvider methods={methods} onSubmit={submitForm}>
        <StyledFieldsContainer>
          <FormControl fullWidth>
            <CustomFormLabel htmlFor="lastName" isRequired>
              Введите код из SMS {otpSendData.smsVersion > 0 ? ` №${otpSendData.smsVersion}` : ''}
            </CustomFormLabel>

            <OTPInput
              value={code}
              length={4}
              autoFocus
              onChange={code => setValue('code', code)}
              validateChar={validateChar}
              TextFieldsProps={{
                disabled: disableInputs,
                inputProps: {
                  inputMode: 'numeric',
                  pattern: '[0-9]*',
                  autoComplete: 'one-time-code',
                },
              }}
            />
            <FormHelperText error={!!methods.formState.errors.code}>
              {methods.formState.errors.code?.message}
            </FormHelperText>
          </FormControl>

          <StyledResendBox>
            {isExpired && <StyledErrorText>Время ввода истекло</StyledErrorText>}

            <Box>
              <CountdownButton
                totalSeconds={totalSeconds}
                minutes={minutes}
                isExpired={isExpired}
                onClick={resendOTP}
              />
            </Box>
          </StyledResendBox>

          <SupportLink />
        </StyledFieldsContainer>

        <StepsNavigation
          onBackwardClick={onBackwardClick}
          currentStep={navigation.currentStep}
          totalSteps={navigation.totalSteps}
          forwardButtonProps={{
            type: 'submit',
            loading: isFormLoading,
            disabled: disableInputs,
          }}
        />
      </StyledFormProvider>
    </StyledFormRoot>
  );
};
