import { useQuery, type UseQueryOptions, type UseQueryResult } from '@tanstack/react-query';

import { AutoSelectionQueryKeys } from '@/api/domains/auto-selection-query.keys';
import { AutoSelectionApi } from '@/api/domains/auto-selection.api';
import type { AutoSelectionInfo } from '@/api/domains/auto-selection.types';

export type UseActiveAutoSelectionOptions<TData> = {
  select?: (data: AutoSelectionInfo[]) => TData;
  enabled?: UseQueryOptions['enabled'];
};

export const useActiveAutoSelection = <TData = AutoSelectionInfo[]>(
  options?: UseActiveAutoSelectionOptions<TData>,
): UseQueryResult<TData> =>
  useQuery({
    queryKey: AutoSelectionQueryKeys.getActiveAutoSelectionKey(),
    queryFn: AutoSelectionApi.fetchActiveAutoSelection,
    staleTime: Infinity,
    ...options,
  });
