import { alertClasses, type AlertProps } from '@mui/material';
import type { Theme } from '@mui/material/styles';

import { ErrorIcon, InfoIcon, SuccessIcon, WarningIcon } from './CustomIcons';

// ----------------------------------------------------------------------

const COLORS = ['info', 'success', 'warning', 'error'] as const;

export default function Alert(theme: Theme) {
  const rootStyle = (ownerState: AlertProps) => {
    const standardVariant = ownerState.variant === 'standard';
    const filledVariant = ownerState.variant === 'filled';
    const outlinedVariant = ownerState.variant === 'outlined';

    const colorStyle = COLORS.map(color => ({
      ...(ownerState.severity === color && {
        // STANDARD
        ...(standardVariant && {
          color: theme.palette[color]['-1'],
          backgroundColor: theme.palette[color]['+1'],

          [`& .${alertClasses.icon}`]: {
            color: theme.palette[color]['main'],
          },
        }),
        // FILLED
        ...(filledVariant && {
          color: theme.palette[color].contrastText,
          backgroundColor: theme.palette[color].main,
        }),
        // OUTLINED
        ...(outlinedVariant && {
          color: theme.palette[color].main,
          border: `solid 1px ${theme.palette[color].main}`,

          [`& .${alertClasses.icon}`]: {
            color: theme.palette[color].main,
          },
        }),
      }),
    }));

    return [
      {
        padding: theme.spacing(1),
        borderRadius: theme.shape.borderRadius,
        alignItems: 'center',

        [`& .${alertClasses.icon}`]: {
          padding: 0,
          marginRight: theme.spacing(1),
        },

        [`& .${alertClasses.message}`]: {
          padding: 0,
        },
      },
      ...colorStyle,
    ];
  };

  return {
    MuiAlert: {
      defaultProps: {
        iconMapping: {
          info: <InfoIcon />,
          success: <SuccessIcon />,
          warning: <WarningIcon />,
          error: <ErrorIcon />,
        },
      },

      styleOverrides: {
        root: ({ ownerState }: { ownerState: AlertProps }) => rootStyle(ownerState),
        icon: {
          opacity: 1,
        },
      },
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          marginBottom: theme.spacing(0.5),
        },
      },
    },
  };
}
