import { CalendarMonthOutlined as CalendarMonthOutlinedIcon } from '@mui/icons-material';
import type { Theme } from '@mui/material/styles';
import type { DatePickerProps } from '@mui/x-date-pickers';

export default function DatePicker(theme: Theme) {
  return {
    MuiDatePicker: {
      defaultProps: {
        slotProps: {
          openPickerButton: {
            size: 'small',
          },
          openPickerIcon: {
            component: CalendarMonthOutlinedIcon,
          },
          textField: {
            inputProps: {
              style: {
                caretColor: 'transparent',
                cursor: 'pointer',
              },
            },
          },
        },
      } satisfies DatePickerProps<any, any>,
    },

    MuiPickersDay: {
      styleOverrides: {
        dayWithMargin: {
          '&&.Mui-selected': {
            backgroundColor: theme.palette.primary.main,

            '&:hover': {
              color: theme.palette.text.primary,
              backgroundColor: theme.palette.neutral['300'],
            },
          },
        },
      },
    },

    MuiPickersMonth: {
      styleOverrides: {
        monthButton: {
          '&&.Mui-selected': {
            backgroundColor: theme.palette.primary.main,

            '&:hover': {
              backgroundColor: theme.palette.neutral['300'],
            },
          },
        },
      },
    },

    MuiPickersYear: {
      styleOverrides: {
        yearButton: {
          '&&.Mui-selected': {
            backgroundColor: theme.palette.primary.main,

            '&:hover': {
              backgroundColor: theme.palette.neutral['300'],
            },
          },
        },
      },
    },
  };
}
