import { Clear } from '@mui/icons-material';
import type { InputAdornmentProps } from '@mui/material';
import { IconButton, InputAdornment } from '@mui/material';

export type ClearEndAdornmentProps = InputAdornmentProps & {
  onClear?: () => void;
};

export const ClearAdornment = (props: ClearEndAdornmentProps) => {
  const { onClear, ...restAdornmentProps } = props;

  const handleMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <InputAdornment {...restAdornmentProps}>
      <IconButton size="small" onClick={onClear} onMouseDown={handleMouseDown} data-testid="clear-input-btn">
        <Clear fontSize="small" />
      </IconButton>
    </InputAdornment>
  );
};
