import { DorimPromoPaths, type RouteObjectWithBreadcrumbs } from '@/shared/lib/react-router';

export const SalesPlanPageRoute: RouteObjectWithBreadcrumbs = {
  path: DorimPromoPaths.SPECIFICATION_DETAIL_SALES_PLAN_PATH,
  lazy: async () => {
    const { SalesPlanPage } = await import(
      /* webpackChunkName: "SalesPlanPage" */
      './SalesPlanPage'
    );

    return {
      Component: SalesPlanPage,
    };
  },
};
