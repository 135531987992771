import { styled } from '@mui/material';

import { PROFILE_INPUT_WIDTH } from '../shared/constants';
import { EntityInfo } from './EntityInfo';
import { EntityInfoFormContent } from './EntityInfoFormContent';

const StyledRoot = styled('div')(({ theme }) => ({
  width: `calc(${PROFILE_INPUT_WIDTH * 3}px + ${theme.spacing(2)} * 2)`,
  padding: theme.spacing(5),
  boxSizing: 'content-box',
}));

export const EntityInfoPage = () => (
  <StyledRoot>
    <EntityInfo />
    <EntityInfoFormContent />
  </StyledRoot>
);
