import { Box } from '@mui/material';

import { ReactComponent as WarningIcon } from '@/shared/assets/icons-2.0/warning.svg';

import { styles } from './styles';

export const FooterText = () => (
  <Box sx={styles.footerWarningBox}>
    <WarningIcon />
    <Box sx={styles.footerWarningBoxText}>Для подтверждения оферты прочитайте ее до конца</Box>
  </Box>
);
