import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import type { Theme } from '@mui/material/styles';

export default function Accordion(theme: Theme) {
  return {
    MuiAccordion: {
      styleOverrides: {
        root: {
          border: theme.shape.divider,
          backgroundColor: theme.palette.neutral['200'],
          overflow: 'hidden',

          '&&': {
            borderRadius: theme.shape.borderRadius,
          },

          '&:before': {
            display: 'none',
          },

          '& ~ &': {
            margin: theme.spacing(1, 0),
          },

          '&.Mui-expanded': {
            boxShadow: 'none',
            margin: theme.spacing(2, 0),
          },
        },
      },
    },
    MuiAccordionSummary: {
      defaultProps: {
        expandIcon: <ExpandMoreIcon color="primary" />,
      },
      styleOverrides: {
        root: {
          borderBottom: theme.shape.divider,
          borderBottomColor: 'transparent',
          transition: `border-bottom-color ${theme.transitions.duration.shortest}ms`,

          '.MuiAccordionSummary-content, .MuiAccordionSummary-content.Mui-expanded': {
            margin: theme.spacing(2, 0),
            paddingRight: theme.spacing(1),
          },

          '&:before': {
            display: 'none',
          },

          '&.Mui-expanded': {
            borderBottomColor: theme.palette.divider,
          },
        },
        expandIconWrapper: {
          color: theme.palette.primary.main,
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: 0,
          margin: theme.spacing(2),

          '&:before': {
            display: 'none',
          },
        },
      },
    },
  };
}
