import { styled } from '@mui/material';
import { MuiOtpInput } from 'mui-one-time-password-input';

export const StyledRoot = styled('div')`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
`;

export const StyledOTPInput = styled(MuiOtpInput)`
  .MuiOtpInput-TextField {
    input {
      width: 63px !important;
      height: 62px;
      border: none;
      box-sizing: border-box;

      color: #1e2f96;
      text-align: center;
      font-size: 36px;
      font-style: normal;
      font-weight: 700;

      border-radius: 4px;
      background: #f6f7f8;
    }
  }
`;
