import { DorimPromoPaths, type RouteObjectWithBreadcrumbs } from '@/shared/lib/react-router';

export const PlanogramTasksPageRoute: RouteObjectWithBreadcrumbs = {
  path: DorimPromoPaths.SPECIFICATION_DETAIL_PLANOGRAM_PATH,
  lazy: async () => {
    const { PlanogramTasksPage } = await import(
      /* webpackChunkName: "PlanogramTasksPage" */
      './PlanogramTasksPage'
    );

    return {
      Component: PlanogramTasksPage,
    };
  },
};
