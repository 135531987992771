// ----------------------------------------------------------------------
// SEARCH

export const SearchPaths = {
  ROOT: '/search',

  ORDER_PREVIEW: 'order-preview',
} as const;

// ----------------------------------------------------------------------
// ORDERS

export const OrdersPaths = {
  ROOT: '/orders',
} as const;

// ----------------------------------------------------------------------
// CREATE-TELEGRAM-GROUP

export const CreateTelegramGroupPaths = {
  ROOT: '/create-telegram-group',
} as const;
