import { paramsToQueryKey } from '@/shared/lib/react-query';

import type { CityDistrictsParams } from './city-districts.api';

// ----------------------------------------------------------------------

const PREFIX = 'city-districts';

// ----------------------------------------------------------------------

export const CityDistrictsQueryKeys = {
  root: [PREFIX],

  cityDistricts: (params?: CityDistrictsParams) => paramsToQueryKey([PREFIX, 'list'], params),
} as const;
