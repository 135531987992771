import { forwardRef } from 'react';

import type { ReactHTMLElementType } from '../types';
import type {
  ComponentCreator,
  ComponentCreatorWithRef,
  PolymorphicComponentProps,
  PolymorphicComponentPropsWithRef,
} from './polymorphic-component.types';

export const polymorphicComponent = <DefaultElement extends ReactHTMLElementType, Props>(
  componentCreator: ComponentCreator<DefaultElement, Props>,
): (<RootComponent extends React.ElementType = DefaultElement>(
  props: PolymorphicComponentProps<RootComponent, Props>,
) => React.ReactNode) => componentCreator;

export const polymorphicComponentWithRef = <DefaultElement extends ReactHTMLElementType, Props>(
  componentCreator: ComponentCreatorWithRef<DefaultElement, Props>,
): (<RootComponent extends React.ElementType = DefaultElement>(
  props: PolymorphicComponentPropsWithRef<RootComponent, Props>,
) => React.ReactNode) =>
  // @ts-expect-error - This is needed to fix the type of the returned function
  forwardRef(componentCreator);
