import { Fragment } from 'react';
import { Stack, styled } from '@mui/material';

import { DocumentToolbar } from '@/components/DocumentToolbar';
import { CashbackStatus } from '@/components/statuses';
import { CASHBACK_STATUS_AWAITING_PAYMENT } from '@/shared/constants/cashback-status.constants';
import { useMainContractDocument } from '@/shared/hooks/useMainContractDocument';
import { useSpecification } from '@/shared/hooks/useSpecification';

import { DocumentToolbarSkeleton } from '../../../../../../ui';
import { useSpecificationIdFromPathParams } from '../../../../lib';
import { DocumentButtons, DownloadTemplatesButton, ToolbarTabs } from './ui';

const StyledRoot = styled('div')(({ theme }) => ({
  padding: theme.spacing(1, 1, 0),
}));

export const Toolbar = () => {
  const {
    query: { isLoading: isLoadingMainContractDocument, data: mainContractDocument },
  } = useMainContractDocument();

  const specificationId = useSpecificationIdFromPathParams();
  const { isLoading: isLoadingSpecification, data: specification } = useSpecification({ specificationId });

  const isLoading = isLoadingMainContractDocument || isLoadingSpecification;

  const renderDocumentToolbar = () => {
    if (isLoading) {
      return <DocumentToolbarSkeleton />;
    }

    if (!specification?.agreement || !mainContractDocument) {
      return null;
    }

    const shouldRenderTemplateButton = specification.agreement.record_status_id === CASHBACK_STATUS_AWAITING_PAYMENT;

    return (
      <DocumentToolbar
        title={specification.agreement.name}
        documentNumber={specification.agreement.document_number}
        button={
          <Fragment>
            {!shouldRenderTemplateButton && (
              <DocumentButtons
                isMainContractSigned={mainContractDocument.is_signed}
                specificationId={specificationId}
                recordStatusId={specification.agreement.record_status_id}
              />
            )}
            {shouldRenderTemplateButton && (
              <Stack direction="row" spacing={2} alignItems="center">
                <DownloadTemplatesButton />
                <DocumentButtons
                  isMainContractSigned={mainContractDocument.is_signed}
                  specificationId={specificationId}
                  recordStatusId={specification.agreement.record_status_id}
                />
              </Stack>
            )}
          </Fragment>
        }
        footer={<ToolbarTabs />}
      >
        <CashbackStatus statusId={specification.agreement.record_status_id} />
      </DocumentToolbar>
    );
  };

  return <StyledRoot>{renderDocumentToolbar()}</StyledRoot>;
};
