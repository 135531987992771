import type { DialogActionsProps } from '@mui/material';
import { DialogActions } from '@mui/material';
import { styled } from '@mui/material/styles';

// ----------------------------------------------------------------------

export type ModalFooterProps = DialogActionsProps;

// ----------------------------------------------------------------------

const StyledRoot = styled(DialogActions)(({ theme }) => ({
  height: theme.dimensions.modal.footer.height,
  padding: theme.spacing(0, 2),
  borderTop: theme.shape.divider,
  borderRadius: theme.spacing(0, 0, 2, 2),
})) as typeof DialogActions;

// ----------------------------------------------------------------------

export const ModalFooter = (props: ModalFooterProps) => <StyledRoot {...props} />;
