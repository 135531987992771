import type { LegalStatus } from '@/api/domains/contractors.types';

// В форме регистрации используется физический адрес для компании и аптеки (согласно документации)
export const PHYSIC_ADDRESS = {
  id: 29,
  author_id: 0,
  initiator_id: 0,
  created_at: '2023-06-25T12:35:32.363092+00:00',
  updated_at: '2023-06-25T12:35:32.363092+00:00',
  author: null,
  initiator: null,
  parent_id: 0,
  alias: 'address_type',
  name: 'Физический',
  description: '',
};

export const companyLegalStatuses: LegalStatus[] = [
  {
    id: 18,
    author_id: 0,
    initiator_id: 0,
    created_at: '2023-06-25T12:35:32.363092+00:00',
    updated_at: '2023-06-25T12:35:32.363092+00:00',
    author: null,
    initiator: null,
    parent_id: 0,
    alias: 'contractor_legal_status',
    name: 'Общество с ограниченной ответственностью',
    description: '',
  },
  {
    id: 19,
    author_id: 0,
    initiator_id: 0,
    created_at: '2023-06-25T12:35:32.363092+00:00',
    updated_at: '2023-06-25T12:35:32.363092+00:00',
    author: null,
    initiator: null,
    parent_id: 0,
    alias: 'contractor_legal_status',
    name: 'Частное предприятие',
    description: '',
  },
  {
    id: 20,
    author_id: 0,
    initiator_id: 0,
    created_at: '2023-06-25T12:35:32.363092+00:00',
    updated_at: '2023-06-25T12:35:32.363092+00:00',
    author: null,
    initiator: null,
    parent_id: 0,
    alias: 'contractor_legal_status',
    name: 'Государственное предприятие',
    description: '',
  },
];
