import type { CitiesParams } from '@/api/domains/cities.api';
import { paramsToQueryKey } from '@/shared/lib/react-query';

// ----------------------------------------------------------------------

const PREFIX = 'cities';

// ----------------------------------------------------------------------

export const CitiesQueryKeys = {
  root: [PREFIX],

  city: (cityId?: number | string) => [PREFIX, cityId?.toString(), 'view'],
  cities: (params?: CitiesParams) => paramsToQueryKey([PREFIX, 'list'], params),
} as const;
