import { useLocation } from 'react-router-dom';

import { useNecessityFile } from '@/pages/product-selection/auto';
import type { RedirectWithoutAccessProps } from '@/shared/lib/react-router';
import { ProductSelectionPaths, RedirectWithoutAccess } from '@/shared/lib/react-router';

type NecessityFileGuardProps = Pick<RedirectWithoutAccessProps, 'children'>;

export const NecessityFileGuard = (props: NecessityFileGuardProps) => {
  const { children } = props;

  const requisitionFile = useNecessityFile();

  const { pathname: from } = useLocation();

  return (
    <RedirectWithoutAccess
      hasAccess={!!requisitionFile}
      to={ProductSelectionPaths.AUTO_SELECTION_PATH}
      state={{ from }}
    >
      {children}
    </RedirectWithoutAccess>
  );
};
