import type { Breakpoint, Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

type Query = 'up' | 'down' | 'between' | 'only';

type Value = Breakpoint;

function useResponsive(query: 'only', startValue: Value, endValue?: undefined): boolean;
function useResponsive(query: 'up' | 'down', startValue: Value | number, endValue?: undefined): boolean;
function useResponsive(query: 'between', startValue: Value | number, endValue: Value | number): boolean;
function useResponsive(query: Query, startValue: Value | number, endValue?: Value | number): boolean {
  return useMediaQuery((theme: Theme) => theme.breakpoints[query](startValue as Value, endValue as Value));
}

export { useResponsive };
