import { CircularProgress, Stack, Typography } from '@mui/material';

import { PageContainerBase } from '@/layouts/Page';
import { ReactComponent as TelegramIcon } from '@/shared/assets/icons-2.0/telegram.svg';

const CreateTelegramGroupPage = () => {
  return (
    <PageContainerBase title="Создание Телеграм группы">
      <Stack direction="row" width="100%" height="100%" justifyContent="center">
        <Stack spacing={3} mt={20} alignItems="center">
          <TelegramIcon width={200} height={200} />
          <Typography variant="Body/large" fontSize={18}>
            Идет создание Телеграм группы. Пожалуйста, ожидайте...
          </Typography>
          <CircularProgress size={30} sx={{ color: theme => theme.palette.grey[600] }} />
        </Stack>
      </Stack>
    </PageContainerBase>
  );
};

export default CreateTelegramGroupPage;
