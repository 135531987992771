import { useQuery } from '@tanstack/react-query';

import { DorimPromoApi } from '@/api/domains/dorim-promo.api';
import { DorimPromoQueryKeys } from '@/api/domains/dorim-promo.query-keys';

export const useMainContractDocument = () => {
  const query = useQuery({
    queryKey: DorimPromoQueryKeys.mainContractDocumentKey,
    queryFn: DorimPromoApi.fetchMainContractDocument,
  });

  const { data } = query;

  const isSigned = data?.is_signed ?? false;

  return {
    query,
    isSigned,
  };
};
