import { Controller, useFormContext } from 'react-hook-form';
import type { AutocompleteProps, PopperProps, TextFieldProps } from '@mui/material';
import { Autocomplete, Popper, TextField } from '@mui/material';

// ----------------------------------------------------------------------

export type RHFAutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> = Omit<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'renderInput'> & {
  name: string;
  label?: string;
  helperText?: React.ReactNode;
  TextFieldProps?: TextFieldProps;
  hasSingleLineOptionsText?: boolean;
};

// ----------------------------------------------------------------------
type CustomPopperProps = {
  popperProps: PopperProps;
  hasSingleLineOptionsText?: boolean;
};

const CustomPopper = ({ popperProps, hasSingleLineOptionsText }: CustomPopperProps) => {
  const { anchorEl, style } = popperProps;
  const extraStyles = { ...style };

  if (hasSingleLineOptionsText) {
    extraStyles.width = 'fit-content';

    if (anchorEl && anchorEl instanceof HTMLElement) {
      extraStyles.minWidth = `${anchorEl.offsetWidth}px`;
    }
  }

  return <Popper {...popperProps} style={extraStyles} />;
};

export const RHFAutocomplete = <
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>(
  props: RHFAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
) => {
  const { name, label, helperText, TextFieldProps, hasSingleLineOptionsText = false, ...restOfProps } = props;

  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, ...restOfFieldProps }, fieldState: { error } }) => {
        const reassignedOnChange: RHFAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>['onChange'] = (
          _,
          value,
        ) => {
          onChange(value);
        };

        return (
          <Autocomplete
            {...restOfFieldProps}
            onChange={reassignedOnChange}
            PopperComponent={(popperProps: PopperProps) => CustomPopper({ popperProps, hasSingleLineOptionsText })}
            renderInput={params => (
              <TextField
                label={label}
                error={!!error}
                helperText={error ? error?.message : helperText}
                {...TextFieldProps}
                {...params}
              />
            )}
            {...restOfProps}
          />
        );
      }}
    />
  );
};
