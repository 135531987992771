import type { IconButtonProps, TooltipProps } from '@mui/material';
import { IconButton, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';

export type ActionIconButtonProps = Omit<IconButtonProps, 'color' | 'title'> & {
  title?: React.ReactNode;
  TooltipProps?: Omit<TooltipProps, 'title'>;
};

const StyledActionButton = styled(IconButton)(
  {
    width: 32,
    minWidth: 32,
    height: 32,
    minHeight: 32,
  },
  ({ theme }) => ({
    color: theme.palette.neutral['700'],

    ':hover': {
      backgroundColor: theme.palette.neutral['300'],
    },
  }),
);

export const ActionIconButton = (props: ActionIconButtonProps) => {
  const { title, TooltipProps, ...restOfProps } = props;

  const button = <StyledActionButton {...restOfProps} color="inherit" />;

  if (title) {
    return (
      <Tooltip
        disableInteractive
        title={title}
        arrow
        placement="top"
        {...TooltipProps}
        TransitionProps={{ exit: false }}
      >
        <span>{button}</span>
      </Tooltip>
    );
  }

  return button;
};
