import { Link } from '@mui/material';

import { ReactComponent as CallIcon } from '@/shared/assets/icons-2.0/call.svg';
import { ReactComponent as TelegramIcon } from '@/shared/assets/icons-2.0/telegram.svg';
import { SUPPORT_PHONE_CALLTO, SUPPORT_PHONE_FORMATTED, TELEGRAM_CHAT_LINK } from '@/shared/constants/dorim.constants';

import { StyledIcon, StyledLinks, StyledRoot, StyledTitle } from './styles';

export const SupportLinks = () => {
  return (
    <StyledRoot>
      <StyledTitle>Тех. поддержка</StyledTitle>
      <StyledLinks>
        <Link href={TELEGRAM_CHAT_LINK} target="_blank" underline="hover" color="inherit">
          <StyledIcon>
            <TelegramIcon />
          </StyledIcon>
          Telegram
        </Link>
        <Link href={`tel:${SUPPORT_PHONE_CALLTO}`} underline="hover" color="inherit">
          <StyledIcon>
            <CallIcon />
          </StyledIcon>
          {SUPPORT_PHONE_FORMATTED}
        </Link>
      </StyledLinks>
    </StyledRoot>
  );
};
