import { css, styled, Typography } from '@mui/material';

import doneImage from '@/shared/assets/image/registration/done.png';

export const StyledContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`;

export const StyledInfoContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  flex-grow: 1;
`;

export const StyledDoneImage = styled('picture')`
  width: 328px;
  height: 328px;
  margin-bottom: 40px;
  background: url(${doneImage}) no-repeat;
  background-size: contain;

  ${({ theme }) => css`
    ${theme.breakpoints.down('tablet')} {
      width: 264px;
      height: 264px;
    }
  `}
`;

export const StyledTitle = styled(Typography)`
  color: #212b36;
  text-align: center;

  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  margin-bottom: 16px;
`;

export const StyledDescription = styled(Typography)`
  color: #212b36;
  text-align: center;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
`;
