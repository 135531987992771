import { lazy } from 'react';

export const NecessityListFieldMatchingPage = lazy(
  () =>
    import(
      /* webpackPreload: true */
      /* webpackChunkName: "NecessityListFieldMatchingPage" */
      './NecessityListFieldMatchingPage'
    ),
);
