import { useEffectOnce, useLatest } from 'react-use';
import { eq } from 'lodash';

export type PostMessageData<TPayload = never> = {
  type: string;
  payload?: TPayload;
};

export type PostMessageHandler<TPayload> = (data: PostMessageData<TPayload>) => void;

const isCurrentOrigin = (origin: string) => origin === window.location.origin;

const isPostMessageData = (data: unknown): data is PostMessageData =>
  typeof data === 'object' && data !== null && 'type' in data && typeof data.type === 'string';

export const usePostMessageListener = <TPayload>(type: string, handler: PostMessageHandler<TPayload>) => {
  const latestHandler = useLatest(handler);

  useEffectOnce(() => {
    const handleMessage = (event: MessageEvent<Partial<PostMessageData>>) => {
      if (isCurrentOrigin(event.origin) && isPostMessageData(event.data) && eq(event.data.type, type)) {
        latestHandler.current(event.data);
      }
    };

    window.addEventListener('message', handleMessage);

    return () => window.removeEventListener('message', handleMessage);
  });
};
