import { forwardRef } from 'react';
import type { Props } from 'simplebar-react';
import SimpleBar from 'simplebar-react';
import type { SxProps } from '@mui/material';
import type { Theme } from '@mui/material/styles';
import { alpha, styled } from '@mui/material/styles';
import type SimpleBarCore from 'simplebar-core';

export type ScrollbarProps = React.PropsWithChildren<
  Props & {
    sx?: SxProps<Theme>;
  }
>;

export type ScrollbarInstance = SimpleBarCore;

export const StyledScrollbarContainer = styled('div')({
  height: '100%',
  overflow: 'hidden',
});

export const StyledScrollbar = styled(SimpleBar, {
  shouldForwardProp: prop => prop !== 'sx',
})(({ theme }) => ({
  height: '100%',

  '.simplebar-track': {
    zIndex: theme.zIndex.appBar,
  },

  '.simplebar-scrollbar': {
    '&:before': {
      backgroundColor: alpha(theme.palette.grey[600], 0.48),
    },
    '&.simplebar-visible:before': {
      opacity: 1,
    },
  },
  '.simplebar-mask': {
    zIndex: 'inherit',
  },
})) as unknown as React.ForwardRefExoticComponent<ScrollbarProps & React.RefAttributes<ScrollbarInstance | null>>;

export const Scrollbar = forwardRef<ScrollbarInstance, ScrollbarProps>((props, ref) => (
  <StyledScrollbarContainer>
    <StyledScrollbar ref={ref} clickOnTrack={false} {...props} />
  </StyledScrollbarContainer>
));
