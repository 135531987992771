import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormControl, Stack } from '@mui/material';

import { useGlobalErrorStore } from '@/components/dialogs';
import { CustomFormLabel, RHFPasswordTextField } from '@/components/form-controls';
import {
  PasswordStrengthValidation,
  usePasswordStrengthValidation,
} from '@/modules/account/PasswordStrengthValidation';
import { StepsNavigation } from '@/pages/auth/components/StepsNavigation';
import { StyledFormProvider, StyledFormRoot, StyledFormTitle } from '@/pages/auth/components/styles';

import type { FormValues, PasswordFormProps } from './types';
import { passwordSchema } from './validation-schema';

export const PasswordForm = ({ onSubmit, navigation }: PasswordFormProps) => {
  const { setGlobalError } = useGlobalErrorStore();
  const { validationResult, runPasswordStrengthValidation } = usePasswordStrengthValidation();

  const methods = useForm<FormValues>({
    mode: 'onTouched',
    resolver: yupResolver(passwordSchema),
    defaultValues: {
      password: '',
      passwordConfirmation: '',
    },
  });

  const { getValues, watch, trigger, formState, setError } = methods;
  const password = watch('password');

  useEffect(() => {
    runPasswordStrengthValidation(password);
    if (password !== '') {
      trigger(['password']);
    }
    if (getValues('passwordConfirmation')) {
      trigger(['password', 'passwordConfirmation']);
    }
    /* eslint-disable-next-line */
  }, [password]);

  const submitForm = (formData: FormValues) => {
    const { password } = formData;
    const { isPasswordStrong } = runPasswordStrengthValidation(password);

    if (!isPasswordStrong) {
      setError('password', { message: 'Пароль не соответствует требованиям' });
      return false;
    }

    try {
      return onSubmit(password);
    } catch (error) {
      setGlobalError(error);
    }
  };

  const onBackwardClick = () => {
    navigation.goToPreviousStep();
  };

  const isFormLoading = formState.isSubmitting;

  return (
    <StyledFormRoot>
      <StyledFormTitle>Создание пароля</StyledFormTitle>
      <StyledFormProvider methods={methods} onSubmit={submitForm}>
        <Stack spacing={3} mb={7} flex="1 1 auto">
          <FormControl fullWidth>
            <CustomFormLabel htmlFor="password" isRequired>
              Пароль
            </CustomFormLabel>
            <RHFPasswordTextField fullWidth name="password" id="password" autoComplete="password" variant="filled" />
          </FormControl>

          <FormControl fullWidth>
            <CustomFormLabel htmlFor="passwordConfirmation" isRequired>
              Подтверждение пароля
            </CustomFormLabel>
            <RHFPasswordTextField
              fullWidth
              name="passwordConfirmation"
              id="passwordConfirmation"
              autoComplete="passwordConfirmation"
              variant="filled"
            />
          </FormControl>
          <PasswordStrengthValidation validationResult={validationResult} />
        </Stack>
        <StepsNavigation
          onBackwardClick={onBackwardClick}
          currentStep={navigation.currentStep}
          totalSteps={navigation.totalSteps}
          backwardButtonProps={{ hidden: true }}
          forwardButtonProps={{ type: 'submit', loading: isFormLoading }}
        />
      </StyledFormProvider>
    </StyledFormRoot>
  );
};
