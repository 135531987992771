import { useInView } from 'react-intersection-observer';

import { useFloatingPanelStoreActions } from '../lib';

export const FloatingPanelTrigger = () => {
  const { showFloatingPanel, hideFloatingPanel } = useFloatingPanelStoreActions();

  const { ref } = useInView({
    onChange: inView => {
      if (inView) {
        hideFloatingPanel();
      } else {
        showFloatingPanel();
      }
    },
  });

  return <div ref={ref} />;
};
