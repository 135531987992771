import { useQuery } from '@tanstack/react-query';

import { DorimPromoApi } from '@/api/domains/dorim-promo.api';
import { DorimPromoQueryKeys } from '@/api/domains/dorim-promo.query-keys';

export const useIsSpecificationContractAccepted = () => {
  const query = useQuery({
    queryKey: DorimPromoQueryKeys.isSpecificationContractAcceptedKey,
    queryFn: () => DorimPromoApi.fetchSpecifications(),
  });

  const { data } = query;
  const specificationId = data?.quick_select_id;
  const isAccepted = !specificationId;

  return {
    isAccepted,
    specificationId,
    query,
  };
};
