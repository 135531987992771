import { styled } from '@mui/material/styles';

import { useCheckoutCloudCartSummery, useIsCheckoutCloudCartEmpty } from '@/entities/cloud-carts';

import { SummaryBarBase, type SummaryBarBaseProps } from './SummaryBarBase';

const StyledRoot = styled(SummaryBarBase)({
  position: 'sticky',
  bottom: 0,
  marginTop: 'auto',
}) as typeof SummaryBarBase;

export type CheckoutCloudCartSummaryBarProps = Omit<
  SummaryBarBaseProps,
  'isLoading' | 'isFetching' | 'isEmpty' | 'summery'
>;

export const CheckoutCloudCartSummaryBar = (props: CheckoutCloudCartSummaryBarProps) => {
  const { isLoading: isCloudCartLoading, data: isCloudCartEmpty = true } = useIsCheckoutCloudCartEmpty();
  const { isFetching: isSummeryFetching, data: summery } = useCheckoutCloudCartSummery();

  return (
    <StyledRoot
      isLoading={isCloudCartLoading}
      isFetching={isSummeryFetching}
      isEmpty={isCloudCartEmpty}
      summery={summery}
      {...props}
    />
  );
};
