import { Stack, type StackProps } from '@mui/material';

import { ReactComponent as EmptySearchListSVG } from '@/shared/assets/icons/common/empty_search_list.svg';

export type DataGridEmptyTextProps = StackProps & {
  icon?: React.ReactNode;
};

export const DataGridEmptyText = (props: DataGridEmptyTextProps) => {
  const { icon = <EmptySearchListSVG />, children, ...restProps } = props;

  return (
    <Stack alignItems="center" {...restProps}>
      {icon}
      {children}
    </Stack>
  );
};
