import { styled } from '@mui/material';

import { Modal } from '@/components/Modal';
import { toImportant } from '@/shared/theme';

export const StyledModal = styled(Modal)(({ theme }) => ({
  borderRadius: toImportant(0),

  '.MuiDialog-paper': {
    margin: 0,
    borderRadius: toImportant(0),
    padding: theme.spacing(2),
  },
}));

export const popperStyle = {
  overflow: 'auto',
  position: toImportant('relative'),
  transform: toImportant('none'),
  borderRadius: 0,
};

export const paperStyle = {
  boxShadow: 'none',
};

export const listboxStyle = {
  height: '100%',
  maxHeight: '100%',
};
