import { FilledInput, type SelectProps } from '@mui/material';
import type { Theme } from '@mui/material/styles';

import { InputSelectIcon } from './CustomIcons';

export default function Select(_: Theme) {
  return {
    MuiSelect: {
      defaultProps: {
        IconComponent: InputSelectIcon,
        input: <FilledInput />,
      } satisfies SelectProps,

      styleOverrides: {
        select: {
          padding: 0,
        },
      },
    },
  };
}
