import { Stack } from '@mui/material';

import { Label } from '@/components/Label';

type BadgeBaseProps = React.PropsWithChildren<{
  labelIcon: React.ReactNode;
  badgeColor: 'red' | 'blue' | 'green';
}>;

export const BadgeBase = (props: BadgeBaseProps) => {
  const { labelIcon, badgeColor, children } = props;

  return (
    <Label
      component={Stack}
      direction="row"
      spacing={0.5}
      color="secondary.contrastText"
      backgroundColor={`custom.${badgeColor}`}
    >
      {labelIcon}
      <span>{children}</span>
    </Label>
  );
};
