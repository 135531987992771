import { useRef } from 'react';
import type { Transition } from 'history';

import { useNavigateConfirmationStore } from '@/components/dialogs/NavigateConfirmationDialog/useNavigateConfirmationStore';
import { useNavigationBlocker } from '@/shared/lib/react-router';

// ----------------------------------------------------------------------

export const useNavigateConfirmationDialog = () => {
  const { isBlocked, isOpen, message, open, close, unblock } = useNavigateConfirmationStore();

  const txRef = useRef<Transition | null>(null);

  useNavigationBlocker(tx => {
    txRef.current = tx;
    open();
  }, isBlocked);

  // ----------------------------------------------------------------------

  const handleCancel = () => close();
  const handleConfirm = () => {
    const tx = txRef.current;

    if (tx) {
      tx.retry();
    }

    close();
    unblock();
  };

  return { isOpen, message, handleCancel, handleConfirm };
};
