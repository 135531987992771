import { matchPath, useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';

import { generatePath } from '@/shared/lib/react-router';

type UseCurrentStepParams = {
  routerPath: string;
  stepParamName?: string;
};
const DEFAULT_STEP_PARAM_NAME = 'step';

export const useCurrentStep = <TStep>({
  routerPath,
  stepParamName = DEFAULT_STEP_PARAM_NAME,
}: UseCurrentStepParams) => {
  const location = useLocation();
  const matchResult = matchPath(routerPath, location.pathname);
  return matchResult?.params[stepParamName] as TStep;
};

type UseStepsNavigationParams<TStep> = UseCurrentStepParams & {
  steps: readonly TStep[];
};

export const useStepsNavigation = <TStep>(params: UseStepsNavigationParams<TStep>) => {
  const { routerPath, steps, stepParamName = DEFAULT_STEP_PARAM_NAME } = params;
  const currentStep = useCurrentStep<TStep>({
    routerPath,
    stepParamName,
  });
  const navigate = useNavigate();

  const goToStep = (step: TStep) => {
    const path = generatePath(routerPath, { [stepParamName]: String(step) });

    return navigate(path);
  };

  const currentStepIndex = steps.indexOf(currentStep);
  const goToPreviousStep = () => {
    if (currentStepIndex === undefined || currentStepIndex <= 0) return;

    const prevStep = steps[currentStepIndex - 1];
    goToStep(prevStep);
  };
  const goToNextStep = () => {
    if (currentStepIndex === undefined) return;
    const nextStep = steps[currentStepIndex + 1];

    if (!nextStep) return;
    goToStep(nextStep);
  };

  return {
    currentStep,
    currentStepIndex,
    totalSteps: steps.length,
    goToStep,
    goToNextStep,
    goToPreviousStep,
  };
};
