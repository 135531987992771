import { lazy } from 'react';

export const ProductSelectionAutoPage = lazy(
  () =>
    import(
      /* webpackPreload: true */
      /* webpackChunkName: "ProductSelectionAutoPage */
      './ProductSelectionAutoPage'
    ),
);
