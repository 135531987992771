import { Fragment } from 'react';
import { useParams } from 'react-router-dom';

import { RoundedButton } from '@/components/buttons';
import { useMainContractDocument } from '@/shared/hooks/useMainContractDocument';
import type { SpecificationDetailPathParams } from '@/shared/lib/react-router';

import { useSpecificationStoreActions } from '../../../../../lib';
import { CalculationErrorReportDialog } from '../CalculationErrorReportDialog';
import { ContractClosingDialog } from '../ContractClosingDialog';
import { MainContractSigningConfirmationDialog } from '../MainContractSigningConfirmationDialog';
import { ReceivePaymentDialog } from '../ReceivePaymentDialog';
import { useConfirmReceivingBonus } from './lib';

export const ReceivePaymentButton = () => {
  const { isSigned } = useMainContractDocument();
  const { openBonusConfirmationDialog, openMainContractConfirmationDialog } = useSpecificationStoreActions();

  const openDialog = isSigned ? openBonusConfirmationDialog : openMainContractConfirmationDialog;

  return (
    <Fragment>
      <RoundedButton variant="contained" onClick={openDialog}>
        Получить выплату
      </RoundedButton>

      <ReceivePaymentDialog />
      <MainContractSigningConfirmationDialog />
    </Fragment>
  );
};

export const AcceptPaymentButton = () => {
  const params = useParams<SpecificationDetailPathParams>();
  const specificationId = Number(params.specificationId);

  const { isLoading, mutate: confirmReceivingCashbackBonus } = useConfirmReceivingBonus();
  const { openContractClosingDialog } = useSpecificationStoreActions();

  return (
    <Fragment>
      <RoundedButton
        loading={isLoading}
        variant="contained"
        onClick={() => {
          confirmReceivingCashbackBonus(specificationId, {
            onSuccess: openContractClosingDialog,
          });
        }}
      >
        Подтвердить получение выплаты
      </RoundedButton>

      <ContractClosingDialog />
    </Fragment>
  );
};

export const CalculationErrorReportButton = () => {
  const { openCalculationErrorReportDialog } = useSpecificationStoreActions();

  return (
    <Fragment>
      <RoundedButton variant="outlined" onClick={openCalculationErrorReportDialog}>
        Сообщить об ошибке в подсчете
      </RoundedButton>

      <CalculationErrorReportDialog />
    </Fragment>
  );
};
