import { HelmetProvider } from 'react-helmet-async';

import { AuthProvider } from './AuthProvider';
import { QueryClientProvider } from './QueryClientProvider';
import { SnackbarProvider } from './SnackbarProvider';
import { ThemeProvider } from './ThemeProvider';

export type ProvidersProps = React.PropsWithChildren<{}>;

export const Providers = (props: ProvidersProps) => {
  const { children } = props;

  return (
    <HelmetProvider>
      <ThemeProvider>
        <SnackbarProvider>
          <QueryClientProvider>
            <AuthProvider>{children}</AuthProvider>
          </QueryClientProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </HelmetProvider>
  );
};
