import { Button, buttonClasses, svgIconClasses, type ButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';

import { polymorphicComponentWithRef } from '@/shared/lib/react';

type StyledRootProps = {
  isSelected?: boolean;
};

const StyledRoot = styled(Button, {
  shouldForwardProp: prop => prop !== 'isSelected',
})<StyledRootProps>(({ theme, isSelected, disabled }) => ({
  variants: [
    {
      props: { variant: 'outlined' },
      style: {
        [`&.${buttonClasses.outlined}`]: {
          justifyContent: 'space-between',
          padding: theme.spacing(0, 1.5),
          color: theme.palette.neutral['900'],
          borderColor: isSelected && !disabled ? theme.palette.primary['+2'] : theme.palette.neutral['500'],
          backgroundColor: isSelected && !disabled ? theme.palette.primary['+2'] : theme.palette.neutral['100'],

          [`.${svgIconClasses.root}`]: {
            width: 18,
            height: 18,
            color: theme.palette.neutral['700'],
          },
        },

        [`&.${buttonClasses.outlined}:hover`]: {
          borderColor: isSelected && !disabled ? theme.palette.primary['+1'] : theme.palette.neutral['500'],
          backgroundColor: isSelected ? theme.palette.primary['+1'] : theme.palette.neutral['200'],
        },

        [`&.${buttonClasses.disabled}`]: {
          color: theme.palette.neutral['500'],
          backgroundColor: isSelected ? theme.palette.neutral[300] : theme.palette.neutral['100'],

          [`.${svgIconClasses.root}`]: {
            color: theme.palette.neutral['500'],
          },
        },
      },
    },
  ],
}));

export type ActionButtonProps = StyledRootProps &
  Omit<ButtonProps, 'variant' | 'size'> & {
    variant?: never;
  };

export const ActionButton = polymorphicComponentWithRef<'button', ActionButtonProps>((props, ref) => (
  <StyledRoot variant="outlined" ref={ref} {...props} />
));
