import { Navigate } from 'react-router-dom';

import { AuthGuard } from '@/guards/AuthGuard.tsx';
import { MainLayout } from '@/layouts/MainLayout';
import { BreadcrumbLink, DorimPromoPaths, type RouteObjectWithBreadcrumbs } from '@/shared/lib/react-router';

import { SpecificationsPageRootRoute } from './specifications-root';

export const DorimPromoRoute: RouteObjectWithBreadcrumbs = {
  path: DorimPromoPaths.ROOT_PATH,
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  handle: {
    breadcrumb: {
      element: <BreadcrumbLink to={DorimPromoPaths.ROOT_PATH}>Dorim.Promo</BreadcrumbLink>,
    },
  },
  children: [
    {
      index: true,
      element: <Navigate replace to={DorimPromoPaths.SPECIFICATIONS_ROOT_PATH} />,
    },
    SpecificationsPageRootRoute,
  ],
};
