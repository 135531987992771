import type { StackProps } from '@mui/material';
import { Stack, Typography } from '@mui/material';

type InfoSectionProps = StackProps & {
  title: string;
};

export const InfoSection = (props: InfoSectionProps) => {
  const { title, children, ...restProps } = props;

  return (
    <Stack spacing={2} {...restProps}>
      <Typography variant="Body/Bold/large">{title}</Typography>
      {children}
    </Stack>
  );
};
