import type { TokenPair, TokenPairStorageItem } from './auth.types';
import { getSessionTokens, getShouldStaySignedIn, resetSessionTokens, setSessionTokens } from './session-tokens.store';

const STORAGE_KEY_TOKENS = 'dorim-price:tokens';

export const tokensStorage = {
  INITIAL_TOKEN_PAIR: {
    access_token: null,
    refresh_token: null,
  } satisfies TokenPairStorageItem,

  setTokenPair: (tokenPair: TokenPair): void => {
    const shouldStaySignedIn = getShouldStaySignedIn();

    if (shouldStaySignedIn) {
      localStorage.setItem(STORAGE_KEY_TOKENS, JSON.stringify(tokenPair));
    } else {
      setSessionTokens(tokenPair);
    }
  },

  getTokenPair: (): TokenPairStorageItem => {
    const storedTokens = getSessionTokens();
    if (storedTokens) {
      return storedTokens;
    }

    const tokenPair = localStorage.getItem(STORAGE_KEY_TOKENS);

    if (!tokenPair) {
      return tokensStorage.INITIAL_TOKEN_PAIR;
    }

    try {
      return JSON.parse(tokenPair) as TokenPairStorageItem;
    } catch {
      return tokensStorage.INITIAL_TOKEN_PAIR;
    }
  },

  removeTokenPair: () => {
    const shouldStaySignedIn = getShouldStaySignedIn();
    if (shouldStaySignedIn) {
      localStorage.removeItem(STORAGE_KEY_TOKENS);
    } else {
      resetSessionTokens();
    }
  },
};
