import { Card, styled } from '@mui/material';

type StyledRootProps = {
  noGutters?: boolean;
};

const StyledRoot = styled('div')<StyledRootProps>(({ theme, noGutters }) => ({
  padding: noGutters ? 0 : theme.spacing(0, 1),
  backgroundColor: theme.palette.background.neutral,
}));

const StyledHeaderTabs = styled(Card)(({ theme }) => ({
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  borderTop: 'none',
  padding: theme.spacing(0, 2),
})) as typeof Card;

export type TabPanelProps = React.PropsWithChildren<StyledRootProps>;

export const HeaderTabs = (props: TabPanelProps) => {
  const { noGutters, ...restProps } = props;

  return (
    <StyledRoot noGutters={noGutters}>
      <StyledHeaderTabs variant="outlined" {...restProps} />
    </StyledRoot>
  );
};
