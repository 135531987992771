import { useNavigate } from 'react-router';

import { PasswordForm } from '@/pages/auth/components/PasswordForm';
import { useNavigation } from '@/pages/auth/reset-password/hooks/useNavigation';
import { useAuthMethods } from '@/shared/lib/auth';
import { AuthPaths } from '@/shared/lib/react-router';
import { useOTPStoreResetPasswordVerifyData } from '@/shared/stores/otp.store';

import { useResetPasswordActions } from './store/resetPassword.store';

export const NewPasswordPage = () => {
  const { setPassword } = useResetPasswordActions();
  const { signup_token } = useOTPStoreResetPasswordVerifyData();
  const { resetPassword } = useAuthMethods();
  const navigate = useNavigate();
  const { goToPreviousStep, currentStepIndex, totalSteps } = useNavigation();

  const onSubmit = async (password: string) => {
    setPassword(password);

    const { access_token, refresh_token } = await resetPassword({
      password,
      signup_token,
    });

    navigate(AuthPaths.RESET_PASSWORD.SUCCESS, {
      state: { access_token, refresh_token },
    });
  };

  return (
    <PasswordForm
      onSubmit={onSubmit}
      navigation={{
        goToPreviousStep,
        currentStep: currentStepIndex + 1,
        totalSteps,
      }}
    />
  );
};
