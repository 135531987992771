import { lazy } from 'react';

export const ProductSelectionBoundDrugsPage = lazy(
  () =>
    import(
      /* webpackPreload: true */
      /* webpackChunkName: "ProductSelectionBoundDrugsPage */
      './ProductSelectionBoundDrugsPage'
    ),
);
