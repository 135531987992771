import type { RegionsParams } from '@/api/domains/regions.api';
import { paramsToQueryKey } from '@/shared/lib/react-query';

// ----------------------------------------------------------------------

const PREFIX = 'regions';

// ----------------------------------------------------------------------

export const RegionsQueryKeys = {
  root: [PREFIX],

  regions: (params?: RegionsParams) => paramsToQueryKey([PREFIX, 'list'], params),
} as const;
