import { lazy } from 'react';

// ----------------------------------------------------------------------

const ExpirationDatesSettingsPage = lazy(
  () =>
    import(
      /* webpackPreload: true */
      /* webpackChunkName: "ExpirationDatesSettingsPage" */
      '@/pages/settings/expiration-dates-settings/ExpirationDatesSettingsPage'
    ),
);

export default ExpirationDatesSettingsPage;
