import { useIsSpecificationContractAccepted } from '@/shared/hooks/useIsSpecificationContractAccepted';
import { DorimPromoPaths, RedirectWithoutAccess } from '@/shared/lib/react-router';

type AcceptedSpecificationContractGuardProps = React.PropsWithChildren;

export const AcceptedSpecificationContractGuard = (props: AcceptedSpecificationContractGuardProps) => {
  const { children } = props;

  const {
    query: { isLoading },
    isAccepted,
    specificationId,
  } = useIsSpecificationContractAccepted();

  if (isLoading) {
    return null;
  }

  const path = !isAccepted
    ? DorimPromoPaths.generateSpecificationsNoticePath({
        specificationId: String(specificationId),
      })
    : '/';

  return (
    <RedirectWithoutAccess hasAccess={isAccepted} to={path}>
      {children}
    </RedirectWithoutAccess>
  );
};
