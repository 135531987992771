import { useQuery, useQueryClient, type UseQueryResult } from '@tanstack/react-query';
import { produce } from 'immer';
import { size } from 'lodash';

import { CloudCartsApi } from '@/api/domains/cloud-carts.api';
import { CLOUD_CART_ENTRY_SOURCE_MANUAL, type CloudCartEntrySource } from '@/api/domains/cloud-carts.constants';
import { CloudCartsQueryKeys } from '@/api/domains/cloud-carts.query-keys';

import {
  cancelCloudCartQueriesByCartId,
  getBaseCloudCartsQueryOptions,
  getCurrentCloudCartId,
  useCurrentCloudCartId,
  useUpdateCloudCart,
  type BaseCloudCartsOptions,
} from './cloud-carts-base';
import {
  computeCheckoutEntriesCount,
  computeCheckoutTotalPackagesCount,
  computeTotalPackagesCount,
  transformCheckoutCloudCartData,
  updateCloudCartCache,
} from './cloud-carts.helpers';
import type { TransformedCheckoutCloudCart } from './cloud-carts.types';

// ----------------------------------------------------------------------

export type UseCheckoutCloudCartSelector<TData> = (data: TransformedCheckoutCloudCart) => TData;

export type UseCheckoutCloudCartOptions<TData = TransformedCheckoutCloudCart> = BaseCloudCartsOptions & {
  select?: UseCheckoutCloudCartSelector<TData>;
};

export const useCheckoutCloudCart = <TData = TransformedCheckoutCloudCart>(
  options: UseCheckoutCloudCartOptions<TData>,
): UseQueryResult<TData> => {
  return useQuery({
    queryKey: CloudCartsQueryKeys.getCheckoutCloudCartKey({ cartId: options.cartId }),
    queryFn: async () => {
      const rawCheckoutCloudCart = await CloudCartsApi.fetchCheckoutCloudCart({
        // !!! There is no additional check for `cartId` because the function should not return `undefined` !!!
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        cartId: options.cartId!,
      });

      return transformCheckoutCloudCartData(rawCheckoutCloudCart);
    },
    select: options.select,
    ...getBaseCloudCartsQueryOptions(options),
  });
};

// ----------------------------------------------------------------------

export type UseCurrentCheckoutCloudCartOptions<TData> = Omit<UseCheckoutCloudCartOptions<TData>, 'cartId'>;

export const useCurrentCheckoutCloudCart = <TData = TransformedCheckoutCloudCart | undefined>(
  options?: UseCurrentCheckoutCloudCartOptions<TData>,
) =>
  useCheckoutCloudCart({
    cartId: useCurrentCloudCartId(),
    ...options,
  });

// ----------------------------------------------------------------------

export type UseUpdateCheckoutCloudCartEntryOptions = {
  distributorId: number;
  drugId: number;
  offerId: number;
  entrySource?: CloudCartEntrySource;
};

export const useUpdateCheckoutCloudCartEntry = ({
  distributorId,
  drugId,
  offerId,
  entrySource = CLOUD_CART_ENTRY_SOURCE_MANUAL,
}: UseUpdateCheckoutCloudCartEntryOptions) => {
  const queryClient = useQueryClient();

  const { debouncedMutate, setUpdateStatus } = useUpdateCloudCart();

  const updateCheckoutCloudCartEntry = async (count: number) => {
    setUpdateStatus(true);

    const cartId = await getCurrentCloudCartId(queryClient);
    await cancelCloudCartQueriesByCartId(queryClient, cartId);

    queryClient.setQueryData<TransformedCheckoutCloudCart>(
      CloudCartsQueryKeys.getCheckoutCloudCartKey({ cartId }),
      prevData => {
        if (!prevData) {
          return;
        }

        return produce(prevData, ({ checkoutDistributorEntries, rawCheckoutCloudCart }) => {
          const checkoutDistributorEntry = checkoutDistributorEntries[distributorId];

          const { entriesPackagesCounts } = updateCloudCartCache({
            data: checkoutDistributorEntry,
            drugId,
            offerId,
            count,
          });

          checkoutDistributorEntry.rawCheckoutCloudCartItem.items_count = size(entriesPackagesCounts);
          checkoutDistributorEntry.rawCheckoutCloudCartItem.packages_count =
            computeTotalPackagesCount(entriesPackagesCounts);

          rawCheckoutCloudCart.items_count = computeCheckoutEntriesCount(checkoutDistributorEntries);
          rawCheckoutCloudCart.packages_count = computeCheckoutTotalPackagesCount(checkoutDistributorEntries);
        });
      },
    );

    return debouncedMutate({
      cartId,
      payload: {
        offer_id: offerId,
        quantity: count,
        source: entrySource,
      },
    });
  };

  return {
    updateCheckoutCloudCartEntry,
  };
};
