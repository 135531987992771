import { lazy } from 'react';

export const ProductSelectionManualPage = lazy(
  () =>
    import(
      /* webpackPreload: true */
      /* webpackChunkName: "ProductSelectionManualPage" */
      './ProductSelectionManualPage'
    ),
);
