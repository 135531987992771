import { memo } from 'react';
import type { StackProps } from '@mui/material';
import { Stack, Typography } from '@mui/material';
import type { Merge } from 'type-fest';

import { rulesConfig } from '@/modules/account/PasswordStrengthValidation/config';
import type { ValidationRuleStatus } from '@/modules/account/PasswordStrengthValidation/types';
import { ValidationRule } from '@/modules/account/PasswordStrengthValidation/ValidationRule';
import { ValidationStatusBadge } from '@/modules/account/PasswordStrengthValidation/ValidationStatusBadge';

export type PasswordStrengthValidationProps = Merge<
  StackProps,
  {
    validationResult: ValidationRuleStatus[];
  }
>;

export const PasswordStrengthValidation = memo((props: PasswordStrengthValidationProps) => {
  const { validationResult, ...restOfProps } = props;

  return (
    <Stack spacing={2} {...restOfProps}>
      <Typography variant="Body/Bold/large">Пароль должен содержать:</Typography>

      <Stack component="ul" spacing={1}>
        {rulesConfig.map((rule, index) => (
          <ValidationRule key={rule.id} order={index + 1} label={rule.label} status={validationResult[index]} />
        ))}
      </Stack>

      <ValidationStatusBadge validationResult={validationResult} />
    </Stack>
  );
});
