import { httpClient } from '@/shared/lib/axios';

import type { CloudCartEntrySource } from './cloud-carts.constants';
import type { CheckoutCloudCart, CloudCart, LatestCloudCartUpdate } from './cloud-carts.types';

export type FetchCloudCartParams = {
  cartId: number;
};

export type FetchCheckoutCloudCartParams = {
  cartId: number;
};

export type UpdateCloudCartEntryParams = {
  cartId: number;
  payload: {
    offer_id: number;
    quantity: number;
    source?: CloudCartEntrySource;
  };
};

export type DeleteCloudCartEntriesParams = {
  cartId: number;
  payload: {
    offer_ids: number[];
  };
};

export type ClearCloudCartParams = {
  cartId: number;
};

export const CloudCartsApi = {
  fetchLatestCloudCartUpdate: async () => {
    const { data } = await httpClient.post<LatestCloudCartUpdate>('cart/get-update');

    return data;
  },

  fetchCloudCart: async (params: FetchCloudCartParams) => {
    const { data } = await httpClient.get<CloudCart>(`cart/${params.cartId}`);

    return data;
  },

  fetchCheckoutCloudCart: async (params: FetchCheckoutCloudCartParams) => {
    const { data } = await httpClient.get<CheckoutCloudCart>(`cart/${params.cartId}/distributor-items`);

    return data;
  },

  updateCloudCartEntry: (params: UpdateCloudCartEntryParams) =>
    httpClient.post<void>(`cart/${params.cartId}/items`, params.payload),

  deleteCloudCartEntries: async (params: DeleteCloudCartEntriesParams) =>
    httpClient.delete<void>(`cart/${params.cartId}/items`, { data: params.payload }),

  clearCloudCart: async (params: ClearCloudCartParams) => httpClient.delete<void>(`cart/${params.cartId}/all-items`),
};
