import { useToggle } from 'react-use';
import CloseArrowIcon from '@mui/icons-material/KeyboardArrowDown';
import UpArrowIcon from '@mui/icons-material/KeyboardArrowUp';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import { Card, CircularProgress, Collapse, Divider, IconButton, Stack, styled, Typography } from '@mui/material';

import type { DistributorInfo } from '@/api/domains/contractors.types';
import { DistributorAvatar } from '@/components/DistributorAvatar';
import { prepareAddress } from '@/shared/helpers/format.helpers';

import { DistributorContactItem } from './DistributorContactCard';
import { InfoItem } from './InfoItem';
import { useDistributorContacts } from './useDistributorContacts';

const StyledInfoContainer = styled(Card)(({ theme }) => ({
  padding: theme.spacing(2, 3),
}));

type MainDistributorInfoProps = {
  distributorInfo: DistributorInfo;
};

export const MainDistributorInfo = (props: MainDistributorInfoProps) => {
  const { distributorInfo } = props;

  const { description, address, id, tin, full_name, name } = distributorInfo;

  const [isOpen, toggleOpen] = useToggle(true);

  const { data: contacts, isFetching } = useDistributorContacts({
    params: {
      contractor_id: id,
    },
    queryOptions: {
      enabled: isOpen,
    },
  });

  const hasContactInfo = address || (contacts && contacts.length > 0);

  return (
    <StyledInfoContainer variant="outlined">
      <Stack direction="column" spacing={3} divider={isOpen && !isFetching && <Divider />}>
        <Stack justifyContent="space-between" direction="row" alignItems="center">
          <Stack spacing={5} direction="row" alignItems="center">
            <Stack spacing={1} direction="row" alignItems="center">
              <DistributorAvatar distributor={distributorInfo} />
              <Typography variant="Body/large" fontWeight="bold">
                {full_name || name}
              </Typography>
            </Stack>

            <Stack spacing={1} direction="row">
              <Typography variant="Body/medium" color="neutral.700">
                ИНН
              </Typography>
              <Typography variant="Body/medium">{tin}</Typography>
            </Stack>
          </Stack>

          <IconButton onClick={toggleOpen}>
            {isFetching ? (
              <CircularProgress color="inherit" size={24} />
            ) : isOpen ? (
              <UpArrowIcon />
            ) : (
              <CloseArrowIcon />
            )}
          </IconButton>
        </Stack>

        <Collapse in={!isFetching && isOpen} timeout="auto" unmountOnExit>
          <Stack spacing={3} divider={<Divider />}>
            {hasContactInfo && (
              <Stack spacing={3}>
                {address && (
                  <Stack spacing={1} direction="row">
                    <PlaceOutlinedIcon color="secondary" />
                    <Typography variant="Body/large">{prepareAddress(address).completeAddress}</Typography>
                  </Stack>
                )}
                {contacts && contacts.length > 0 && (
                  <Stack spacing={5} direction="row">
                    {contacts.map((contact, index) => (
                      <DistributorContactItem key={index} contact={contact} />
                    ))}
                  </Stack>
                )}
              </Stack>
            )}
            {description && <InfoItem title="Описание" description={description} />}
          </Stack>
        </Collapse>
      </Stack>
    </StyledInfoContainer>
  );
};
