import {
  FormProvider as RHFProvider,
  type FieldValues,
  type SubmitErrorHandler,
  type SubmitHandler,
  type UseFormReturn,
} from 'react-hook-form';
import { type BoxProps } from '@mui/material';

import { Form } from './Form';

type FormProviderProps<TFieldValues extends FieldValues> = Omit<BoxProps<'form'>, 'onSubmit'> & {
  isDisabled?: boolean;
  id?: string;
  methods: UseFormReturn<TFieldValues>;
  onSubmit: SubmitHandler<TFieldValues>;
  onError?: SubmitErrorHandler<TFieldValues>;
};

/**
 * @deprecated Use instead.
 * ```ts
 * import { FormProvider } from 'react-hook-form';
 * import { Form } from '@/components/form-control'
 * ```
 */
export const FormProvider = <TFieldValues extends FieldValues>(props: FormProviderProps<TFieldValues>) => {
  const { isDisabled, methods, onSubmit, onError, ...restProps } = props;

  return (
    <RHFProvider {...methods}>
      <Form isDisabled={isDisabled} onSubmit={methods.handleSubmit(onSubmit, onError)} {...restProps} />
    </RHFProvider>
  );
};
