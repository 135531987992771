import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import type { FetchContractorContactsParams } from '@/api/domains/contractors.api';
import { ContractorsApi } from '@/api/domains/contractors.api';
import { ContractorsQueryKeys } from '@/api/domains/contractors.query-keys';

type UseDistributorContacts = {
  params: FetchContractorContactsParams;
  queryOptions?: Pick<UseQueryOptions, 'enabled'>;
};

export const useDistributorContacts = ({ params, queryOptions }: UseDistributorContacts) =>
  useQuery({
    queryKey: ContractorsQueryKeys.getContractorContactsKey(params),
    queryFn: async () => {
      const { items } = await ContractorsApi.fetchContractorContacts(params);

      return items;
    },
    staleTime: Infinity,
    ...queryOptions,
  });
