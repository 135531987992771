import 'simplebar-react/dist/simplebar.min.css';

import { Fragment } from 'react';
import { Outlet } from 'react-router';

import { GlobalErrorDialog } from '@/components/dialogs';

import { Initialize } from './Initialize';

export const Root = () => (
  <Fragment>
    <Initialize>
      <Outlet />
    </Initialize>
    <GlobalErrorDialog />
  </Fragment>
);
