import { ConfirmationDialogContainer, ConfirmationDialogHeader } from '@/components/dialogs';

import { useSpecificationContextWindowsVisibility, useSpecificationStoreActions } from '../../../../../lib';
import { CalculationErrorReportForm } from './ui';

export const CalculationErrorReportDialog = () => {
  const { isCalculationErrorReportDialogVisible } = useSpecificationContextWindowsVisibility();
  const { closeCalculationErrorReportDialog } = useSpecificationStoreActions();

  return (
    <ConfirmationDialogContainer
      open={isCalculationErrorReportDialogVisible}
      fullWidth
      maxWidth="xs"
      subject="warning"
      onClose={closeCalculationErrorReportDialog}
    >
      <ConfirmationDialogHeader>Сообщить об ошибке</ConfirmationDialogHeader>
      <CalculationErrorReportForm />
    </ConfirmationDialogContainer>
  );
};
