import type { FormLabelProps } from '@mui/material';
import { FormLabel, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

// ----------------------------------------------------------------------

export type CustomFormLabelProps = FormLabelProps & {
  isRequired?: boolean;
};

// ----------------------------------------------------------------------

const StyledRoot = styled(FormLabel)(({ theme }) => ({
  display: 'block',
  lineHeight: 1,
  marginBottom: theme.spacing(1),
})) as typeof FormLabel;

// ----------------------------------------------------------------------

export const CustomFormLabel = (props: CustomFormLabelProps) => {
  const { isRequired, children, ...restOfProps } = props;

  return (
    <StyledRoot {...restOfProps}>
      {children}{' '}
      {isRequired && (
        <Typography component="span" variant="inherit" color="error.main">
          *
        </Typography>
      )}
    </StyledRoot>
  );
};
