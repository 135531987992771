import { forwardRef } from 'react';
import { every, some } from 'lodash';

import type { LabelProps } from '@/components/labels';
import { Label } from '@/components/labels';

import type { PasswordStrengthValidationProps } from './PasswordStrengthValidation';

type ValidationBadgeProps = Pick<PasswordStrengthValidationProps, 'validationResult'>;

const StatusBadge = forwardRef<HTMLSpanElement, LabelProps>((props, ref) => (
  <Label
    variant="soft"
    sx={{ width: 'fit-content', textTransform: 'none' }}
    {...props}
    data-testid="password-validation-status"
    ref={ref}
  />
));

export const ValidationStatusBadge = (props: ValidationBadgeProps) => {
  const { validationResult } = props;

  const hasAnyError = some(validationResult, status => status === 'error');

  if (hasAnyError) {
    return <StatusBadge color="warning">Пароль не соответствует</StatusBadge>;
  }

  const hasPassedValidation = every(validationResult, status => status === 'success');

  if (hasPassedValidation) {
    return <StatusBadge color="success">Хороший пароль</StatusBadge>;
  }

  return <StatusBadge>Пароль не введен</StatusBadge>;
};
