import { useLocation } from 'react-router-dom';

import { useIsCheckoutCloudCartEmpty } from '@/entities/cloud-carts';
import {
  ProductSelectionPaths,
  RedirectWithoutAccess,
  type RedirectWithoutAccessProps,
} from '@/shared/lib/react-router';

type CheckoutCloudCartGuardProps = Pick<RedirectWithoutAccessProps, 'children'>;

export const CheckoutCloudCartGuard = (props: CheckoutCloudCartGuardProps) => {
  const { children } = props;

  const { pathname: from } = useLocation();
  const { isLoading, isRefetching, isFetchedAfterMount, data: isCloudCartEmpty } = useIsCheckoutCloudCartEmpty();

  if (isLoading || (isRefetching && !isFetchedAfterMount)) {
    return null;
  }

  return (
    <RedirectWithoutAccess hasAccess={!isCloudCartEmpty} to={ProductSelectionPaths.ROOT_PATH} state={{ from }}>
      {children}
    </RedirectWithoutAccess>
  );
};
