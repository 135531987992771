import {
  ConfirmationDialogActionCancelButton,
  ConfirmationDialogActionProceedButton,
  ConfirmationDialogActions,
  ConfirmationDialogBodyMutedText,
  ConfirmationDialogContainer,
  ConfirmationDialogHeader,
  type ConfirmationDialogContainerProps,
} from '@/components/dialogs/elements.confirmation-dialog';

import type { RoundedButtonProps } from '../buttons';
import { warningDialogTexts } from './constants/dialogs-texts.constants';

export type ConfirmationWarningDialogProps = Omit<ConfirmationDialogContainerProps, 'subject'> & {
  isLoading?: boolean;
  onProceed: () => void;
  titleText?: string;
  contentText?: string;
  actionButtonText?: string;
  slotProps?: {
    proceedButtonProps?: RoundedButtonProps;
    cancelButtonProps?: RoundedButtonProps;
  };
};

export const ConfirmationWarningDialog = (props: ConfirmationWarningDialogProps) => {
  const {
    isLoading,
    titleText = warningDialogTexts.headerText,
    contentText = warningDialogTexts.bodyText,
    actionButtonText = warningDialogTexts.actionText,
    onClose,
    onProceed,
    slotProps,
    ...restOfDialogProps
  } = props;

  return (
    <ConfirmationDialogContainer subject="warning" onClose={onClose} {...restOfDialogProps}>
      <ConfirmationDialogHeader>{titleText}</ConfirmationDialogHeader>

      <ConfirmationDialogBodyMutedText>{contentText}</ConfirmationDialogBodyMutedText>

      <ConfirmationDialogActions>
        <ConfirmationDialogActionCancelButton onClick={onClose} {...slotProps?.cancelButtonProps} />
        <ConfirmationDialogActionProceedButton
          autoFocus
          loading={isLoading}
          onClick={onProceed}
          {...slotProps?.proceedButtonProps}
        >
          {actionButtonText}
        </ConfirmationDialogActionProceedButton>
      </ConfirmationDialogActions>
    </ConfirmationDialogContainer>
  );
};
