import { forwardRef, useState } from 'react';
import {
  PatternFormat,
  type NumberFormatValues,
  type OnValueChange,
  type PatternFormatProps,
} from 'react-number-format';

import { ClearableTextField, type ClearableTextFieldProps } from './ClearableTextField';

type LimitedTextFieldProps = Omit<ClearableTextFieldProps, 'ref' | 'onValueChange'>;
type LimitedPatternFormatProps = Omit<PatternFormatProps, 'mask' | 'customInput' | 'format' | 'onValueChange'>;

export type MaskedPhoneTextFieldProps = LimitedTextFieldProps &
  LimitedPatternFormatProps & {
    isClearable?: boolean;
    onValueChange?: (value: NumberFormatValues) => void;
  };

const COUNTRY_PHONE_CODE = 998;

export const MaskedPhoneTextField = forwardRef<HTMLInputElement, MaskedPhoneTextFieldProps>((props, forwardedRef) => {
  const {
    value,
    onFocus: passedOnFocus,
    onBlur: passedOnBlur,
    onValueChange: passedOnValueChange,
    onClear: passedOnClear,
    ...restOfProps
  } = props;

  const [isFocused, setIsFocused] = useState(false);

  const handleFocus: React.FocusEventHandler<HTMLInputElement> = event => {
    setIsFocused(true);
    passedOnFocus?.(event);
  };

  const handleBlur: React.FocusEventHandler<HTMLInputElement> = event => {
    setIsFocused(false);
    passedOnBlur?.(event);
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();

    const paste = (event.clipboardData || window.clipboardData).getData('text').replace(/\D/g, '');
    const phoneWithoutCode = paste && paste.slice(-9);

    if (paste) {
      // TODO: надо доработать метод!? I have no idea what to do with this
      passedOnValueChange?.({
        value: `${COUNTRY_PHONE_CODE}${phoneWithoutCode}`,
        formattedValue: '',
        floatValue: undefined,
      });
    }
  };

  const handleClear = () => {
    passedOnClear?.();
    passedOnValueChange?.({ value: '', formattedValue: '', floatValue: undefined });
  };

  const patchedOnValueChange: OnValueChange = values => {
    const { formattedValue, floatValue } = values;
    const numericValue = formattedValue.replace(/\D/g, '');
    // skip setting value if it only represents "998" (country code)
    const transformedValue = numericValue.length > 3 ? numericValue : '';

    passedOnValueChange?.({ formattedValue, value: transformedValue, floatValue });
  };

  // skip leading "998" (country code)
  const transformedValue = value ? value.toString().slice(3) : '';

  return (
    <PatternFormat
      allowEmptyFormatting={isFocused}
      format="+998 (##) ###-##-##"
      mask="_"
      customInput={ClearableTextField}
      getInputRef={forwardedRef}
      value={transformedValue}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onValueChange={patchedOnValueChange}
      onPaste={handlePaste}
      onClear={handleClear}
      {...restOfProps}
    />
  );
});
