import { useCallback, useRef } from 'react';
import { useMutation, useQuery, useQueryClient, type QueryClient, type UseQueryOptions } from '@tanstack/react-query';
import { useDebounce, useUpdateEffect } from 'react-use';

import { CloudCartsApi } from '@/api/domains/cloud-carts.api';
import { CloudCartsQueryKeys } from '@/api/domains/cloud-carts.query-keys';
import type { LatestCloudCartUpdate } from '@/api/domains/cloud-carts.types';
import { useGlobalErrorStore } from '@/components/dialogs';
import { useDebouncedCallback } from '@/shared/lib/react';

import { useCloudCartStoreActions, useIsCloudCartUpdating } from './cloud-cart.store';

// ----------------------------------------------------------------------

export type BaseCloudCartsOptions = {
  cartId?: number;
  enabled?: boolean;
};

export const getBaseCloudCartsQueryOptions = (options: BaseCloudCartsOptions) =>
  ({
    retry: false,
    refetchOnWindowFocus: true,
    enabled: !!options.cartId && options.enabled !== false,
    staleTime: 1000 * 60 * 5, // 5 minutes
  }) as const;

// ----------------------------------------------------------------------

export type UseLatestCartUpdateOptionsSelector<TData> = (data: LatestCloudCartUpdate) => TData;

export type UseLatestCloudCartUpdateOptions<TData = LatestCloudCartUpdate> = Pick<
  UseQueryOptions<LatestCloudCartUpdate, unknown, TData, string[]>,
  'refetchInterval' | 'refetchIntervalInBackground'
> & {
  select?: UseLatestCartUpdateOptionsSelector<TData>;
};

const latestCloudCartUpdateConfig = {
  queryKey: CloudCartsQueryKeys.getLatestCloudCartUpdateKey(),
  queryFn: CloudCartsApi.fetchLatestCloudCartUpdate,
  refetchOnWindowFocus: true,
  staleTime: Infinity,
};

export const useLatestCloudCartUpdate = <TData = LatestCloudCartUpdate>(
  options?: UseLatestCloudCartUpdateOptions<TData>,
): TData | undefined => {
  const { data } = useQuery({
    ...latestCloudCartUpdateConfig,
    ...options,
  });

  return data;
};

export const useCurrentCloudCartId = () =>
  useLatestCloudCartUpdate({
    select: useCallback((data: LatestCloudCartUpdate) => data.cart_id, []),
  });

export const getCurrentCloudCartId = async (queryClient: QueryClient) => {
  const { cart_id } = await queryClient.ensureQueryData(latestCloudCartUpdateConfig);

  return cart_id;
};

// ----------------------------------------------------------------------

export const invalidateCloudCartQueriesByCartId = async (queryClient: QueryClient, cartId: number) =>
  Promise.all([
    queryClient.invalidateQueries({
      queryKey: CloudCartsQueryKeys.getCloudCartKey({ cartId }),
    }),
    queryClient.invalidateQueries({
      queryKey: CloudCartsQueryKeys.getCheckoutCloudCartKey({ cartId }),
    }),
  ]);

export const cancelCloudCartQueriesByCartId = async (queryClient: QueryClient, cartId: number) =>
  Promise.all([
    queryClient.cancelQueries({
      queryKey: CloudCartsQueryKeys.getCloudCartKey({ cartId }),
    }),
    queryClient.cancelQueries({
      queryKey: CloudCartsQueryKeys.getCheckoutCloudCartKey({ cartId }),
    }),
  ]);

export const invalidateCurrentCloudCartQueries = async (queryClient: QueryClient) => {
  const cartId = await getCurrentCloudCartId(queryClient);

  return invalidateCloudCartQueriesByCartId(queryClient, cartId);
};

// ----------------------------------------------------------------------

export const usePollCloudCart = () => {
  const latestCartUpdate = useLatestCloudCartUpdate({
    refetchInterval: 1000 * 60 * 2, // 1 minute
    refetchIntervalInBackground: true,
  });

  const isFirstEffect = useRef(false);

  const queryClient = useQueryClient();
  useUpdateEffect(() => {
    if (!latestCartUpdate || !isFirstEffect.current) {
      isFirstEffect.current = true;

      return;
    }

    queryClient.invalidateQueries({ queryKey: CloudCartsQueryKeys.getRootKey() });
  }, [latestCartUpdate?.updated_at]);
};

export const useInvalidateCurrentCloudCart = () => {
  const isCloudCartUpdating = useIsCloudCartUpdating();
  const hasFirstCloudCartUpdate = useRef(false);

  if (isCloudCartUpdating && !hasFirstCloudCartUpdate.current) {
    hasFirstCloudCartUpdate.current = true;
  }

  const queryClient = useQueryClient();
  const cartId = useCurrentCloudCartId();

  useDebounce(
    () => {
      if (isCloudCartUpdating || !cartId || !hasFirstCloudCartUpdate.current) {
        return;
      }

      invalidateCloudCartQueriesByCartId(queryClient, cartId);
    },
    500,
    [isCloudCartUpdating, cartId],
  );
};

// ----------------------------------------------------------------------

export const useUpdateCloudCart = () => {
  const { setGlobalError } = useGlobalErrorStore();

  const { setUpdateStatus } = useCloudCartStoreActions();
  const updateCloudCartItemMutation = useMutation({
    mutationFn: CloudCartsApi.updateCloudCartEntry,
    onSettled: () => {
      setUpdateStatus(false);
    },
    onError: setGlobalError,
  });

  const [debouncedMutate] = useDebouncedCallback(updateCloudCartItemMutation.mutate, { wait: 500 });

  return {
    debouncedMutate,
    setUpdateStatus,
    updateCloudCartItemMutation,
  };
};

// ----------------------------------------------------------------------

export type DeleteCloudCartEntryParams = {
  offerIds: number[];
};

export const useDeleteCloudCartEntry = () => {
  const queryClient = useQueryClient();

  const { setGlobalError } = useGlobalErrorStore();

  const deleteCloudCartItemsMutation = useMutation({
    mutationFn: CloudCartsApi.deleteCloudCartEntries,
    onSettled: (_, __, { cartId }) => invalidateCloudCartQueriesByCartId(queryClient, cartId),
    onError: setGlobalError,
  });

  const deleteCloudCartEntry = async (payload: DeleteCloudCartEntryParams) => {
    const cartId = await getCurrentCloudCartId(queryClient);

    return deleteCloudCartItemsMutation.mutate({
      cartId,
      payload: {
        offer_ids: payload.offerIds,
      },
    });
  };

  return {
    isLoading: deleteCloudCartItemsMutation.isLoading,
    deleteCloudCartEntry,
  };
};
