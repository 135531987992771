import { lazy } from 'react';

// ----------------------------------------------------------------------

const OrdersPage = lazy(
  () =>
    import(
      /* webpackPreload: true */
      /* webpackChunkName: "OrdersPage" */
      '@/pages/orders/OrdersPage'
    ),
);

export default OrdersPage;
