import { Button, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledSupportMenuPopover = styled(Stack)(({ theme: { palette } }) => ({
  backgroundColor: palette.common.white,
  padding: 8,
}));

export const StyledSupportMenuPopoverHeader = styled(Stack)(({ theme: { palette } }) => ({
  borderBottom: `1px solid ${palette.custom.grey}`,
  marginBottom: 8,
  padding: 8,
}));

export const StyledSupportMenuPopoverButton = styled(Button)(({ theme }) => ({
  paddingLeft: theme.spacing(1.5),
  paddingRight: theme.spacing(1.5),

  '&:hover': {
    backgroundColor: theme.palette.custom.grey,
    color: theme.palette.neutral['700'],
  },
  '&:not(:hover)': {
    backgroundColor: 'transparent',

    '&:not(.Mui-disabled)': {
      color: theme.palette.neutral['700'],
    },
  },
})) as typeof Button;

export const popoverSxStyle = {
  title: {
    color: 'neutral.700',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    letterSpacing: '0.4px',
    lineHeight: '16px',
    whiteSpace: 'nowrap',
  },
} as const;
