import { css, Paper, Skeleton, styled, Typography } from '@mui/material';

import { FormProvider } from '@/components/form-controls';

export const StyledFormProvider = styled(FormProvider)`
  display: flex;
  flex-flow: column;
  flex: 1 1 auto;
` as typeof FormProvider;

export const StyledWideFormRoot = styled(Paper)`
  display: flex;
  flex-flow: column;
  align-items: stretch;
  justify-content: stretch;
  flex: 1 1 auto;

  position: relative;

  border-radius: 16px;
  padding: 32px 40px 40px;

  ${({ theme }) => css`
    ${theme.breakpoints.down('tablet')} {
      border-radius: 0;
      padding: 40px 16px 0 16px;
      box-shadow: none;
    }
  `}
`;
export const StyledFormRoot = styled(StyledWideFormRoot)`
  max-width: 408px;
`;

export const StyledFormTitle = styled('header')`
  color: #1e2f96;

  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 24px;
`;

export const StyledFormDescription = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacing(4)};
  color: ${({ theme }) => theme.palette.neutral['700']};

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
`;

export const RHFAutocompleteStyle = {
  '& .MuiAutocomplete-inputRoot': {
    paddingTop: 0,
  },
};

export const StyledSkeleton = styled(Skeleton)`
  width: 100%;
  height: 48px;
  transform: none;
`;
