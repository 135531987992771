import { BreadcrumbLink, DorimPromoPaths, type RouteObjectWithBreadcrumbs } from '@/shared/lib/react-router';

import { MainContractPageRoute, SpecificationDetailPageRoute } from './detail';
import { SpecificationsNoticePageRoute } from './notice';
import { SpecificationsPageRoute } from './specifications';

export const SpecificationsPageRootRoute: RouteObjectWithBreadcrumbs = {
  path: DorimPromoPaths.SPECIFICATIONS_ROOT_PATH,
  handle: {
    breadcrumb: {
      element: (
        <BreadcrumbLink to={DorimPromoPaths.SPECIFICATIONS_ROOT_PATH}>Спецификации с контрагентами</BreadcrumbLink>
      ),
    },
  },
  children: [
    SpecificationsNoticePageRoute,
    SpecificationsPageRoute,
    SpecificationDetailPageRoute,
    MainContractPageRoute,
  ],
};
