import { lazy } from 'react';

// ----------------------------------------------------------------------

const DistributorsSettingsPage = lazy(
  () =>
    import(
      /* webpackPreload: true */
      /* webpackChunkName: "DistributorsSettingsPage" */
      '@/pages/settings/distributors-settings/DistributorsSettingsPage'
    ),
);

export default DistributorsSettingsPage;
