import { css, styled, Typography } from '@mui/material';

import { ReactComponent as LogoSVG } from '@/shared/assets/image/registration/logotype.svg';

export const StyledRoot = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  width: 367px;

  ${({ theme }) => css`
    ${theme.breakpoints.down('md')} {
      width: 408px;
      margin: 0 auto;
    }

    ${theme.breakpoints.down('tablet')} {
      width: auto;
      margin: 0;
    }
  `}
`;
export const StyledLogotype = styled(LogoSVG)`
  width: 264px;
  height: 80px;

  ${({ theme }) => css`
    ${theme.breakpoints.down('md')} {
      width: 160px;
      height: 48px;
    }
    ${theme.breakpoints.down('tablet')} {
      width: 80px;
      height: 24px;
    }
  `}
`;
export const StyledIntroText = styled(Typography)`
  color: #1e2f96;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-top: 60px;

  ${({ theme }) => css`
    ${theme.breakpoints.down('md')} {
      margin-top: 24px;
      color: #1e2f96;

      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.15px;
    }
    ${theme.breakpoints.down('tablet')} {
      display: none;
    }
  `}
`;
