import { Typography, type TypographyProps, type TypographyTypeMap } from '@mui/material';
import type { OverridableComponent } from '@mui/material/OverridableComponent';
import { styled } from '@mui/material/styles';
import { get } from 'lodash';

type StyledLabelProps = {
  backgroundColor?: Extract<TypographyProps['color'], string>;
};

export type LabelProps = StyledLabelProps;

const StyledLabel = styled(Typography, {
  shouldForwardProp: prop => prop !== 'backgroundColor',
})<StyledLabelProps>(
  ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    height: 20,
    padding: theme.spacing(0, 1),
    borderRadius: theme.shape.borderRadiusHalf,
    lineHeight: 1,
  }),
  ({ theme, backgroundColor = 'neutral.300' }) => ({
    backgroundColor: get(theme.palette, backgroundColor),
  }),
) as React.FC<TypographyProps & StyledLabelProps>;

export const Label: OverridableComponent<TypographyTypeMap<LabelProps>> = (props: LabelProps) => (
  <StyledLabel noWrap variant="Label/medium" {...props} />
);
