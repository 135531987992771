import { Stack, styled, type SxProps } from '@mui/material';

import { UserProfile } from '@/modules/profile';
import { SupportMenu } from '@/modules/support/components/SupportMenu';
import { OFFERS_LINK } from '@/shared/constants/dorim.constants';
import { Breadcrumbs, ProfilePaths } from '@/shared/lib/react-router';

import { HeaderTitle } from './HeaderTitle';

export const StyledHeader = styled('header')(({ theme }) => ({
  position: 'sticky',
  top: 0,
  zIndex: theme.zIndex.appBar,
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  flexShrink: 0,
  alignItems: 'center',
  height: theme.dimensions.header.height,
  padding: theme.spacing(0, 2),
  borderBottom: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.paper,
}));

export const StyledHeaderAction = styled('div')(({ theme }) => ({
  display: 'inline-block',
  marginLeft: theme.spacing(2),
}));

export type HeaderProps = {
  sx?: SxProps;
} & (
  | {
      leftSlot?: React.ReactNode;
      title?: React.ReactNode;
      hasBreadcrumbs?: never;
    }
  | {
      leftSlot?: never;
      title?: never;
      hasBreadcrumbs: boolean;
    }
);

export const Header = (props: HeaderProps) => {
  const { sx, title, hasBreadcrumbs } = props;

  return (
    <StyledHeader sx={sx}>
      <Stack direction="row" justifyContent="end" sx={{ flex: 1 }} width="100%">
        {hasBreadcrumbs && <Breadcrumbs mr="auto" />}
        {!hasBreadcrumbs && <HeaderTitle>{title}</HeaderTitle>}

        <StyledHeaderAction>
          <SupportMenu />
        </StyledHeaderAction>
        <StyledHeaderAction>
          <UserProfile routeLink={ProfilePaths.ROOT_PATH} privacyPolicyLink={OFFERS_LINK} />
        </StyledHeaderAction>
      </Stack>
    </StyledHeader>
  );
};
