import { Stack, styled } from '@mui/material';

const StyledRoot = styled(Stack)(({ theme }) => ({
  position: 'absolute',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
})) as typeof Stack;

export type ToolbarProps = React.PropsWithChildren<{}>;

export const Toolbar = (props: ToolbarProps) => {
  return <StyledRoot spacing={1} {...props} />;
};
