export type Step = (typeof SIGN_UP_FORM_STEPS)[number];

export const STEP_REGISTRATION = '1';
export const STEP_VERIFY_PHONE = '2';
export const STEP_CREATE_PASSWORD = '3';
export const STEP_COMPANY_INFO = '4';
export const STEP_PHARMACY_INFO = '5';

export const SIGN_UP_FORM_STEPS = [
  STEP_REGISTRATION,
  STEP_VERIFY_PHONE,
  STEP_CREATE_PASSWORD,
  STEP_COMPANY_INFO,
  STEP_PHARMACY_INFO,
] as const;

export const FIRST_STEP = STEP_REGISTRATION;
export const TOTAL_STEPS = SIGN_UP_FORM_STEPS.length;
