import { Skeleton, Stack } from '@mui/material';

import { UserProfileStyled } from './styles';

export const UserProfileSkeleton = () => (
  <UserProfileStyled direction="row">
    <Skeleton variant="circular" width={32} height={32} />
    <Stack
      sx={{
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 400,
        letterSpacing: '0.4px',
        lineHeight: '16px',
        paddingLeft: '12px',
      }}
    >
      <Stack>
        <Skeleton variant="rectangular" width={30} height={10} />
      </Stack>
      <Stack sx={{ marginTop: '5px' }}>
        <Skeleton variant="rectangular" width={40} height={10} />
      </Stack>
    </Stack>
  </UserProfileStyled>
);
