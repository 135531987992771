import { Outlet } from 'react-router';
import { styled } from '@mui/material/styles';

import { BaseLayout } from '@/layouts/BaseLayout';

const StyledContainer = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
});

export const MainLayout = () => (
  <BaseLayout>
    <StyledContainer>
      <Outlet />
    </StyledContainer>
  </BaseLayout>
);
