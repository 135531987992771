import { createSelectorHooks } from 'auto-zustand-selectors-hook';
import { devtools } from 'zustand/middleware';

import { createResetStoreOnUnmountHook, createStoreWithReset } from '@/shared/lib/zustand';

type FloatingPanelStore = {
  shouldFloatingPanelPanelBeVisible: boolean;

  actions: {
    showFloatingPanel: () => void;
    hideFloatingPanel: () => void;
  };
};

const [floatingPanelStore, resetFloatingPanelStore] = createStoreWithReset<FloatingPanelStore>()(
  devtools(
    set => ({
      shouldFloatingPanelPanelBeVisible: false,

      actions: {
        showFloatingPanel: () =>
          set(
            {
              shouldFloatingPanelPanelBeVisible: true,
            },
            false,
            'showFloatingPanel',
          ),
        hideFloatingPanel: () =>
          set(
            {
              shouldFloatingPanelPanelBeVisible: false,
            },
            false,
            'hideFloatingPanel',
          ),
      },
    }),
    {
      name: 'floating-panel-store',
      enabled: process.env.NODE_ENV === 'development',
    },
  ),
);

export const { useActions: useFloatingPanelStoreActions, useShouldFloatingPanelPanelBeVisible } =
  createSelectorHooks(floatingPanelStore);
export const useResetFloatingPanelStoreOnUnmount = createResetStoreOnUnmountHook(resetFloatingPanelStore);
