import { styled } from '@mui/material';

export const StyledRoot = styled('div')`
  display: flex;
  flex: 1;
  flex-flow: column;
  padding: 8px;
`;

export const StyledTitle = styled('div')`
  color: #212b36;

  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.25px;
  text-align: center;
  margin-bottom: 8px;
`;

export const StyledLinks = styled('div')`
  color: #212b36;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;

  display: flex;
  flex-flow: row;
  gap: 24px;
`;

export const StyledIcon = styled('span')`
  margin-right: 8px;
  vertical-align: middle;
`;
