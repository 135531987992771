import { useNavigate } from 'react-router';

import { getExpiredTimestamp } from '@/pages/auth/helpers/getExpiredTimestamp';
import { useBanActions, useBanState } from '@/pages/auth/reset-password/store/ban.store';
import { AuthPaths } from '@/shared/lib/react-router';

export const useBan = () => {
  const { resetBan, setExpiredAt } = useBanActions();
  const { expiredAt } = useBanState();
  const navigate = useNavigate();

  return {
    resetBan,
    applyBan(value: number) {
      setExpiredAt(getExpiredTimestamp(value));
      navigate(AuthPaths.RESET_PASSWORD.BAN);
    },
    expiredAt,
  };
};
