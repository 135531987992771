import { Yup } from '@/shared/lib/yup';

export const companySchema = Yup.object().shape({
  legalStatus: Yup.object().shape({ id: Yup.number() }).nullable().required('Обязательное поле'),
  companyName: Yup.string().trim().required('Обязательное поле'),
  tin: Yup.number().required('Обязательное поле'),
});

export const pharmacySchema = Yup.object().shape({
  name: Yup.string().trim().required('Обязательное поле'),
});

export const addressSchema = Yup.object().shape({
  region: Yup.object().shape({ id: Yup.number() }).nullable().required('Обязательное поле'),
  city: Yup.object()
    .shape({ id: Yup.number() })
    .nullable()
    .when('canSelectCity', {
      is: true,
      then: schema => schema.required('Обязательное поле'),
    }),
  cityDistrict: Yup.object()
    .shape({ id: Yup.number() })
    .nullable()
    .when('canSelectCityDistrict', {
      is: true,
      then: schema => schema.required('Обязательное поле'),
    }),
  streetType: Yup.object().shape({ id: Yup.number() }).nullable().required('Обязательное поле'),
  street: Yup.string().trim().required('Обязательное поле'),
  house: Yup.string().trim().required('Обязательное поле'),
  building: Yup.string(),
  apartment: Yup.string(),
  postalCode: Yup.string(),
  phone: Yup.string().isPossiblePhoneNumber(),
});

export const pharmacyFormSchema = Yup.object().shape({
  ...pharmacySchema.fields,
  ...addressSchema.fields,
});

export const companyFormSchema = Yup.object().shape({
  ...companySchema.fields,
  ...addressSchema.fields,
});

export const userSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('Обязательное поле')
    .min(2, ({ min }) => `Как минимум ${min} символа`),
  lastName: Yup.string()
    .required('Обязательное поле')
    .min(2, ({ min }) => `Как минимум ${min} символа`),
  phoneNumber: Yup.string().required('Обязательное поле').isPossiblePhoneNumber(),
  isAgree: Yup.boolean()
    .required('Необходимо ознакомиться и принять все условия')
    .isTrue('Необходимо ознакомиться и принять все условия'),
});

export const passwordSchema = Yup.object().shape({
  password: Yup.string().required('Обязательное поле'),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password')], 'Пароли не совпадают')
    .required('Обязательное поле'),
});
