import { Skeleton } from '@mui/material';

import { DocumentToolbar } from '@/components/DocumentToolbar';

export const DocumentToolbarSkeleton = () => (
  <DocumentToolbar
    title={<Skeleton variant="text" animation="wave" width={400} />}
    documentNumber={<Skeleton variant="text" animation="wave" width={90} />}
    button={null}
  />
);
