type GetDorimEnvironment = () => Record<'isDev' | 'isStage' | 'isProd', boolean>;

export const getDorimEnvironment: GetDorimEnvironment = () => {
  const currentEnvironment = import.meta.env.VITE_APP_DORIM_ENVIRONMENT;

  return {
    isDev: currentEnvironment === 'dev',
    isStage: currentEnvironment === 'stage',
    isProd: currentEnvironment === 'prod',
  };
};
