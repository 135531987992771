import { useMarketingOrg } from '@/shared/hooks/useMarketingOrg';
import {
  BreadcrumbLink,
  DorimPromoPaths,
  LazyBreadcrumb,
  type MarketingOrgDetailPathParams,
  type RouteObjectWithBreadcrumbs,
} from '@/shared/lib/react-router';

import { AnalyticsPageRoute } from './analytics';
import { MarketingOrgPageRoute } from './marketing-org';

export const MarketingOrgDetailPageRoute: RouteObjectWithBreadcrumbs = {
  path: DorimPromoPaths.MARKETING_ORG_DETAIL_PATH,
  handle: {
    breadcrumb: (params: MarketingOrgDetailPathParams) => ({
      Component: () => {
        const { data, isLoading } = useMarketingOrg({
          specificationId: Number(params.specificationId),
          marketingOrgId: Number(params.marketingOrgId),
        });

        return (
          <LazyBreadcrumb isLoading={isLoading}>
            <BreadcrumbLink to={DorimPromoPaths.generateMarketingOrgDetailPath(params)}>
              {data?.marketing_org_details?.marketing_org?.name || 'Маркетирующая организация'}
            </BreadcrumbLink>
          </LazyBreadcrumb>
        );
      },
    }),
  },
  children: [MarketingOrgPageRoute, AnalyticsPageRoute],
};
