import { useUnmount } from 'react-use';
import { devtools } from 'zustand/middleware';

import type { CloudCartDistributor } from '@/api/domains/cloud-carts.types';
import type { DrugDistributor } from '@/api/domains/drugs.types';
import { createStoreWithReset } from '@/shared/lib/zustand';

type Distributor = DrugDistributor | CloudCartDistributor;

type DistributorInfoModalStore = {
  isDistributorInfoModalOpen: boolean;
  selectedDistributor: Distributor | null;

  actions: {
    openDistributorInfoModal: () => void;
    closeDistributorInfoModal: () => void;
    setSelectedDistributor: (distributor: Distributor) => void;
    resetSelectedDistributor: () => void;
  };
};

const [useDistributorInfoStore, resetDistributorInfoStore] = createStoreWithReset<DistributorInfoModalStore>()(
  devtools(
    set => ({
      isDistributorInfoModalOpen: false,
      selectedDistributor: null,

      actions: {
        openDistributorInfoModal: () =>
          set(
            {
              isDistributorInfoModalOpen: true,
            },
            false,
            'openDistributorInfoModal',
          ),
        closeDistributorInfoModal: () =>
          set(
            {
              isDistributorInfoModalOpen: false,
            },
            false,
            'closeDistributorInfoModal',
          ),
        setSelectedDistributor: distributor =>
          set(
            {
              selectedDistributor: distributor,
            },
            false,
            'setSelectedDistributor',
          ),
        resetSelectedDistributor: () => set({ selectedDistributor: null }, false, 'resetSelectedDistributor'),
      },
    }),
    {
      name: 'distributor-info-modal-store',
      enabled: import.meta.env.DEV,
    },
  ),
);

export const useIsDistributorInfoModalOpen = () => useDistributorInfoStore(state => state.isDistributorInfoModalOpen);
export const useSelectedDistributor = () => useDistributorInfoStore(state => state.selectedDistributor);

export const useDistributorInfoActions = () => useDistributorInfoStore(state => state.actions);
export const useResetDistributorInfoStoreOnUnmount = () => useUnmount(resetDistributorInfoStore);
