import { DorimPromoPaths, type RouteObjectWithBreadcrumbs } from '@/shared/lib/react-router';

import { UnsignedMainContractGuard } from '../../../../guards';

export const NoticePageRoute: RouteObjectWithBreadcrumbs = {
  path: DorimPromoPaths.SPECIFICATION_DETAIL_MAIN_CONTRACT_NOTICE_PATH,
  lazy: async () => {
    const { NoticePage } = await import(
      /* webpackChunkName: "NoticePage" */
      './NoticePage'
    );

    return {
      Component: () => (
        <UnsignedMainContractGuard>
          <NoticePage />
        </UnsignedMainContractGuard>
      ),
    };
  },
};
