import type { SxProps, Theme } from '@mui/material';

import { toImportant } from '@/shared/theme';

export const styles: Record<string, SxProps<Theme>> = {
  modalBody: {
    padding: toImportant(0),
    overflow: 'hidden',
  },

  iFrame: {
    border: 'none',
    width: 1 / 1,
    height: 1 / 1,
  },

  footerWarningBox: theme => ({
    background: theme.palette.warning.lighter,
    color: theme.palette.warning.main,
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    height: 88,
    justifyContent: 'center',
    placeItems: 'center',
    width: 1 / 1,
  }),

  footerWarningBoxText: {
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 400,
    letterSpacing: 0.25,
    marginLeft: '8px',
  },

  footerForm: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    height: 88,
    justifyContent: 'space-between',
    padding: '0 24px',
    placeItems: 'center',
    width: 1 / 1,
  },

  checkboxError: theme => ({
    color: theme.palette.error.main,
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    letterSpacing: '0.4px',
    lineHeight: '16px',
    padding: '0 0 0 30px',
  }),
};
