import { IconButton } from '@mui/material';

import { StyledSupportMenuAura, StyledSupportMenuIcon } from '@/modules/support/components/SupportMenu/styles';

const DEFAULT_SIZE = 32;

type SupportButtonProps = {
  isAura?: boolean;
  size?: number;
};

export const SupportButton = (props: SupportButtonProps) => {
  const { isAura, size = DEFAULT_SIZE } = props;
  return (
    <StyledSupportMenuAura width={size} isAura={isAura}>
      <IconButton size="large">
        <StyledSupportMenuIcon width={size} />
      </IconButton>
    </StyledSupportMenuAura>
  );
};
