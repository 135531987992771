import { useMutation } from '@tanstack/react-query';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Stack } from '@mui/material';

import { useGlobalErrorStore } from '@/components/dialogs';
import { useProfile } from '@/modules/profile/queries/useProfile';
import { ReactComponent as ContractSVG } from '@/shared/assets/icons-2.0/contract.svg';
import { ReactComponent as LogoutSVG } from '@/shared/assets/icons-2.0/logout.svg';
import { ReactComponent as PersonSVG } from '@/shared/assets/icons-2.0/person.svg';
import { ReactComponent as TelegramSVG } from '@/shared/assets/icons-2.0/telegram.svg';
import { TELEGRAM_CHANNEL_LINK } from '@/shared/constants/dorim.constants';
import { useAuthMethods } from '@/shared/lib/auth';

import type { ProfileLinks } from '../../../types';
import { Avatar } from '../../Avatar';
import {
  popoverSxStyle,
  ProfilePopoverFooterStyled,
  ProfilePopoverHeaderStyled,
  ProfilePopoverHeaderUserInfoStyled,
  ProfilePopoverStyled,
} from './styles';

type Props = ProfileLinks;

export const ProfilePopover = ({ routeLink, privacyPolicyLink }: Props) => {
  const { user, fullName, profile, contractor } = useProfile();

  const authMethods = useAuthMethods();
  const { setGlobalError } = useGlobalErrorStore();

  const { isLoading: isLoadingMutation, mutate: signOut } = useMutation({
    mutationFn: authMethods.signOut,
    onError: setGlobalError,
  });
  const handleSignOut = () => signOut();

  return (
    <ProfilePopoverStyled minWidth={300} maxWidth={400}>
      <ProfilePopoverHeaderStyled direction="row" data-testid="profile-popover-info-block">
        <Avatar fullName={fullName} imgLink={user?.avatar_url} size={56} />

        <ProfilePopoverHeaderUserInfoStyled>
          <Stack sx={popoverSxStyle.userName}>
            <Stack>{user?.first_name}</Stack>
            <Stack>{user?.last_name}</Stack>
          </Stack>
          {profile && (
            <Stack sx={popoverSxStyle.contractorInfo}>
              {profile?.job_title}
              {contractor?.name && <Stack sx={popoverSxStyle.contractorInfo.name}>{contractor?.name}</Stack>}
            </Stack>
          )}
        </ProfilePopoverHeaderUserInfoStyled>
      </ProfilePopoverHeaderStyled>

      <Stack
        spacing={1}
        sx={theme => ({
          borderBottom: `1px solid ${theme.palette.custom.grey}`,
          marginBottom: 1,
          paddingBottom: 1,
        })}
      >
        {routeLink && (
          <Button
            color="inherit"
            component={RouterLink}
            disabled={isLoadingMutation}
            startIcon={<PersonSVG width={24} />}
            sx={{ justifyContent: 'left' }}
            to={routeLink}
            data-testid="open-profile-btn"
          >
            Профиль
          </Button>
        )}
        {privacyPolicyLink && (
          <Button
            color="inherit"
            component={RouterLink}
            disabled={isLoadingMutation}
            startIcon={<ContractSVG width={24} />}
            sx={{ justifyContent: 'left' }}
            target="_blank"
            to={privacyPolicyLink}
            data-testid="public-offer-btn"
          >
            Публичная оферта
          </Button>
        )}
      </Stack>

      <Button
        color="inherit"
        component={RouterLink}
        startIcon={<TelegramSVG width={24} />}
        sx={{ justifyContent: 'left' }}
        target="_blank"
        to={TELEGRAM_CHANNEL_LINK}
        data-testid="dorim-news-btn"
      >
        Новости Dorim
      </Button>

      <ProfilePopoverFooterStyled>
        <Button
          color="inherit"
          disabled={isLoadingMutation}
          onClick={handleSignOut}
          startIcon={<LogoutSVG width={24} />}
          sx={{ justifyContent: 'left' }}
          data-testid="signout-btn"
        >
          Выход
        </Button>
      </ProfilePopoverFooterStyled>
    </ProfilePopoverStyled>
  );
};
