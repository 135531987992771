import { lazy } from 'react';

export const ProductSelectionSpecialOffersPage = lazy(
  () =>
    import(
      /* webpackPreload: true */
      /* webpackChunkName: "ProductSelectionSpecialOffersPage" */
      './ProductSelectionSpecialOffersPage'
    ),
);
