import { httpClient } from '@/shared/lib/axios';

import type { Profile, UpdateProfileRequestParams, User } from './types';

const endpoints = {
  profile: '/profile',
  profileUpdate: (id: number) => `/profile/${id}`,
  profileBlock: (id: number) => `/profile/${id}/block`,
  profileOffer: '/profile/offer',
};

export const profileApi = {
  profile: async () => {
    const { data } = await httpClient.get<Profile>(endpoints.profile);

    return data;
  },

  updateProfile: async (params: UpdateProfileRequestParams) => {
    const { data } = await httpClient.patch<Profile>(endpoints.profile, { ...params });

    return data;
  },

  updateProfileWithId: async (id: number, params: UpdateProfileRequestParams) => {
    const { data } = await httpClient.patch<Profile>(endpoints.profileUpdate(id), { ...params });

    return data;
  },

  blockProfile: async (id: number) => await httpClient.put<Profile>(endpoints.profileBlock(id)),

  profileOffer: async () => {
    const { data } = await httpClient.put<User>(endpoints.profileOffer);

    return data;
  },
};
