import { DorimPromoPaths, type RouteObjectWithBreadcrumbs } from '@/shared/lib/react-router';

import { UnacceptedSpecificationContractGuard } from '../../guards';

export const SpecificationsNoticePageRoute: RouteObjectWithBreadcrumbs = {
  path: DorimPromoPaths.SPECIFICATIONS_NOTICE_PATH,
  lazy: async () => {
    const { NoticePage } = await import(
      /* webpackChunkName: "NoticePage" */
      './NoticePage'
    );

    return {
      Component: () => (
        <UnacceptedSpecificationContractGuard>
          <NoticePage />
        </UnacceptedSpecificationContractGuard>
      ),
    };
  },
};
