import { Fragment } from 'react';
import { Outlet } from 'react-router';

import { CloudCartBehavior } from '@/components/CloudCart';

export const CloudCartRootLayout = () => {
  return (
    <Fragment>
      <Outlet />
      <CloudCartBehavior />
    </Fragment>
  );
};
