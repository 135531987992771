import { useCallback } from 'react';
import { printPlugin as usePrintPlugin, type RenderPrintProps } from '@react-pdf-viewer/print';
import { Print as PrintIcon } from '@mui/icons-material';

import { ToolbarButton } from './ToolbarButton';

export const useToolbarPrint = () => {
  const printPluginInstance = usePrintPlugin({
    enableShortcuts: true,
  });
  const { Print } = printPluginInstance;

  const PrintButton = useCallback(
    () => (
      <Print>
        {(props: RenderPrintProps) => (
          <ToolbarButton onClick={props.onClick}>
            <PrintIcon />
          </ToolbarButton>
        )}
      </Print>
    ),
    /* eslint-disable-next-line */
    [printPluginInstance, Print],
  );

  return {
    printPluginInstance,
    PrintButton,
  };
};
