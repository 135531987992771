import { useQuery } from '@tanstack/react-query';

import { DorimPromoApi, type FetchSpecificationContractDocumentParams } from '@/api/domains/dorim-promo.api';
import { DorimPromoQueryKeys } from '@/api/domains/dorim-promo.query-keys';

export const useSpecificationContractDocument = ({
  specificationId,
}: Partial<FetchSpecificationContractDocumentParams>) => {
  const query = useQuery({
    queryKey: DorimPromoQueryKeys.getSpecificationContractDocumentKey({ specificationId }),
    queryFn: () => {
      if (!specificationId) {
        return;
      }

      return DorimPromoApi.fetchSpecificationContractDocument({ specificationId });
    },
    enabled: !!specificationId,
  });

  const { data } = query;

  const isSigned = data?.is_signed ?? false;

  return {
    query,
    isSigned,
  };
};
