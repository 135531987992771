import { lazy } from 'react';

// ----------------------------------------------------------------------

const ContractorsPage = lazy(
  () =>
    import(
      /* webpackPreload: true */
      /* webpackChunkName: "ContractorsPage" */
      './ContractorsPage'
    ),
);

export default ContractorsPage;
