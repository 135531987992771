export const rulesConfig = [
  {
    id: 1,
    label: 'Минимум 1 строчная буква (a-z)',
    test: (password: string) => /[a-z]/.test(password),
  },
  {
    id: 2,
    label: 'Минимум 1 заглавная буква (A-Z)',
    test: (password: string) => /[A-Z]/.test(password),
  },
  {
    id: 3,
    label: 'Минимум 1 цифра (0-9)',
    test: (password: string) => /[0-9]/.test(password),
  },
  {
    id: 4,
    label: 'Как минимум 8 символов',
    test: (password: string) => password.length >= 8,
  },
];
