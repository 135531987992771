import { Navigate } from 'react-router-dom';

import { DorimPromoPaths, type RouteObjectWithBreadcrumbs } from '@/shared/lib/react-router';

import { Layout } from './_layout';
import { DocumentPageRoute } from './document';
import { SignatureProtocolPageRoute } from './signature-protocol';

export const ViewPageRoute: RouteObjectWithBreadcrumbs = {
  path: DorimPromoPaths.SPECIFICATION_DETAIL_CONTRACT_DETAIL_PATH,
  element: <Layout />,
  children: [
    {
      index: true,
      element: <Navigate to={DorimPromoPaths.ROOT_PATH} />,
    },
    DocumentPageRoute,
    SignatureProtocolPageRoute,
  ],
};
