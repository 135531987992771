import { Stack, Typography } from '@mui/material';

type InfoItemProps = {
  title: React.ReactNode;
  description: React.ReactNode;
};

export const InfoItem = (props: InfoItemProps) => {
  const { title, description } = props;

  return (
    <Stack spacing={0.5}>
      <Typography component="div" variant="Body/small" color="grey.500">
        {title}
      </Typography>
      <Typography component="div" variant="inherit">
        {description}
      </Typography>
    </Stack>
  );
};
