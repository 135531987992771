import type { FetchMarketingOrgParams } from '@/api/domains/dorim-promo.api';
import { getSumPlanState } from '@/shared/helpers/cashback.helpers';
import { useMarketingOrg } from '@/shared/hooks/useMarketingOrg';

type UseSumPlanStateParams = FetchMarketingOrgParams;

const defaultSumPlanState = getSumPlanState({
  minPlan: 0,
  maxPlan: 0,
  orderSum: 0,
});

export const useSumPlanState = (params: UseSumPlanStateParams) => {
  const { data } = useMarketingOrg(params);

  if (!data?.marketing_org_details) {
    return defaultSumPlanState;
  }

  const { minimum_plan: minPlan, maximum_plan: maxPlan, order_sum: orderSum } = data.marketing_org_details;

  return getSumPlanState({ minPlan, maxPlan, orderSum });
};
