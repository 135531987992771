import { Fragment } from 'react';

import { PrivacyPolicyModal } from '@/modules/privacy-policy';
import { useProfile } from '@/modules/profile';
import { useAuthState } from '@/shared/lib/auth';

const ProtectedInitialize = () => {
  const { isLoading, profile } = useProfile();

  if (isLoading) {
    return null;
  }

  return <PrivacyPolicyModal isOpen={!profile?.user?.offer_accepted} />;
};

type InitializeProps = React.PropsWithChildren;

export const Initialize = (props: InitializeProps) => {
  const { children } = props;

  const { isAuthenticated } = useAuthState();

  return (
    <Fragment>
      {children}
      {isAuthenticated && <ProtectedInitialize />}
    </Fragment>
  );
};
