import { lazy } from 'react';

export const PersonalInfoPage = lazy(
  () =>
    import(
      /* webpackPreload: true */
      /* webpackChunkName: "PersonalInfoPage" */
      './PersonalInfoPage'
    ),
);
