import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

// ----------------------------------------------------------------------

export type NavigateConfirmationStore = {
  isOpen: boolean;
  isBlocked: boolean;
  message: string | null;

  open: () => void;
  close: () => void;

  block: (message: string) => void;
  unblock: () => void;
};

// ----------------------------------------------------------------------

export const useNavigateConfirmationStore = create<NavigateConfirmationStore>()(
  devtools(
    set => ({
      isOpen: false,
      isBlocked: false,
      message: null,

      open: () => set({ isOpen: true }, false, 'open'),
      close: () => set({ isOpen: false }, false, 'close'),

      block: message => set({ isBlocked: true, message }, false, 'block'),
      unblock: () => set({ isBlocked: false, message: null }, false, 'unblock'),
    }),
    {
      name: 'useNavigateConfirmationStore',
    },
  ),
);
