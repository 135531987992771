import { Box, Divider, Stack, type BoxProps, type StackProps } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Scrollbar } from '@/components/Scrollbar';

const StyledRoot = styled(Box)(({ theme }) => ({
  minHeight: theme.dimensions.dataGrid.toolbar.minHeight,
  borderBottom: theme.shape.divider,
  overflow: 'hidden',

  '.DataGridToolbar-root-container > *': {
    flexShrink: 0,
  },

  '.simplebar-content': {
    height: '100%',
  },
})) as typeof Stack;

export type DataGridToolbarProps = BoxProps<'div'> & {
  isScrollable?: boolean;
  actions?: React.ReactNode;
  slotProps?: {
    rootContainer?: Omit<StackProps, 'children'>;
    contentContainer?: Omit<StackProps, 'children'>;
    actionsContainer?: Omit<StackProps, 'children'>;
  };
};

export const DataGridToolbar = (props: DataGridToolbarProps) => {
  const { isScrollable = true, actions, slotProps, children, ...restOfProps } = props;

  const content = (
    <Stack
      direction="row"
      px={1}
      alignItems="center"
      height="100%"
      width="100%"
      className="DataGridToolbar-root-container"
      {...slotProps?.rootContainer}
    >
      {children && (
        <Stack
          direction="row"
          spacing={1}
          flexGrow={1}
          mr={1}
          alignItems="center"
          divider={<Divider orientation="vertical" flexItem />}
          maxWidth="100%"
          {...slotProps?.contentContainer}
        >
          {children}
        </Stack>
      )}
      {actions && (
        <Stack direction="row" spacing={1} alignItems="center" ml="auto" {...slotProps?.actionsContainer}>
          {actions}
        </Stack>
      )}
    </Stack>
  );

  return (
    <StyledRoot component="div" {...restOfProps}>
      {isScrollable && <Scrollbar>{content}</Scrollbar>}
      {!isScrollable && content}
    </StyledRoot>
  );
};
