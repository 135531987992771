import { CircularProgress, Stack, Typography } from '@mui/material';

import { SummaryBarContainer } from './SummaryBarContainer';

export const SummaryBarLoading = () => (
  <SummaryBarContainer justifyContent="center">
    <CircularProgress size="2em" color="secondary" />
  </SummaryBarContainer>
);

export type SummaryBarEmptyProps = React.PropsWithChildren;

export const SummaryBarEmpty = (props: SummaryBarEmptyProps) => (
  <SummaryBarContainer justifyContent={props.children ? 'space-between' : 'flex-end'}>
    <Typography variant="Label/large">Ваша корзина пуста</Typography>
    {props.children && (
      <Stack direction="row" spacing={1} alignItems="center">
        {props.children}
      </Stack>
    )}
  </SummaryBarContainer>
);
