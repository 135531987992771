import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

export const UserProfileStyled = styled(Stack)(() => ({
  alignItems: 'center',
  flexShrink: 0,
  cursor: 'pointer',
  padding: 6,

  '&:hover': {
    opacity: '0.85',
  },
})) as typeof Stack;

export const userProfileSxStyles = {
  userName: {
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    letterSpacing: '0.4px',
    lineHeight: '16px',
    paddingLeft: '12px',
  },
};
