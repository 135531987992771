import { LoadingButton } from '@mui/lab';
import { Collapse, collapseClasses, Fab, styled } from '@mui/material';

const StyledRoot = styled(Collapse)(({ theme }) => ({
  position: 'absolute',
  bottom: theme.spacing(5),
  zIndex: theme.zIndex.appBar,
  width: '100%',

  [`.${collapseClasses.wrapperInner}`]: {
    display: 'flex',
    justifyContent: 'center',
  },
})) as typeof Collapse;

export type CashbackDocumentButtonProps = React.PropsWithChildren<{
  isVisible: boolean;
  isLoading?: boolean;
  onClick?: () => void;
}>;

export const DocumentActionButton = (props: CashbackDocumentButtonProps) => {
  const { isVisible, isLoading, children, onClick } = props;

  return (
    <StyledRoot in={isVisible}>
      <Fab
        component={LoadingButton}
        loading={isLoading}
        size="large"
        variant="extended"
        color="secondary"
        sx={{ textTransform: 'none' }}
        onClick={onClick}
      >
        {children}
      </Fab>
    </StyledRoot>
  );
};
