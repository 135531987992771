import type { MouseEvent } from 'react';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useUpdateEffect } from 'react-use';
import { FormControl } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import type { City } from '@/api/domains/cities.types';
import type { CityDistrict } from '@/api/domains/city-districts.types';
import type { Info } from '@/api/domains/info.types';
import type { Region } from '@/api/domains/regions.types';
import {
  CustomFormLabel,
  RHFAutocomplete,
  RHFIntegerTextField,
  RHFMaskedPhoneTextField,
  RHFTextField,
} from '@/components/form-controls';
import { RHFAutocompleteStyle, StyledSkeleton } from '@/pages/auth/components/styles';
import { formatOptionsText } from '@/pages/auth/sign-up/forms/PharmacyForm/helpers/form';
import { blurCurrentInput } from '@/pages/auth/sign-up/helpers/inputFields';
import { useSignupData } from '@/pages/auth/sign-up/hooks/useSignupData';
import { useAutocompleteModalStoreActions } from '@/pages/auth/sign-up/store/autocompleteModal.store';
import { useSignUpStoreCompanyData } from '@/pages/auth/sign-up/store/signUp.store';
import type { Address } from '@/pages/auth/sign-up/store/signup.types';
import { SETTLEMENT_TYPE_CITY } from '@/shared/constants/settlement-type.constants';
import { useResponsive } from '@/shared/lib/mui';

import type { AutocompleteOption } from '../types';

// ----------------------------------------------------------------------

export type AddressFormFields = {
  apartment?: string;
  building?: string;
  canSelectCity: boolean;
  canSelectCityDistrict: boolean;
  city: City | null;
  cityDistrict: CityDistrict | null;
  house: string;
  phoneNumber: string;
  postalCode: string;
  region: Region | null;
  street: string;
  streetType: Info | null;
};

export const getDefaultAddressFormValues = (
  shouldPrefillForm: boolean,
  storedAddress: Partial<Address>,
  userPhone: string,
) => {
  const storedPhoneNumber = storedAddress.phoneNumber ? String(storedAddress.phoneNumber) : '';

  return {
    canSelectCity: !!(shouldPrefillForm && storedAddress.city),
    canSelectCityDistrict: !!(shouldPrefillForm && storedAddress.cityDistrict),
    street: shouldPrefillForm ? storedAddress.street : '',
    house: shouldPrefillForm ? storedAddress.house : '',
    apartment: storedAddress.apartment,
    building: storedAddress.building,
    postalCode: shouldPrefillForm ? storedAddress.postalCode : '',
    phoneNumber: storedPhoneNumber || userPhone,
    streetType: shouldPrefillForm ? storedAddress.streetType : null,
    region: shouldPrefillForm ? storedAddress.region : null,
    city: shouldPrefillForm ? storedAddress.city : null,
    cityDistrict: shouldPrefillForm ? storedAddress.cityDistrict : null,
  };
};

// ----------------------------------------------------------------------

export const AddressForm = () => {
  const isMobile = useResponsive('down', 'tablet');

  const storedCompanyData = useSignUpStoreCompanyData();
  const shouldPrefillForm = !!storedCompanyData;
  const { openAutocompleteModal, setSelectedFieldName, setSelectedOptionTitle, setSelectedOptions } =
    useAutocompleteModalStoreActions();

  const methods = useFormContext<AddressFormFields>();

  const {
    watch,
    setValue,
    clearErrors,
    formState: { isDirty, defaultValues },
  } = methods;

  const shouldSkipLoadingState = shouldPrefillForm && !isDirty;

  // ----------------------------------------------------------------------
  // TRACK MUTUALLY DEPENDED FIELDS

  const [region, city] = watch(['region', 'city']);

  const canHaveCityDistricts = city?.settlement_type?.alias === SETTLEMENT_TYPE_CITY;
  const {
    isFetchingRegions,
    isFetchingCities,
    isFetchingCityDistricts,
    isFetchingStreetType,
    regions,
    cities,
    cityDistricts,
    streetTypes,
  } = useSignupData({
    canHaveCityDistricts,
    selectedRegion: region,
    selectedCity: city,
  });

  useUpdateEffect(() => {
    if (isDirty) {
      setValue('city', null);
      setValue('cityDistrict', null);
      clearErrors(['cityDistrict']);
    }
  }, [region]);

  useUpdateEffect(() => {
    if (isDirty && (!city || city?.id !== defaultValues?.city?.id)) {
      setValue('cityDistrict', null);
      clearErrors(['cityDistrict']);
    }
  }, [city]);

  useEffect(() => {
    if (streetTypes.length > 0) {
      setValue('streetType', streetTypes[0]);
    }
    /* eslint-disable-next-line */
  }, [streetTypes]);

  // ----------------------------------------------------------------------
  // CITY FIELD

  const canSelectCity = !!region;
  const shouldFillCity = cities?.length === 1;

  useUpdateEffect(() => {
    setValue('canSelectCity', canSelectCity);
    clearErrors(['city']);
  }, [canSelectCity]);

  useUpdateEffect(() => {
    if (!!region && !city && shouldFillCity) {
      const [city] = cities;

      setValue('city', city, {
        shouldValidate: true,
      });
    }
  }, [region, city, shouldFillCity]);

  // ----------------------------------------------------------------------
  // CITY DISTRICT FIELD

  const canSelectCityDistrict =
    (shouldPrefillForm && !isDirty && !!storedCompanyData.cityDistrict) ||
    (canHaveCityDistricts && cityDistricts.length > 0);

  const isVisibleCityDistrictSkeleton = !shouldSkipLoadingState && isFetchingCityDistricts;
  const isVisibleCityDistrictAutocomplete = shouldSkipLoadingState || !isFetchingCityDistricts;

  useUpdateEffect(() => {
    setValue('canSelectCityDistrict', canSelectCityDistrict);
  }, [canSelectCityDistrict]);

  // ----------------------------------------------------------------------
  // AUTOCOMPLETE MODAL
  const handleDropdownClick = ({ title, fieldName, options }: AutocompleteOption<AddressFormFields>) => {
    if (isMobile) {
      blurCurrentInput();

      openAutocompleteModal();
      setSelectedOptionTitle(title);
      setSelectedOptions(options);
      setSelectedFieldName(fieldName);
    }
  };

  const fieldInfoForModal: Record<string, AutocompleteOption<AddressFormFields>> = {
    region: {
      title: 'Область',
      fieldName: 'region',
      options: regions,
    },
    city: {
      title: 'Город',
      fieldName: 'city',
      options: cities,
    },
    cityDistrict: {
      title: 'Район',
      fieldName: 'cityDistrict',
      options: cityDistricts,
    },
    streetType: {
      title: 'Тип улицы',
      fieldName: 'streetType',
      options: streetTypes,
    },
  };

  // ----------------------------------------------------------------------

  return (
    <Grid container rowSpacing={3} columnSpacing={2}>
      <Grid mobile={12} tablet={4}>
        {/* REGION */}
        <FormControl fullWidth>
          <CustomFormLabel isRequired htmlFor="region">
            Область
          </CustomFormLabel>
          {!shouldSkipLoadingState && isFetchingRegions && <StyledSkeleton variant="text" />}
          {(shouldSkipLoadingState || !isFetchingRegions) && (
            <RHFAutocomplete
              fullWidth
              name="region"
              id="region"
              loading={isFetchingRegions}
              loadingText="Загрузка..."
              noOptionsText="Нет данных"
              options={regions}
              getOptionLabel={option => (typeof option === 'string' ? option : option.name)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              TextFieldProps={{ variant: 'filled' }}
              onFocus={() => {
                handleDropdownClick(fieldInfoForModal.region);
              }}
              sx={RHFAutocompleteStyle}
              blurOnSelect
              componentsProps={
                isMobile
                  ? {
                      popupIndicator: {
                        onClick: (event: MouseEvent<HTMLElement>) => {
                          event.preventDefault();
                        },
                      },
                      popper: {
                        sx: {
                          display: 'none',
                        },
                      },
                    }
                  : {}
              }
              disableClearable={isMobile}
            />
          )}
        </FormControl>
      </Grid>
      <Grid mobile={12} tablet={4}>
        {/* CITY */}
        <FormControl fullWidth>
          <CustomFormLabel isRequired htmlFor="city">
            Населенный пункт
          </CustomFormLabel>
          {!shouldSkipLoadingState && isFetchingCities && <StyledSkeleton variant="text" />}
          {(shouldSkipLoadingState || !isFetchingCities) && (
            <RHFAutocomplete
              fullWidth
              disableClearable={shouldFillCity || isMobile}
              name="city"
              id="city"
              loading={isFetchingCities}
              options={cities}
              disabled={!canSelectCity}
              getOptionLabel={option =>
                typeof option === 'string' ? option : `${option.settlement_type?.name} ${option.full_name}`
              }
              isOptionEqualToValue={(option, value) => option.id === value.id}
              TextFieldProps={{ variant: 'filled' }}
              hasSingleLineOptionsText
              onFocus={() => {
                handleDropdownClick(fieldInfoForModal.city);
              }}
              sx={RHFAutocompleteStyle}
              blurOnSelect
              componentsProps={
                isMobile
                  ? {
                      popupIndicator: {
                        onClick: (event: MouseEvent<HTMLElement>) => {
                          event.preventDefault();
                        },
                      },
                      popper: {
                        sx: {
                          display: 'none',
                        },
                      },
                    }
                  : {}
              }
            />
          )}
        </FormControl>
      </Grid>
      <Grid mobile={12} tablet={4}>
        {/* CITY DISTRICT */}
        <FormControl fullWidth>
          <CustomFormLabel isRequired={canSelectCityDistrict} htmlFor="cityDistrict">
            Район города
          </CustomFormLabel>
          {isVisibleCityDistrictSkeleton && <StyledSkeleton variant="text" />}
          {isVisibleCityDistrictAutocomplete && (
            <RHFAutocomplete
              fullWidth
              name="cityDistrict"
              id="cityDistrict"
              loading={isFetchingCityDistricts}
              loadingText="Загрузка..."
              noOptionsText="Нет данных"
              options={cityDistricts}
              disabled={!canSelectCityDistrict}
              getOptionLabel={option => (typeof option === 'string' ? option : option.name)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              TextFieldProps={{ variant: 'filled' }}
              onFocus={() => {
                handleDropdownClick(fieldInfoForModal.cityDistrict);
              }}
              sx={RHFAutocompleteStyle}
              blurOnSelect
              componentsProps={
                isMobile
                  ? {
                      popupIndicator: {
                        onClick: (event: MouseEvent<HTMLElement>) => {
                          event.preventDefault();
                        },
                      },
                      popper: {
                        sx: {
                          display: 'none',
                        },
                      },
                    }
                  : {}
              }
              disableClearable={isMobile}
            />
          )}
        </FormControl>
      </Grid>

      <Grid mobile={5} tablet={2}>
        {/* STREET TYPE */}
        <FormControl fullWidth>
          <CustomFormLabel isRequired htmlFor="streetType">
            Тип улицы
          </CustomFormLabel>
          <RHFAutocomplete
            fullWidth
            name="streetType"
            id="streetType"
            loading={isFetchingStreetType}
            loadingText="Загрузка..."
            noOptionsText="Нет данных"
            options={streetTypes}
            TextFieldProps={{ variant: 'filled' }}
            getOptionLabel={option => (typeof option === 'string' ? option : option.name)}
            renderOption={(props, option) => <li {...props}>{formatOptionsText(option)}</li>}
            hasSingleLineOptionsText
            onFocus={() => {
              handleDropdownClick(fieldInfoForModal.streetType);
            }}
            sx={RHFAutocompleteStyle}
            blurOnSelect
            componentsProps={
              isMobile
                ? {
                    popupIndicator: {
                      onClick: (event: MouseEvent<HTMLElement>) => {
                        event.preventDefault();
                      },
                    },
                    popper: {
                      sx: {
                        display: 'none',
                      },
                    },
                  }
                : {}
            }
            disableClearable={isMobile}
          />
        </FormControl>
      </Grid>
      <Grid mobile={12} tablet={8}>
        {/* STREET */}
        <FormControl fullWidth>
          <CustomFormLabel isRequired htmlFor="street">
            Улица
          </CustomFormLabel>
          <RHFTextField fullWidth name="street" id="street" variant="filled" />
        </FormControl>
      </Grid>
      <Grid mobile={4} tablet={2}>
        {/* HOUSE */}
        <FormControl fullWidth>
          <CustomFormLabel isRequired htmlFor="house">
            Дом
          </CustomFormLabel>
          <RHFTextField fullWidth name="house" id="house" variant="filled" />
        </FormControl>
      </Grid>

      <Grid mobile={4} tablet={2}>
        {/* BUILDING */}
        <FormControl fullWidth>
          <CustomFormLabel htmlFor="building">Корпус</CustomFormLabel>
          <RHFTextField fullWidth name="building" id="building" variant="filled" />
        </FormControl>
      </Grid>
      <Grid mobile={4} tablet={2}>
        {/* APARTMENT/OFFICE */}
        <FormControl fullWidth>
          <CustomFormLabel htmlFor="apartment">Квартира/офис</CustomFormLabel>
          <RHFTextField fullWidth name="apartment" id="apartment" variant="filled" />
        </FormControl>
      </Grid>
      <Grid mobile={12} tablet={4}>
        {/* PHONE NUMBER */}
        <FormControl fullWidth>
          <CustomFormLabel htmlFor="phoneNumber">Номер телефона</CustomFormLabel>
          <RHFMaskedPhoneTextField fullWidth name="phoneNumber" id="phoneNumber" variant="filled" />
        </FormControl>
      </Grid>
      <Grid mobile={12} tablet={4}>
        {/* POSTAL CODE */}
        <FormControl fullWidth>
          <CustomFormLabel htmlFor="postalCode">Почтовый индекс</CustomFormLabel>
          <RHFIntegerTextField fullWidth name="postalCode" id="postalCode" variant="filled" thousandSeparator={false} />
        </FormControl>
      </Grid>
    </Grid>
  );
};
