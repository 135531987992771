import type { TypeDataGridProps } from '@inovua/reactdatagrid-enterprise/types';
import { CircularProgress, styled, SvgIcon, type SvgIconTypeMap } from '@mui/material';
import type { OverridableComponent } from '@mui/material/OverridableComponent';
import { useTheme } from '@mui/material/styles';

import { ReactComponent as SortSVG } from '@/shared/assets/icons/common/sort.svg';
import { ReactComponent as ChevronDownSVG } from '@/shared/assets/icons/minimal/ic_chevron_down.svg';
import { ReactComponent as ChevronRightSVG } from '@/shared/assets/icons/minimal/ic_chevron_right.svg';
import { pxToRem, toImportant } from '@/shared/theme';

import type { DataGridRenderTreeToolProps } from './types';

type SortIndicatorProps = {
  direction: Parameters<Extract<TypeDataGridProps['renderSortTool'], Function>>[0];
};

type StyledToolProps = Pick<DataGridRenderTreeToolProps['domProps'], 'style'>;

const StyledTool = styled(SvgIcon, {
  shouldForwardProp: prop => prop !== 'style',
})<StyledToolProps>(({ theme, style }) => ({
  position: 'relative',
  zIndex: 1,
  marginLeft: toImportant(pxToRem(style.marginLeft + 5)),
  marginRight: '-10px',
  cursor: 'pointer',
  color: theme.palette.grey[600],
})) as OverridableComponent<SvgIconTypeMap<StyledToolProps>>;

const StyledSortIndicator = styled('div', {
  shouldForwardProp: prop => prop !== 'direction',
})<SortIndicatorProps>(({ theme, direction }) => ({
  display: toImportant(direction === null ? 'none' : 'flex'),
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 1,
  paddingRight: theme.spacing(1.5),
  color: theme.palette.neutral[500],
  backgroundColor: theme.palette.common.white,

  '& svg': {
    transform: direction === -1 ? 'rotate(180deg)' : 'none',
  },
}));

export const renderSortTool: TypeDataGridProps['renderSortTool'] = direction => (
  <StyledSortIndicator direction={direction}>
    <SvgIcon component={SortSVG} inheritViewBox fontSize="small" />
  </StyledSortIndicator>
);

export const renderTreeCollapseTool = (props: DataGridRenderTreeToolProps) => (
  <StyledTool component={ChevronRightSVG} inheritViewBox fontSize="medium" {...props.domProps} />
);

export const renderTreeExpandTool = (props: DataGridRenderTreeToolProps) => (
  <StyledTool component={ChevronDownSVG} inheritViewBox fontSize="medium" {...props.domProps} />
);

export const renderTreeLoadingTool = (props: DataGridRenderTreeToolProps) => {
  // You have to not do such bullshit!!!
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const theme = useTheme();

  return <StyledTool component={CircularProgress} {...props.domProps} size={theme.spacing(3)} />;
};
