import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Scrollbar } from '@/components/Scrollbar';
import { NavbarLogo } from '@/layouts/Navbar/NavbarLogo';

const StyledRoot = styled(Stack)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: theme.zIndex.appBar,
  minWidth: theme.dimensions.sidebar.width,
  width: theme.dimensions.sidebar.width,
  height: '100%',
  borderRight: `1px solid ${theme.palette.custom.grey}`,
  backgroundColor: theme.palette.background.paper,

  '.NavbarContentContainer': {
    flexGrow: 1,
    width: '100%',
    position: 'relative',
  },

  '.NavbarInnerContainer': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    padding: theme.spacing(2, 0.5),
  },

  '.NavbarSignOutButtonContainer': {
    padding: theme.spacing(0.5),
  },
})) as typeof Stack;

export type NavbarBaseProps = React.PropsWithChildren<{}>;

export const NavbarBase = (props: NavbarBaseProps) => {
  const { children } = props;

  return (
    <StyledRoot component="nav">
      <NavbarLogo />
      <div className="NavbarContentContainer">
        <Scrollbar className="NavbarInnerContainer">
          <Stack spacing={2}>{children}</Stack>
        </Scrollbar>
      </div>
    </StyledRoot>
  );
};
