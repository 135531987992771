import type { ButtonProps } from '@mui/material';
import { Button } from '@mui/material';

type CountdownButtonProps = ButtonProps & {
  totalSeconds: number;
  minutes: number;
  isExpired: boolean;
};

export const CountdownButton = (props: CountdownButtonProps) => {
  const { totalSeconds, minutes, isExpired, ...restOfProps } = props;

  const shouldShowSeconds = totalSeconds <= 90;

  return (
    <Button fullWidth variant="outlined" color="secondary" disabled={!isExpired} {...restOfProps}>
      {!isExpired && `Отправить код повторно через ${shouldShowSeconds ? `${totalSeconds} с` : `${minutes} мин`}`}
      {isExpired && 'Отправить код повторно'}
    </Button>
  );
};
