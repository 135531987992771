import { Grow, Paper, Popper, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { PopupState } from 'material-ui-popup-state/hooks';
import { bindPopper } from 'material-ui-popup-state/hooks';

// ----------------------------------------------------------------------

export type NavbarSubmenuProps = React.PropsWithChildren<{
  popupState: PopupState;
}>;

// ----------------------------------------------------------------------

const StyledRoot = styled(Popper)(({ theme }) => ({
  zIndex: theme.zIndex.modal + 1,

  '.MuiPaper-root': {
    minWidth: 150,
    padding: theme.spacing(1),
    borderRadius: theme.spacing(2),
  },
})) as unknown as typeof Popper;

const StyledGrow = styled(Grow)({
  transformOrigin: '0 0 0',
}) as typeof Grow;

// ----------------------------------------------------------------------

export const NavbarSubmenu = (props: NavbarSubmenuProps) => {
  const { popupState, children } = props;

  return (
    <StyledRoot transition placement="right-start" {...bindPopper(popupState)}>
      {({ TransitionProps }) => (
        <StyledGrow {...TransitionProps} timeout={250}>
          <Paper component={Stack} elevation={4} spacing={1}>
            {children}
          </Paper>
        </StyledGrow>
      )}
    </StyledRoot>
  );
};
