import { useEffect } from 'react';
import type { Mutate, StateCreator, StoreApi, StoreMutatorIdentifier } from 'zustand';
import { createWithEqualityFn, type UseBoundStoreWithEqualityFn } from 'zustand/traditional';

export type ResetStore = () => void;

export type CreateStoreResult<T, Mos extends Array<[StoreMutatorIdentifier, unknown]> = []> = [
  UseBoundStoreWithEqualityFn<Mutate<StoreApi<T>, Mos>>,
  ResetStore,
];

export type CreateStore = {
  <T, Mos extends Array<[StoreMutatorIdentifier, unknown]> = []>(
    initializer: StateCreator<T, [], Mos>,
  ): CreateStoreResult<T, Mos>;
  <T>(): <Mos extends Array<[StoreMutatorIdentifier, unknown]> = []>(
    initializer: StateCreator<T, [], Mos>,
  ) => CreateStoreResult<T, Mos>;
};

/**
 * {@link https://docs.pmnd.rs/zustand/guides/how-to-reset-state}
 */
export const createStoreWithReset = (<T>(stateCreator: StateCreator<T> | undefined) => {
  if (!stateCreator) {
    return createStoreWithReset;
  }

  const store = createWithEqualityFn(stateCreator);
  const initialState = store.getState();

  const resetState = () => {
    // @ts-expect-error - We are resetting the state to its initial value
    store.setState(initialState, true, 'resetState');
  };

  return [store, resetState];
}) as CreateStore;

export const createResetStoreOnUnmountHook = (resetStore: ResetStore) => {
  return () => useEffect(() => resetStore, []);
};
