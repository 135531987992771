import { SvgIcon, type CheckboxProps } from '@mui/material';
import type { Theme } from '@mui/material/styles';

import { ReactComponent as CheckBoxOutlineBlankSVG } from '../../assets/icons-2.0/check_box_outline_blank.svg';
import { ReactComponent as CheckBoxSVG } from '../../assets/icons-2.0/check_box.svg';
import { ReactComponent as IndeterminateCheckBoxSVG } from '../../assets/icons-2.0/indeterminate_check_box.svg';

export default function Checkbox(theme: Theme) {
  return {
    MuiCheckbox: {
      defaultProps: {
        icon: <SvgIcon component={CheckBoxOutlineBlankSVG} inheritViewBox />,
        checkedIcon: <SvgIcon component={CheckBoxSVG} inheritViewBox />,
        indeterminateIcon: <SvgIcon component={IndeterminateCheckBoxSVG} inheritViewBox />,
      } satisfies CheckboxProps,

      styleOverrides: {
        root: {
          padding: theme.spacing(1),
          '.MuiCheckbox-sizeSmall': {
            '& svg': { width: 20, height: 20 },
          },
          '.MuiCheckbox-sizeMedium': {
            '& svg': { width: 24, height: 24 },
          },
        },
      },
    },
  };
}
