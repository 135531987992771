import type { MouseEvent } from 'react';
import { FormControl } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import type { LegalStatus } from '@/api/domains/contractors.types';
import { CustomFormLabel, RHFAutocomplete, RHFIntegerTextField, RHFTextField } from '@/components/form-controls';
import { RHFAutocompleteStyle } from '@/pages/auth/components/styles';
import { companyLegalStatuses } from '@/pages/auth/sign-up/constants/registration.constants';
import type { AutocompleteOption } from '@/pages/auth/sign-up/forms/types';
import { blurCurrentInput } from '@/pages/auth/sign-up/helpers/inputFields';
import { useAutocompleteModalStoreActions } from '@/pages/auth/sign-up/store/autocompleteModal.store';
import type { SignUpCompany } from '@/pages/auth/sign-up/store/signUp.store';
import { useResponsive } from '@/shared/lib/mui';

// ----------------------------------------------------------------------

export type CompanyFormFields = {
  legalStatus: LegalStatus | null;
  companyName: string;
  tin: number | null;
};

export const getDefaultCompanyFormValues = (shouldPrefillForm: boolean, storedCompanyData: Partial<SignUpCompany>) => ({
  legalStatus: shouldPrefillForm ? storedCompanyData.legalStatus : null,
  companyName: shouldPrefillForm ? storedCompanyData.companyName : '',
  tin: shouldPrefillForm ? storedCompanyData.tin : null,
});

// ----------------------------------------------------------------------

export const CompanyInfoForm = () => {
  const isMobile = useResponsive('down', 'tablet');
  const { openAutocompleteModal, setSelectedFieldName, setSelectedOptionTitle, setSelectedOptions } =
    useAutocompleteModalStoreActions();

  const handleDropdownClick = ({ title, fieldName, options }: AutocompleteOption<CompanyFormFields>) => {
    if (isMobile) {
      blurCurrentInput();

      openAutocompleteModal();
      setSelectedOptionTitle(title);
      setSelectedOptions(options);
      setSelectedFieldName(fieldName);
    }
  };

  const fieldInfoForModal: Record<string, AutocompleteOption<CompanyFormFields>> = {
    legalStatus: {
      title: 'Форма собственности',
      fieldName: 'legalStatus',
      options: companyLegalStatuses,
    },
  };

  return (
    <Grid container rowSpacing={3} columnSpacing={2}>
      <Grid mobile={12} tablet={4}>
        {/* LEGAL STATUS */}
        <FormControl fullWidth>
          <CustomFormLabel isRequired htmlFor="legalStatus">
            Форма собственности
          </CustomFormLabel>
          <RHFAutocomplete
            fullWidth
            name="legalStatus"
            id="legalStatus"
            options={companyLegalStatuses}
            getOptionLabel={option => (typeof option === 'string' ? option : option.name)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            TextFieldProps={{ variant: 'filled' }}
            onFocus={() => {
              handleDropdownClick(fieldInfoForModal.legalStatus);
            }}
            hasSingleLineOptionsText
            blurOnSelect
            sx={RHFAutocompleteStyle}
            disableClearable={isMobile}
            componentsProps={
              isMobile
                ? {
                    popupIndicator: {
                      onClick: (event: MouseEvent<HTMLElement>) => {
                        event.preventDefault();
                      },
                    },
                    popper: {
                      sx: {
                        display: 'none',
                      },
                    },
                  }
                : {}
            }
          />
        </FormControl>
        {/* LEGAL STATUS END */}
      </Grid>

      <Grid mobile={12} tablet={8}>
        {/* NAME */}
        <FormControl fullWidth>
          <CustomFormLabel isRequired htmlFor="companyName">
            Название
          </CustomFormLabel>
          <RHFTextField fullWidth name="companyName" id="companyName" variant="filled" />
        </FormControl>
        {/* NAME END */}
      </Grid>

      <Grid container mobile={12} tablet={12}>
        <Grid mobile={12} tablet={4}>
          {/* TIN */}
          <FormControl fullWidth>
            <CustomFormLabel isRequired htmlFor="tin">
              ИНН
            </CustomFormLabel>
            <RHFIntegerTextField fullWidth name="tin" id="tin" variant="filled" />
          </FormControl>
          {/* TIN END */}
        </Grid>
      </Grid>
    </Grid>
  );
};
