import { joinPath } from '../lib';

const ROOT_PATH = '/profile';
const PERSONAL_INFO_PATH = joinPath(ROOT_PATH, 'personal-info');
const ENTITY_INFO_PATH = joinPath(ROOT_PATH, 'entity-info');

export const ProfilePaths = {
  ROOT_PATH,
  PERSONAL_INFO_PATH,
  ENTITY_INFO_PATH,
} as const;
