import { forwardRef, useState } from 'react';
import type { NumericFormatProps, OnValueChange } from 'react-number-format';
import { useUpdateEffect } from 'react-use';

import type { CloudCartItem } from '@/api/domains/cloud-carts.types';
import { useCloudCartEntryPackagesCount, useUpdateCloudCartEntry } from '@/entities/cloud-carts';

import { CloudCartCounterBase, type CartCounterBaseProps } from './CloudCartCounterBase';

export type CloudCartCounterProps = Omit<CartCounterBaseProps, 'value' | 'disabled' | 'onIncrease' | 'onDecrease'> & {
  drugId: CloudCartItem['drug_id'];
  offerId: CloudCartItem['offer_id'];
  entrySource?: CloudCartItem['source'];
};

export const CloudCartCounter = forwardRef<HTMLDivElement, CloudCartCounterProps>((props, ref) => {
  const { drugId, offerId, entrySource, onBlur, ...restProps } = props;

  const count = useCloudCartEntryPackagesCount(offerId);
  const [inputCountValue, setInputCountValue] = useState<number | string>(count);

  const { updateCloudCartEntry } = useUpdateCloudCartEntry({
    drugId,
    offerId,
    entrySource,
  });

  useUpdateEffect(() => {
    setInputCountValue(count);
  }, [count]);

  const increaseCount = () => {
    updateCloudCartEntry(count + 1);
    setInputCountValue(count + 1);
  };

  const decreaseCount = () => {
    updateCloudCartEntry(count - 1);
    setInputCountValue(count - 1);
  };

  const handleValueChange: OnValueChange = ({ floatValue: count, value: countValue }) => {
    updateCloudCartEntry(count ?? 0);
    setInputCountValue(countValue);
  };

  const handleBlur: NumericFormatProps['onBlur'] = event => {
    onBlur?.(event);
    setInputCountValue(count);
  };

  const handleKeyDown: NumericFormatProps['onKeyDown'] = event => {
    switch (event.key) {
      case 'ArrowUp':
        return increaseCount();
      case 'ArrowDown':
        return decreaseCount();
      default:
        return;
    }
  };

  return (
    <CloudCartCounterBase
      ref={ref}
      value={inputCountValue}
      onKeyDown={handleKeyDown}
      onIncrease={increaseCount}
      onDecrease={decreaseCount}
      onBlur={handleBlur}
      onValueChange={handleValueChange}
      {...restProps}
    />
  );
});
