import { Stack, Typography } from '@mui/material';

type EntityInfoItemProps = React.PropsWithChildren<{
  title: React.ReactNode;
}>;

export const EntityInfoItem = (props: EntityInfoItemProps) => {
  const { title, children } = props;

  return (
    <Stack spacing={2} width={160}>
      <Typography component="div" variant="Body/Bold/large">
        {title}
      </Typography>
      <Stack spacing={3}>{children}</Stack>
    </Stack>
  );
};
