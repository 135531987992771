import { Stack, Typography } from '@mui/material';

type EntityInfoItemContentProps = {
  title: React.ReactNode;
  description: React.ReactNode;
};

export const EntityInfoItemContent = (props: EntityInfoItemContentProps) => {
  const { title, description } = props;

  return (
    <Stack spacing={1}>
      <Typography component="div" variant="Body/small" color="grey.500">
        {title}
      </Typography>
      <Typography component="div" variant="Body/medium">
        {description}
      </Typography>
    </Stack>
  );
};
