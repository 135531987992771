import { StyledIntroText, StyledLogotype, StyledRoot } from './styles';

export const Welcome = () => (
  <StyledRoot>
    <StyledLogotype />
    <StyledIntroText>
      Удобная и бесплатная связь <br /> между аптекой и дистрибьютором
    </StyledIntroText>
  </StyledRoot>
);
