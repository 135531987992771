import { useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';

import { DorimPromoApi, type FetchSpecificationParams, type Specification } from '@/api/domains/dorim-promo.api';
import { DorimPromoQueryKeys } from '@/api/domains/dorim-promo.query-keys';

import { addZeroPlanToDrug } from '../helpers/cashback.helpers';

export const useSpecification = (params?: FetchSpecificationParams) =>
  useQuery({
    queryKey: DorimPromoQueryKeys.getSpecificationKey(params),
    queryFn: () => {
      if (!params) {
        return;
      }

      return DorimPromoApi.fetchSpecification(params);
    },
    select: useCallback((data: Specification | undefined) => {
      if (!data) {
        return;
      }

      return {
        ...data,
        marketing_org_details: data.marketing_org_details.map(marketingOrg => ({
          ...marketingOrg,
          cashback_drugs: marketingOrg.cashback_drugs.map(addZeroPlanToDrug),
        })),
      };
    }, []),
    enabled: !!params && !!params.specificationId,
    staleTime: 1000 * 60 * 5,
  });
