import { useParams } from 'react-router-dom';
import { Stack, styled } from '@mui/material';

import { useSpecification } from '@/shared/hooks/useSpecification';
import type { SpecificationDetailPathParams } from '@/shared/lib/react-router';

import { getActionBarState, hasActionBar } from '../../lib';
import { AcceptPaymentButton, CalculationErrorReportButton, ReceivePaymentButton } from './ui';

const StyledActionBar = styled(Stack)(({ theme }) => ({
  position: 'sticky',
  bottom: 0,
  zIndex: theme.zIndex.appBar,
  width: '100%',
  height: theme.dimensions.summaryBar.height,
  padding: theme.spacing(0, 3),
  alignItems: 'center',
  borderRadius: 0,
  border: 'none',
  borderTop: theme.shape.divider,
  backgroundColor: theme.palette.background.paper,
})) as typeof Stack;

export const ActionBar = () => {
  const params = useParams<SpecificationDetailPathParams>();
  const { data: specification } = useSpecification({ specificationId: Number(params.specificationId) });

  if (!specification?.agreement) {
    return null;
  }

  const actionBarState = getActionBarState(specification.agreement);
  if (!hasActionBar(actionBarState)) {
    return null;
  }

  const { isCashbackCalculated, isCashbackPaymentCompleted, isCashbackCanceled } = actionBarState;
  const shouldRenderErrorReportButton = isCashbackCalculated || isCashbackCanceled;

  return (
    <StyledActionBar direction="row" spacing={1} justifyContent="end">
      {shouldRenderErrorReportButton && <CalculationErrorReportButton />}
      {isCashbackCalculated && <ReceivePaymentButton />}
      {isCashbackPaymentCompleted && <AcceptPaymentButton />}
    </StyledActionBar>
  );
};
