import { Button } from '@mui/material';

import { StyledContainer, StyledDescription, StyledDoneImage, StyledInfoContainer, StyledTitle } from './styles';

type SuccessActionProps = {
  title: string;
  description: string;
  actionButtonTitle: string;
  onActionButtonClick: () => void;
};

export const SuccessAction = ({ title, onActionButtonClick, description, actionButtonTitle }: SuccessActionProps) => (
  <StyledContainer>
    <StyledInfoContainer>
      <StyledDoneImage />
      <StyledTitle>{title}</StyledTitle>
      <StyledDescription>{description}</StyledDescription>
    </StyledInfoContainer>
    <Button variant="contained" color="secondary" fullWidth onClick={onActionButtonClick}>
      {actionButtonTitle}
    </Button>
  </StyledContainer>
);
