import type {
  Auth,
  ResetPasswordFormData,
  ResetPasswordResponseBody,
  SendOTPFormData,
  SendOTPResponseBody,
  SignUpFormData,
  VerifyOTPFormData,
  VerifyOTPResponseBody,
} from '@/api/domains/auth.types';
import { httpClient } from '@/shared/lib/axios';

// ----------------------------------------------------------------------

export type SignInFormData = {
  phone: string;
  password: string;
};

// ----------------------------------------------------------------------

export const AuthApi = {
  signIn: async (formData: SignInFormData) => {
    const { data } = await httpClient.post<Auth>('auth/sign-in', formData, {
      shouldRefreshTokenIfNeeded: false,
      shouldInjectAccessToken: false,
    });

    return data;
  },

  // ----------------------------------------------------------------------

  refreshToken: async (refreshToken: string) => {
    const { data } = await httpClient.post<Auth>(
      'auth/refresh',
      { refresh_token: refreshToken },
      {
        shouldRefreshTokenIfNeeded: false,
        shouldInjectAccessToken: false,
      },
    );

    return data;
  },

  // ----------------------------------------------------------------------

  signOut: async (refreshToken: string) => {
    await httpClient.post(
      'auth/logout',
      { refresh_token: refreshToken },
      {
        shouldRefreshTokenIfNeeded: false,
        shouldInjectAccessToken: false,
      },
    );
  },

  // ----------------------------------------------------------------------

  signUpSendOTP: async ({ phone }: SendOTPFormData) => {
    const { data } = await httpClient.post<SendOTPResponseBody>(
      'auth/sign-up/send-otp',
      { phone },
      {
        shouldRefreshTokenIfNeeded: false,
        shouldInjectAccessToken: false,
      },
    );
    return data;
  },

  // ----------------------------------------------------------------------

  signUpVerifyOTP: async ({ phone, otp_code }: VerifyOTPFormData) => {
    const { data } = await httpClient.post<VerifyOTPResponseBody>(
      'auth/sign-up/verify-otp',
      { phone, otp_code },
      {
        shouldRefreshTokenIfNeeded: false,
        shouldInjectAccessToken: false,
      },
    );
    return data;
  },
  // ----------------------------------------------------------------------

  signUpCheckIpLimits: async () => {
    await httpClient.post(
      'auth/sign-up/check-ip-limits',
      {},
      {
        shouldRefreshTokenIfNeeded: false,
        shouldInjectAccessToken: false,
      },
    );
  },

  signUp: async (formData: SignUpFormData) => {
    const { data } = await httpClient.post<Auth>('auth/sign-up', formData, {
      shouldRefreshTokenIfNeeded: false,
      shouldInjectAccessToken: false,
    });

    return data;
  },

  // ----------------------------------------------------------------------

  resetPasswordSendOTP: async ({ phone }: SendOTPFormData) => {
    const { data } = await httpClient.post<SendOTPResponseBody>(
      'auth/reset-password/send-otp',
      { phone },
      {
        shouldRefreshTokenIfNeeded: false,
        shouldInjectAccessToken: false,
      },
    );
    return data;
  },

  // ----------------------------------------------------------------------

  resetPasswordVerifyOTP: async ({ phone, otp_code }: VerifyOTPFormData) => {
    const { data } = await httpClient.post<VerifyOTPResponseBody>(
      'auth/reset-password/verify-otp',
      { phone, otp_code },
      {
        shouldRefreshTokenIfNeeded: false,
        shouldInjectAccessToken: false,
      },
    );
    return data;
  },
  // ----------------------------------------------------------------------

  resetPassword: async ({ password, signup_token }: ResetPasswordFormData) => {
    const { data } = await httpClient.post<ResetPasswordResponseBody>(
      'auth/reset-password',
      { password, signup_token },
      {
        shouldRefreshTokenIfNeeded: false,
        shouldInjectAccessToken: false,
      },
    );
    return data;
  },
  // ----------------------------------------------------------------------

  resetPasswordCheckIpLimits: async () => {
    await httpClient.post(
      'auth/reset-password/check-ip-limits',
      {},
      {
        shouldRefreshTokenIfNeeded: false,
        shouldInjectAccessToken: false,
      },
    );
  },
  // ----------------------------------------------------------------------
};
