import { useCallback } from 'react';
import { filter, isEmpty, orderBy, size } from 'lodash';
import { createSelector } from 'reselect';

import type { CloudCartEntrySource } from '@/api/domains/cloud-carts.constants';
import type { CloudCartItem } from '@/api/domains/cloud-carts.types';

import { useCurrentCloudCart } from './cloud-carts';
import { composeCloudCartSummery } from './cloud-carts.helpers';
import type { CloudCartEntryCounts, TransformedCloudCart } from './cloud-carts.types';

// ----------------------------------------------------------------------

const selectRawCloudCart = (cloudCart: TransformedCloudCart) => cloudCart.rawCloudCart;

const selectCloudCartEntries = (cloudCart: TransformedCloudCart) => cloudCart.entries;

const selectCloudCartEntry = (
  cloudCart: TransformedCloudCart,
  offerId: CloudCartItem['offer_id'],
): CloudCartItem | undefined => cloudCart.entries[offerId];

const selectCloudCartEntryCounts = (
  cloudCart: TransformedCloudCart,
  offerId: CloudCartItem['offer_id'],
): CloudCartEntryCounts | undefined => cloudCart.entriesPackagesCounts[offerId];

const selectCloudCartDrugPackagesCount = (cloudCart: TransformedCloudCart, drugId: CloudCartItem['drug_id']): number =>
  cloudCart.drugsPackagesCount[drugId]?.packagesCount ?? 0;

// ----------------------------------------------------------------------

const selectCloudCartSummery = createSelector([selectRawCloudCart], composeCloudCartSummery);

const selectCloudCartEntriesOptimized = createSelector([selectCloudCartEntries], entries => entries, {
  memoizeOptions: {
    resultEqualityCheck: (previousResult, nextResult) => size(previousResult) === size(nextResult),
  },
  devModeChecks: { identityFunctionCheck: 'never' },
});

const selectCloudCartEntryOptimized = createSelector(
  [
    (cloudCart: TransformedCloudCart, offerId: CloudCartItem['offer_id']) =>
      selectCloudCartEntry(cloudCart, offerId)?.drug,
    (cloudCart: TransformedCloudCart, offerId: CloudCartItem['offer_id']) => {
      const entry = selectCloudCartEntry(cloudCart, offerId);
      const offerInfo = entry?.offer;
      const cartOfferInfo = entry?.cart_offer;

      if (!entry || (!offerInfo && !cartOfferInfo)) return;

      if (offerInfo) {
        return {
          offer: offerInfo.offer,
          distributor: offerInfo.distributor,
          specialOfferBadges: offerInfo.share_badges,
          hasSpecialOffer: !!offerInfo.share && offerInfo.share_badges.length > 0,
        };
      } else if (cartOfferInfo) {
        return {
          offer: cartOfferInfo,
          distributor: entry.distributor,
          specialOfferBadges: cartOfferInfo.share_badges,
          hasSpecialOffer: !!cartOfferInfo.share && cartOfferInfo.share_badges.length > 0,
        };
      }
    },
    (cloudCart: TransformedCloudCart, offerId: CloudCartItem['offer_id']) =>
      selectCloudCartEntry(cloudCart, offerId)?.source,
  ],
  (drug, offerInfo, entrySource) => {
    if (!drug || !offerInfo || !entrySource) {
      return;
    }

    return {
      drug,
      offerInfo,
      entrySource,
    };
  },
);

const selectCloudCartEntrySpecialOfferOptimized = createSelector(
  [
    (cloudCart: TransformedCloudCart, offerId: CloudCartItem['offer_id']) =>
      selectCloudCartEntry(cloudCart, offerId)?.offer?.share,
  ],
  specialOffer => specialOffer,
  {
    devModeChecks: { identityFunctionCheck: 'never' },
  },
);

const selectCloudCartEntryTotalPriceWithVat = createSelector(
  [selectCloudCartEntry],
  entry => entry?.total_price_with_vat ?? 0,
);

const selectIsCloudCartEmpty = createSelector([selectCloudCartEntriesOptimized], isEmpty);

const selectCloudCartEntriesByEntrySource = createSelector(
  [selectCloudCartEntriesOptimized, (_: TransformedCloudCart, entrySource: CloudCartEntrySource | null) => entrySource],
  (entries, entrySource) => {
    const sortedEntries = orderBy(entries, entry => entry.drug?.name);

    if (!entrySource) {
      return sortedEntries;
    }

    return filter(sortedEntries, entry => entry.source === entrySource);
  },
);

const selectCloudCartEntryPackagesCount = createSelector(
  [selectCloudCartEntryCounts],
  entryCounts => entryCounts?.packagesCount ?? 0,
);

const selectCloudCartGiftPackagesCount = createSelector(
  [selectCloudCartEntryCounts],
  entryCounts => entryCounts?.giftPackagesCount ?? 0,
);

// ----------------------------------------------------------------------

export const useCloudCartSummery = () =>
  useCurrentCloudCart({
    select: selectCloudCartSummery,
  });

export const useIsCloudCartEmpty = () =>
  useCurrentCloudCart({
    select: selectIsCloudCartEmpty,
  });

export const useCloudCartEntriesByEntrySource = (entrySource: CloudCartEntrySource | null) =>
  useCurrentCloudCart({
    select: useCallback(
      (cloudCart: TransformedCloudCart) => selectCloudCartEntriesByEntrySource(cloudCart, entrySource),
      [entrySource],
    ),
  });

export const useCloudCartEntry = (offerId?: CloudCartItem['offer_id']) =>
  useCurrentCloudCart({
    enabled: !!offerId,
    select: useCallback(
      (cloudCart: TransformedCloudCart) => {
        if (!offerId) {
          return;
        }

        return selectCloudCartEntryOptimized(cloudCart, offerId);
      },
      [offerId],
    ),
  });

export const useCloudCartEntrySpecialOffer = (offerId?: CloudCartItem['offer_id']) =>
  useCurrentCloudCart({
    enabled: !!offerId,
    select: useCallback(
      (cloudCart: TransformedCloudCart) => {
        if (!offerId) {
          return;
        }

        return selectCloudCartEntrySpecialOfferOptimized(cloudCart, offerId);
      },
      [offerId],
    ),
  });

export const useCloudCartEntryTotalPriceWithVat = (offerId?: CloudCartItem['offer_id']) => {
  const { data = 0 } = useCurrentCloudCart({
    enabled: !!offerId,
    select: useCallback(
      (cloudCart: TransformedCloudCart) => {
        if (!offerId) {
          return 0;
        }

        return selectCloudCartEntryTotalPriceWithVat(cloudCart, offerId);
      },
      [offerId],
    ),
  });

  return data;
};

export const useHasCloudCartEntry = (offerId?: CloudCartItem['offer_id']) => {
  const { data: cartEntry } = useCloudCartEntry(offerId);

  return !!cartEntry;
};

export const useCloudCartEntryPackagesCount = (offerId?: CloudCartItem['offer_id']) => {
  const { data = 0 } = useCurrentCloudCart({
    select: useCallback(
      (cloudCart: TransformedCloudCart) => {
        if (!offerId) {
          return 0;
        }

        return selectCloudCartEntryPackagesCount(cloudCart, offerId);
      },
      [offerId],
    ),
  });

  return data;
};

export const useCloudCartGiftPackagesCount = (offerId?: CloudCartItem['offer_id']) => {
  const { data = 0 } = useCurrentCloudCart({
    select: useCallback(
      (cloudCart: TransformedCloudCart) => {
        if (!offerId) {
          return 0;
        }

        return selectCloudCartGiftPackagesCount(cloudCart, offerId);
      },
      [offerId],
    ),
  });

  return data;
};

export const useCloudCartDrugPackagesCount = (drugId?: CloudCartItem['drug_id']) => {
  const { data = 0 } = useCurrentCloudCart({
    enabled: !!drugId,
    select: useCallback(
      (cloudCart: TransformedCloudCart) => {
        if (!drugId) {
          return 0;
        }

        return selectCloudCartDrugPackagesCount(cloudCart, drugId);
      },
      [drugId],
    ),
  });

  return data;
};
