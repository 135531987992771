import { LoadingButton } from '@mui/lab';
import type { CheckboxProps } from '@mui/material';
import { Box, Checkbox, FormControl, FormControlLabel, FormHelperText } from '@mui/material';

import { styles } from './styles';

type Props = {
  isChecked: boolean;
  isLoaded: boolean;
  isNotChecked: boolean;
  onClick: () => void;
  onCheckboxChange: (value: boolean) => void;
};

export const FooterForm = ({ isChecked, isLoaded, isNotChecked, onCheckboxChange, onClick }: Props) => {
  const handleCheckboxChange: CheckboxProps['onChange'] = event => {
    onCheckboxChange(event.target.checked);
  };

  return (
    <Box sx={styles.footerForm}>
      <FormControl>
        <FormControlLabel
          control={<Checkbox checked={isChecked} color="secondary" onChange={handleCheckboxChange} />}
          label="Я прочитал и согласен с вышеуказанными условиями"
        />
        {isNotChecked && (
          <FormHelperText error sx={{ mt: -1, ml: '29px' }}>
            Для продолжения работы необходимо подтвердить согласие с условиями оферты
          </FormHelperText>
        )}
      </FormControl>
      <Box>
        <LoadingButton color="secondary" loading={isLoaded} onClick={onClick} variant="contained">
          Принять
        </LoadingButton>
      </Box>
    </Box>
  );
};
